import React, { useState, useEffect, useRef, useCallback } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import ITCHsCode from "./ITCHsCode";
import csc from "country-state-city";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";
import CertificatePopup from './CertificatePopup';
import AWS from 'aws-sdk';
import S3 from "react-aws-s3";
import axios from "axios";

window.Buffer = window.Buffer || require("buffer").Buffer;


const PTIRecordShipmentItem = () => {
    const certiGridRef = useRef();
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [url, setUrl] = useState("");
    const fileInput = React.useRef();
    const regExpNumber = /^[0-9\b]+$/;
    const [itemParts, setItemParts] = useState([]);
    const expFileInput = useRef(null);
    const wpcFileInput = useRef(null);
    const [hscodes, setHSCodes] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const gridRef = useRef();
    const { globalState } = React.useContext(GlobalContext);
    const [shipId, setShipId] = useState("");
    const [partNo, setPartNo] = useState("");
    const [intOrderNo, setIntOrderNo] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [manuNo, setManuNo] = useState("");
    const [description, setDescription] = useState("");
    const [eccn, setEccn] = useState("");
    const [encCode, setEncCode] = useState("");
    const [itcHsCode, setItcHsCode] = useState("");
    const [itcHsCodeId, setItcHsCodeId] = useState("");
    const [itcSelectedRow, setItcSelectedRow] = useState({});
    const [usCode, setUsCode] = useState("");
    const [htsCode, setHtsCode] = useState("");
    const [coo, setCoo] = useState("");
    const [umo, setUmo] = useState("");
    const [quantity, setQuantity] = useState("");
    const [amount, setAmount] = useState(0);
    const [unitPrice, setUnitPrice] = useState("");
    const [itemCertificate, setItemCertificate] = useState([]);
    const [serialNoDropDown, setSerialNoDropDown] = useState([]);
    const [expLIC1, setExpLIC1] = useState("");
    const [expUrl, setExpUrl] = useState("");
    const [expLIC2, setExpLIC2] = useState("");
    const [wpcETA1, setWpcETA1] = useState("");
    const [wpcETA2, setWpcETA2] = useState("");
    const [wpcUrl, setWpcUrl] = useState("");
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    const [populateEccn, setpopulateEccn] = useState(false);
    const [populateEncCode, setpopulateEncCode] = useState(false);
    const [populateHsCode, setpopulateHsCode] = useState(false);
    const [populateUsCode, setpopulateUsCode] = useState(false);
    const [populateHtsCode, setPopulateHtsCode] = useState(false);
    const [populateQuantity, setPopulateQuantity] = useState(false);
    const [populateDescription, setPopulateDescription] = useState(false);
    const [populateSerialNo, setPopulateSerialNo] = useState(false);
    const [certificateUrl, setCertificateUrl] = useState("");
    const [certificateId, setCertificateId] = useState("");
    const [eccnMessage, setEccnMessage] = useState("");
    const [partMessage, setPartMessage] = useState("");
    const [cooMessage, setCooMessage] = useState("");
    const [umoMessage, setUmoMessage] = useState("");
    const [descMessage, setDescMessage] = useState("");
    const [encMessage, setEncMessage] = useState("");
    const [hsCodeMessage, setHsCodeMessage] = useState("");
    const [htsMessage, setHtsMessage] = useState("");
    const [usCodeMessage, setUsCodeMessage] = useState("");
    const [quantityMessage, setQuantityMessage] = useState("");
    const [unitPriceMessage, setUnitPriceMessage] = useState("");
    const onlyNumber = "Please enter only numeric";
    const numberErrorMessage = "Quantity & Unit price should take numbers only (or) Quantity & Unit price should be numbers only";
    const [isSerialized, setIsSerialized] = useState(false);
    const [serialNoErrorMessage, setSerialNoErrorMessage] = useState(false);
    const [ptiRecord, setPtiRecord] = useState([]);
    const [itemStatus, setItemStatus] = useState(false);
    const [uploadDisable, setUploadDisable] = useState(false);
    const [assess, setAssess] = useState("");
    const [bcd, setBcd] = useState("");
    const [acd, setAcd] = useState("");
    const [sws, setSws] = useState("");
    const [sad, setSad] = useState("");
    const [igst, setIgst] = useState("");
    const [cess, setCess] = useState("");
    const [add, setAdd] = useState("");
    const [cvd, setCvd] = useState("");
    const [sg, setSg] = useState("");
    const [tValue, setTValue] = useState("");
    const [clientList, setClientList] = useState([]);


    // let test=2222222
    // let test2=test.toLocaleString("en-IN")
    // console.log(test2);
    const domLayout = "autoHeight";


    const togglePopup = () => {
        setIsOpen(!isOpen);
        setCertificateUrl("");
        setCertificateId("");
    }

    // const onExpCertificate = () => {
    //     expFileInput.current.click();
    // };

    const onWPCCertificate = () => {
        wpcFileInput.current.click();
    };

    const selectItcHsCode = () => {
        setIsOpen(!isOpen);
        setItcHsCode(itcSelectedRow.codeId);
        setItcHsCodeId(itcSelectedRow.id);
    };

    const handleCertView = (certId, certUrl) => {
        setIsOpen(!isOpen);
        setCertificateUrl(certUrl);
        setCertificateId(certId);
    };

    // clients fetch
    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            //console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
                //console.log(data);
                setClientList(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };


    // Delete with fetchAPI
    const deleteCertificateId = async (certificateId) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result) {
            try {
                const requestURL = globalState.endPointURL + "/certificate/id/" + certificateId;
                const response = await fetch(requestURL, {
                    method: 'DELETE',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                if (responseData !== "" && responseData.isSuccess) {
                    if (responseData.itemCertificate[0].url !== null && responseData.itemCertificate[0].url !== ""
                        && responseData.itemCertificate[0].url !== undefined) {
                        let key = new URL(responseData.itemCertificate[0].url).pathname.substr(1);
                        key = key.replace("%20", " ");
                        const params = { Bucket: globalState.s3BuscketConfig.bucketName, Key: key };
                        const s3 = new AWS.S3(globalState.s3BuscketConfig);
                        s3.deleteObject(params, function (err, data) {
                            if (err) {
                                // console.log(err);
                            } else {
                                // console.log("deleted successfully from s3");
                            }
                        });
                    }
                    setItemCertificate(itemCertificate.filter((certificate) => { return certificate.id !== certificateId; }));
                    globalState.shipmentDetail.certificates = globalState.shipmentDetail?.certificates?.filter((certificate) => { return certificate.id !== certificateId; });
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                console.log(e);
            }
        }

    };

    const actionButton = (event) => {
        return (
            <p>
                <button type="button" onClick={() => handleCertView(event.data?.id, event.data?.url)}
                    className="btn btn-link btn-sm bg-secondary text-light input-flex-button"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Certificate">
                    <i className="bx bxs-show" aria-hidden="true" ></i></button>
                <button type="button" onClick={() => deleteCertificateId(event.data?.id)} className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    // const columnDef = [
    //     { headerName: 'HS Codes', field: "codeId", sortable: true, filter: true ,width:250 },
    //     { headerName: '4 Digit Category', field: "digitCode", sortable: true, filter: true.valueOf, width:250 },
    //     { headerName: 'Product Description', field: "description", sortable: true, filter: true },     
    // ];

    const certiColumnDef = [
        { headerName: 'Certificate Type', field: "certificateTypeId", sort: 'asc', sortable: true, filter: false, width: 300 },
        { headerName: 'Cerificate #', field: "certificateName", sortable: false, filter: true.valueOf, width: 300 },
        { headerName: 'Expiry Date', field: "expiryDate", sortable: false, filter: true, width: 300 },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/hscodes/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const data = await response.json();
           // console.log(data);
            if (data !== null)
                setHSCodes(data.hscodeInfos);
            else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(data.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    useEffect(() => {

        fetchClients()

        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });

        const fetchShipmentItems = async () => {
            try {
                const requestURL = globalState.endPointURL + "/shipment/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log(responseData);
                if (responseData !== "") {
                    responseData.shipmentItem[0].shipmentId = responseData.shipmentItem[0].id;
                    globalState.shipmentDetail = responseData.shipmentItem[0];
                    const data = responseData.shipmentItem[0];
                    mappingData(data);
                } else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
            // console.log(globalState.shipmentDetail);
        };

        if (id !== undefined) {
            if (globalState?.shipmentDetail?.partNo || globalState?.shipmentDetail.certificates.length > 0) {
                const responseData = globalState?.shipmentDetail;
                mappingResponseData(responseData);
            } else {
                fetchShipmentItems();
            }
        } else {
            const responseData = globalState?.shipmentDetail;
            mappingResponseData(responseData);
        }
        const fetchItemParts = async () => {
            try {
                const requestURL = globalState.endPointURL + "/item/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log(responseData);

                if (responseData != null) {
                    setItemParts(responseData?.itemInfo);
                }
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        };
        fetchItemParts();

        globalState.itemPartRedirect = "View";
    }, []);

    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.id, ...state }));
    //const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));

    const mappingData = (data) => {
        if (data.certificates !== null) {
            data.certificates = data.certificates?.filter((item) => {
                if (item !== null) {
                    return item;
                }
            });
        }
        setShipId(id);
        setItemStatus(data.itemStatus);
        setAssess(data.assess);
        setBcd(data.bcd);
        setAcd(data.acd);
        setSws(data.sws);
        setSad(data.sad);
        setIgst(data.igst);
        setCess(data.cess);
        setAdd(data.add);
        setCvd(data.cvd);
        setSg(data.sg);
        setTValue(data.tValue);
        setPartNo(data.item?.partNumber);
        setIntOrderNo(data.item?.internalOrderNumber);
        setSerialNo(data.item?.serialNumber);
        setManuNo(data.item?.manufactureNumber);
        setDescription(data.item?.description);
        setEccn(data.codes?.eccn);
        setEncCode(data.codes?.encCode);
        setItcHsCode(data.codes?.itcHsCode);
        setUsCode(data.codes?.usCode);
        setHtsCode(data.codes?.htsCode);
        setCoo(data.itemDetails?.countryOfOrigin);
        setUmo(data.itemDetails?.uomId);
        setQuantity(data.itemDetails?.quantity);
        setAmount(data.itemDetails?.amount);
        setUnitPrice(data.itemDetails?.unitPrice);
        data.certificates?.map((item) => {
            item.id = item.certificateId;
            item.expiryDate = item.expiryDate === "null" || item.expiryDate === undefined || item.expiryDate === null ? "" : item.expiryDate;
        });
        setItemCertificate(data?.certificates);
    };

    const mappingResponseData = (data) => {
        if (data.certificates !== null) {
            data.certificates = data.certificates?.filter((item) => {
                if (item !== null) {
                    return item;
                }
            });
        }
        setShipId(id);
        setItemStatus(data.itemStatus);
        setAssess(data.assess);
        setBcd(data.bcd);
        setAcd(data.acd);
        setSws(data.sws);
        setSad(data.sad);
        setIgst(data.igst);
        setCess(data.cess);
        setAdd(data.add);
        setCvd(data.cvd);
        setSg(data.sg);
        setTValue(data.tValue);
        setPartNo(data.partNo);
        setIntOrderNo(data.internalOrderNo);
        setSerialNo(data.serialNo);
        setManuNo(data.mfgName);
        setDescription(data.desc);
        setEccn(data.eccn);
        setEncCode(data.encCode);
        setItcHsCode(data.hsCode);
        setUsCode(data.usCode);
        setHtsCode(data.htsCode);
        setCoo(data.countryOrg);
        setUmo(data.uomId);
        setQuantity(data.quantity);
        setAmount(data.amount);
        setUnitPrice(data.unitPrice);
        data.certificates?.map((item) => {
            item.id = item.certificateId;
            item.expiryDate = item.expiryDate === "null" || item.expiryDate === undefined || item.expiryDate === null ? "" : item.expiryDate;
        });
        setItemCertificate(data?.certificates);
    };

    //cancel
    const handleClose = () => {
        if (globalState.ptiRecord.ptiId !== undefined) {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/" + globalState.ptiRecord.ptiId);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/");
            });
        }
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
       // console.log(selectedRows);
        setItcSelectedRow(selectedRows[0]);
    };

    const updateShipmentDetails = () => {
        const shipmentDetails = {
            id: shipId,
            itemStatus: itemStatus,
            assess: assess,
            bcd: bcd,
            acd: acd,
            sws: sws,
            sad: sad,
            igst: igst,
            cess: cess,
            add: add,
            cvd: cvd,
            sg: sg,
            tValue: tValue,
            shipmentId: shipId,
            invoiceNumber: globalState?.ptiRecord?.invNo,
            ptiId: globalState?.ptiRecord?.ptiId,
            partNo: partNo === null || partNo === undefined ? "" : partNo,
            internalOrderNo: intOrderNo === null || intOrderNo === undefined ? "" : intOrderNo,
            serialNo: serialNo === null || serialNo === undefined ? "" : serialNo,
            mfgName: manuNo === null || manuNo === undefined ? "" : manuNo,
            desc: description === null || description === undefined ? "" : description,
            countryOrg: coo === null || coo === undefined ? "" : coo,
            quantity: quantity === null || quantity === undefined ? "" : quantity,
            unitPrice: unitPrice === null || unitPrice === undefined ? "" : unitPrice,
            uomId: umo === null || umo === undefined ? "" : umo,
            uom: {
                id: umo
            },
            amount: amount === null || amount === undefined ? "" : amount,
            eccn: eccn === null || eccn === undefined ? "" : eccn,
            encCode: encCode === null || encCode === undefined ? "" : encCode,
            usCode: usCode === null || usCode === undefined ? "" : usCode,
            htsCode: htsCode === null || htsCode === undefined ? "" : htsCode,
            hsCode: itcHsCode === null || itcHsCode === undefined ? "" : itcHsCode,
            certificates: itemCertificate === null ? [] : itemCertificate
        };
        return shipmentDetails;
    };

    const validateForm = () => {
        let returnVal = true;
        if (isSerialized && (serialNo === null || serialNo === undefined || serialNo === "")) {
            setSerialNoErrorMessage("This part has Serial No. Please Update ");
            document.getElementById("serialNo").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (itcHsCode === null || itcHsCode === undefined || itcHsCode === "") {
            setHsCodeMessage("Please enter ITC HS Code Details");
            document.getElementById("itcHsCode").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (partNo === null || partNo === undefined || partNo === "") {
            setPartMessage("Please enter Part No.");
            document.getElementById("partNo").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (description === null || description === undefined || description === "") {
            setDescMessage("Please enter Description");
            document.getElementById("description").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (coo === null || coo === undefined || coo === "") {
            setCooMessage("Please enter Country of Origin");
            document.getElementById("coo").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (umo === null || umo === undefined || umo === "") {
            setUmoMessage("Please enter UOM");
            document.getElementById("umo").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        if (unitPrice === null || unitPrice === undefined || unitPrice === "") {
            setUnitPriceMessage("Please enter Unit Price");
            document.getElementById("unitPrice").focus();
            setUploadDisable(false);
            returnVal = false;
        }
        return returnVal;
    };

    const saveAndAdd = () => {
        onSubmit(false);
    }
    const saveAndClose = () => {
        onSubmit(true);
    }

    const clearingValues = () => {
        setIsSerialized(false);
        setPopulateSerialNo(false);
        setPopulateDescription(false);
        setPopulateHtsCode(false);
        setpopulateEncCode(false);
        setpopulateEccn(false);
        setpopulateHsCode(false);
        setpopulateUsCode(false);
        setPopulateQuantity(false);
        setDescription("");
        setEccn("");
        setQuantity("");
        setEncCode("");
        setItcHsCode("");
        setUsCode("");
        setHtsCode("");
        setItemCertificate([]);
        setPartNo("");
        setSerialNo("");
        setIntOrderNo("");
        setCoo("");
        setUnitPrice("");
        setAmount("");
        setUmo("");
        setManuNo("");
        setAssess("");
        setBcd("");
        setAcd("");
        setSws("");
        setSad("");
        setIgst("");
        setCess("");
        setAdd("");
        setCvd("");
        setSg("");
        setTValue("");
        setItemStatus(false);
    }

    const onSubmit = (saveAddClose) => {
        setUploadDisable(true);
        setErrorMessage("");
        setEccnMessage("");
        setPartMessage("");
        setDescMessage("");
        setHsCodeMessage("");
        setCooMessage("");
        setUmoMessage("");
        setUnitPriceMessage("");
        if (validateForm()) {
            const updatedCertificates = itemCertificate?.flatMap((item) => {
                return {
                    certificateId: String(item?.id)
                }
            });
            let exporterName;

            clientList?.filter((item) => {
                if (parseInt(item.id) === parseInt(globalState.ptiRecord.exporterId))
                    return exporterName = item.name;
            });
            const createState = {
                itemStatus: false,
                assess: assess === undefined ? "" : assess,
                bcd: bcd === undefined ? "" : bcd,
                acd: acd === undefined ? "" : acd,
                sws: sws === undefined ? "" : sws,
                sad: sad === undefined ? "" : sad,
                igst: igst === undefined ? "" : igst,
                cess: cess === undefined ? "" : cess,
                add: add === undefined ? "" : add,
                cvd: cvd === undefined ? "" : cvd,
                sg: sg === undefined ? "" : sg,
                tValue: tValue === undefined ? "" : tValue,
                codes: {
                    eccn: eccn === null || eccn === undefined ? 0 : parseInt(eccn),
                    encCode: encCode === null || encCode === undefined ? 0 : parseInt(encCode),
                    usCode: usCode === null || usCode === undefined ? 0 : parseInt(usCode),
                    htsCode: htsCode === null || htsCode === undefined ? 0 : parseInt(htsCode),
                    itcHsCode: itcHsCode === null || itcHsCode === undefined ? 0 : parseInt(itcHsCode),
                },
                item: {
                    description: description === null || description === undefined ? "" : description,
                    internalOrderNumber: intOrderNo === null || intOrderNo === undefined ? "" : intOrderNo,
                    manufactureNumber: manuNo === null || manuNo === undefined ? "" : manuNo,
                    partNumber: partNo === null || partNo === undefined ? "" : partNo,
                    serialNumber: serialNo === null || serialNo === undefined ? "" : serialNo,
                },
                itemDetails: {
                    amount: amount === null || amount === undefined ? 0 : amount,
                    countryOfOrigin: coo === null || coo === undefined ? 0 : coo,
                    quantity: quantity === null || quantity === undefined ? 0 : quantity,
                    unitPrice: unitPrice === null || unitPrice === undefined ? 0 : unitPrice,
                    uomId: umo === null || umo === undefined ? 0 : umo,
                },
                certificates: updatedCertificates,
                exporterName: exporterName
            };
            handleSave(createState, saveAddClose);
        }
    };

    const fetchItemPartsById = async (itemId) => {
        try {
            const requestURL = globalState.endPointURL + "/item/id/" + itemId;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseDataById = await response.json();
           // console.log("responseDataById", responseDataById);
            if (responseDataById !== null) {
                const data = responseDataById?.itemInfo[0];
                setIsSerialized(data.isSerialized);
                const dataQuantity = data.isSerialized ? 1 : (data.isMultiPack ? data.quantity : "");
                data.isMultiPack ? setPopulateSerialNo(true) : setPopulateSerialNo(false);
                setDescription(data.description);
                data.description ? setPopulateDescription(true) : setPopulateDescription(false);
                setEccn(data?.eccnCode);
                data.eccnCode ? setpopulateEccn(true) : setpopulateEccn(false);
                setEncCode(data?.eanCode);
                data.eanCode ? setpopulateEncCode(true) : setpopulateEncCode(false);
                setItcHsCode(data?.itcHsCode);
                data.itcHsCode ? setpopulateHsCode(true) : setpopulateHsCode(false);
                setUsCode(data?.upcCode);
                data.upcCode ? setpopulateUsCode(true) : setpopulateUsCode(false);
                setHtsCode(data?.mpin);
                data.mpin ? setPopulateHtsCode(true) : setPopulateHtsCode(false);
                setQuantity(dataQuantity);
                dataQuantity ? setPopulateQuantity(true) : setPopulateQuantity(false);
                const certificates = [];
                data.itemCertificate?.map((item) => {
                    item.certificate.certificateId = item.certificate.id;
                    item.certificate.expiryDate = item.certificate.expiryDate === "null" || item.certificate.expiryDate === undefined || item.certificate.expiryDate === null ? "" : item.certificate.expiryDate;
                    const certificate = {
                        id: item.certificate.id,
                        certificateId: item?.certificate.certificateId,
                        certificateTypeId: item?.certificate.certificateTypeId,
                        expiryDate: item?.certificate.expiryDate,
                        certificateId: item?.certificate.id,
                        isActive: item?.certificate.isActive,
                        isDeleted: item?.certificate.isDeleted,
                        certificateName: item?.certificate.name,
                        url: item?.certificate.url,
                        isManadatory: item?.certificate.isManadatory,
                        otherCert: item?.certificate.otherCert
                    };
                    certificates.push(certificate);
                });
                setItemCertificate(certificates);
            }
            else if (!responseDataById?.isSuccess && responseDataById?.errorCodes?.errorCodes?.length > 0) {
                setErrorMessage(responseDataById.errorCodes.errorCodes[0].message);
                setUploadDisable(false);
            }
            else {
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            setUploadDisable(false);
           // console.log(e);
        }
    };

    const handlePartNo = (partNo) => {
        let responseData = itemParts?.filter(item => { return String(item.part) === partNo });
       // console.log(responseData);
        if (responseData?.length > 0) {
            fetchItemPartsById(responseData[0].itemId);
        } else {
            setIsSerialized(false);
            setPopulateSerialNo(false);
            setPopulateDescription(false);
            setPopulateHtsCode(false);
            setpopulateEncCode(false);
            setpopulateEccn(false);
            setpopulateHsCode(false);
            setpopulateUsCode(false);
            setPopulateQuantity(false);
            setDescription("");
            setEccn("");
            setQuantity("");
            setEncCode("");
            setItcHsCode("");
            setUsCode("");
            setHtsCode("");
            setItemCertificate([]);
        }
        setPartNo(partNo);
    };

    const handleSave = async (data, saveAddClose) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                let requestURL = globalState.endPointURL;
                let methodType;
                if (shipId !== undefined && shipId !== "") {
                    requestURL = requestURL + "/shipment";
                    data.id = shipId;
                    methodType = "PUT";
                } else {
                    requestURL = requestURL + "/shipment";
                    methodType = "POST";
                }
               // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log(responseData);
                if (responseData !== null && responseData.isSuccess) {
                    const shipmentData = responseData.shipmentItemResponse.shipmentItem[0];
                    const shipmentDetails = {
                        id: shipmentData?.id,
                        itemStatus: shipmentData?.itemStatus,
                        assess: shipmentData?.assess,
                        bcd: shipmentData?.bcd,
                        acd: shipmentData?.acd,
                        sws: shipmentData?.sws,
                        sad: shipmentData?.sad,
                        igst: shipmentData?.igst,
                        cess: shipmentData?.cess,
                        add: shipmentData?.add,
                        cvd: shipmentData?.cvd,
                        sg: shipmentData?.sg,
                        tValue: shipmentData?.tValue,
                        partNo: shipmentData?.item.partNumber,
                        internalOrderNo: shipmentData?.item.internalOrderNumber,
                        serialNo: shipmentData?.item.serialNumber,
                        mfgName: shipmentData?.item.manufactureNumber,
                        desc: shipmentData?.item.description,
                        countryOrg: shipmentData?.itemDetails.countryOfOrigin,
                        quantity: shipmentData?.itemDetails.quantity,
                        unitPrice: shipmentData?.itemDetails.unitPrice,
                        umo: shipmentData?.itemDetails?.uomId,
                        uom: {
                            id: shipmentData?.itemDetails?.uomId
                        },
                        amount: shipmentData?.itemDetails.amount,
                        eccn: shipmentData?.codes.eccn,
                        encCode: shipmentData?.codes.encCode,
                        hsCode: shipmentData?.codes.itcHsCode,
                        usCode: shipmentData?.codes.usCode,
                        htsCode: shipmentData?.codes.htsCode,
                        certificates: shipmentData?.certificates
                    };

                    if (id !== undefined) {
                        let index = globalState?.ptiRecord?.shipmentItemDetails.findIndex(function (item, i) {
                            return item.id === shipmentData.id;
                        });
                        if (index >= 0)
                            globalState?.ptiRecord?.shipmentItemDetails.splice(index, 1);

                    }
                    globalState?.ptiRecord?.shipmentItemDetails?.push(shipmentDetails);
                    if (saveAddClose) {
                        handleClose();
                    } else {
                        clearingValues();
                        setUploadDisable(false);
                    }
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    //setErrorMessage(globalState.commonErrorMessage);
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
          //  console.log("Error" + e);
        }
        finally {
            setUploadDisable(false);
        }
    };

    const addCertificate = () => {
        globalState.shipmentDetail = updateShipmentDetails();
      //  console.log(globalState.shipmentDetail);
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItemView/pti-certificate-details");
        });
    };

    //View Certificate
    const viewCertificate = (certificateId) => {
        //globalState.itemParts = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItemView/pti-certificate-view/" + certificateId);
        });
    };

    const deleteCertificate = () => {
        let key = new URL(certificateUrl).pathname.substr(1);
        key = key.replace("%20", " ");
        const params = { Bucket: globalState.s3BuscketConfig.bucketName, Key: key };
        const s3 = new AWS.S3(globalState.s3BuscketConfig);
        s3.deleteObject(params, function (err, data) {
            if (err) {
                // console.log(err);
            } else {
                console.log("deleted successfully from s3");
                itemCertificate.map((item) => {
                    if (String(item.id) === String(certificateId))
                        item.url = "";
                });
                togglePopup();
            }
        });
    };

    const handleUpload = async (file) => {
        let responseData = itemCertificate.filter((item) => {
            return String(item.id) === String(certificateId);
        });
       // console.log(responseData);
       const formData = new FormData();
       if (file) {
           const fileName = file.name;
           const modifiedFileName = fileName.replace(/\.(?=.*\.)/g, "_"); // removes extra dots from filename
           const modifiedFile = new File([file], modifiedFileName, { type: file.type });
           formData.append("file", modifiedFile);
       }
       const docResponse = await axios.post('http://139.59.40.225:5000/upload-single', formData,);

        let newFileName = responseData[0].certificateTypeId + "_" + responseData[0].certificateName + "." + file?.name;
        const ReactS3Client = new S3(globalState.s3BuscketConfig);
        //ReactS3Client.uploadFile(file, newFileName).then(data => {
          //  console.log(data.location);
            itemCertificate.map((item) => {
                if (String(item.id) === String(certificateId)) {
                    item.url = docResponse?.data?.data.location;
                }
            });
            fileInput.current.value = "";
            togglePopup();
       // }).catch(err => console.error(err));
    };

    const uploadCertificate = (file) => {
        //deleteCertificate();
        let newArr = fileInput.current.files;
        if (newArr.length > 0) {
            for (let i = 0; i < newArr.length; i++) {
                handleUpload(newArr[i]);
            }
        }
    };

    // const handleUnitPrice = (e) => {
    //     const regExpFloat = /^\d*(?:[.,]\d*)?$/;
    //     if (regExpFloat.test(e.target.value) || e.target.value === "") {
    //         setUnitPrice(e.target.value);

    //         let amount = e.target.value === "" || quantity === "" ? 0 : e.target.value * quantity;
    //         setAmount((amount.toFixed(2)));
    //     } else if (!regExpFloat.test(e.target.value)) {
    //         setUnitPriceMessage(numberErrorMessage);
    //     } else {
    //         setUnitPrice("");
    //     }
    // };

    const handleUnitPrice = (e) => {
        const regExpFloat = /[0-9]+(,[0-9]+)*,?/;
        if (regExpFloat.test(e.target.value) || e.target.value === "") {
            const unitPrice = parseFloat(e.target.value.replace(/,/g, ''));
            setUnitPrice(e.target.value);
            let amount = e.target.value === "" || quantity === "" ? 0 : unitPrice * quantity;
            setAmount(amount.toLocaleString('en-IN', { maximumFractionDigits: 2 }));
        } else if (!regExpFloat.test(e.target.value)) {
            setUnitPriceMessage(numberErrorMessage);
        } else {
            setUnitPrice("");
        }
    };



    const handleQuantity = (e) => {
        if (regExpNumber.test(e.target.value) || e.target.value === "") {
            setQuantity(e.target.value);
            let amount = e.target.value === "" || unitPrice === "" ? 0 : e.target.value * unitPrice;
            setAmount((amount.toFixed(2)));
        } else if (!regExpNumber.test(e.target.value)) {
            setQuantityMessage(numberErrorMessage);
        } else {
            setQuantity("");
        }
    };

    const handleUrl = (val) => {
        setUrlErrorMessage("");
        if (fileInput.current.files[0].type === "application/pdf" || fileInput.current.files[0].type === "image/png"
            || fileInput.current.files[0].type === "image/jpeg" || fileInput.current.files[0].type === "image/jpg") {
            setUrl(val);
        } else {
            setUrlErrorMessage("Please upload image or pdf file");
            fileInput.current.value = "";
            setUrl("");
        }
    };


    return <div className="dashboard">
        <div className="table-heading">
            <h4 className="w-50">Shipment Items</h4>
        </div>
        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <form action="">
            <div className="pti-detail-container">
                {/* <!-- pti id --> */}
                <div className="row">
                    <div className="col-lg-2 col-sm-4">
                        <div className="from-group">
                            <label>PTI Id</label>
                            <div className="input-box">
                                <input type="text" className="form-control" readOnly value={globalState.ptiRecord.ptiId} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2 col-sm-4">
                        <div className="from-group ">
                            <label>Invoice #</label>
                            <div className="input-box">
                                <input type="text" className="form-control" readOnly value={globalState.ptiRecord.invNo} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- pti single container --> */}
            <div className="row">
                {/* <!-- Shipments items --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Shipment Items</div>
                        <div className="p-2">
                            {/* <!--Part No. --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Part No. </label>
                                <div className="input-box">
                                    <div>
                                        <input type="text" id="partNo" className="form-control" value={partNo}
                                            onChange={e => { setPartMessage(""); e.target.value === "" ? setPartNo(e.target.value) : handlePartNo(e.target.value); }} />
                                    </div>
                                    {partMessage && <p className="text-warning mb-0"> {partMessage} </p>}
                                </div>
                            </div>
                            {/* <!-- end Part No. --> */}
                            {/* <!-- Internal Order No. --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Internal Order No.</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" value={intOrderNo} onChange={e => setIntOrderNo(e.target.value)} />
                                </div>
                            </div>
                            {/* <!-- end Internal Order No. --> */}
                            {/* <!-- Serial Number --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Serial Number</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" id="serialNo" value={serialNo} readOnly={populateSerialNo}
                                        onChange={e => {
                                            setSerialNoErrorMessage(""); setSerialNo(e.target.value);
                                            e.target.value === "" ? setQuantity("0") : setQuantity("1");
                                            setAmount(((e.target.value === "" ? 0 : 1) * unitPrice).toFixed(2));
                                        }} />
                                    <div className="text-warning">{serialNoErrorMessage}</div>
                                </div>

                            </div>
                            {/* <!-- end Serial Number --> */}
                            {/* <!-- Manufacture Number --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Manufacture Number</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" value={manuNo} onChange={e => setManuNo(e.target.value)} />
                                </div>
                            </div>
                            {/* <!-- end Manufacture Number --> */}
                            {/* <!-- Manufacture Number --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Description</label>
                                <div className="input-box">
                                    <div>
                                        <input type="text" id="description" className="form-control"
                                            readOnly={populateDescription} value={description}
                                            onChange={e => { setDescMessage(""); setDescription(e.target.value); }} />
                                    </div>
                                    {descMessage && <p className="text-warning mb-0"> {descMessage} </p>}
                                </div>
                            </div>
                            {/* <!-- end Manufacture Number --> */}
                        </div>
                    </div>
                    {/* <!-- end single-pti-box  --> */}
                </div>
                {/* <!--end Shipments items --> */}
                {/* <!-- codes --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Codes</div>
                        <div className="p-2">
                            <div className="single-pti-box-group width-full form-group">
                                <label>ECCN</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={populateEccn} value={eccn}
                                        onChange={e => {
                                            setEccnMessage("");
                                            return (regExpNumber.test(e.target.value) || e.target.value === "" ? setEccn(e.target.value) : !regExpNumber.test(e.target.value) ? setEccnMessage(onlyNumber) : "")
                                        }
                                        } />
                                    {eccnMessage && <p className="text-warning"> {eccnMessage} </p>}
                                </div>
                            </div>

                            <div className="single-pti-box-group width-full form-group">
                                <label>ENC Code</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={populateEncCode} value={encCode}
                                        onChange={e => {
                                            setEncMessage("");
                                            return (regExpNumber.test(e.target.value) || e.target.value === "" ? setEncCode(e.target.value) : !regExpNumber.test(e.target.value) ? setEncMessage(onlyNumber) : "")
                                        }
                                        } />
                                    {encMessage && <p className="text-warning"> {encMessage} </p>}
                                </div>
                            </div>

                            <div className="single-pti-box-group width-full form-group">
                                <label>ITC HS Code</label>
                                <div className="input-box">
                                    <div className="threeDots">
                                        <input type="text" id="itcHsCode" className="form-control" readOnly={populateHsCode} value={itcHsCode}
                                            onChange={e => {
                                                setHsCodeMessage("");
                                                return (regExpNumber.test(e.target.value) || e.target.value === "" ? setItcHsCode(e.target.value) : !regExpNumber.test(e.target.value) ? setHsCodeMessage(onlyNumber) : "")
                                            }
                                            } />
                                    </div>
                                    {hsCodeMessage && <p className="text-warning mb-0"> {hsCodeMessage} </p>}
                                </div>
                            </div>

                            <div className="single-pti-box-group width-full form-group">
                                <label>US Code</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={populateUsCode} value={usCode}
                                        onChange={e => {
                                            setUsCodeMessage("");
                                            return (regExpNumber.test(e.target.value) || e.target.value === "" ? setUsCode(e.target.value) : !regExpNumber.test(e.target.value) ? setUsCodeMessage(onlyNumber) : "")
                                        }
                                        } />
                                    {usCodeMessage && <p className="text-warning"> {usCodeMessage} </p>}
                                </div>
                            </div>
                            <div className="single-pti-box-group width-full form-group">
                                <label>HTS Code
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={populateHtsCode} value={htsCode}

                                        onChange={e => {
                                            setHtsMessage("");
                                            return (regExpNumber.test(e.target.value) || e.target.value === "" ? setHtsCode(e.target.value) : !regExpNumber.test(e.target.value) ? setHtsMessage(onlyNumber) : "")
                                        }
                                        } />
                                    {htsMessage && <p className="text-warning"> {htsMessage} </p>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end codes --> */}
                {/* <!--shipment item --> */}
                <div className="col-lg-12 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Permits & Certificate
                            <button type="button" className="pti-btn" onClick={e => addCertificate()}> Upload Certificate</button>
                        </div>
                        <div className="row p-2">
                            <div className="ag-theme-alpine">
                                <AgGridReact
                                    ref={certiGridRef}
                                    rowData={itemCertificate}
                                    columnDefs={certiColumnDef}
                                    domLayout={domLayout}
                                    defaultColDef={{
                                        sortable: true,
                                        flex: 1,
                                        minWidth: 100,
                                        filter: true,
                                        resizable: true,
                                    }}
                                    pagination={true}
                                    // onGridReady={onGridReady}
                                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                                />
                            </div>

                            {isOpen && <CertificatePopup
                                content={
                                    <div id="exampleModal">
                                        <div className="modal-dialog" >
                                            <div className="modal-content">
                                                <div className="popup-header">
                                                    <h5 className="popup-title">Document</h5>
                                                    <br></br>
                                                </div>
                                                <div className="modal-body row">
                                                    <div className=" m-auto">
                                                        <embed src={certificateUrl} type="" width="100%" height="420" />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <div>
                                                            <input type="file" className="form-control" ref={fileInput} name="url" placeholder=""
                                                                onChange={e => { handleUrl(e.target.value); }} />
                                                        </div>
                                                        {urlErrorMessage && <p className="text-warning"> {urlErrorMessage} </p>}
                                                    </div>
                                                    <div className="col-lg-6 text-right">
                                                        <button type="button" onClick={uploadCertificate} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Certificate"> Upload Certificate <i className="bx bx-upload" aria-hidden="true"></i></button>
                                                        <button type="button" onClick={togglePopup} className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                                        {certificateUrl === null || certificateUrl === "" || certificateUrl === undefined ? "" :
                                                            <button type="button" onClick={deleteCertificate} className="btn btn-danger ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">Delete <i className="bx bx-trash" aria-hidden="true"></i></button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                handleClose={togglePopup} />}
                        </div>
                    </div>
                </div>
            </div>


            {/* <!-- item/parts --> */}
            <div className="col-lg-12 col-sm-12 m-0 p-0">
                <div className="row m-0 p-0">
                    <div className="col-lg-12 col-sm-12 p-0 m-0">
                        <div className="single-pti-box">
                            <div className="single-pti-box-header">Item / Part Details</div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="p-2">
                                        {/* <!-- Country of origin --> */}
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Country of origin</label>
                                            <div className="select-box">
                                                <select value={coo} id="coo" onChange={e => { setCooMessage(""); setCoo(e.target.value); }}>
                                                    <option value="select">select</option>
                                                    {updatedCountries.map((country) => {
                                                        return <option value={country.name}>{country.name}</option>
                                                    })}
                                                </select>
                                                {cooMessage && <p className="text-warning mb-0"> {cooMessage} </p>}
                                            </div>

                                        </div>
                                        {/* <!-- end Country of origin --> */}
                                        {/* <!--  Quantity  --> */}
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Quantity</label>
                                            <div className="input-box">
                                                <input type="text" className="form-control" value={quantity} readOnly={populateQuantity || serialNo}
                                                    onChange={e => {
                                                        setQuantityMessage("");
                                                        handleQuantity(e);
                                                    }} />
                                                {quantityMessage && <p className="text-warning"> {quantityMessage} </p>}
                                            </div>
                                        </div>
                                        {/* <!-- end Quantity --> */}
                                        {/* <!--  Unit Price --> */}
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Unit Price
                                            </label>
                                            <div className="input-box">
                                                <input type="text" id="unitPrice" className="form-control" value={unitPrice.toLocaleString("en-IN")}
                                                    onChange={e => { setUnitPriceMessage(""); handleUnitPrice(e) }} />
                                                {unitPriceMessage && <p className="text-warning"> {unitPriceMessage} </p>}
                                            </div>
                                        </div>
                                        {/* <!-- end Unit Price--> */}
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    <div className="p-2">
                                        {/* <!-- UOM --> */}
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>UOM</label>
                                            <div className="select-box">
                                                <select value={umo} id="umo" onChange={e => { setUmoMessage(""); setUmo(e.target.value); }}>
                                                    <option value="select">select</option>
                                                    {globalState.uom?.map((item) => {
                                                        return <option value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                {umoMessage && <p className="text-warning mb-0"> {umoMessage} </p>}
                                            </div>

                                        </div>
                                        {/* <!-- end UOM --> */}
                                        {/* <!-- Amount --> */}
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Amount</label>
                                            <div className="input-box">
                                                <input type="text" className="form-control" readOnly={true} value={amount.toLocaleString("en-IN")} />
                                            </div>
                                        </div>
                                        {/* <!-- end Amount --> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- item/parts --> */}
                        </div>
                    </div>
                    <div className="btn-footer text-right">
                        <button type="button" disabled={uploadDisable} onClick={saveAndAdd} class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save & More">Save & Add More <i class="fa fa-check-circle" aria-hidden="true"></i></button>
                        <button type="button" disabled={uploadDisable} onClick={saveAndClose} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save / close">Save & Close <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                        <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

};

export default PTIRecordShipmentItem;

