import React, { useRef, useState, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import { GlobalContext } from "../../../GlobalContext";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
var clientData;


const ViewBOE = () => {
    const gridRef = useRef();
    const [uploadDisable, setUploadDisable] = useState(false);
    const [boeData, setBoeData] = useState([]);
    const [exchange, setExchange] = useState("");
    const [awb, setAwb] = useState("");
    const [ooc, setOoc] = useState("");
    const [boeInfo, setBoeInfo] = useState([]);
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    const [globalBEO, setGlobalBEO] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const { id } = useParams();
    const [url, setUrl] = useState("");
    const [cha, setCha] = useState("");
    const [beDate, setBeDate] = useState("");
    const [portcode, setportCode] = useState("");
    const [beNo, setbeNo] = useState("");
    const [bcd, setbcd] = useState(0);
    const [acd, setacd] = useState(0);
    const [sws, setsws] = useState(0);
    const [nccd, setnccd] = useState(0);
    const [sad, setsad] = useState(0);
    const [sg, setsg] = useState(0);
    const [add, setadd] = useState(0);
    const [cvd, setcvd] = useState(0);
    const [fine, setfine] = useState(0);
    const [pnlty, setpnlty] = useState(0);
    const [totalDuty, settotalDuty] = useState(0);
    const [igst, setigst] = useState(0);
    const [tValue, setTValue] = useState(0);
    const [int, setint] = useState(0);
    const [cess, setcess] = useState(0);
    const [totalDutyAmount, setTotalDutyAmount] = useState(0);
    const [assessed, setassessed] = useState(0);
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { login } = useAuth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [boeId, setBoeId] = useState("");
    const [clientList, setClientList] = useState([]);

   
    const [columnDef, setColumnDef] = useState([
        { headerName: '#', field: "itemId", sortable: true, filter: true, width: 40, editable: true },
        {
            headerName: 'Part #', field: "part", readOnly: true,
            sortable: true, filter: true, editable: false, width: 180,
        },
        {
            headerName: 'SL #', field: "sl", readOnly: true,
            sortable: true, filter: true, editable: false, width: 180
        },
        {
            headerName: 'Desc', field: "desc", readOnly: true,
            sortable: true, filter: true, editable: false, width: 150
        },
        {
            headerName: 'HS Code/CTH', field: "hsCode", readOnly: true,
            sortable: true, filter: true, width: 100, editable: false
        },
        {
            headerName: 'U Price', field: "uPrice", readOnly: true,
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'Qty', field: "qty", readOnly: true,
            sortable: true, filter: true, width: 60, editable: false
        },
        {
            headerName: 'Amount', field: "amount", readOnly:true,            
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'Assess Value', field: "assess", readOnly:true,            
            sortable: true, filter: true, editable: false, width: 100,
            
        },
        {
            headerName: 'BCD%', field: "bcdPercent",   readOnly:true,          
            sortable: true, filter: true, width: 100, editable: false,
        },
        {
            headerName: 'BCD', field: "bcd",     readOnly:true,         
            sortable: true, filter: true, width: 100, editable: false, 
        },
        {
            headerName: 'ACD%', field: "acdPercent",   readOnly:true,           
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'ACD', field: "acd",  readOnly:true,            
            sortable: true, filter: true, width: 100, editable: false,
        },
        {
            headerName: 'SWS', field: "sws",  readOnly:true,            
            sortable: true, filter: true, width: 100, editable: false,
        },
        {
            headerName: 'SAD', field: "sad", readOnly:true,             
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'IGST%', field: "igstPercent",  readOnly:true,            
            sortable: true, filter: true, width: 100, editable: false, 
        },
        {
            headerName: 'IGST', field: "igst",    readOnly:true,          
            sortable: true, filter: true, width: 100, editable: false,
        },
        {
            headerName: 'CESS', field: "cess",    readOnly:true,          
            sortable: true, filter: true, editable: false, width: 100, 
        },
        {
            headerName: 'ADD', field: "add",readOnly:true,  
            sortable: true, filter: true, editable: false, width: 100, 
        },
        {
            headerName: 'CVD', field: "cvd",   readOnly:true,           
            sortable: true, filter: true, width: 100, editable: false, 
        },
        {
            headerName: 'SG', field: "sg",    readOnly:true,          
            sortable: true, filter: true, width: 100, editable: false, 
        },
        {
            headerName: 'T Value', field: "tValue",  readOnly:true,            
            sortable: true, filter: true, width: 100, editable: false, 
        },
        {
            headerName: 'Total Duty', field: "tDuty", readOnly:true,  
            sortable: true, filter: true, editable: false, width: 100
        }
    ]);

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    // const handleClick = event => {
    //     fileInput.current.click();
    // };

    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

      // clients fetch
      const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            //console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
               // console.log(data);
                setClientList(data);
                clientData = data;
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const onGridReady = async () => {
        const fetchPTIRecord = async () => {
            try {
                const requestURL = globalState.endPointURL + "/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData != null && responseData.isSuccess) {
                    const boeList = [];
                    responseData.ptiStatus.map((pti) => {
                        pti.ptiRecords.map((ptiRecord) => {
                            globalState.ptiStatus?.filter((item) => {
                                if (item.name === String(ptiRecord.ptiStatus))
                                    return ptiRecord.ptiStatusName = item.value;
                            });

                            globalState.exporter?.filter((item) => {
                                if (item.name === String(ptiRecord.exporter.exporterId))
                                    return ptiRecord.exporter.exporterName = item.value;
                            });
                            clientData?.filter((item) => {
                                if (item.id === ptiRecord.importer.importerId)
                                    return ptiRecord.importer.importerName = item.name;
                            });
                            globalState.incotermId?.filter((item) => {
                                if (item.name === String(ptiRecord.shipment.incoterm))
                                    return ptiRecord.shipment.incotermName = item.value;
                            });
                            globalState.currency?.filter((item) => {
                                if (item.name === String(ptiRecord.shipment.currency))
                                    return ptiRecord.shipment.currencyName = item.value;
                            });
                            let invTotal = 0;
                            let itemId = 1;
                            ptiRecord.shipmentItem?.map((item) => {
                                const itemStatus = {
                                    "itemId": itemId,
                                    "shipmentId": item.id,
                                    "part": item.item.partNumber,
                                    "sl": item.item.serialNumber,
                                    "desc": item.item.description,
                                    "hsCode": item.codes.itcHsCode,
                                    "uPrice": item.itemDetails.unitPrice,
                                    "qty": item.itemDetails.quantity,
                                    "amount": item.itemDetails.amount,
                                    "acd": 0,
                                    "acdPercent": 0,
                                    "add": 0,
                                    "assess": 0,
                                    "bcd": 0,
                                    "bcdPercent": 10,
                                    "cess": 0,
                                    "cvd": 0,
                                    "igst": 0,
                                    "igstPercent": 18,
                                    "sad": 0,
                                    "sg": 0,
                                    "sws": 0,
                                    "tValue": 0,
                                    "tDuty": 0,
                                    "id": 0
                                };
                                invTotal = invTotal + Math.round(itemStatus.amount);
                                itemId = itemId + 1;
                                boeList.push(itemStatus);
                            });

                            const boeDetails = {
                                "invoice": ptiRecord.invoiceNumber,
                                "invDate": ptiRecord.invDate,
                                "invoiceItem": ptiRecord?.shipmentItem?.length,
                                "invoiceAmount": Math.round(invTotal),
                                "currency": ptiRecord.shipment.currencyName,
                                "grossWt": ptiRecord.shipment.grossWeight,
                                "importerName": ptiRecord.importer.importerName,
                                "awbBlNumber": ptiRecord.shipment.awbBlNumber
                            };
                            setAwb(ptiRecord.shipment.awbBlNumber);
                            setBoeInfo(boeDetails);
                        });
                    });
                    fetchPTIBoe(boeList);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        const fetchPTIBoe = async (boeList) => {
            try {
                const requestURL = globalState.endPointURL + "/pti/boe/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
              //  console.log(responseData);
                if (responseData != null) {
                    const data = responseData?.boeDetails;
                    setExchange(data?.boeDetails?.exchangeRate);
                    setOoc(data?.boeDetails?.ooc);
                    setBeDate(data?.beDetails?.beDate);
                    setbeNo(data?.beDetails?.beNumber);
                    setCha(data?.beDetails?.cha);
                    setportCode(data?.beDetails?.portCode);
                    setAwb(data?.beDetails?.awb);
                    setUrl(data?.documentUrl);
                    setbcd(data?.dutySummary?.bcd);
                    setcess(data?.dutySummary?.cess);
                    setnccd(data?.dutySummary?.nccd);
                    setsad(data?.dutySummary?.sad);
                    setsg(data?.dutySummary?.sg);
                    setadd(data?.dutySummary?.add);
                    setcvd(data?.dutySummary?.cvd);
                    setassessed(data?.dutySummary?.assessedValue);
                    setacd(data?.dutySummary?.acd);
                    setsws(data?.dutySummary?.sws);
                    setigst(data?.dutySummary?.igst);
                    settotalDuty(data?.dutySummary?.totalDuty === undefined ? 0 : data?.dutySummary?.totalDuty);
                    setfine(data?.dutySummary?.fine === undefined ? 0 : data?.dutySummary?.fine);
                    setint(data?.dutySummary?.interest === undefined ? 0 : data?.dutySummary?.interest);
                    setpnlty(data?.dutySummary?.penalty === undefined ? 0 : data?.dutySummary?.penalty);
                    setTotalDutyAmount(data?.dutySummary?.totalDutyAmount === undefined ? 0 : data?.dutySummary?.totalDutyAmount);
                    setBoeId(data?.id);
                    boeList?.map((boeDetails) => {
                        responseData?.boeDetails?.itemAccessDetails?.map((item) => {
                            if (boeDetails.shipmentId === item.shipmentId) {
                                boeDetails.acd = item.acd;
                                boeDetails.acdPercent = item.acdPercent;
                                boeDetails.add = item.add;
                                boeDetails.assess = item.assessValue;
                                boeDetails.bcd = item.bcd;
                                boeDetails.bcdPercent = item.bcdPercent;
                                boeDetails.cess = item.cess;
                                boeDetails.cvd = item.cvd;
                                boeDetails.igst = item.igst;
                                boeDetails.igstPercent = item.igstPercent;
                                boeDetails.sad = item.sad;
                                boeDetails.sg = item.sg;
                                boeDetails.sws = item.sws;
                                boeDetails.tValue = item.tValue;
                                boeDetails.tDuty = item.totalDuty;
                                boeDetails.shipmentId = item.shipmentId;
                                boeDetails.id = item.id;
                            }
                        });
                    });
                    //setBoe(boeList);
                    //setGlobalBEO(boeList);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

                setBoeData(boeList);
                setGlobalBEO(boeList);                
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                setBoeData(boeList);
                setGlobalBEO(boeList);
            }
        };
        fetchPTIRecord();
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/");
        });
    };

  

   
    


    const domLayout = "autoHeight";

    return <form>
        <div className="dashboard" id="dashboard">
            <div className="table-heading">
                <h4 className="w-50">BOE Details</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}

            {/* <!- Duty summary--> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">BE Details</div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="p-2">
                                    {/* <!-- Invoice # --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label> Invoice # </label>
                                        <div className="input-box">
                                            <input type="text" readOnly={true} value={boeInfo.invoice} className="form-control" />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Date --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Date</label>
                                        <div className="date-box date-box-pti">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invDate} />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Item --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Items</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invoiceItem} />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Amount --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Amount $</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invoiceAmount} />
                                        </div>
                                    </div>
                                    {/* <!-- currency --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Currency</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.currency} />
                                        </div>
                                    </div>
                                    {/* <!-- Exchange --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Exchange (INR)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={exchange} onChange={e => setExchange(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <!-- currency --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>OOC</label>
                                        <div className="input-box">
                                            <input type="date" className="form-control" readOnly={true} value={ooc} onChange={e => setOoc(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-12">
                                <div className="p-2">
                                    {/* Port Code */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Port Code</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={portcode} onChange={e => setportCode(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* BE No. */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BE No.</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={beNo} onChange={e => setbeNo(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* BE Date */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BE Date</label>
                                        <div className="date-box date-box-pti">
                                            <input type="date" className="form-control" readOnly={true} value={beDate} onChange={e => setBeDate(e.target.value)} placeholder="dd/mm/yyyy" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} />
                                        </div>
                                    </div>
                                    {/* Gross Wt (KG) */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Gross Wt (KG)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control"  readOnly={true} value={boeInfo.grossWt} />
                                        </div>
                                    </div>
                                    {/* Importer */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Importer</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.importerName} />
                                        </div>
                                    </div>
                                    {/* CHA */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>CHA</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={cha} />
                                        </div>
                                        {/* <div className="select-box">
                                            <select value={cha} onChange={e => setCha(e.target.value)} readOnly={true}>
                                                <option value="select">Select</option>
                                                <option value="cha1">CHA 1</option>
                                                <option value="cha2">CHA 2</option>
                                                <option value="cha3">CHA 3</option>
                                                <option value="cha4">CHA 4</option>
                                                <option value="cha5">CHA 5</option>

                                            </select>
                                        </div> */}
                                    </div>
                                    {/* AWB */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>AWB</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={awb} onChange={e => setAwb(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                </div>
            </div>

            {/* <!- Duty summary--> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Duty Summary</div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="p-2">
                                    {/* <!--  BCD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BCD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={bcd} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!--  ACD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>ACD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={acd} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!--  SWS --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>SWS</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sws} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- NCCD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>NCCD</label>
                                        <div className="input-box">
                                            <input type="text" id="nccd" className="form-control" readOnly={true} value={nccd} />
                                        </div>
                                    </div>
                                    {/* <!--  ADD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>ADD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={add} />
                                        </div>
                                    </div>
                                    {/* <!--  CVD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>CVD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={cvd} />
                                        </div>
                                    </div>
                                    {/* <!-- IGST --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>IGST</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={igst} readOnly={true} />
                                        </div>
                                    </div>
                                       {/* <!--  CESS --> */}
                                       <div className="single-pti-box-group  form-group width-full">
                                        <label>CESS</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={cess} readOnly={true} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="p-2">
                                       {/* <!--  SAD --> */}
                                       <div className="single-pti-box-group  form-group width-full">
                                        <label>SAD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sad} readOnly={true} />
                                        </div>
                                    </div>
                                 
                                   {/* <!--  SG --> */}
                                   <div className="single-pti-box-group  form-group width-full">
                                        <label>SG</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sg} readOnly={true} />
                                        </div>
                                    </div>

                                    {/* <!--  Assessed Value --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Assessed Value</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={assessed} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- Total Duty --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Total Duty (A)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={totalDuty} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- Int --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>INT (B)</label>
                                        <div className="input-box">
                                            <input type="text" id="int" className="form-control" value={int} readOnly={true}
                                                 />
                                        </div>
                                    </div>
                                    {/* <!-- PNLTY --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>PNLTY (C)</label>
                                        <div className="input-box">
                                            <input type="text" id="pnlty" className="form-control" value={pnlty} readOnly={true}
                                               />
                                        </div>
                                    </div>
                                    {/* <!-- Fine --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>FINE (D)</label>
                                        <div className="input-box">
                                            <input type="text" id="fine" className="form-control" value={fine} readOnly={true}/>
                                        </div>
                                    </div>
                                    {/* <!-- Total duty amount --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Total Duty Amount (E) <br /> (E = A+B+C+D)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={totalDutyAmount} readOnly={true} />
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                </div>
            </div>
        </div>

        {/* item assess */}
        <div className="dashboard mt-2">
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="w-50">Item Assess Details</h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                        <div className="sorting-num mr-2">
                            Page Size:&nbsp;
                            <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                    </div>
                </div>
            </div>
            <div className="ag-theme-alpine" id="boe-table" >
                <AgGridReact
                    ref={gridRef}
                    rowData={boeData}
                    columnDefs={columnDef}
                    domLayout={domLayout}
                    defaultColDef={{
                        //editable: true,
                        sortable: true,
                        // flex: 1,
                        // minWidth: 100,
                        filter: true,
                        resizable: true,
                    }}
                    pagination={true}
                    onGridReady={onGridReady}                   
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>
            <br></br><br></br>
            {/* <!-- buttons row --> */}
            <div className="row">
              
                <div className="col-lg-12 text-right" >
                    <div className="btnooter text-right">
                        <button type="button" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                        <button type="button" onClick={handleClose} className="btn btn-secondary ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            {/* <!-- end buttons row --> */}

        </div>
    </form>
};

export default ViewBOE;