import React, { useState, useCallback, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

 
const History = () => {
    const { id } = useParams();
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const gridRef = useRef();
    const [searchText, setSearchText] = useState("");
    //Redirect to dash board
    const handleClose = () => {
        login().then(() => {            
            navigate(state?.path || "/shipments/PTIRecords");
        });
    };

    const domLayout = "autoHeight";

    const columnDef = [
        { headerName: 'Date', field: "updateDateTime", sort: 'desc', sortable: true, filter: true,width:200 },
        { headerName: 'PTI Record Status', field: "message", sortable: true, filter: true,minWidth:400 },
        { headerName: 'User Name', field: "userName", sortable: true, filter: true,width:150 },
    ];

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/pti/history/" + id;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response?.json();
          //  console.log(responseData);
            if (responseData != null) {                
                setHistory(responseData?.history);
            }
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                 setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
             setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
      //  console.log(event);
        setpaginationPageSize(event);
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    return <section className="home-section">
        <div className="dashboard">
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="">History of PTI Changes </h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                        <div className="sorting-num mr-2">
                            Page Size:&nbsp;
                            <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                        </div>
                </div>
            </div>

            <div className="ag-theme-alpine"  >
                <AgGridReact
                    ref={gridRef}
                    rowData={history}
                    columnDefs={columnDef}
                    domLayout={domLayout}
                    pagination={true}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>
            <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>

        </div>
    </section>

   /*return <div className="dashboard">
            {*//* !-- history --> *//*}
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <div class="single-pti-box">
                        <div class="single-pti-box-header">History of PTI Changes</div>
                            <div class="row p-0 m-0">
                <table className="history-table">
                                          <tbody>
                                            <tr className="history-data">
                                                <th>Date & Time</th>
                                                <th>PTI Record Status</th>
                                                <th>User Name</th>
                                            </tr>
                                            <tr>
                                                <th>12/11/2022 & 8:04 pm</th>
                                                <th>Invoice No. 23453456 PTI status changed for Delivered from Out of Charge</th>
                                                <th>Harish</th>
                                            </tr>
                                            <tr>
                                                <th>12/11/2022 & 10:04 pm</th>
                                                <th>Invoice No. 23453456 PTI status pending Approved for Delivered from Out of Charge</th>
                                                <th>Diwakar</th>
                                            </tr>
                                            <tr>
                                                <th>04/12/2022 & 10:04 pm</th>
                                                <th>Invoice No. 23453456 PTI status changed for Delivered from Out of Charge</th>
                                                <th>prabhat</th>
                                            </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                    
                    </div>
                    <div className="text-right">
                        <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
                    </div>
           
                </div>
            </div>

            </div>*/

};
export default History;