import React, { useState, useEffect, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import CertificatePopup from '../../shipments/PTIRecords/CertificatePopup';

const CertificateView = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { formState } = useForm();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [certificateTypeId, setCertificateTypeId] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [url, setUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    // const { errors } = formState;
    const [errorMessage, setErrorMessage] = useState("");
    const [isMandatory, setIsMandatory] = useState(false);
    const [otherCert, setOtherCert] = useState("");
    // const [itemCertificate, setitemCertificate] = useState("");
    const inputFile = useRef(null);
    const onButtonClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const uploadDocument = () => {
        // console.log();
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const deleteCertificate = () => {
        /*let key = new URL(url).pathname.substr(1);
        key = key.replace("%20", " ");
        const params = { Bucket: globalState.s3BuscketConfig.bucketName, Key: key };
        const s3 = new AWS.S3(globalState.s3BuscketConfig);
        s3.deleteObject(params, function (err, data) {
            if (err) {
                console.log(err);
            } else {
                console.log("deleted successfully from s3");
                itemCertificate.map((item) => {
                    if (String(item.id) === String(certificateId))
                        item.url = "";
                });
                togglePopup();
            }
        });*/
    };

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const responseData = globalState?.itemParts?.itemCertificate?.filter(item => { return String(item.certificate.id) === id; });

                if (responseData !== "") {
                    const data = responseData[0].certificate;
                    // console.log(data);
                    setName(data.name);
                    setExpiryDate(data.expiryDate);
                    /* let certificateType = "";
                     if (String(data.certificateTypeId) === "1") {
                         certificateType = "BIS";
                     } else if (String(data.certificateTypeId) === "2") {
                         certificateType = "ETA";
                     } else if (String(data.certificateTypeId) === "3") {
                         certificateType = "Import License";
                     } else if (String(data.certificateTypeId) === "4") {
                         certificateType = "EPR";
                     }*/
                    setCertificateTypeId(data.certificateTypeId);
                    setUrl(data.url);
                    setOtherCert(data.otherCert);
                    setIsMandatory(data.isMandatory);
                } else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined)
            fetchCertificates();
    }, []);



    // Delete with fetchAPI
    //     const deleteCertificateFile = async (id) => {
    //         try {
    //             const requestURL = globalState.endPointURL + "/certificate/id/" + url;
    //             const response = await fetch(requestURL, {
    //                 method: 'DELETE',
    //                 headers: globalState.headers,
    //             });
    //             const responseData = await response.json();
    //             if (responseData !== "" && responseData.isSuccess) {
    //                 setitemCertificate(itemCertificate.filter((certificate) => { return certificate.certificate.url !== url; }));
    //             } 
    //        else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
    //                 setErrorMessage(responseData.errorCodes.errorCodes[0].message);
    //             else
    //                 setErrorMessage("Failed to fetch");
    //         } 
    //         catch (e) {
    //             setErrorMessage("Failed to fetch");
    //             console.log(e);

    //     };
    // }



    const handleClose = () => {
        const itemId = globalState?.itemParts?.itemId;
        if (globalState.itemPartRedirect === "View") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-view/" + itemId);
            });
        } else if (globalState.itemPartRedirect === "Add") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details");
            });
        } else if (globalState.itemPartRedirect === "Edit") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details/" + itemId);
            });
        }
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 >Certificate</h4>
            </div>

            <form action="" method="">
                <div className="row mb-4">

                    <div className="col-lg-6 col-md-6">

                        <div className="detail-view">
                            <h5>Certificate # </h5>
                            <p>{name}</p>
                        </div>
                    </div>


                    <div className="col-lg-6 col-md-6">

                        <div className="detail-view">
                            <h5>Expiry Date </h5>
                            <p>{expiryDate} </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <div className="detail-view">
                            <h5>Certificate / Licence Type </h5>
                            <p>{certificateTypeId} </p>
                        </div>
                    </div>

                    {certificateTypeId === "Others" ?
                        <div className="col-lg-6 col-md-6">
                            <div className="detail-view">
                                <label>Other Certificate Type</label>
                                <p> {otherCert} </p>
                            </div> </div> : ""}


                    <div className="col-lg-6 col-md-6">


                        <div className="detail-view ">
                            <h5>Certificate file </h5>
                            <div className="certi-view"><p >{url}
                                {url === "" || url === undefined ? "" :
                                    <div className="certi-view-icon" onClick={togglePopup}><i className="bx bxs-show" aria-hidden="true"></i></div>}
                                {isOpen && <CertificatePopup
                                    content={
                                        <div id="exampleModal">
                                            <div className="modal-dialog" >
                                                <div className="modal-content">
                                                    <div className="popup-header">
                                                        <h5 className="popup-title">Document</h5>
                                                        <br></br>
                                                    </div>
                                                    <div className="modal-body row">
                                                        <div className="m-auto certi-popup">
                                                            <embed src={url} type="" width="100%" height="400" />
                                                        </div>
                                                    </div>
                                                    <br></br>
                                                    <div className="col-lg-12 text-right">
                                                        {/*<input type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                                                        <button type="button" className="btn btn-primary" onClick={onButtonClick} data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Certificate"> Upload Certificate <i className="bx bx-upload" aria-hidden="true"></i></button>
                                                       */} <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                                        {/*<button onClick={deleteCertificate} type="button" className="btn btn-danger ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">Delete <i className="bx bx-trash" aria-hidden="true"></i></button>*/}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    handleClose={togglePopup} />}

                            </p></div>
                        </div>
                    </div>

                    <div className="col-lg-6 col-sm-6">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" readOnly={true} className="custom-control-input"
                                checked={isMandatory} />
                            <label className="custom-control-label" for="customSwitches">Is Mandatory</label>
                        </div>
                    </div>

                </div>
                <div className="text-right">

                    <br />

                    <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to Certificates <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                </div>
            </form>
        </div>
    </section>
};
export default CertificateView;
