import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { GlobalContext } from "../../../GlobalContext";
import { useNavigate, useLocation, useParams } from "react-router-dom";

const PTIRecordsView = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const [ptiRecords, setPtiRecords] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const { id } = useParams();
    const [clientList, setClientList] = useState([]);
    const [IncotermList, setIncotermList] = useState([]);
    const currency = "3";
    var incodata;

    useEffect(() => {     
             // Incoterms fetch
      const fetchIncoterms = async () => {
        try {
            const requestURL = globalState.endPointURL + "/incoterm/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null) {
                let data = responseData.incotermInfo?.filter((incoterm) => { return incoterm.isActive === true })
               // console.log(data);
                setIncotermList(data);
                incodata = data;
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };
        
        const fetchPTIRecord = async () => {
            try {
                const clientRequestURL = globalState.endPointURL + "/client/all";
                const headersUserName = window.localStorage.getItem("headers-username");
                globalState.headers.userName = headersUserName;
                const clientResponse = await fetch(clientRequestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const clientResponseData = await clientResponse.json();
               // console.log(clientResponseData);
                if (clientResponseData != null) {
                    clientResponseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                    let clientData = clientResponseData.clientInfo?.filter((client) => { return client.isActive === true })
                   // console.log(clientData);
                    setClientList(clientData);                
                    const requestURL = globalState.endPointURL + "/pti/" + id;
                    const response = await fetch(requestURL, {
                        method: 'GET',
                        headers: globalState.headers,
                    });
                    const responseData = await response.json();
                 //   console.log(responseData);
                    if (responseData != null) {
                        responseData?.ptiStatus?.map((pti) => {

                            pti.ptiRecords.map((ptiRecord) => {
                                ptiRecord.invDate = ptiRecord.invDate === null || ptiRecord.invDate === "null" ? "" : ptiRecord.invDate;
                                ptiRecord.shipDate = ptiRecord.shipDate === null || ptiRecord.shipDate === "null" ? "" : ptiRecord.shipDate;
                                ptiRecord.deliveryDate = ptiRecord.deliveryDate === null || ptiRecord.deliveryDate === "null" ? "" : ptiRecord.deliveryDate;

                                // globalState.ptiStatus?.filter((item) => {
                                //     if (item.name === String(ptiRecord.ptiStatus))
                                //         return ptiRecord.ptiStatusName = item.value;
                                // });
                                ptiRecord.ptiStatusName = ptiRecord.ptiStatus;

                                clientData?.filter((item) => {
                                    if (item.id === ptiRecord.exporter?.exporterId)
                                        return ptiRecord.exporter.exporterName = item.name;
                                });

                                clientData?.filter((item) => {
                                    if (item.id === ptiRecord.exporter?.shipperId)
                                        return ptiRecord.exporter.shipperName = item.name;
                                });

                                clientData?.filter((item) => {
                                    if (item.id === ptiRecord.importer?.importerId)
                                        return ptiRecord.importer.importerName = item.name;
                                });

                                clientData?.filter((item) => {
                                    if (item.id === ptiRecord.importer?.consigneeId)
                                        return ptiRecord.importer.consigneeName = item.name;
                                });

                                incodata?.filter((item) => {
                                    if (item.id === ptiRecord.shipment.incoterm)
                                        return ptiRecord.shipment.incotermName = item.name;
                                });

                                globalState.shipVia?.filter((item) => {
                                    if (item.name === String(ptiRecord.shipment.shipVia))
                                        return ptiRecord.shipment.shipViaName = item.value;
                                });

                                globalState.modeTran?.filter((item) => {
                                    if (item.name === String(ptiRecord.shipment.modeOfTransport))
                                        return ptiRecord.shipment.modeOfTransportName = item.value;
                                });

                                globalState.currency?.filter((item) => {
                                    if (item.name === String(ptiRecord.shipment.currency))
                                        return ptiRecord.shipment.currencyName = item.value;
                                });
                            
                            });
                        });
                        setPtiRecords(responseData.ptiStatus[0].ptiRecords[0]);
                    } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                        setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    else
                        setErrorMessage(globalState.commonErrorMessage);
                } else if (!clientResponseData?.isSuccess && clientResponseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(clientResponseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined) {
            fetchPTIRecord();
            fetchIncoterms();
        }
    }, []);

    // clients fetch
    const fetchClients = async () => {
        try {
            
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords");
        });
    };

    return <div className="dashboard">
        <div className="table-heading">
            <h4 className="w-50">PTI Records View</h4>
        </div>
        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="pti-detail-container">
          
            {/* <!-- calender --> */}
            <div className="row mt-3 mb-3 pti-date-grid p-0 m-0">
            <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>PTI Id</h5>
                        <p>{ptiRecords?.ptiId}</p>
                    </div>
                </div>
                <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>Invoice #</h5>
                        <p>{ptiRecords?.invoiceNumber}</p>
                    </div>
                </div>
                <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>Invoice Date </h5>
                        <p>{ptiRecords?.invDate}</p>
                    </div>
                </div>
                <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>Ship Date </h5>
                        <p>{ptiRecords?.shipDate}</p>
                    </div>
                </div>
                <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>PTI Status</h5>
                        <p>{ptiRecords?.ptiStatusName}</p>
                    </div>
                </div>

                <div className="col-lg-2 pr-0">
                    <div className="detail-view">
                        <h5>Delivery Date</h5>
                        <p>{ptiRecords?.deliveryDate}</p>
                    </div>
                </div>
            </div>
            {/* <!-- pti single container --> */}
            <div className="row">
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Exporter Details</div>
                        <div className="p-2">
                            {/* <!-- Exporter --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Exporter</h5>
                                    <p>{ptiRecords?.exporter?.exporterName}</p>
                                </div>
                            </div>
                            {/* <!-- end Exporter --> */}
                            {/* <!-- Shipper --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Shipper</h5>
                                    <p>{ptiRecords?.exporter?.shipperName}</p>
                                </div>
                            </div>
                            {/* <!-- end Shipper --> */}
                            {/* <!-- Country of Export --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Country of Export</h5>
                                    <p>{ptiRecords?.exporter?.countryOfExportId}</p>
                                </div>
                            </div>
                            {/* <!-- end Country of Export --> */}
                        </div>
                    </div>
                    {/* <!-- end single-pti-box  --> */}
                    </div>
                
                {/* <!-- end col-6 --> */}

                <div className="col-lg-6 col-sm-6">
                    <div className="single-pti-box">
 {/* <!-- single-pti-box --> */}
                        <div className="single-pti-box-header">Importer Details</div>
                        <div className="p-2">
                            {/* <!-- Importer --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Importer</h5>
                                    <p>{ptiRecords?.importer?.importerName}</p>
                                </div>
                            </div>
                            {/* <!-- end Exporter --> */}
                            {/* <!-- Consignee --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Consignee</h5>
                                    <p>{ptiRecords?.importer?.consigneeName}</p>
                                </div>
                            </div>
                            {/* <!-- end Consignee --> */}
                            {/* <!-- Country of Export --> */}
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Country of Export</h5>
                                    <p>{ptiRecords?.importer?.countryOfImportId}</p>
                                </div>
                            </div>
                            {/* <!-- end Country of Export --> */}
                        </div>

                        </div>
</div>


                <div className="col-lg-12 col-sm-12">
                    <div className="single-pti-box ">

                        <div className="single-pti-box-header">Shipment Details</div>
                        <div className="p-2 row">
                        <div className="col-sm-6">
            
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Incoterm</h5>
                                    <p>{ptiRecords?.shipment?.incotermName}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Ship Via</h5>
                                    <p>{ptiRecords?.shipment?.shipViaName}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>AWB-BL No</h5>
                                    <p>{ptiRecords?.shipment?.awbBlNumber}</p>
                                </div>
                            </div>

                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Mode of Transport</h5>
                                    <p>{ptiRecords?.shipment?.modeOfTransportName}</p>
                                </div>
                            </div>
                            </div>
                            <div className="col-sm-6">
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Currency</h5>
                                    <p>{ptiRecords?.shipment?.currencyName}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>Gross Wt.</h5>
                                    <p>{ptiRecords?.shipment?.grossWeight}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group form-group">
                                <div className="detail-view-flex">
                                    <h5>No. of Packages</h5>
                                    <p>{ptiRecords?.shipment?.numberOfPackage}</p>
                                </div>
                            </div>
</div>
                        </div>
                    </div>
                    {/* <!-- end single-pti-box --> */}
                    </div>


                    <div className="btn-footer text-right">
                        <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Back to PTI Record <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </div>
                {/* <!-- end col-6 --> */}
            </div>
            {/* <!-- end row --> */}


        </div>

};

export default PTIRecordsView;

