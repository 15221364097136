import React, { useEffect, useState } from "react";
import { BarChart, Bar, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {createUseStyles} from 'react-jss'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalContext } from "../GlobalContext";

const LifetimeOfRecords = () => {

    const [fromDate, setFromDate] = React.useState(dayjs(new Date).subtract(1,'month'));
    const [toDate, setToDate] = React.useState(dayjs(new Date));
    const { globalState } = React.useContext(GlobalContext)
    const [errorMessage, setErrorMessage] = useState("")
    const [lifetime,setLifetime] = useState({})

    const PickDate = ()=>{
        const styles = createUseStyles({
          datePicker: {
            "& .MuiTextField-root": {
              width: 165,
              height:100
            }}
        })
        const classes = styles()
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              
            <div className={classes.datePicker}>
            <DatePicker
                label="From"
                value={fromDate} 
                onChange={(newValue) => setFromDate(newValue)}
                
              />
              </div>
              <div className={classes.datePicker}>
              <DatePicker
                label="To"
                value={toDate} 
                onChange={(newValue) => setToDate(newValue)}
                
              />
              </div>
      
            </DemoContainer>
          </LocalizationProvider>
      }

      useEffect(()=>{
        prepareIorEorData()
      },[fromDate,toDate])

      const prepareIorEorData = (async ()=> {


        
        try {
          const requestURL = globalState.endPointURL + "/dashboard/info";
          const reqObject={
            "startDate":fromDate.format("YYYY-MM-DD"),
            "endDate":toDate.format("YYYY-MM-DD"),
            "requestType":"ALL",
            "iorStatus":"ALL",
            "eorStatus":"ALL"
      
          }
          const headersUserName = window.localStorage.getItem("headers-username");
          globalState.headers.userName = headersUserName;
          
          const response = await fetch(requestURL, {
              method: 'POST',
              headers:globalState.headers,
              body:JSON.stringify(reqObject)
          });
      
          const data = await response.json();
          // console.log("data",data)
      
          if (data !== "" && data.isSuccess) {
            
            setLifetime(processLifetime(data))
          
          } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
              setErrorMessage(data.errorCodes.errorCodes[0].message);
          else
              setErrorMessage(globalState.commonErrorMessage);
      } catch (e) {
          setErrorMessage(globalState.commonErrorMessage);
      }
      })

      const processLifetime=(data)=>{

        let eorLifeTimeCount = data?.information?.eorLifeTimeCount;
        let iorLifeTimeCount = data?.information?.iorLifeTimeCount;
        let lessThanTenDays = 0;
        let lessThanTwentyDays = 0;
        let lessThanThirtyDays = 0;
        let greaterThanThirtyDays = 0;
        

        iorLifeTimeCount?.filter((record)=>{

          let lifeTime=parseInt(record?.days)
          let count = parseInt(record?.count)
           if(lifeTime<10){
            lessThanTenDays+=count
           }
           else if(lifeTime<20){
            lessThanTwentyDays+=count

           }
           else if(lifeTime<30){
            lessThanThirtyDays+=count

           }
           else if(lifeTime>30){
            greaterThanThirtyDays+=count

           }
        })

        eorLifeTimeCount?.filter((record)=>{

          let lifeTime=parseInt(record?.days)
          let count = parseInt(record?.count)
           if(lifeTime<10){
            lessThanTenDays+=count
           }
           else if(lifeTime<20){
            lessThanTwentyDays+=count

           }
           else if(lifeTime<30){
            lessThanThirtyDays+=count

           }
           else if(lifeTime>30){
            greaterThanThirtyDays+=count

           }
        })

        const lessThan10={name:"<10 days","days <10":lessThanTenDays}
        const lessThan20={name:"<20 days","days <20":lessThanTwentyDays}
        const lessThan30={name:"<30 days","days <30":lessThanThirtyDays}
        const greaterThan30={name:">30 days","days >30":greaterThanThirtyDays}


        const lifeTimeArray =[lessThan10,lessThan20,lessThan30,greaterThan30]

        return lifeTimeArray

    }

    return <div className="row"> 
    <div className="row"> 
    <h5 style={{align:"center"}}>Lifetime Of Records</h5>
    <PickDate/>
    </div>
    <div className="row">
              <BarChart
                width={500}
                height={300}
                data={lifetime}
                margin={{
                  top: 5,
                  right: 0,
                  left: 0,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="days <10" fill="#9c9fa2" />
                <Bar dataKey="days <20" fill="#ff0000"/>
                <Bar dataKey="days <30" fill="#018500" />
                <Bar dataKey="days >30" fill="#fa9a50"/>
              </BarChart>

    </div>
    </div>
};
export default LifetimeOfRecords;
