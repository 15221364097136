import React, { useState, useCallback, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import csc from "country-state-city";
import { confirm } from "react-confirm-box";


const SerializedPart = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const gridRef = useRef();
    const [listActive, setListActive] = useState(true);
    const [editActive, setEditActive] = useState(false);
    const [serializedParts, setSerializedParts] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [quentity, setQuentity] = useState("1");
    const [coi, setCoi] = useState("");
    const [coo, setCoo] = useState("");
    const [serialId, setSerialId] = useState("");
    //const [part, setPart] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [savePart, setSavePart] = useState(true);
    const [partErrorMessage, setPartErrorMessage] = useState("");
    const [serialIdErrorMessage, setSerialIdErrorMessage] = useState("");
    const [coiErrorMessage, setCoiErrorMessage] = useState("");
    const [cooErrorMessage, setCooErrorMessage] = useState("");
    const [editId, setEditId] = useState("");
    

    const { register, handleSubmit, reset, formState, clearErrors } = useForm();
    
    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    const updatedStates = (clientCountry) => csc.getStatesOfCountry(clientCountry).map((state) => ({ label: state.name, value: state.id, ...state }));
    const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));

    const viewSerializedPart = (id) => {
        setSavePart(false);
        setListActive(!listActive);
        setEditActive(!editActive);
        clearingValues();
        setErrorMessage("");
        renderPartData(id);
    }

    const editSerializedPart = (id) => {
        handleClick();
        renderPartData(id);
    }

    const deleteSerializedPart = async (serialId) => {        
        let itemDetails = globalState?.itemParts?.itemDetails.slice();
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result) {
            //console.log(itemDetails);
            //console.log(serialId);
            let index = itemDetails.findIndex(function (item, i) {
                return item.serialId === serialId;
            });
            if(index >= 0)
                globalState?.itemParts?.itemDetails.splice(index, 1);

            itemDetails = globalState?.itemParts?.itemDetails.slice();
            setSerializedParts(itemDetails);
        }
    }

    const actionButton = (event) => {
        return (
            <p>
                <button type="button" onClick={() => viewSerializedPart(event.data.serialId) } className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button type="button" onClick={() => editSerializedPart(event.data.serialId) } className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button type="button" onClick={() => deleteSerializedPart(event.data.serialId) } className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const columnDef = [  
        { headerName: 'Serial', field: "serialId", sortable: true, filter: true },
        { headerName: 'COI', field: "coi", sortable: true, filter: true },
        { headerName: 'COO', field: "coo", sortable: true, filter: true },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const onGridReady = () => {
        try {
            //console.log(globalState?.itemParts);
            let itemDetails = globalState?.itemParts?.itemDetails.slice();            
            setSerializedParts(itemDetails);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            //console.log(e);
        }
    };

    const renderPartData = (serialId) => {
        setEditId(serialId);
        setErrorMessage("");
        setPartErrorMessage("");
        setSerialIdErrorMessage("");
        setCoiErrorMessage("");
        setCooErrorMessage("");
        let itemDetails = globalState?.itemParts?.itemDetails.slice();
        let responseData = itemDetails.filter(item => { return String(item.serialId) === serialId });
        if (responseData?.length > 0) {
            setSerialId(responseData[0].serialId);
            setCoi(responseData[0].coi);
            setCoo(responseData[0].coo);
            setQuentity(responseData[0].quentity);
        } else {
            clearingValues();
            //setPart(serialId);
            setEditId("");            
        }
    }

    const handleClick = (event) => {
        clearErrors();
        setListActive(!listActive);
        setEditActive(!editActive);
        clearingValues();
        setErrorMessage("");
        setSavePart(true);
        setEditId("");
    }

    const saveAndAdd = () => {
        if (validateForm()) {
            onSubmit();
            clearingValues();
        }        
    }

    const saveAndClose = () => {
        if (validateForm()) {
            onSubmit();
            clearingValues();
            handleClick();
        }
    }

    const clearingValues = () => {
        setCoi("");
        setCoo("");        
        setQuentity("1");
        setSerialId("");
        setCoiErrorMessage("");
        setCooErrorMessage("");
        setPartErrorMessage("");
        setSerialIdErrorMessage("");
    }

    const validateForm = () => {
        let returnVal = true;
        if (serialId === undefined || serialId === null || serialId === '') {
            setSerialIdErrorMessage("Please enter serial id");
            returnVal = false;
        }
        if (coi === undefined || coi === null || coi === '') {
            setCoiErrorMessage("Please enter country of  import");
            returnVal = false;
        }
        if (coo === undefined || coo === null || coo === '') {
            setCooErrorMessage("Please enter country of origin");
            returnVal = false;
        }
        return returnVal;
    };

    const onSubmit = () => {
        const createState = {
            "quentity": quentity === undefined ? "" : quentity,
            "coi": coi === undefined ? "" : coi,
            "coo": coo === undefined ? "" : coo,
            "serialId": serialId === undefined ? "" : serialId            
        };
        handleSave(createState);               
    }

    const handleClose = () => {
        //console.log(globalState?.itemParts?.itemDetails);
        if (globalState.itemPartRedirect === "View") {
            const itemId = globalState?.itemParts?.itemId;
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-view/" + itemId);
            });
        } else if (globalState.itemPartRedirect === "Add") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details");
            });
        } else if (globalState.itemPartRedirect === "Edit") {
            const itemId = globalState?.itemParts?.itemId;
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details/" + itemId);
            });
        }
    };

    const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handleSave = (data) => {
        try {
            let itemDetails = globalState?.itemParts?.itemDetails.slice();
            let responseData = itemDetails.filter(item => { return String(item.serialId) === data.serialId });
            if (responseData?.length > 0) {
                globalState?.itemParts?.itemDetails?.filter(item => {
                    if (item.serialId === data.serialId) {
                        item.coi = data.coi;
                        item.coo = data.coo;
                        item.quentity = data.quentity;                        
                        item.serialId = data.serialId;
                    }
                });
            } else {
                globalState?.itemParts?.itemDetails.push(data);
            }            
            itemDetails = globalState?.itemParts?.itemDetails.slice();
            setSerializedParts(itemDetails);     
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            //  console.log(e);
             }
    }; 

    const handleQuentity = (val) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (val === '' || re.test(val)) {
            setQuentity(val);
        }        
    };

    const activeStyle = {
        display: 'block',
    };
    const inactiveStyle = {
        display: 'none',
    };
    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4>Serialized Parts</h4>
            </div>

            <div className="serialized">
                <ul className="nav nav-tabs serialized-nav">
                    <li id={"first"} onClick={handleClick} className={listActive ? "active" : ""}  ><a>List</a></li>
                    {globalState.itemPartRedirect !== "View" ?
                        editId === undefined || editId === "" ?
                            <li id={"second"} onClick={handleClick} className={editActive ? "active" : ""}  ><a>Add</a></li>
                            : (savePart ? < li id={"second"} onClick={handleClick} className={editActive ? "active" : ""}  ><a>Edit</a></li>
                                : < li id={"second"} onClick={handleClick} className={editActive ? "active" : ""}  ><a>View</a></li>)
                            : ""}
                    
                </ul>

                <div className="tab-content">
                    <div id="firstcontent" className={listActive ? "active" : ""} style={listActive ? activeStyle : inactiveStyle}>
                        {globalState.itemPartRedirect !== "View" ?
                            <div className="col-sm-6 pl-0">
                                <form action="">
                                    <div className="form-group">
                                        <label for="">Parts#</label>
                                        <input type="text" className="w-100 p-2" value={globalState?.itemParts?.part} readOnly={true} />
                                    </div>
                                </form>
                            </div>
                            : ""}
                        <div className="example-header">
                            Page Size:
                            <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div><br />
                        <div className="ag-theme-alpine" style={{ height: 420 }}>
                            <AgGridReact
                                ref={gridRef}
                                rowData={serializedParts}
                                columnDefs={columnDef}
                                pagination={true}
                                onFirstDataRendered={onFirstDataRendered}
                                onGridReady={onGridReady}
                                paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                            ></AgGridReact>
                        </div>                        
                        <div className="btn-footer text-right mt-4">
                            <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i>
                            </button>
                        </div>
                    </div>
                    {globalState.itemPartRedirect !== "View" ?
                        <div id="secondcontent" className={editActive ? "active" : ""} style={editActive ? activeStyle : inactiveStyle}>
                            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                            <form action="" method="">
                                <div className="row mb-4">

                                    <div className="col-lg-6 col-md-6">
                                        <label>Parts #</label>
                                        {savePart ? 
                                        <div className="input-box">
                                                <input type="text" readOnly={true} className="form-control" name="part" placeholder="" value={globalState?.itemParts?.part} />
                                            <div className="text-warning">{partErrorMessage}</div>
                                            </div>
                                            : 
                                        <div className="detail-view">
                                                <p>{globalState?.itemParts?.part}</p>
                                        </div>}
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <label>Serial #</label>
                                        {savePart ? <div className="input-box">
                                            <input type="text" className="form-control" name="serialId" placeholder="" value={serialId} onChange={e => { setSerialId(e.target.value); setSerialIdErrorMessage(""); }} />
                                            <div className="text-warning">{serialIdErrorMessage}</div>
                                        </div>
                                            : 
                                            <div className="detail-view">
                                                <p>{serialId}</p>
                                            </div>}
                                       
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <label>Country of Import
                                        </label>
                                        {savePart ?
                                            <div className="select-box">
                                                <select name="coi" value={coi} onChange={e => { setCoi(e.target.value); setCoiErrorMessage(""); }} >
                                                    <option value="">Select</option>
                                                    {updatedCountries.map((country) => {
                                                        return <option value={country.name}>{country.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            : <div className="detail-view">
                                                <p>{coi}</p>
                                            </div>}
                                        <div className="text-warning">{coiErrorMessage}</div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <label>Country Of Origin</label>
                                        
                                        {savePart ?                                            
                                            <div className="select-box">
                                                <select name="coo" value={coo} onChange={e => { setCoo(e.target.value); setCooErrorMessage(""); }} >
                                                    <option value="">Select</option>
                                                    {updatedCountries.map((country) => {
                                                        return <option value={country.name}>{country.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            : <div className="detail-view">
                                                <p>{coo}</p>
                                            </div>}
                                        
                                        <div className="text-warning">{cooErrorMessage}</div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <label>Total Serialized Quality</label>
                                        {savePart ? 
                                            <div className="input-box">
                                                <input type="text" className="form-control" name="quentity" placeholder="" value={quentity} onChange={e => setQuentity(e.target.value)} />
                                            </div>
                                            : <div className="detail-view">
                                                <p>{quentity}</p>
                                            </div>}
                                        
                                    </div>

                                </div>
                                
                                    {savePart ?
                                    <div className="btn-footer text-right">                                
                                        <button type="button" onClick={handleSubmit(saveAndAdd)} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save & More">Save & Add More <i className="fa fa-check-circle" aria-hidden="true"></i>
                                            </button>
                                        <button type="button" onClick={handleSubmit(saveAndClose)} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save & Close">Save & Close <i className="fa fa-check-circle" aria-hidden="true"></i>
                                                </button>
                                        <button type="button" onClick={handleClick} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    : <div className="btn-footer text-right">
                                    <button type="button" onClick={handleClick} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i>
                                    </button>
                                </div>}                                    
                            </form>
                        </div>
                        : ""}
                </div>
            </div>
        </div>

    </section>
};
export default SerializedPart;
