
import { Navbar } from "react-bootstrap";
import classNames from "classnames";
import React, { useRef } from "react";
import { useDetectOutsideClick } from "./useDetectOutsideClick";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
const Topbar = ({ toggleSideBar }) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive] = useDetectOutsideClick(dropdownRef, false);
    const onClick = () => {
        setIsActive(!isActive);
    };
    const { logout } = useAuth();
    const navigate = useNavigate();
    const handleLogout = () => {
        logout();
        navigate("/");
        window.localStorage.setItem("login-user", false);
    };
  
  return (
    <Navbar className="main-header">      
      <div className="sidebar-toggle" onClick={() => toggleSideBar()}>
        <i className="bx bx-menu"></i>
      </div>     
      <div className={classNames("justify-content-end")}>
      <div className="container">
      <div className="menu-container">
        <button ref={dropdownRef}  onClick={onClick} className="menu-trigger">
          <img src="https://webdamn.com/demo/forum-php-mysql-demo/images/user-avatar.png" alt="User avatar"/>
        </button>
             <nav className={`menu ${isActive ? "active" : "inactive"}`}>
          <ul>
            <li>
              <p>Profile</p>
            </li>
            <li>
              <p>Change Password</p>
            </li>
            <li>
             <p onClick={handleLogout}>Logout</p>
            </li>
          </ul>
        </nav>
      </div>
        </div>
      </div>
    </Navbar>
  );
};
export default Topbar;
