import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import csc from "country-state-city";
import { confirm } from "react-confirm-box";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { AgGridReact } from 'ag-grid-react';
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const PTIRecordDetails = () => {
    const navigate = useNavigate();
    const { handleSubmit, formState } = useForm();
    const gridRef = useRef();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const [seqId, setSeqId] = useState('');
    const [ptiId, setPtiId] = useState('');
    const [ptiStatus, setPtiStatus] = useState('');
    const [ptiStatusDDL, setPtiStatusDDL] = useState('');
    const [partStatus, setPartStatus] = useState('');
    const [exporter, setExporter] = useState('');
    const [shipperId, setShipperId] = useState('');
    const [countryExp, setCountryExp] = useState('');
    const [consignee, setConsignee] = useState('');
    const [countryImp, setCountryImp] = useState('');
    const [incotermId, setIncotermId] = useState('');
    const [shipVia, setShipVia] = useState('');
    const [wayBillNo, setWayBillNo] = useState('');
    const [modeTran, setModeTran] = useState('');
    const [currency, setCurrency] = useState('');
    const [grossWt, setGrosswt] = useState('');
    const [noPkg, setNoPkg] = useState("");
    const [importerId, setImporterId] = useState('');
    const [shipDate, setShipDate] = useState('');
    const [deliveryDate, setDeliveryDate] = useState('');
    const [invoiceDate, setInvoiceDate] = useState('');
    const [invoice, setInvoice] = useState('');
    const [shipmentItemDetails, setShipmentItemDetails] = useState([]);
    const [shipItemDetails, setShipItemDetails] = useState([]);
    const [iorDetails, setIorDetails] = useState([]);
    const { id } = useParams();
    const [errorMessage, setErrorMessage] = useState("");
    const [invoiceMessage, setInvoiceMessage] = useState("");
    const [invoiceDateMessage, setInvoiceDateMessage] = useState("");
    const [shipDateMessage, setShipDateMessage] = useState("");
    const [deliveryDateMessage, setDeliveryDateMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const [clientList, setClientList] = useState([]);
    const [IncotermList, setIncotermList] = useState([]);
    const [ptiStatusList, setPtiStatusList] = useState([]);
    const [noOfPackageMessage, setNoOfPackageMessage] = useState("");
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage, confirmNavigation, cancelNavigation ] = useNavigatingAway(canShowDialogLeavingPage);


    const noofTotalShipments = globalState.ptiRecord.shipmentItemDetails === undefined || globalState.ptiRecord.shipmentItemDetails === "" ? 0 : parseInt(globalState.ptiRecord.shipmentItemDetails.length)
    const total = noPkg === 0 || noPkg === "" || noPkg === undefined ? 0 : parseInt(noPkg);
    useEffect(() => {
        if (total != noofTotalShipments) {
            // console.log(responseData.shipmentItemDetails);
            setCanShowDialogLeavingPage(true);
        }
    }, []);

    const fetchPtiSatus = async () => {
        try {
            const requestURL = globalState.endPointURL + "/ptistatus/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,

            });
            const responseData = await response.json();
            //console.log(responseData);
            // console.log(data);
            var statusArray = []
            if (responseData != null) {


                let data = responseData.ptiStatusInfo?.filter((ptiStatus) => { return ptiStatus.isActive === true })

                setPtiStatusList(data);
               // console.log("PTI status data", data);

            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);

            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    }


    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    const domLayout = "autoHeight";

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const clearShipmentDetail = {
        shipmentId: "",
        id: "",
        itemStatus: false,
        "assess": "",
        "bcd": "",
        "acd": "",
        "sws": "",
        "sad": "",
        "igst": "",
        "cess": "",
        "add": "",
        "cvd": "",
        "sg": "",
        "tValue": "",
        partNo: "",
        internalOrderNo: "",
        serialNo: "",
        mfgName: "",
        desc: "",
        countryOrg: "",
        quantity: "",
        unitPrice: "",
        umo: "",
        uom: {
            id: ""
        },
        amount: "",
        eccn: "",
        encCode: "",
        hsCode: "",
        usCode: "",
        htsCode: "",
        certificates: []
    };
    
    const addShipmentButton = document.getElementsByClassName("addbutton");

    for (let i = 0; i < addShipmentButton.length; i++) {
        addShipmentButton[i].addEventListener("click", function () {
            setCanShowDialogLeavingPage(false);
        });
    }

    const uploadDocumentButton = document.getElementsByClassName("uploadDocument");
    
   for (let i = 0; i < uploadDocumentButton.length; i++) {
      uploadDocumentButton[i].addEventListener("click", function () {
           setCanShowDialogLeavingPage(false);
       });
    }

    //Add User
    const addPtiShipment = () => {
        if (invoice === undefined || invoice === "") {
            setInvoiceMessage("Please enter the invoice");
            document.getElementById("invoice").focus();
            return false;
        } else {
            globalState.shipmentDetail = clearShipmentDetail;
            globalState.ptiRecord = updateGlobalState;
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItem");
            });
            setCanShowDialogLeavingPage(false);
        }
        setCanShowDialogLeavingPage(false);
    };

    //Add User
    const editPtiShipment = (shipmentId) => {
        globalState.shipmentDetail = clearShipmentDetail;
        globalState.ptiRecord = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItem/" + shipmentId);
        });
    };

    // view
    const viewPtiShipment = (shipmentId) => {
        globalState.shipmentDetail = clearShipmentDetail;
        globalState.ptiRecord = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItemView/" + shipmentId);
        });
    };

    // clients fetch
    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
               // console.log(data);
                setClientList(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };



    // Incoterms fetch
    const fetchIncoterms = async () => {
        try {
            const requestURL = globalState.endPointURL + "/incoterm/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null) {
                let data = responseData.incotermInfo?.filter((incoterm) => { return incoterm.isActive === true })
                //console.log(data);
                setIncotermList(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };


    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewPtiShipment(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editPtiShipment(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deletePtiShipment(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const columnDef = [
        { headerName: '#', field: "id", valueGetter: 'node.rowIndex+1', sortable: true, filter: true },
        { headerName: 'Parts', field: "partNo", sortable: true, filter: true },
        { headerName: 'Serial#', field: "serialNo", sortable: true, filter: true },
        { headerName: 'HS Code', field: "hsCode", sortable: true, filter: true },
        { headerName: 'Description', field: "desc", sortable: true, filter: true },
        { headerName: 'Eccn', field: "eccn", sortable: true, filter: true },
        { headerName: 'Qty', field: "quantity", sortable: true, filter: true },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.id, ...state }));
    //const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));

    useEffect(() => {
        fetchPtiSatus();
        fetchClients();
        fetchIncoterms();
        const fetchPTIRecord = async () => {
            try {
                const requestURL = globalState.endPointURL + "/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
              //  console.log(responseData);

                if (responseData != null && responseData.isSuccess) {
                    mappingData(responseData.ptiStatus[0].ptiRecords[0]);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        };
        if (id !== undefined) {
            if (globalState?.ptiRecord?.ptiId) {
                const responseData = globalState?.ptiRecord;
                mappingGlobalPTIRecordData(responseData);
            } else {
                fetchPTIRecord();
            }
            globalState.ptiRecordRedirect = "Edit";
        } else {
            const responseData = globalState?.ptiRecord;
            mappingGlobalPTIRecordData(responseData);
            globalState.ptiRecordRedirect = "Add";
        }
       // console.log(globalState?.ptiRecord);
    }, []);


    // Delete with fetchAPI
    const deletePtiShipment = async (shipmentId) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        // if (result) {
        //     globalState.ptiRecord = updateGlobalState;
        //     try {
        //         const requestURL = globalState.endPointURL + "/shipment/id/" + shipmentId;
        //         const response = await fetch(requestURL, {
        //             method: 'DELETE',
        //             headers: globalState.headers,
        //         });
        //         const responseData = await response.json();
        //          console.log(responseData);
        //         if (responseData !== "" && responseData.isSuccess) {

        //         } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
        //             setErrorMessage(responseData.errorCodes.errorCodes[0].message);
        //         else
        //             setErrorMessage(globalState.commonErrorMessage);
        //     } catch (e) {
        //         setErrorMessage(globalState.commonErrorMessage);
        //         console.log(e);
        //     }
        // }

        if (result) {
            globalState.ptiRecord = updateGlobalState;
            try {
                const requestURL = globalState.endPointURL + "/shipment/id/" + shipmentId;
                const response = await fetch(requestURL, {
                    method: 'DELETE',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                //console.log(responseData);
                globalState.ptiRecord.shipmentItemDetails = globalState.ptiRecord.shipmentItemDetails.filter((item) => { return item.id !== shipmentId; });
                const updatedShipments = globalState.ptiRecord.shipmentItemDetails?.filter((item) => {
                    if (item !== null) {
                        return {
                            item
                        }
                    }
                });

                setShipmentItemDetails(updatedShipments);
                setShipItemDetails(updatedShipments);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        }
    };


    const formingDataToPTIRecord = (ptiRecords) => {
        if (ptiRecords.documents !== null) {
            ptiRecords.documents = ptiRecords.documents.filter((item) => {
                if (item !== null) {
                    return item;
                }
            });
        }

        const updatePTIRecord = {
            seqId: ptiRecords?.id,
            ptiId: ptiRecords?.ptiId,
            exporterId: ptiRecords?.exporterDetls?.exporter?.expId,
            invNo: ptiRecords?.invNo,
            invDate: ptiRecords?.invDate,
            shipDate: ptiRecords?.shipDate,
            deliveryDate: ptiRecords?.deliveryDate,
            ptiStatus: ptiRecords?.ptiStatus,
            partStatus: ptiRecords?.partItemStatus?.id,
            shipperId: ptiRecords?.exporterDetls?.shipper?.id,
            importerId: ptiRecords?.importerDetails?.importer?.id,
            countryImp: ptiRecords?.importerDetails?.countryImport,
            consignee: ptiRecords?.importerDetails?.consignee?.id,
            countryExp: ptiRecords?.exporterDetls?.countryExport,
            shipVia: ptiRecords?.shipmentDetails?.shipVia?.id,
            incotermId: ptiRecords?.shipmentDetails?.incoTerms?.incoTermId,
            wayBillNo: ptiRecords?.shipmentDetails?.wayBillNo,
            modeTran: ptiRecords?.shipmentDetails?.modeTrans,
            currency: ptiRecords?.shipmentDetails?.currency,
            grossWt: ptiRecords?.shipmentDetails?.grossWt,
            noPkg: ptiRecords?.shipmentDetails?.noPckg,
            shipmentItemDetails: formingShipmentDetails(ptiRecords?.shipmentItem === null ? [] : ptiRecords?.shipmentItem),
            // shipItemDetails: globalState.ptiRecord.shipItemDetails,
            iorDetails: ptiRecords?.documents === null ? [] : ptiRecords?.documents
        };
        globalState.ptiRecord = updatePTIRecord;
    };

    const mappingGlobalPTIRecordData = (responseData) => {
        globalState.ptiRecord = responseData;
        setSeqId(responseData?.id);
        setPtiId(responseData?.ptiId);
        setInvoice(responseData?.invNo);
        setInvoiceDate(responseData?.invDate);
        setShipDate(responseData?.shipDate);
        setDeliveryDate(responseData?.deliveryDate);
        setPtiStatus(responseData?.ptiStatus === "" || responseData.ptiStatus === undefined ? "Pending Approval" : responseData.ptiStatus);
        setPtiStatusDDL(responseData?.ptiStatus === "" || responseData.ptiStatus === undefined ? "Pending Approval" : responseData.ptiStatus);
        setPartStatus(responseData?.partStatus);
        setExporter(responseData?.exporterId);
        setShipperId(responseData?.shipperId);
        setCountryExp(responseData?.countryExp);
        setImporterId(responseData?.importerId);
        setConsignee(responseData?.consignee);
        setCountryImp(responseData?.countryImp);
        setIncotermId(responseData?.incotermId);
        setShipVia(responseData?.shipVia);
        setWayBillNo(responseData?.wayBillNo);
        setModeTran(responseData?.modeTran);
        setCurrency(responseData?.currency);
        setGrosswt(responseData?.grossWt);
        setNoPkg(responseData?.noPkg);
        setShipmentItemDetails(responseData?.shipmentItemDetails);
        setIorDetails(responseData?.iorDetails);
    }

    const mappingData = (responseData) => {
        formingDataToPTIRecord(responseData);
        setSeqId(responseData?.id);
        setPtiId(responseData?.ptiId);
        setInvoice(responseData?.invoiceNumber);
        setInvoiceDate(responseData?.invDate === "null" ? "" : responseData?.invDate);
        setShipDate(responseData?.shipDate === "null" ? "" : responseData?.shipDate);
        setDeliveryDate(responseData?.deliveryDate === "null" ? "" : responseData?.deliveryDate);
        setPtiStatus(responseData?.ptiStatus === "" || responseData?.ptiStatus === undefined ? "Pending Approval" : responseData?.ptiStatus);
        setPtiStatusDDL(responseData?.ptiStatus === "" || responseData?.ptiStatus === undefined ? "Pending Approval" : responseData?.ptiStatus);

        //setPartStatus(responseData?.partItemStatus?.id);
        setExporter(responseData?.exporter?.exporterId);
        setShipperId(responseData?.exporter?.shipperId);
        setCountryExp(responseData?.exporter?.countryOfExportId);
        setImporterId(responseData?.importer?.importerId);
        setConsignee(responseData?.importer?.consigneeId);
        setCountryImp(responseData?.importer.countryOfImportId);
        setIncotermId(responseData?.shipment?.incoterm);
        setShipVia(responseData?.shipment?.shipVia);
        setWayBillNo(responseData?.shipment?.awbBlNumber);
        setModeTran(responseData?.shipment?.modeOfTransport);
        setCurrency(responseData?.shipment?.currency);
        setGrosswt(responseData?.shipment?.grossWeight);
        setNoPkg(responseData?.shipment?.numberOfPackage);
        setShipmentItemDetails(formingShipmentDetails(responseData?.shipmentItem === null ? [] : responseData?.shipmentItem));
        globalState.shipItemDetails =  (formingShipmentDetails(responseData?.shipmentItem === null ? [] : responseData?.shipmentItem));
        setIorDetails(responseData?.documents === null ? [] : responseData?.documents);
    };

    const formingShipmentDetails = (shipmentDataList) => {
        const shipmentDetailsList = [];
        shipmentDataList?.map((shipmentData) => {
            const shipmentDetails = {
                id: shipmentData?.id,
                itemStatus: shipmentData?.itemStatus,
                assess: shipmentData?.assess,
                bcd: shipmentData?.bcd,
                acd: shipmentData?.acd,
                sws: shipmentData?.sws,
                sad: shipmentData?.sad,
                igst: shipmentData?.igst,
                cess: shipmentData?.cess,
                add: shipmentData?.add,
                cvd: shipmentData?.cvd,
                sg: shipmentData?.sg,
                tValue: shipmentData?.tValue,
                partNo: shipmentData?.item.partNumber,
                internalOrderNo: shipmentData?.item.internalOrderNumber,
                serialNo: shipmentData?.item.serialNumber,
                mfgName: shipmentData?.item.manufactureNumber,
                desc: shipmentData?.item.description,
                countryOrg: shipmentData?.itemDetails.countryOfOrigin,
                quantity: shipmentData?.itemDetails.quantity,
                unitPrice: shipmentData?.itemDetails.unitPrice,
                umo: shipmentData?.itemDetails?.uomId,
                uom: {
                    id: shipmentData?.itemDetails?.uomId
                },
                amount: shipmentData?.itemDetails.amount,
                eccn: shipmentData?.codes.eccn,
                encCode: shipmentData?.codes.encCode,
                hsCode: shipmentData?.codes.itcHsCode,
                usCode: shipmentData?.codes.usCode,
                htsCode: shipmentData?.codes.htsCode,
                certificates: shipmentData?.certificates
            };
            shipmentDetailsList.push(shipmentDetails);
        });
        return shipmentDetailsList;
    };

    const formDocumentsRequest = (iorDetails) => {

    };

    const onSubmit = (data) => {

        const noOfShipmentsAttached = globalState.ptiRecord.shipmentItemDetails === undefined || globalState.ptiRecord.shipmentItemDetails === "" ? 0 : parseInt(globalState.ptiRecord.shipmentItemDetails.length)
        const noOfPackages = noPkg === 0 || noPkg === "" || noPkg === undefined ? 0 : parseInt(noPkg)

        setUploadDisable(true);
        if (invoice === undefined || invoice === "") {
            setInvoiceMessage("Please enter the invoice");
            setUploadDisable(false);
            document.getElementById("invoice").focus();
            return false;
        }
        else if (ptiStatus === "Shipped" && (shipDate === undefined || shipDate === "")) {
            setShipDateMessage("Please enter the shipment date");
            setUploadDisable(false);
            document.getElementById("shipDate").focus();
            return false;
        } else if (ptiStatus === "Delivered" && (deliveryDate === undefined || deliveryDate === "")) {
            setDeliveryDateMessage("Please enter the delivery date");
            setUploadDisable(false);
            document.getElementById("deliveryDate").focus();
            return false;
        }
        // else if (noOfPackages != noOfShipmentsAttached) {

        //     setNoOfPackageMessage("No. of packages should be equal to number of shipment items");
        //     setUploadDisable(false);
        //     document.getElementById("noPackage").focus();
        //     return false;
        // }
        else {
            const updatedDocuments = iorDetails?.flatMap((item) => {
                return {
                    id: item.id,
                }
            });
            const updatedShipments = shipmentItemDetails?.flatMap((item) => {
                return {
                    id: item.id,
                }
            });

            const createState = {

                documents: updatedDocuments,
                exporter: {
                    countryOfExportId: countryExp === undefined ? null : countryExp,
                    exporterId: exporter === undefined || exporter === null ? 0 : parseInt(exporter),
                    shipperId: shipperId === undefined || shipperId === null ? 0 : parseInt(shipperId),
                },
                importer: {
                    consigneeId: consignee === undefined || consignee === null ? 0 : parseInt(consignee),
                    countryOfImportId: countryImp === undefined || countryImp === null ? null : countryImp,
                    importerId: importerId === undefined || importerId === null ? 0 : parseInt(importerId)
                },
                invDate: invoiceDate === "" || invoiceDate === undefined || invoiceDate === null ? null : invoiceDate,
                invoiceNumber: invoice === undefined || invoice === null ? "" : invoice,
                ptiStatus: ptiStatus === undefined || ptiStatus === null ? 0 : String(ptiStatus),
                shipDate: shipDate === "" || shipDate === undefined || shipDate === null ? null : shipDate,
                deliveryDate: deliveryDate === "" || deliveryDate === undefined || deliveryDate === null ? null : deliveryDate,
                shipment: {
                    awbBlNumber: wayBillNo === undefined || wayBillNo === null ? "" : wayBillNo,
                    currency: currency === undefined || currency === null ? 0 : parseInt(currency),
                    grossWeight: grossWt === undefined || grossWt === null ? "" : grossWt,
                    incoterm: incotermId === undefined || incotermId === null ? 0 : parseInt(incotermId),
                    modeOfTransport: modeTran === undefined || modeTran === null ? 0 : parseInt(modeTran),
                    numberOfPackage: noPkg === undefined || noPkg === null ? 0 : parseInt(noPkg),
                    shipVia: shipVia === undefined || shipVia === null ? 0 : parseInt(shipVia),
                },
                shipmentItem: updatedShipments
            };

            handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                let requestURL = globalState.endPointURL;
                let methodType;
                if (id !== undefined) {
                    requestURL = requestURL + "/pti/";
                    data.id = id;
                    methodType = "PUT";
                    data.ptiId = ptiId === undefined ? "" : parseInt(ptiId);
                } else {
                    requestURL = requestURL + "/pti/";
                    methodType = "POST";
                }
              //  console.log(JSON.stringify(data));
                const headersUserName = window.localStorage.getItem("headers-username");
                globalState.headers.userName = headersUserName;
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log(JSON.stringify(responseData));
               // console.log(responseData);
                if (id !== undefined) {
                    if (responseData !== null && responseData.isSuccess) {
                        handleClose();
                    } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                        //setErrorMessage(globalState.commonErrorMessage);
                        setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    }
                } else {
                    if (responseData !== null && responseData.isSuccess) {
                        handleClose();
                    } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                        setErrorMessage(globalState.commonErrorMessage);
                        //setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    }
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
           // console.log("Error" + e);
        }
        finally {
            setUploadDisable(false);
        }
    };
    const updateGlobalState = {
        "id": seqId,
        "ptiId": ptiId,
        "exporterId": exporter,
        "invNo": invoice,
        "invDate": invoiceDate,
        "shipDate": shipDate,
        "deliveryDate": deliveryDate,
        "ptiStatus": ptiStatus,
        "partStatus": partStatus,
        "shipperId": shipperId,
        "importerId": importerId,
        "countryImp": countryImp,
        "consignee": consignee,
        "countryExp": countryExp,
        "shipVia": shipVia,
        "incotermId": incotermId,
        "wayBillNo": wayBillNo,
        "modeTran": modeTran,
        "currency": currency,
        "grossWt": grossWt,
        "noPkg": noPkg,
        "shipmentItemDetails": shipmentItemDetails === undefined ? [] : shipmentItemDetails,
        "iorDetails": iorDetails === undefined ? [] : iorDetails,
    };

    //closed
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords");
        });
    };

    //Upload document 
    const iorDocument = () => {
        globalState.ptiRecord = updateGlobalState;
        if (invoice === undefined || invoice === "") {
            setInvoiceMessage("Please enter the invoive");
            document.getElementById("invoice").focus();
            return false;
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/IORDocument");
            });
              setCanShowDialogLeavingPage(false);
                 }
           setCanShowDialogLeavingPage(false);
        };
    


    return (<><DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation} 
        /> 
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="dashboard">
                <div className="table-heading">
                    <h4 className="w-50">PTI Records Detail</h4>
                </div>
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                <div className="pti-detail-container">
                    {/* <!-- calender --> */}
                    <div className="row mt-1 mb-1 pti-date-grid p-0 m-0">
                        <div className="col-lg-2 col-sm-4 pr-0">
                            <div className="from-group">
                                <label>PTI Id</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={true} name="ptiId" placeholder="" value={ptiId} onChange={e => setPtiId(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 pr-0">
                            <div className="from-group">
                                <label>Invoice #</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" id="invoice" name="invoice" value={invoice} onChange={e => { setInvoice(e.target.value); setInvoiceMessage(""); id !== undefined && setCanShowDialogLeavingPage(true); }} />
                                </div>
                                {invoiceMessage && <p className="text-warning"> {invoiceMessage} </p>}
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 pr-0">
                            <div className="from-group">
                                <label>Invoice Date </label>
                                <div className="date-box date-box-pti">
                                    <input type="date" className="form-control" id="invoiceDate" value={invoiceDate} onChange={e => { setInvoiceDate(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }} onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} placeholder="dd/mm/yyyy" />
                                </div>
                                {invoiceDateMessage && <p className="text-warning"> {invoiceDateMessage} </p>}
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 pr-0">
                            <div className="from-group">
                                <label>Shipment Date</label>
                                <div className="date-box date-box-pti">
                                    <input type="date" id="shipDate" value={shipDate} disabled={ptiStatus === "Pending Approval" ? true : false} onChange={e => { setShipDate(e.target.value); setPtiStatus("Shipped"); id !== undefined && setCanShowDialogLeavingPage(true); }} onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} placeholder="dd/mm/yyyy" />
                                </div>
                                {shipDateMessage && <p className="text-warning"> {shipDateMessage} </p>}
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 pr-0">
                            {/* <!-- PTI Status --> */}
                            <label>PTI Status</label>
                            <div className="select-box-pti select-box">
                                {ptiStatusDDL === "" || ptiStatusDDL === undefined || ptiStatusDDL === "Pending Approval" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {
                                            ptiStatusList?.map((item) => {
                                                return <option disabled={item.name === "Pending Approval" ? false : true} value={item.name}>{item.name}</option>
                                            })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Approved" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Approved" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Shipped" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Shipped" || item.name === "CL Approval Pending" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "CL Approval Pending" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatus}
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "CL Approval Pending" || item.name === "Checklist Approved" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}


                                {ptiStatusDDL === "Checklist Approved" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Checklist Approved" || item.name === "Assesed" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Assesed" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Assesed" || item.name === "Duty Paid" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Duty Paid" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Duty Paid" || item.name === "Custom Query" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Custom Query" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Custom Query" || item.name === "Out of Charge" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Out of Charge" ?
                                    <select value={ptiStatus}
                                        onChange={e => { e.target.value === "Out of Charge" ? setDeliveryDate("") : setDeliveryDate(deliveryDate); setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Out of Charge" || item.name === "Delivered" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Delivered" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Delivered" || item.name === "Billed" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}
                                {ptiStatusDDL === "Billed" ?
                                    <select value={ptiStatus}
                                        onChange={e => { setPtiStatus(e.target.value); }}>
                                        {ptiStatusList?.map((item) => {
                                            return <option disabled={item.name === "Billed" ? false : true} value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    : ""}


                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-4 pr-0">
                            <div className="from-group">
                                <label>Delivery Date</label>
                                <div className="date-box date-box-pti">
                                    <input type="date" id="deliveryDate" value={deliveryDate} disabled={ptiStatus === "" || (ptiStatus === "Pending Approval" || ptiStatus === "Approved" || ptiStatus === "Shipped" || ptiStatus === "CL Approval Pending" || ptiStatus === "Checklist Approved" || ptiStatus === "Assesed" || ptiStatus === "Duty Paid" || ptiStatus === "Custom Query" || ptiStatus === "Out of Charge") ? true : false} onChange={e => { setDeliveryDateMessage(""); setDeliveryDate(e.target.value); setPtiStatus("Delivered"); id !== undefined && setCanShowDialogLeavingPage(true); }} onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} placeholder="dd/mm/yyyy" />
                                </div>
                                {deliveryDateMessage && <p className="text-warning"> {deliveryDateMessage} </p>}
                            </div>
                        </div>
                    </div>
                    {/* <!-- pti single container --> */}
                    <div className="row">
                        <div className="col-lg-6 col-sm-12">
                            <div className="single-pti-box">
                                <div className="single-pti-box-header">Exporter Details</div>
                                <div className="p-2">
                                    {/* <!-- Exporter --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Exporter </label>
                                        <div className="select-box">
                                            <select value={exporter} onChange={e => { setExporter(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option value="">select</option>
                                                {clientList?.map((exporter) => {
                                                    return <option value={exporter.id}>{exporter.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Exporter --> */}
                                    {/* <!-- Shipper --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Shipper</label>
                                        <div className="select-box">
                                            <select value={shipperId} onChange={e => { setShipperId(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option >Select</option>
                                                {clientList?.map((shipper) => {
                                                    return <option value={shipper.id}>{shipper.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Shipper --> */}
                                    {/* <!-- Country of Export --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Country of Export</label>
                                        <div className="select-box">
                                            <select value={countryExp} onChange={e => { setCountryExp(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option value="">Select</option>
                                                {updatedCountries.map((country) => {
                                                    return <option value={country.name}>{country.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Country of Export --> */}
                                </div>
                            </div>
                            {/* <!-- end single-pti-box  --> */}
                        </div>
                        {/* <!-- end col-6 --> */}

                        <div className="col-lg-6">
                            {/* <!-- single-pti-box --> */}
                            <div className="single-pti-box">
                                <div className="single-pti-box-header">Importer Details</div>
                                <div className="p-2">
                                    {/* <!-- Importer --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Importer</label>
                                        <div className="select-box">
                                            <select value={importerId} onChange={e => { setImporterId(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option value="">Select</option>
                                                {clientList?.map((importer) => {
                                                    return <option value={importer.id}>{importer.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Exporter --> */}
                                    {/* <!-- Consignee --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Consignee</label>
                                        <div className="select-box">
                                            <select value={consignee} onChange={e => { setConsignee(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option value="">Select</option>
                                                {clientList?.map((item) => {
                                                    return <option value={item.id}>{item.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Consignee --> */}
                                    {/* <!-- Country of import --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Country of Import</label>
                                        <div className="select-box">
                                            <select value={countryImp} onChange={e => { setCountryImp(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                <option value="">Select</option>
                                                {updatedCountries.map((country) => {
                                                    return <option value={country.name}>{country.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <!-- end Country of import --> */}
                                </div>
                            </div>
                            {/* <!-- end single-pti-box  --> */}
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="single-pti-box">
                                <div className="single-pti-box-header">Shipment Details</div>
                                <div className="p-2 row">
                                    <div className="col-lg-6">
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Incoterm </label>
                                            <div className="select-box">
                                                <select value={incotermId} onChange={e => { setIncotermId(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                    <option value="">select</option>
                                                    {IncotermList?.map((item) => {
                                                        return <option value={item.id}>{item.name}</option>
                                                    })}
                                                </select>
                                                {/* <select value={incotermId} onChange={e => setIncotermId(e.target.value)}>
                                            <option value="">Select</option>
                                            {globalState.incotermId?.map((item) => {
                                                return <option value={item.name}>{item.value}</option>
                                            })}
                                        </select> */}
                                            </div>
                                        </div>
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Ship via</label>
                                            <div className="select-box">
                                                <select value={shipVia} onChange={e => { setShipVia(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                    <option value="">Select</option>
                                                    {globalState.shipVia?.map((item) => {
                                                        return <option value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>AWB-BL No</label>
                                            <div className="input-box">
                                                <input type="text" className="form-control" value={wayBillNo} onChange={e => { setWayBillNo(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }} />
                                            </div>
                                        </div>
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Mode of Transport</label>
                                            <div className="select-box">
                                                <select value={modeTran} onChange={e => setModeTran(e.target.value)}>
                                                    <option value="">Select</option>
                                                    {globalState.modeTran?.map((item) => {
                                                        return <option value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>

                                    </div>


                                    <div className="col-lg-6">
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Currency
                                            </label>
                                            <div className="select-box">
                                                <select value={currency} onChange={e => { setCurrency(e.target.value); id !== undefined && setCanShowDialogLeavingPage(true); }}>
                                                    <option value="">Select</option>
                                                    {globalState.currency?.map((currency) => {
                                                        return <option value={currency.name}>{currency.value}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>Gross Wt.
                                            </label>
                                            <div className="input-box">
                                                <input type="text" className="form-control" value={grossWt} onChange={e => setGrosswt(e.target.value)} />
                                            </div>
                                        </div>
                                        <div className="single-pti-box-group width-full form-group">
                                            <label>No. of Packages
                                            </label>
                                            <div className="input-box">
                                                <input type="text" id="noPackage" className="form-control" value={noPkg} onChange={e => { setNoPkg(e.target.value); }} />
                                            </div>
                                        </div>
                                        <div>
                                            <p className="text-warning"> {noOfPackageMessage} </p>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            {/* <!-- end single-pti-box --> */}
                        </div>
                        {/* <!-- end col-6 --> */}
                    </div>
                    {/* <!-- end row --> */}


                </div>
            </div>
            <div className="dashboard mt-2">
                {/* <!-- Shipment Details row --> */}
                <div className="row">
                    <div className="col-lg-12 mt-3">
                        <div className="example-header row">
                            <div className="col-lg-6 col-sm-12 ">
                                <div className="table-heading">
                                    <h4>Shipment Details</h4>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="search-add">
                                    <div className="sorting-num d-flex">
                                        <span className="mt-2">Page Size:</span>&nbsp;
                                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>&nbsp;
                                    <label className="table-search">
                                        <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                                        <i className="bx bx-search"></i>
                                    </label>
                                    &nbsp;
                                    <button type="button" className="addbutton" disabled={ptiStatus === "Pending Approval" ? false : true} onClick={addPtiShipment} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle font-size" aria-hidden="true"></i> </button>

                                </div>
                            </div>
                        </div>
                        <div className="ag-theme-alpine" >
                            <AgGridReact
                                ref={gridRef}
                                onFirstDataRendered={onFirstDataRendered}
                                rowData={shipmentItemDetails}
                                domLayout={domLayout}
                                columnDefs={columnDef}
                                defaultColDef={{
                                    sortable: true,
                                    flex: 1,
                                    minWidth: 150,
                                    filter: true,
                                    resizable: true,
                                }}
                                pagination={true}
                                paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                                cacheQuickFilter={true}
                            />
                        </div>
                    </div><br /><br />

                    {/* <!-- buttons row --> */}
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="btn-footer">
                                <button type="button" className="btn btn-primary" onClick={iorDocument} data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Document">Upload Document <i className="bx bx-upload" aria-hidden="true"></i></button>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="btnooter text-right">
                                <button type="button" disabled={uploadDisable} onClick={onSubmit} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                                <button type="button" onClick={handleClose} className="btn btn-secondary ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                    {/* <!-- end buttons row --> */}

                </div>

            </div>
            {/* <!-- end shipment Details row --> */}


        </form></>
    )
};

export default PTIRecordDetails;