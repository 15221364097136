import React, { useState, useCallback ,useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const Roles = () => {
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const gridRef = useRef();
    const [searchText, setSearchText] = useState("");

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewRole(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editRole(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deleteRole(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const columnDef = [
        { headerName: 'S.no', field: "id", valueGetter: 'node.rowIndex+1', sort: "desc", minWidth:70},
        { headerName: 'Role', field: "name", minWidth:200 },
        { headerName: 'Description', field: "description", minWidth:400 },
        { headerName: 'Is Active', field: "isActive", minWidth:80 },
        { headerName: 'Actions', "cellRenderer": actionButton, minWidth:200 },
    ];
    const domLayout = "autoHeight";

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/role/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response?.json();
            // console.log(responseData);
            if (responseData != null) {
                responseData.rolesInfo?.map((role) => {
                    role.isActive = role.isActive ? "Yes" : "No";                    
                });
                setRoles(responseData?.rolesInfo);
            }   
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    // Delete with fetchAPI
    const deleteRole = async (id) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result){
            try {             
                const requestURL = globalState.endPointURL + "/role/id/" + id;
                
                const response = await fetch(requestURL, {
                    method: 'DELETE',
                    headers: globalState.headers,                
                });                   
               
                if (response !== "" && response.status === 200) {
                    setRoles(roles.filter((role) => { return role.id !== id; }));
                    return;
                } else if (response !== "" && response.status === 400) {
                    setErrorMessage(globalState.commonErrorMessage);
                    return;
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            } catch (e) { 
                setErrorMessage(globalState.commonErrorMessage);
            // console.log(e); 
        }
        }
       
    };

    //Add Role
    const addRole = () => {
        login().then(() => {
            navigate(state?.path || "/masters/roles/role-details");
        });
    };

    //Redirect to dash board
    const handleClose = () => {
        login().then(() => {            
            navigate(state?.path || "/dashboard");
        });
    };

    //View Role
    const viewRole = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/roles/role-view/" + id);
        });
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };
    

    //Edit Role
    const editRole = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/roles/role-details/" + id);
        });
    };

    return <section className="home-section">        
        <div className="dashboard">
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="">Roles</h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                    <div className="sorting-num mr-2">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                        &nbsp;
                        <button className="addbutton" onClick={addRole} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle font-size" aria-hidden="true"></i> </button>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine" >
                <AgGridReact
                    ref={gridRef}
                    rowData={roles}
                    columnDefs={columnDef}
                    pagination={true}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>         
            <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>
       
        </div>   
    </section>
};
export default Roles;
