import React, { useEffect, useState } from "react";
import { BarChart, Bar, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {createUseStyles} from 'react-jss'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalContext } from "../GlobalContext";

const IorEorLineChart = () => {

    const [fromDate, setFromDate] = React.useState(dayjs(new Date).subtract(1,'month'));
    const [toDate, setToDate] = React.useState(dayjs(new Date));
    const { globalState } = React.useContext(GlobalContext)
    const [errorMessage, setErrorMessage] = useState("")
    const [iorVolume, setIorVolume] = useState([])
    const [eorVolume, setEorVolume] = useState([])

    const PickDate = ()=>{
        const styles = createUseStyles({
          datePicker: {
            "& .MuiTextField-root": {
              width: 165,
              height:100
            }}
        })
        const classes = styles()
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              
            <div className={classes.datePicker}>
            <DatePicker
                label="From"
                value={fromDate} 
                onChange={(newValue) => setFromDate(newValue)}
                
              />
              </div>
              <div className={classes.datePicker}>
              <DatePicker
                label="To"
                value={toDate} 
                onChange={(newValue) => setToDate(newValue)}
                
              />
              </div>
      
            </DemoContainer>
          </LocalizationProvider>
      }

      useEffect(()=>{
        prepareIorEorData()
      },[fromDate,toDate])

      const prepareIorEorData = (async ()=> {

        try {
          const requestURL = globalState.endPointURL + "/dashboard/info";
          const reqObject={
            "startDate":fromDate.format("YYYY-MM-DD"),
            "endDate":toDate.format("YYYY-MM-DD"),
            "requestType":"volume-Trend",
            "iorStatus":"ALL",
            "eorStatus":"ALL"
      
          }
          const headersUserName = window.localStorage.getItem("headers-username");
          globalState.headers.userName = headersUserName;
          
          const response = await fetch(requestURL, {
              method: 'POST',
              headers:globalState.headers,
              body:JSON.stringify(reqObject)
          });
          const data = await response.json();
          // console.log("data",data)
      
          if (data !== "" && data.isSuccess) {
            
            let eorVolume = data?.information?.eorVolume?data.information.eorVolume:[];
            let iorVolume = data?.information?.iorVolume?data.information.iorVolume:[];

        setIorVolume(iorVolume)
        setEorVolume(eorVolume)
          
          } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
              setErrorMessage(data.errorCodes.errorCodes[0].message);
          else
              setErrorMessage(globalState.commonErrorMessage);
      } catch (e) {
          setErrorMessage(globalState.commonErrorMessage);
      }
      })

    return <div className="row"> 
    <div className="row"> 
    <h5 style={{align:"center"}}>Monthly /Quarterly volume Trend</h5>
    <PickDate/>
    </div>
    <div className="row">            
    <LineChart width={500} height={300} 
    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
    <CartesianGrid strokeDasharray="3 3" />
    <YAxis />
    <Tooltip />
    <Legend />
    <Line type="monotone" name="IOR" dataKey="count" data={iorVolume} stroke="#8884d8" />
    <Line type="monotone" name="EOR" dataKey="count" data={eorVolume} stroke="#82ca9d" />
   </LineChart>
   </div>
    </div>

};
export default IorEorLineChart;
