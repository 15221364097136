import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import ITCHsCode from "../../shipments/PTIRecords/ITCHsCode";
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { confirm } from "react-confirm-box";
import S3 from "react-aws-s3";
import AWS from 'aws-sdk';
window.Buffer = window.Buffer || require("buffer").Buffer;

const ItemPartDetails = () => {
    const regExpNumber = /^[0-9\b]+$/;
    const gridRef = useRef();
    const itcGridRef = useRef();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);
    const [hscodes, setHSCodes] = useState([]);
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [itcPaginationPageSize, setitcPaginationPageSize] = useState("");
    const { login } = useAuth();
    const { state } = useLocation();
    const [errorMessage, setErrorMessage] = useState("");
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [clientName, setClientName] = useState("");
    const [eanCode, setEanCode] = useState("");
    const [eccnCode, setEccnCode] = useState("");
    const [isSerialized, setIsSerialized] = useState(false);
    const [isMultiPack, setIsMultiPack] = useState(false);
    const [itcHsCode, setItcHsCode] = useState("");
    const [itcHsCodeId, setItcHsCodeId] = useState("");
    const [mpin, setMpin] = useState("");
    const [quantity, setQuantity] = useState("");
    const [part, setPart] = useState("");
    const [description, setDescription] = useState("");
    const [upcCode, setUpcCode] = useState("");
    const [itemId, setItemId] = useState("");
    const [serializedPart, setSerializedPart] = useState([]);
    const [itemCertificate, setItemCertificate] = useState([]);
    const [itemPart, setItemPart] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [itcSearchText, setItcSearchText] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
    const [partErrorMessage, setPartErrorMessage] = useState("");
    const [itcSelectedRow, setItcSelectedRow] = useState({});
    const [clientId, setClientId] = useState("");
    const [clientErrorMessage, setClientErrorMessage] = useState("");
    const [isItcHsCode, setIsItcHsCode] = useState(false);
    const [activeErrorMessage, setActiveErrorMessage] = useState(false);
    const [uploadDisable, setUploadDisable] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const selectItcHsCode = () => {
        setIsOpen(!isOpen);
        setItcHsCode(itcSelectedRow.digitCode);
        // console.log(selectItcHsCode);
        setItcHsCodeId(itcSelectedRow.id);
    };

    const itcHandlePagination = (event) => {
        setitcPaginationPageSize(event);
    };

    const itcHandleSearchText = (text) => {
        setItcSearchText(text);
        itcGridRef.current.api.setQuickFilter(text);
    };

    const onSelectionChanged = () => {
        const selectedRows = itcGridRef.current.api.getSelectedRows();
        // console.log(selectedRows);
        setItcSelectedRow(selectedRows[0]);
    };

    const { register, handleSubmit, reset, formState, clearErrors } = useForm();

    useEffect(() => {
        const fetchItemParts = async () => {
            try {
                const requestURL = globalState.endPointURL + "/item/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                //console.log("responseData", responseData);
                if (responseData !== null) {
                    mappingData(responseData?.itemInfo[0]);
                }
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        };
        const fetchClients = async () => {
            try {
                const requestURL = globalState.endPointURL + "/client/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                //console.log(responseData);
                if (responseData != null) {
                    responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                    let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
                    setClients(data);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                //console.log(e);
            }
        };
        fetchClients();
        if (id !== undefined) {
            if (globalState?.itemParts?.itemId) {
                const responseData = globalState?.itemParts;
                mappingData(responseData);
                //setClientName(responseData?.roleId);  
            } else {
                fetchItemParts();
            }
            globalState.itemPartRedirect = "Edit";
        } else {
            const responseData = globalState?.itemParts;
            mappingData(responseData);
            globalState.itemPartRedirect = "Add";
        }

      //  console.log(globalState?.itemParts);
    }, []);


    const mappingData = (responseData) => {
        globalState.itemParts = responseData;
        setItemId(responseData?.itemId);
        setClientName(responseData?.clientName);
        setUpcCode(responseData?.upcCode);
        setEanCode(responseData?.eanCode);
        setDescription(responseData?.description);
        setPart(responseData?.part);
        setEccnCode(responseData?.eccnCode);
        setMpin(responseData?.mpin);
        setQuantity(responseData?.quantity);
        setItcHsCode(responseData?.itcHsCode);
        setIsSerialized(responseData?.isSerialized);
        setIsMultiPack(responseData?.isMultiPack);
        setItemPart(responseData);
        setSerializedPart(responseData.itemDetails);
        responseData.itemCertificate?.map((item) => {
            item.certificate.expiryDate = item.certificate.expiryDate === "null" || item.certificate.expiryDate === undefined ? "" : item.certificate.expiryDate;
        });
        setItemCertificate(responseData?.itemCertificate);
    }

    const createState = (updatedItemCertificates) => {
        return {
            clientName: clientName === undefined ? "" : clientName,
            eanCode: eanCode === undefined ? "" : eanCode,
            eccnCode: eccnCode === undefined ? "" : eccnCode,
            isSeralized: isSerialized === undefined ? "" : isSerialized,
            isMultiPack: isMultiPack === undefined ? "" : isMultiPack,
            itcHsCode: itcHsCode === undefined ? "" : itcHsCode,
            mpin: mpin === undefined ? "" : mpin,
            quantity: quantity === undefined ? "" : isSerialized ? 1 : quantity,
            upcCode: upcCode === undefined ? "" : upcCode,
            itemId: itemId === undefined ? "" : itemId,
            description: description === undefined ? "" : description,
            part: part === undefined ? "" : part,
            isActive: true,
            isDeleted: false,
            certificates: updatedItemCertificates === undefined ? [] : updatedItemCertificates,
            itemDetails: serializedPart === undefined ? [] : serializedPart
        }
    };

    const validateForm = () => {
        let returnVal = true;
        if (clientName === undefined || clientName === null || clientName === '') {
            setClientNameErrorMessage("Please enter client name");
            setUploadDisable(false);
            returnVal = false;
        }
        if (part === undefined || part === null || part === '') {
            setPartErrorMessage("Please enter part");
            setUploadDisable(false);
            returnVal = false;
        }
        if (isSerialized || isMultiPack) {
            return true;
        } else {
            setActiveErrorMessage("Please Select anyone IsSerialized/IsMultipack");
            setUploadDisable(false);
            return false;
        }
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        if (validateForm()) {
            const updatedItemCertificates = itemCertificate?.flatMap((item) => {
                return {
                    certificateTypeId: item.certificate.certificateTypeId,
                    expiryDate: item.certificate.expiryDate,
                    id: item.certificate.id,
                    isActive: item.certificate.isActive,
                    name: item.certificate.name,
                    url: item.certificate.url,
                    isMandatory: item.certificate.isMandatory,
                    otherCert: item.certificate.otherCert
                }
            });
            handleSave(createState(updatedItemCertificates));
        }
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/item";
                let methodType = "";
                if (id === undefined)
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.itemId = id;
                }
               // console.log(JSON.stringify(data));

                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log("responseData", responseData);
                if (responseData != null && responseData.isSuccess) {
                    handleClose();
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
           // console.log(e);
        }
        finally {
            setUploadDisable(false);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/item-parts");
        });
    };


    const updateGlobalState = {
        "clientName": clientName,
        "createdBy": globalState.createdBy,
        "createdDatetime": globalState.createdDatetime,
        "eanCode": eanCode,
        "eccnCode": eccnCode,
        "isSerialized": isSerialized,
        "isMultiPack": isMultiPack,
        "itcHsCode": itcHsCode,
        "mpin": mpin,
        "quantity": quantity,
        "upcCode": upcCode,
        "itemId": itemId,
        "part": part,
        "description": description,
        "isActive": true,
        "isDeleted": false,
        "itemCertificate": itemCertificate === undefined ? [] : itemCertificate,
        "itemDetails": serializedPart === undefined ? [] : serializedPart,
    };

    const handleSerializedPart = () => {
        globalState.itemParts = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/masters/item-parts/item-parts-serialized-parts");
        });
    };

    const addCertificate = () => {
        setPartErrorMessage("");
        globalState.itemParts = updateGlobalState;
        if (part === undefined || part === "") {
            setPartErrorMessage("Part should not be empty");
            document.getElementById("part").focus();
        } else {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-certificate-details");
            });
        }
    };

    //View Certificate
    const viewCertificate = (certificateId) => {
        globalState.itemParts = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/masters/item-parts/item-parts-certificate-view/" + certificateId);
        });
    };

    // search

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    /*//Edit Certificate
    const editCertificate = (id) => {
        globalState.itemParts = updateGlobalState;
        login().then(() => {
            navigate(state?.path || "/masters/item-parts/item-parts-certificate-details/" + id);
        });
    };*/

    // Delete with fetchAPI
    const deleteCertificate = async (certificateId) => {
        globalState.itemParts = updateGlobalState;
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result) {
            try {
                const requestURL = globalState.endPointURL + "/certificate/id/" + certificateId;
                const response = await fetch(requestURL, {
                    method: 'DELETE',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                if (responseData !== "" && responseData.isSuccess) {
                    if (responseData.itemCertificate[0].url !== null && responseData.itemCertificate[0].url !== ""
                        && responseData.itemCertificate[0].url !== undefined) {
                        let key = new URL(responseData.itemCertificate[0].url).pathname.substr(1);
                        key = key.replace("%20", " ");
                        const params = { Bucket: globalState.s3BuscketConfig.bucketName, Key: key };
                        const s3 = new AWS.S3(globalState.s3BuscketConfig);
                        s3.deleteObject(params, function (err, data) {
                            if (err) {
                                // console.log(err);                        
                            } else {
                                // console.log("deleted successfully from s3");
                            }
                        });
                    }
                    setItemCertificate(itemCertificate.filter((certificate) => { return certificate.certificate.id !== certificateId; }));
                    globalState.itemParts.itemCertificate = globalState.itemParts.itemCertificate.filter((certificate) => { return certificate.certificate.id !== certificateId; });
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        }

    };

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewCertificate(event.data.certificate.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => deleteCertificate(event.data.certificate.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const domLayout = "autoHeight";

    const columnDef = [
        { headerName: '#', field: "certificate.id", valueGetter: 'node.rowIndex+1', sortable: true, filter: true, width: 150 },
        { headerName: 'Certificate / Licence Type', field: "certificate.certificateTypeId", sortable: true, filter: true, width: 250 },
        { headerName: 'Certificate Name', field: "certificate.name", sortable: true, filter: true, width: 250 },
        { headerName: 'Expiry Date', field: "certificate.expiryDate", sortable: true, filter: true, width: 300 },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const itcColumnDef = [
        { headerName: 'Chapter', field: "hscode", sortable: true, filter: true, width: 250 },
        { headerName: 'Hs Code', field: "digitCode", sortable: true, filter: true, width: 250 },
        { headerName: 'Product Description', field: "description", sortable: true, filter: true, width: 750 },
    ];

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const handleItcHSCode = (val) => {
        // console.log(val);
        if (val === "Backspace" || val === "Delete") {
            setIsItcHsCode(true);
        }
        setIsItcHsCode(false);
    };

    const itcGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/hscodes/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            // console.log(JSON.stringify(data));
            const data = await response.json();
           // console.log(data);
            if (data !== null)
                setHSCodes(data.hscodeInfos);
            else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(data.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            console.log(e);
        }
    };

    return <section className="home-section">
        <div className="dashboard item-dashboard">

            <div className="row">
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                <div className="col-sm-6">

                    <div className="row g-3 ">

                        <div className="table-heading mb-2 mt-3">
                            <h4 >Item / Parts </h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>Item / Parts Id
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" readOnly={true} name="itemId" placeholder="" value={itemId} onChange={e => setItemId(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>Client Name</label>
                                <div className="select-box" name="clientId">
                                    <select value={clientName} onChange={e => { setClientName(e.target.value); setClientNameErrorMessage(""); }} >
                                        <option value="">Select</option>
                                        {clients?.map((client) => { return (<option value={client.name}>{client.name} </option>) })};
                                    </select>
                                    <div className="text-warning">{clientNameErrorMessage}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>Part#</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" id="part" name="part" placeholder="" value={part} onChange={e => { setPart(e.target.value); setPartErrorMessage(""); }} />
                                    <div className="text-warning">{partErrorMessage}</div>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>Description</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="description" placeholder="" value={description} onChange={e => setDescription(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        {/* <div className="col-lg-12 col-md-12">
                            <label>MPIN
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="mpin" placeholder="" value={mpin} onChange={e => setMpin(e.target.value)} />
                            </div>
                        </div>*/}

                        <div className="col-lg-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-12 col-sm-12">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitches" name="isSerialized"
                                            checked={isSerialized} onChange={e => { setActiveErrorMessage(""); setIsSerialized(!isSerialized); setIsMultiPack(false); }} />
                                        <label className="custom-control-label" for="customSwitches">Is Serialized</label>
                                    </div>
                                    <br></br>
                                    <div className="text-warning">{activeErrorMessage}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div className="col-sm-6">

                    <div className="row g-3 ">

                        <div className="table-heading mb-2 mt-3">
                            <h4 >Codes</h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>UPC CODE</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="upcCode" placeholder="" value={upcCode}
                                        onChange={e => regExpNumber.test(e.target.value) || e.target.value === "" ? setUpcCode(e.target.value) : ""} />

                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>EAN CODE </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="eanCode" placeholder="" value={eanCode}
                                        onChange={e => regExpNumber.test(e.target.value) || e.target.value === "" ? setEanCode(e.target.value) : ""} />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>ECCN </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="eccnCode" placeholder="" value={eccnCode}
                                        onChange={e => regExpNumber.test(e.target.value) || e.target.value === "" ? setEccnCode(e.target.value) : ""} />
                                </div>
                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12">
                            <div className="single-pti-box-group width-full form-group">
                                <label>ITC HS Code</label>
                                <div className="input-box">
                                    <div className="threeDots">
                                        <input type="text"
                                            className="form-control" value={itcHsCode} />
                                        <button type="button"
                                            className="form-control pop-btn"
                                            onClick={togglePopup} ><i className="bx bx-dots-horizontal" aria-hidden="true"></i></button>
                                    </div>
                                </div>

                                {isOpen && <ITCHsCode
                                    content={
                                        <div id="exampleModal">
                                            <div className="modal-dialog" >
                                                <div className="modal-content">
                                                    <div className="modal-body row">
                                                        <div className="col-lg-6 col-sm-12 ">
                                                            <div className="popup-header">
                                                                <h5 className="popup-title pt-2">ITC HS Code</h5>
                                                                <br></br>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-sm-12">
                                                            <div className="search-add">
                                                                {/* <div className="sorting-num">
                                                                Page Size:&nbsp;
                                                                <select value={itcPaginationPageSize} onChange={e => itcHandlePagination(e.target.value)}>
                                                                    <option value="10">10</option>
                                                                    <option value="25">25</option>
                                                                    <option value="50">50</option>
                                                                    <option value="100">100</option>
                                                                </select>
                                                            </div> */}
                                                                <label className="table-search w-100">
                                                                    <input type="text" name="part" placeholder="Search" value={itcSearchText} onChange={e => itcHandleSearchText(e.target.value)} />
                                                                    <i className="bx bx-search"></i>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="ag-theme-alpine" style={{ height: 400 }}>
                                                        <AgGridReact
                                                            ref={itcGridRef}
                                                            rowData={hscodes}
                                                            columnDefs={itcColumnDef}
                                                            pagination={true}
                                                            onGridReady={itcGridReady}
                                                            paginationPageSize={itcPaginationPageSize === "" ? 10 : itcPaginationPageSize}
                                                            rowSelection={'single'}
                                                            onSelectionChanged={onSelectionChanged}
                                                        ></AgGridReact>
                                                    </div>


                                                    <div className="col-lg-12 text-right">
                                                        <button onClick={selectItcHsCode} type="button" className="btn btn-primary addbutton2 mr-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Select">Select <i className="fa fa-check-circle"></i></button>
                                                        <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    handleClose={togglePopup} />}
                            </div>
                        </div>

                        {/* multipack */}
                        <div className="col-lg-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" id="customSwitches2"
                                            checked={isMultiPack} onChange={e => { setActiveErrorMessage(""); setIsSerialized(false); setIsMultiPack(!isMultiPack); }} />
                                        <label className="custom-control-label" for="customSwitches2">Is Multipack</label>

                                    </div>
                                </div>

                                {isMultiPack ?
                                    <div className="col-lg-6 col-md-6 qty-flex">
                                        <label>Quantity
                                        </label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" name="quantity" placeholder="" value={quantity} onChange={e => setQuantity(e.target.value)} />
                                        </div>
                                    </div> : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="dashboard">
            <div className="certificate-item">
                <div className="example-header row">
                    <div className="col-lg-6 col-sm-12 ">
                        <div className="table-heading">
                            <h4 className="">Certificates</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="search-add">
                            <div className="sorting-num pr-2">
                                Page Size:&nbsp;
                                <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <label className="table-search">
                                <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                                <i className="bx bx-search"></i>
                            </label>
                            &nbsp;
                            <button className="addbutton" data-bs-toggle="tooltip" onClick={addCertificate} data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>

                        </div>
                    </div>
                </div>
                <div className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={itemCertificate}
                        columnDefs={columnDef}
                        pagination={true}
                        domLayout={domLayout}
                        defaultColDef={{
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                        }}
                        // onGridReady={onGridReady}
                        paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                        cacheQuickFilter={true}
                    />
                </div>
            </div><br /><br />
            <div className="btn-footer text-right">
                {id === undefined ? <button disabled={uploadDisable} onClick={handleSubmit(onSubmit)} type="submit" className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                    : <button type="submit" disabled={uploadDisable} onClick={handleSubmit(onSubmit)} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}
                <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true">
                </i>
                </button>
            </div>

        </div>
    </section>
};
export default ItemPartDetails;
