import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

import forgotPasswordIcon from "../../assets/images/forgot-password-icon.png";
import useAuth from "../../hooks/useAuth";
import "../../assets/css/components/forgot-password.scss";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter a valid email").email("Enter a valid email address"),
  });
  const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    //console.log(JSON.stringify(data, null, 4));
    handleForgotPassword(data);
  };

    const handleForgotPassword = async (data) => {
        let response = await fetch('https://jsonplaceholder.typicode.com/posts', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        let responseData = await response.json();
        //console.log(responseData);
        if (responseData.email === data.email) {
            login().then(() => {
                reset();
                navigate(state?.path || "/reset-password");
            });
        }      
  };

  return (
    <section className="forgot-password">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 m-auto">
            <div className="send-req-card text-center">
              <div className="send-img text-center">
                <img src={forgotPasswordIcon} alt="send-request" className="img-fluid" />
              </div>
              <div className="send-txt">
                <h3>Forgot Your password ?</h3>
                <p>No worries Enter your email and we will send you a reset link</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <div className="input-icon">
                    <i className="bx bxs-envelope"></i>
                  </div>
                  <input type="email" className="form-control" name="email" placeholder="Email" {...register("email")} />
                  <div className="text-warning">{errors.email?.message}</div>
                </div>
                <button type="submit" className="send-btn">
                  Send Request
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
