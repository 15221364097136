import React, { useState, useCallback ,useRef} from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const Users = () => {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    
    const gridRef = useRef();
    
    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewUser(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editUser(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deleteUser(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const columnDef = [
        { headerName: '#', field: "id", valueGetter: 'node.rowIndex+1', sort: 'asc', maxWidth:70 },
        { headerName: 'User Name', field: "name", maxWidth:120  },
        { headerName: 'User Id / Email', field: "email"},
        { headerName: 'Mobile Number', field: "mobileNumber", maxWidth:120 },
        { headerName: 'User Type', field: "roleName" },
        { headerName: 'Is Active', field: "isActive", maxWidth:80 },
        { headerName: 'Actions', "cellRenderer": actionButton, maxWidth:200 },
    ];

    const domLayout = "autoHeight";

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/user/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null) {
                let data = responseData.usersInfo?.filter((user) => {
                    if (user !== null) {
                        return user;
                    }
                });
                data?.map((user) => {
                    if (user !== null) {
                        user.isActive = user?.isActive ? "Yes" : "No";
                    }
                });
                // console.log(data);
                setUsers(data);
            }
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
               setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
           setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    // Delete with fetchAPI
    const deleteUser = async (id) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result){
        try {
            const requestURL = globalState.endPointURL + "/user/id/" + id;
            const response = await fetch(requestURL, {
                method: 'DELETE',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            if (responseData !== "" && responseData.isSuccess) {
                setUsers(users.filter((user) => {return user.id !== id; }));
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    }
    };

    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    //Add User
    const addUser = () => {
        login().then(() => {
            navigate(state?.path || "/masters/users/user-details");
        });
    };

      const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
      }, []);

    //Redirect to dash board
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/dashboard");
        });
    };

    //View User
    const viewUser = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/users/user-view/" + id);
        });
    };

    //Edit User
    const editUser = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/users/user-details/" + id);
        });
    };

    return <section className="home-section">        
        <div className="dashboard">
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <div className="example-header row">
                <div className="col-lg-4 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="">Users</h4>
                    </div>
                </div>
                <div className="col-lg-8 col-sm-12">
                    <div className="search-add">
                    <div className="sorting-num mr-2">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                        &nbsp;
                        <button className="addbutton" onClick={addUser} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>
                    </div>
                </div>
            </div>
            <div className="ag-theme-alpine" >
                <AgGridReact
                    ref={gridRef}
                    rowData={users}
                    columnDefs={columnDef}
                    pagination={true}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div> 
            <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>
        </div>
    </section>
};
export default Users;
