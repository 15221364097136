import React, { useState, useCallback ,useRef} from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const ValidationBOE = () => {  
const navigate = useNavigate(); 
const { login } = useAuth();
const { state } = useLocation();
const [partNumber, setPartNumber] = useState("");
const [serialNumber, setSerialNumber] = useState("");
const [clientName, setClientName] = useState("");
const [ Validate, setValidate] = useState([]);
const [ GlobalValidate, setGlobalValidate ] = useState([]);
const { globalState } = React.useContext(GlobalContext);
const [errorMessage, setErrorMessage] = useState("");
const [paginationPageSize, setpaginationPageSize] = useState("");
const gridRef = useRef();
const [searchText, setSearchText] = useState("");
const [exporter, setExporter] = useState('');
const [isExported, setisExported] = useState('No');

const columnDef = [
    { headerName: 'Part', field: "partNo", sortable: true, filter: true },
    { headerName: 'Serial #', field: "serialNo", sortable: true, filter: true },
    { headerName: 'Description', field: "description", sortable: true, filter: true },
    { headerName: 'BE No.', field: "beNo", sortable: true, filter: true },
    { headerName: 'BE DT', field: "beDt", sortable: true, filter: true },
    { headerName: 'Is Exported', field: "isExported", sortable: true, filter: true },
    { headerName: 'BOE Serial No.', field: "beSeqNo", sortable: true, filter: true },
];

const domLayout = "autoHeight";

const onSubmit = async () => {
    try {
        const requestURL = globalState.endPointURL + "/pti/validateboe";
        const reqObject = {
            "clientId": exporter,
            "clientName" : clientName,
            "partNumber" : partNumber,
            "serialNumber" : serialNumber,
        };
        //console.log(reqObject);
        const response = await fetch(requestURL, {
             method: 'POST',
             body: JSON.stringify(reqObject),
             headers: globalState.headers,
         }
         );
        const responseData = await response.json();
       // console.log(responseData);
        setValidate(responseData?.boeInfos);
        setGlobalValidate(responseData?.boeInfos);
    } catch (e) {
        setErrorMessage(globalState.commonErrorMessage);
        // console.log(e);
    }
}

const clearingValues = () => {
    onSubmit(false);
    setExporter("");
    setPartNumber("")
    setSerialNumber("");
}

const resetSubmit = () => {
    clearingValues();
}

const handlePagination = (event) => {
    setpaginationPageSize(event);
};
//Redirect to dash board
const handleClose = () => {
    login().then(() => {            
        navigate(state?.path || "/dashboard");
    });
};// size fit Column in table
const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
}, []);

// search bar
const handleSearchText = (text) => {
    setSearchText(text);
    gridRef.current.api.setQuickFilter(text)
};

    return <section className="home-section">
    <div className="dashboard">
        <div className="table-heading mb-3">
            <h4 className="w-50">Validate BOE </h4>
        </div>
        <div className="row mb-2">
            <div className="col-lg-6 col-md-6">
            <div className="single-pti-box-group width-full form-group">
                <label>Client</label>
                <div className="select-box">
                        <select value={exporter} onChange={e => setExporter(e.target.value)}>
                            <option value="">select</option>
                                {globalState.exporter?.map((item) => {
                                    return <option value={item.name}>{item.value}</option>
                                })}
                        </select>
                    </div>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
            <div className="single-pti-box-group width-full form-group">
                <label>Item / Part No</label>
                <div className="input-box">
                    <input type="text" className="form-control"  value={partNumber} onChange={e => setPartNumber(e.target.value)}/>
                </div>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
            <div className="single-pti-box-group width-full form-group">
                <label>Serial No</label>
                <div className="input-box">
                    <input type="text" className="form-control" value={serialNumber} onChange={e => setSerialNumber(e.target.value)}/>
                </div>
                </div>
                    
            </div>

        <div className="btn-footer text-right col-lg-12 col-md-12">
                <button type="button" className="btn btn-primary" onClick={onSubmit} data-bs-toggle="tooltip" data-bs-placement="top" title="Filter">Apply Filter <i className="fa fa-filter" aria-hidden="true"></i></button>  
                <button type="button" className="btn btn-secondary" onClick={resetSubmit} data-bs-toggle="tooltip" data-bs-placement="top" title="Reset">Reset <i className="fa fa-repeat" aria-hidden="true"></i></button>
        </div>

</div>

</div>
{/* validation boe table */}
<div className="dashboard">
<div className="example-header row">
                <div className="col-lg-6 col-sm-12">
                    <div className="sorting-num">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                        <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                ref={gridRef}
                    rowData={Validate}
                    columnDefs={columnDef}
                    pagination={true}
                    //onGridReady={onGridReady}
                    domLayout={domLayout}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>         
            <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>

    </div>
</section>
};
export default ValidationBOE;