import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { confirm } from "react-confirm-box";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const PtiStatusDetails = () => {
    const [ptiStatus, setPtiStatus] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [status, setStatus] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [statusErrorMessage, setStatusErrorMessage] = useState("");
    const [descriptionErrorMsg, setDescriptionErrorMsg] = useState("");
    const [activeErrorMessage, setActiveErrorMessage] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const { handleSubmit, formState, register, clearErrors } = useForm();
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage,confirmNavigation,cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);


    useEffect(() => {
        const fetchPtiStatus = async () => {
            try {
                const requestURL = globalState.endPointURL + "/ptistatus/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);
                if (data !== "") {
                    let responseData = data?.ptiStatusInfo[0];
                    setStatus(responseData?.name);
                    setDescription(responseData?.description);
                    setActive(responseData?.isActive);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };

        const fetchPtiStatusExit = async () => {
            try {
                const requestURL = globalState.endPointURL + "/ptistatus/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);
                if (data !== null) {
                    data.ptiStatusInfo?.map((status) => {
                        status.isActive = status.isActive ? "Yes" : "No";
                    });
                    setPtiStatus(data.ptiStatusInfo);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        // fetchPtiStatusExit();

        if (id !== undefined)
            fetchPtiStatus();
    }, []);



    const validateForm = () => {
        let returnVal = true;
        if (status === undefined || status === null || status === '') {
            setStatusErrorMessage("Please enter a status");
            setUploadDisable(false);
            returnVal = false;
        }
        if (description === undefined || description === null || description === '') {
            setDescriptionErrorMsg("Please enter description");
            setUploadDisable(false);
            returnVal = false;
        }
        //if (!active) {
        //    setActiveErrorMessage("Please select the active");
        //    returnVal = false;
        //}
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        clearErrors();

        setErrorMessage("");
        /*let ptiStatusExist = false;
        ptiStatus?.map((ptiStatus) => {
            if (ptiStatus.name === status) {
                ptiStatusExist = true;
            }
        });
        if (id === undefined && ptiStatusExist) {
            setErrorMessage("Pti status name was already exist");      
       }else*/ if (validateForm()) {
            const createState = {
                status: status,
                description: description,
                isActive: active
            };
            handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/ptistatus";
                let methodType = "";
                if (id === undefined)
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.id = id;
                }
                // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null && responseData?.isSuccess)
                    handleClose();
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
        finally {
            setUploadDisable(false);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/pti-status");
        });
    };

    return(<><DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation} /><section className="home-section">
            <div className="dashboard">
                <div className="table-heading">
                    <h4 className="w-50">PTI Status Details</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                    <div className="row mb-4">

                        <div className="col-lg-6 col-md-6">
                            <label>Status <span className="impred">*</span></label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="status" placeholder="" value={status} onChange={e => { setStatus(e.target.value); setStatusErrorMessage(""); setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{statusErrorMessage}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <label>Description</label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="description" placeholder="" value={description} onChange={e => { setDescription(e.target.value); setDescriptionErrorMsg(""); setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{descriptionErrorMsg}</div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitches" checked={active} onChange={e => { setActive(!active); setActiveErrorMessage(""); setCanShowDialogLeavingPage(true); } } />
                                <label className="custom-control-label" for="customSwitches">Is Active</label></div>
                        </div>
                        <div className="text-warning">{activeErrorMessage}</div>
                    </div>
                    <div className="btn-footer text-right">
                        {id === undefined ? <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                            : <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}
                        <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </form>
            </div>
        </section></>
    )
};
export default PtiStatusDetails;
