import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams, Link  } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { confirm } from "react-confirm-box";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const UserDetails = () => {

    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [active, setActive] = useState(true);
    const [roleId, setRoleId] = useState("");
    const [user, setUser] = useState("");
    const [roles, setRoles] = useState([]);
    const [mobileNumber, setMobileNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [mobileErrorMessage, setMobileErrorMessage] = useState("");
    const [roleErrorMessage, setRoleErrorMessage] = useState("");
    const [emailErrorMessage, setEmailErrorMessage] = useState("");
    const [activeErrorMessage, setActiveErrorMessage] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage,confirmNavigation,cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);

    const { register, handleSubmit, reset, formState, clearErrors } = useForm();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const requestURL = globalState.endPointURL + "/user/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);
                if (data !== null) {
                    let responseData = data?.usersInfo[0];
                    setActive(responseData?.isActive);
                    setName(responseData?.name);
                    setMobileNumber(responseData?.mobileNumber);
                    setRoleId(responseData?.roleId);
                    setUser(responseData?.email);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        const fetchUsersExit = async () => {
            try {
                const requestURL = globalState.endPointURL + "/user/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData != null) {
                    responseData.usersInfo?.map((user) => {
                        user.isActive = user.isActive ? "Yes" : "No";
                    });
                    setUsers(responseData.usersInfo);
                }
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        }
        //fetchUsersExit();


        const fetchRoles = async () => {
            try {
                let data;
                const requestURL = globalState.endPointURL + "/role/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                data = await response.json();
                // console.log(data);
                if (data != null) {
                    let rolesData = data.rolesInfo?.filter((client) => { return client.isActive === true })
                    setRoles(rolesData);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
                // console.log("roles",roles);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        fetchRoles();
        // console.log(roles);
        if (id !== undefined)
            fetchUsers();
    }, []);

    const validateEmail = (email) => {
        //new RegExp('[a-z0-9]+@zenxl.com')
        return new RegExp('[a-z0-9]+@zenxl.com').test(email) || new RegExp('[a-z0-9]+@zenxl.in').test(email);
    };

    const validateForm = () => {
        let returnVal = true;
        if (name === undefined || name === null || name === '') {
            setNameErrorMessage("Please enter a Username");
            setUploadDisable(false);
            returnVal = false;
        }
        if (mobileNumber === undefined || mobileNumber === null || mobileNumber === '') {
            setMobileErrorMessage("Please enter Mobile Number");
            setUploadDisable(false);
            returnVal = false;
        } else if (mobileNumber.length !== 10) {
            setMobileErrorMessage("Please enter correct Mobile Number");
            setUploadDisable(false);
            returnVal = false;
        }
        if (roleId === undefined || roleId === null || roleId === '') {
            setRoleErrorMessage("Please enter roleId");
            setUploadDisable(false);
            returnVal = false;
        }
        if (user === undefined || user === null || user === '') {
            setEmailErrorMessage("Please enter User / Email");
            setUploadDisable(false);
            returnVal = false;
        } else if (!validateEmail(user)) {
            setEmailErrorMessage("Please enter Valid Email or Email extension must be zenxl.com or zenxl.in");
            setUploadDisable(false);
            returnVal = false;
        } else if (validateEmail(user)) {
            let mail = user.split("@");
            if (mail[1] !== "zenxl.com" && mail[1] !== "zenxl.in") {
                setEmailErrorMessage("Email extension must be zenxl.com or zenxl.in");
                setUploadDisable(false);
                returnVal = false;
            }
        }
        /*if (!active) {
            setActiveErrorMessage("Please select the active");
            returnVal = false;
        }*/
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        clearErrors();

        setErrorMessage("");
        if (validateForm()) {
            const createState = {
                name: name,
                mobileNumber: mobileNumber,
                role: roleId,
                email: user,
                isActive: active,
                isDeleted: false,
            };
            handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/user";
                let methodType = "";
                if (id === undefined)
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.id = id;
                }
                // console.log(JSON.stringify(data));
                // console.log(globalState.headers);
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();

                // console.log(responseData);
                if (responseData != null && responseData.isSuccess)
                    handleClose();
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message)
                } else {
                    setErrorMessage(globalState.commonErrorMessage)
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
        finally {
            setUploadDisable(false);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/users");
        });
    };

    const handleMobileNumber = (val) => {
        setMobileErrorMessage("");
        const regExpNumber = /^[0-9\b]+$/;
        if (regExpNumber.test(val) || val === ""){
            setMobileNumber(val);
            setCanShowDialogLeavingPage(true)
        }

    };

    return (
    <><DialogLeavingPage
            showDialog={showDialogLeavingPage}
            setShowDialog={setCanShowDialogLeavingPage}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation} /><section className="home-section">
                <div className="dashboard">
                    <div className="table-heading">
                        <h4 className="w-50">User Details</h4>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                        <div className="row mb-4">

                            <div className="col-lg-6 col-md-6">
                                <label>Name</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="name" placeholder="" value={name} {...register("name")} onChange={e => { setName(e.target.value); setNameErrorMessage("");setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{nameErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Mobile Number
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="mobileNumber" maxLength={10} placeholder="" value={mobileNumber}
                                        onChange={e => handleMobileNumber(e.target.value)} />
                                    <div className="text-warning">{mobileErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>User / Role </label>
                                {<div className="select-box" name="roleId">
                                    <select value={roleId} onChange={e => { setRoleId(e.target.value); setRoleErrorMessage("");setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        {roles?.map((role) => { return (<option value={role.id}>{role.name} </option>); })};
                                    </select>
                                </div>}
                                <div className="text-warning">{roleErrorMessage}</div>

                            </div>
                            <div className="col-lg-6 col-md-6">
                                <label>User Id / Email</label>
                                <div className="input-box">
                                    <input type="email" className="form-control" name="user" placeholder="" value={user} onChange={e => { setUser(e.target.value); setEmailErrorMessage("");setCanShowDialogLeavingPage(true);  } } />
                                    <div className="text-warning">{emailErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" name="isActive" id="customSwitches" checked={active} onChange={e => { setActive(!active); setActiveErrorMessage(""); setCanShowDialogLeavingPage(true);} } />
                                    <label className="custom-control-label" for="customSwitches">Is Active
                                    </label>
                                </div>
                                <div className="text-warning">{activeErrorMessage}</div>
                            </div>
                        </div>
                        <div className="btn-footer text-right">
                            {id === undefined ? <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                                : <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}
                            <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true">
                            </i>
                            </button>
                        </div>
                    </form>
                </div>
            </section></>
    )
};
export default UserDetails;
