import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const UserView = () => {

    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        const fetchUsers = async () => {
            try {               
                const requestURL = globalState.endPointURL + "/user/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null)
                    setUsers(responseData?.usersInfo[0]);
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        fetchUsers();
    }, []);

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/users");
        });
    };


    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">User Details View</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="row mb-4">
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Name </h5>
                        <p>{users.name}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Mobile Number </h5>
                        <p>{users.mobileNumber}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>User / Role </h5>
                        <p>{users.roleName}</p>
                </div>
            </div>
           
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>User Id / Email </h5>
                        <p>{users.email}</p>
                </div>
            </div>
            <div className="text-right">
                    <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to Users <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
        </div>
        </div>
       
</div>
    </section>
};
export default UserView;
