import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import CertificatePopup from '../PTIRecords/CertificatePopup';

const IORDocumentView = () => {    
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const [documentUrl, setDocumentUrl] = useState("");
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [IORDocument, setIORDocument] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const fetchShipmentItems = async () => {
            try {
                const responseData = globalState.ptiRecord?.iorDetails?.filter(item => { return String(item.id) === id; });
                // console.log(responseData[0]);
                if (responseData !== "") {
                    setIORDocument(responseData[0]);
                    setDocumentUrl(responseData[0].documentUrl);
                } else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined) {
            fetchShipmentItems();
        }
        globalState.itemPartRedirect = "View";
    }, []);


    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/IORDocument");
        });
    };
   

    return <section className="home-section">        
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">IOR Document View</h4>                
            </div>
       
            <form>
            <div className="row mb-4">

            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                            <h5>#</h5>
                            <p>{IORDocument.id}</p>
                </div>
                </div>
               
                <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Document Type</h5>
                            <p>{IORDocument.documentType}</p>
                </div>                   
                </div>
               
                <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Document Number</h5>
                            <p>{IORDocument.documentNumber}</p>
                </div>
                </div>

                <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Upload Date</h5>
                            <p>{IORDocument.updatedDate}</p>
                </div>
                </div>

                <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Upload Document</h5>
                    <p class="certi-view">
                        {IORDocument.documentUrl}
                        {/* {documentUrl === "" || documentUrl === undefined ? "" : */}
                    <div class="certi-view-icon" onClick={togglePopup}><i class="bx bxs-show" aria-hidden="true"></i></div>
                    {isOpen && <CertificatePopup
                        content={
                            <div id="exampleModal">
                                <div className="modal-dialog" >
                                    <div className="modal-content">
                                        <div className="popup-header">
                                            <h5 className="popup-title">Document</h5>
                                            <br></br>
                                        </div>
                                        <div className="modal-body row">
                                            <div className="m-auto">
                                                <embed src={documentUrl} type="" width="100%" height="400" />
                                            </div>
                                        </div>
                                        <br></br>
                                        <div className="col-lg-12 text-right">
                                            {/*<input type='file' id='file' ref={inputFile} style={{ display: 'none' }} />
                                            <button type="button" className="btn btn-primary" onClick={onButtonClick} data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Certificate"> Upload Certificate <i className="bx bx-upload" aria-hidden="true"></i></button>
                                           */} <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                            {/*<button onClick={deleteCertificate} type="button" className="btn btn-danger ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">Delete <i className="bx bx-trash" aria-hidden="true"></i></button>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        handleClose={togglePopup} />}

                    </p>
                </div>
                </div>
                   
             
            </div>
            <div className="btn-footer text-right">         
                    <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
            </div>
        </form>
        </div>   
    </section>
};
export default IORDocumentView;