import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { GlobalContext } from '../GlobalContext';
import useAuth from "../hooks/useAuth";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: '2px solid #0077cc'
    }
};
const NewPasswordPopup = ({ userName, setUsername, password, setPassword, modalIsOpen, setIsOpen }) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [usernameError, setUserNameError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const { globalState } = React.useContext(GlobalContext);
    const navigate = useNavigate();
    const { login } = useAuth();

    const handleClose = () => {
        setIsOpen(false);
        login().then(() => {
            navigate("/dashboard");
        });
    };

    const handleUpdatePassword = async (event) => {
        event.preventDefault();
        try {
            const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
            if (!passwordRegex.test(password)) {
                setPasswordError("Password must be valid");
                return;
            } else {
                setPasswordError("");
            }
            if (userName === "") {
                setUserNameError("Username should not be empty")
            }
            const data = { userName, password, isActive: true };
            const requestURL = globalState.endPointURL + "/activate";
            let response = await fetch(requestURL, {
                method: "POST",
                headers: globalState.headers,
                body: JSON.stringify(data),
            })
            const responseData = await response.json();
            if (responseData != null && responseData.isSuccess)
                handleClose();
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            } else {
                setErrorMessage(globalState.commonErrorMessage);
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            onRequestClose={handleClose}
            contentLabel="Update Password Modal"
        >
            <h2>Update Password</h2>
            <form onSubmit={handleUpdatePassword}>
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                {usernameError && <p className="text-warning"> {usernameError} </p>}
                {passwordError && <p className="text-warning"> {passwordError} </p>}
                <div className="row">
                    <div className="col-md-5 m-2">
                        <label htmlFor="userName" className="password-label">User Name :</label>
                        <input
                            type="text"
                            name="userName"
                            value={userName}
                            onChange={e => setUsername(e.target.value)}
                        />
                    </div>
                    <div className="col-1" />
                    <div className="col-md-5 m-2">
                        <label htmlFor="password" className="password-label">Confirm Password :</label>
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                    </div>
                </div>
                <div className="text-right">
                    <button type="submit" className="addbutton">Update</button>
                </div>
            </form>
        </Modal>
    )
}

export default NewPasswordPopup
