import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import loginPageBanner from "../../assets/images/login-page-banner.png";
import logoLarge from "../../assets/images/logo-large.jpg";
import useAuth from "../../hooks/useAuth";
import "../../assets/css/components/login.scss";
import { GlobalContext } from "../../GlobalContext";

const Login = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);

    const [errorMessage, setErrorMessage] = useState("");
    const validationSchema = Yup.object().shape({
        userName: Yup.string().required("Please enter a valid email").email("Enter a valid email address"),
        encodedPassword: Yup.string()
            .required("Please enter a valid password")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
    });
    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = (data) => {
        // console.log(JSON.stringify(data));
        handleLogin(data);
    };
    // const handleClose = () => {
    //     login().then(() => {
    //         navigate(state?.path || "/login");
    //     });
    // };

    const handleLogin = async (data) => {
        try {
            const requestURL = globalState.endPointURL + "/login";
            let response = await fetch(requestURL, {
                method: 'POST',
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();

            // console.log(responseData);
            if (responseData !== "" && responseData.isSuccess) {
                const loginUser = {
                    premissions: [],
                    isSuccess: responseData.isSuccess,
                    errorCodes: [],
                    roleId: "",
                    userId: "",
                    userName: responseData.userName,
                    roleName: responseData.roleName,
                    isFirstTimeLogin: responseData.isFirstTimeLogin
                };
                globalState.selectedUser = loginUser;
                globalState.headers.userName = responseData.userName;
                window.localStorage.setItem("headers-username", responseData.userName);
                window.localStorage.setItem("login-user", loginUser.isSuccess);
                window.localStorage.setItem("first-login", loginUser.isFirstTimeLogin);
                window.localStorage.setItem("user-role", loginUser.roleName);
                login().then(() => {
                    reset();
                    navigate("/dashboard");
                });
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                //setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                setErrorMessage("Invalid credentials");
                window.localStorage.setItem("login-user", false);
            } else {
                setErrorMessage("Invalid credentials");
                window.localStorage.setItem("login-user", false);
            }
        } catch (e) {
            //setErrorMessage("Failed to fetch"); 
            // console.log(e);
            setErrorMessage(globalState.commonErrorMessage);
            window.localStorage.setItem("login-user", false);
        }
    };

    return (
        <section className="login-container">
            <div className="container_fluid">
                <div className="row p-0 m-0">
                    <div className="col-lg-7 col-sm-12 col-md-12">
                        <div className="login-bg">
                            <img src={loginPageBanner} alt="Login-background" className="img-fluid" />
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 col-md-8 m-auto">
                        <div className="login-card">
                            <div className="login-logo">
                                <img src={logoLarge} alt="login-logo" className="img-fluid" />
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                                <div className="form-group">
                                    <label htmlFor="">Email</label>
                                    <div className="input-icon">
                                        <i className="bx bxs-user"></i>
                                    </div>
                                    <input type="text" className="form-control" name="userName" placeholder="Email" {...register("userName")} />
                                    <div className="text-warning">{errors.userName?.message}</div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="">Password</label>
                                    <div className="input-icon">
                                        <i className="bx bxs-lock-alt"></i>
                                    </div>
                                    <input type="password" className="form-control" name="encodedPassword" placeholder="Password" {...register("encodedPassword")} />
                                    <div className="text-warning">{errors.encodedPassword?.message}</div>

                                </div>
                                <div className="forgot-password-link">
                                    <Link className="forget-pass" to="/forgot-password">
                                        Forgot Password?
                                    </Link>
                                </div>

                                <button type="submit" className="login-btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Login">
                                    <i className="fa fa-long-arrow-right" aria-hidden="true"></i> Login
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Login;
