import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const CertificateDetails = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { formState } = useForm();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [certificateTypeId, setCertificateTypeId] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [otherCert, setOtherCert] = useState("");
    const [url, setUrl] = useState("");
    const fileInput = React.useRef();
    const { errors } = formState;
    const [errorMessage, setErrorMessage] = useState("");
    const [certificateTypeErrorMessage, setCertificateTypeErrorMessage] = useState("");
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [isMandatory, setIsMandatory] = useState(true);
    const [uploadDisable, setUploadDisable] = useState(false);

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const responseData = globalState?.itemParts?.itemCertificate?.filter(item => { return String(item.certificate.id) === id; });

                if (responseData !== "") {
                    const data = responseData[0].certificate;
                    setName(data.name);
                    setExpiryDate(data.expiryDate);
                    setCertificateTypeId(data.certificateTypeId);
                    setUrl(data.url);
                    setIsMandatory(data.isMandatory);
                    setOtherCert(data.otherCert);
                } else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined)
            fetchCertificates();
    }, []);

    const handleUpload = async (file) => {       
        let newFileName = globalState.itemParts.part + "/" + certificateTypeId + "_" + name + "." + file?.name?.split('.').pop();
        const ReactS3Client = new S3(globalState.s3BuscketConfig);
        ReactS3Client.uploadFile(file, newFileName).then(data => {
            // console.log(data.location);
            const createState = {
                certificateTypeId: certificateTypeId === undefined ? "" : certificateTypeId,
                expiryDate: expiryDate === undefined ? "" : expiryDate,
                name: name === undefined ? "" : name,
                url: data.location === undefined ? "" : data.location,
                otherCert: otherCert === undefined ? "" : otherCert,
                isMandatory: isMandatory === undefined ? "" : isMandatory
            };
            handleSave(createState);
        }).catch(err => console.error(err));
    };

    const validateForm = () => {
        let returnVal = true;
        if (certificateTypeId === undefined || certificateTypeId === null || certificateTypeId === '') {
            setErrorMessage("Please select Certificate / Licence Type");
            setUploadDisable(false);
            returnVal = false;
        }
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        if (validateForm()) {
            let existCertificate = undefined;
            globalState.itemParts.itemCertificate.map((item) => {
                if (String(item.certificate.certificateTypeId) === String(certificateTypeId)) {
                    if (certificateTypeId === "Others") {
                        if (item.certificate.otherCert === undefined) {
                            item.certificate.otherCert = "";
                        }
                        if (item.certificate.otherCert === otherCert)
                            return existCertificate = item.certificate.certificateTypeId;
                    } else
                        return existCertificate = item.certificate.certificateTypeId;
                }
            });
            if (globalState.itemParts.itemCertificate.length < 0 && existCertificate == undefined) {
                setErrorMessage("Already created a certificate with certificate type name");
                setUploadDisable(false);
                return false;
            } else {
                setUrlErrorMessage("");
                let newArr = fileInput.current.files;
                if (newArr.length > 0) {
                    for (let i = 0; i < newArr.length; i++) {
                        handleUpload(newArr[i]);
                    }
                } else {
                    const createState = {
                        certificateTypeId: certificateTypeId === undefined ? "" : certificateTypeId,
                        expiryDate: expiryDate === undefined ? "" : expiryDate,
                        name: name === undefined ? "" : name,
                        url: "",
                        otherCert: otherCert === undefined ? "" : otherCert,
                        isMandatory: isMandatory === undefined ? "" : isMandatory
                    };
                    handleSave(createState);
                }
            }
        }        
    };

    const handleSave = async (data) => {
        try {
            const requestURL = globalState.endPointURL + "/certificate";
            let methodType = "";
            if (id === undefined)
                methodType = "POST";
            else {
                methodType = "PUT";
                data.id = id;
            }
            // console.log(JSON.stringify(data));

            let response = await fetch(requestURL, {
                method: methodType,
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData?.isSuccess) {
                const certificateInfo = responseData?.itemCertificate[0];
                const certificate = {
                    certificate: {
                        certificateTypeId: certificateInfo?.certificateTypeId,
                        createdBy: certificateInfo?.createdBy,
                        createdDatetime: certificateInfo?.createdDatetime,
                        expiryDate: certificateInfo?.expiryDate === "null" ? "" : certificateInfo?.expiryDate,
                        id: certificateInfo?.id,
                        isActive: certificateInfo?.isActive,
                        isDeleted: certificateInfo?.isDeleted,
                        name: certificateInfo?.name,
                        url: certificateInfo?.url,
                        isMandatory: certificateInfo?.isMandatory,
                        otherCert: certificateInfo?.otherCert
                    }
                };
                globalState.itemParts.itemCertificate.push(certificate);
                handleClose();
            } else if (responseData?.isSuccess === null && responseData?.errorCodes?.errorCodes?.length > 0) {
                errors.message = responseData.errorCodes.errorCodes[0].message;
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            } else {
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            }
        } catch (e) { 
            setErrorMessage(globalState.commonErrorMessage);
        setUploadDisable(false); 
        // console.log(e); 
    }

    };

    const handleClose = () => {
        const itemId = globalState?.itemParts?.itemId;
        if (globalState.itemPartRedirect === "View") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-view/" + itemId);
            });
        } else if (globalState.itemPartRedirect === "Add") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details");
            });
        } else if (globalState.itemPartRedirect === "Edit") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details/" + itemId);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details/" + itemId);
            });
        }
    };


    const handleExpiryDate = (date) => {
        setExpiryDate(date);
    }

    const handleUrl = (val) => {
        setUrlErrorMessage("");        
        if (fileInput.current.files[0].type === "application/pdf" || fileInput.current.files[0].type === "image/png"
            || fileInput.current.files[0].type === "image/jpeg" || fileInput.current.files[0].type === "image/jpg") {
            setUrl(val);
        } else {
            setUrlErrorMessage("Please upload image or pdf file"); 
            setUrl("");
        }
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 >Certificate</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <form action="" method="">
                <div className="row mb-4">

                    <div className="col-lg-6 col-md-6">
                        <label>Certificate #</label>
                        <div className="input-box">
                            <input type="text" className="form-control" name="" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>


                    <div className="col-lg-6 col-md-6">
                        <label>Expiry Date</label>
                        <div className="date-box">
                            <input type="date" className="form-control" name="expiryDate" placeholder="" value={expiryDate} onChange={e => handleExpiryDate(e.target.value)} />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">
                        <label>Certificate / Licence Type</label>
                        <div className="select-box" name="certificateTypeId">
                            <select value={certificateTypeId} onChange={e => { setCertificateTypeErrorMessage(""); setErrorMessage(""); setCertificateTypeId(e.target.value); }}>
                                <option value="">Select</option>
                                <option value="BIS">BIS</option>
                                <option value="ETA">ETA</option>
                                <option value="Import License">Import License</option>
                                <option value="EPR">EPR</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                        <div className="text-warning">{certificateTypeErrorMessage}</div>
                    </div>

                    {certificateTypeId === "Others" ?
                        <div className="col-lg-6 col-md-6">
                            <label>Other Certificate Type</label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="expiryDate" placeholder="" value={otherCert} onChange={e => setOtherCert(e.target.value)} />
                            </div>
                        </div> : ""}

                    <div className="col-lg-6 col-md-6">
                        <label>Certificate file</label>
                        <div className="input-box">
                            <input type="file" className="form-control" ref={fileInput} name="url" placeholder="" value={url}
                                onChange={e => { handleUrl(e.target.value);}} />                                
                        </div>
                        {urlErrorMessage && <p className="text-warning"> {urlErrorMessage} </p>}
                    </div>
                    
                    <div className="col-lg-12 col-sm-12">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches" name="isSerialized"
                                checked={isMandatory} onChange={e => { setIsMandatory(!isMandatory); }} />
                            <label className="custom-control-label" for="customSwitches">Is Mandatory</label>
                        </div>
                    </div>
                    

                </div>
                <div className="btn-footer text-right">
                    <button type="button" disabled={uploadDisable} onClick={onSubmit} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload">Upload <i className="fa fa-upload" aria-hidden="true"></i></button>
                    <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                </div>
            </form>
        </div>
    </section>
};
export default CertificateDetails;