import React from "react";
import { Routes, Route } from "react-router-dom";

import { AuthProvider } from "./hooks/useAuth";
import ProtectedLayout from "./components/ProtectedLayout";
import ProtectedRoute from "./components/ProtectedRoute";

import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import ForgotPassword from "./pages/auth/ForgotPassword";
import ResetPassword from "./pages/auth/ResetPassword";

import Dashboard from "./pages/Dashboard";

import Users from "./pages/masters/Users/Users";
import UserView from "./pages/masters/Users/UserView";
import UserDetails from "./pages/masters/Users/UserDetails";

import Roles from "./pages/masters/Roles/Roles";
import RoleView from "./pages/masters/Roles/RoleView";
import RoleDetails from "./pages/masters/Roles/RoleDetails";

import Clients from "./pages/masters/Clients/Clients";
import ClientView from "./pages/masters/Clients/ClientView";
import ClientDetails from "./pages/masters/Clients/ClientDetails";

import PtiStatus from "./pages/masters/PtiStatus/PtiStatus";
import PtiStatusView from "./pages/masters/PtiStatus/PtiStatusView";
import PtiStatusDetails from "./pages/masters/PtiStatus/PtiStatusDetails";

import PtiItemStatus from "./pages/masters/PtiItemStatus/PtiItemStatus";
import PtiItemStatusView from "./pages/masters/PtiItemStatus/PtiItemStatusView";
import PtiItemStatusDetails from "./pages/masters/PtiItemStatus/PtiItemStatusDetails";

import ItemParts from "./pages/masters/ItemParts/ItemParts";
import ItemPartsView from "./pages/masters/ItemParts/ItemPartsView";
import ItemPartsDetails from "./pages/masters/ItemParts/ItemPartsDetails";
import ItemPartsCertificateDetails from "./pages/masters/ItemParts/CertificateDetails";
import ItemPartsCertificateView from "./pages/masters/ItemParts/CertificateView";
import ItemPartsSerilizedPart from "./pages/masters/ItemParts/SerializedParts";


import Incoterm from "./pages/masters/Incoterm/Incoterm";
import IncotermView from "./pages/masters/Incoterm/IncotermView";
import IncotermDetails from "./pages/masters/Incoterm/IncotermDetails";

import HSCodes from "./pages/masters/HSCodes/HSCodes";
import HSCodesView from "./pages/masters/HSCodes/HSCodesView";
import HSCodesDetails from "./pages/masters/HSCodes/HSCodesDetails";

import PTIRecords from "./pages/shipments/PTIRecords/PTIRecords";
import PTIRecordsView from "./pages/shipments/PTIRecords/PTIRecordsView";
import PTIRecordDetails from "./pages/shipments/PTIRecords/PTIRecordDetails";
import PTIRecordDelivery from "./pages/shipments/PTIRecords/PTIRecordDelivery";
import PTIRecordShipmentItem from "./pages/shipments/PTIRecords/PTIRecordShipmentItem";
import PTIRecordShipmentItemStatus from "./pages/shipments/PTIRecords/PTIRecordShipmentItemStatus";
import PTIRecordShipmentItemView from "./pages/shipments/PTIRecords/PTIRecordShipmentItemView";
import PTIRecordShipmentItemDeliveredView from "./pages/shipments/PTIRecords/PTIRecordShipmentItemDeliveredView";
import IORDocument from "./pages/shipments/PTIRecords/IORDocument";
import IORDocumentView from "./pages/shipments/PTIRecords/IORDocumentView";
import BOE from "./pages/shipments/PTIRecords/BOE";
import ViewBOE from "./pages/shipments/PTIRecords/ViewBOE";
import PTICertificateDetails from "./pages/shipments/PTIRecords/PTICertificateDetails";
import PTICertificateView from "./pages/shipments/PTIRecords/PTICertificateView";
import History from "./pages/shipments/PTIRecords/history";


import ExportRecords from "./pages/shipments/ExportRecords/ExportRecords";
import ExportRecordView from "./pages/shipments/ExportRecords/ExportRecordView";
import ExportRecordsDetail from "./pages/shipments/ExportRecords/ExportRecordsDetail";
import ExportRecordsDelivery from "./pages/shipments/ExportRecords/ExportRecordsDelivery";
import EORDocument from "./pages/shipments/ExportRecords/EORDocument";
import EORDocumentView from "./pages/shipments/ExportRecords/EORDocumentView";
import ExportShipmentItem from "./pages/shipments/ExportRecords/ExportShipmentItem";
import ValidateBOE from "./pages/shipments/ValidateBOE/ValidateBOE";
import Historyeor from "./pages/shipments/ExportRecords/historyeor";

import Reports from "./pages/Reports";
import Settings from "./pages/Settings";
import Error from "./pages/Error";

import Chart from "./pages/chart";

const App = () => {
    return (
        <AuthProvider>
            <Routes>
                <Route path="*" element={<Error />} />
                <Route exact path="/" element={<Login />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/forgot-password" element={<ForgotPassword />} />
                <Route exact path="/reset-password" element={<ResetPassword />} />

                <Route
                    exact
                    path="/dashboard"
                    element={
                        <ProtectedLayout>
                            <Dashboard><Chart /></Dashboard>
                        </ProtectedLayout>
                    }
                />

                <Route
                    exact
                    path="/masters/users"
                    element={
                        <ProtectedLayout>
                            <Users />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/users/user-view/:id"
                    element={
                        <ProtectedLayout>
                            <UserView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/users/user-details"
                    element={
                        <ProtectedLayout>
                            <UserDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/users/user-details/:id"
                    element={
                        <ProtectedLayout>
                            <UserDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/roles"
                    element={
                        <ProtectedLayout>
                            <Roles />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/roles/role-view/:id"
                    element={
                        <ProtectedLayout>
                            <RoleView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/roles/role-details"
                    element={
                        <ProtectedLayout>
                            <RoleDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/roles/role-details/:id"
                    element={
                        <ProtectedLayout>
                            <RoleDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/clients"
                    element={
                        <ProtectedLayout>
                            <Clients />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/clients/client-view/:id"
                    element={
                        <ProtectedLayout>
                            <ClientView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/clients/client-details"
                    element={
                        <ProtectedLayout>
                            <ClientDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/clients/client-details/:id"
                    element={
                        <ProtectedLayout>
                            <ClientDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-status"
                    element={
                        <ProtectedLayout>
                            <PtiStatus />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-status/pti-status-view/:id"
                    element={
                        <ProtectedLayout>
                            <PtiStatusView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-status/pti-status-details"
                    element={
                        <ProtectedLayout>
                            <PtiStatusDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-status/pti-status-details/:id"
                    element={
                        <ProtectedLayout>
                            <PtiStatusDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-item-status"
                    element={
                        <ProtectedLayout>
                            <PtiItemStatus />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-item-status/pti-item-status-view/:id"
                    element={
                        <ProtectedLayout>
                            <PtiItemStatusView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-item-status/pti-item-status-details"
                    element={
                        <ProtectedLayout>
                            <PtiItemStatusDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/pti-item-status/pti-item-status-details/:id"
                    element={
                        <ProtectedLayout>
                            <PtiItemStatusDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts"
                    element={
                        <ProtectedLayout>
                            <ItemParts />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-view/:id"
                    element={
                        <ProtectedLayout>
                            <ItemPartsView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-view"
                    element={
                        <ProtectedLayout>
                            <ItemPartsView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-certificate-details"
                    element={
                        <ProtectedLayout>
                            <ItemPartsCertificateDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-certificate-details/:id"
                    element={
                        <ProtectedLayout>
                            <ItemPartsCertificateDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-certificate-view/:id"
                    element={
                        <ProtectedLayout>
                            <ItemPartsCertificateView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-serialized-parts"
                    element={
                        <ProtectedLayout>
                            <ItemPartsSerilizedPart />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-details"
                    element={
                        <ProtectedLayout>
                            <ItemPartsDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/item-parts/item-parts-details/:id"
                    element={
                        <ProtectedLayout>
                            <ItemPartsDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/incoterm"
                    element={
                        <ProtectedLayout>
                            <Incoterm />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/incoterm/incoterm-view/:id"
                    element={
                        <ProtectedLayout>
                            <IncotermView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/incoterm/incoterm-details"
                    element={
                        <ProtectedLayout>
                            <IncotermDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/incoterm/incoterm-details/:id"
                    element={
                        <ProtectedLayout>
                            <IncotermDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/hscodes"
                    element={
                        <ProtectedLayout>
                            <HSCodes />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/hscodes/hscodes-view/:id"
                    element={
                        <ProtectedLayout>
                            <HSCodesView />
                        </ProtectedLayout>
                    }
                /> <Route
                    exact
                    path="/masters/hscodes/hscodes-details"
                    element={
                        <ProtectedLayout>
                            <HSCodesDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/masters/hscodes/hscodes-details/:id"
                    element={
                        <ProtectedLayout>
                            <HSCodesDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords"
                    element={
                        <ProtectedLayout>
                            <PTIRecords />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecordsView/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordsView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordDetails"
                    element={
                        <ProtectedLayout>
                            <PTIRecordDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordDetails/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordDelivery/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordDelivery />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItem"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItem />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItem/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItem />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemStatus/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItemStatus />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemView/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItemView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemView"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItemView />
                        </ProtectedLayout>
                    }
                />
                 <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemDeliveredView/:id"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItemDeliveredView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemDeliveredView"
                    element={
                        <ProtectedLayout>
                            <PTIRecordShipmentItemDeliveredView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemView/pti-certificate-details"
                    element={
                        <ProtectedLayout>
                            <PTICertificateDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemView/pti-certificate-details/:id"
                    element={
                        <ProtectedLayout>
                            <PTICertificateDetails />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/PTIRecordShipmentItemView/pti-certificate-view/:id"
                    element={
                        <ProtectedLayout>
                            <PTICertificateView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/History/:id"
                    element={
                        <ProtectedLayout>
                            <History />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/IORDocument"
                    element={
                        <ProtectedLayout>
                            <IORDocument />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/IORDocumentView"
                    element={
                        <ProtectedLayout>
                            <IORDocumentView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/IORDocumentView/:id"
                    element={
                        <ProtectedLayout>
                            <IORDocumentView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/BOE/:id"
                    element={
                        <ProtectedLayout>
                            <BOE />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/PTIRecords/ViewBOE/:id"
                    element={
                        <ProtectedLayout>
                            <ViewBOE />
                        </ProtectedLayout>
                    }
                />


                <Route
                    exact
                    path="/shipments/ExportRecords/ExportRecords"
                    element={
                        <ProtectedLayout>
                            <ExportRecords />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/ExportRecordsDetail"
                    element={
                        <ProtectedLayout>
                            <ExportRecordsDetail />
                        </ProtectedLayout>
                    }
                />
                 <Route
                    exact
                    path="/shipments/ExportRecords/ExportRecordsDelivery/:id"
                    element={
                        <ProtectedLayout>
                            <ExportRecordsDelivery />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/ExportRecordsDetail/:id"
                    element={
                        <ProtectedLayout>
                            <ExportRecordsDetail />
                        </ProtectedLayout>
                    }
                />
                  <Route
                    exact
                    path="/shipments/ExportRecords/Historyeor/:id"
                    element={
                        <ProtectedLayout>
                            <Historyeor />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/ExportRecordView/:id"
                    element={
                        <ProtectedLayout>
                            <ExportRecordView />
                        </ProtectedLayout>
                    }
                />

                <Route
                    exact
                    path="/shipments/ExportRecords/EORDocument"
                    element={
                        <ProtectedLayout>
                            <EORDocument />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/EORDocumentView"
                    element={
                        <ProtectedLayout>
                            <EORDocumentView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/EORDocumentView/:id"
                    element={
                        <ProtectedLayout>
                            <EORDocumentView />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ExportRecords/ExportShipmentItem"
                    element={
                        <ProtectedLayout>
                            <ExportShipmentItem />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/shipments/ValidateBOE/ValidateBOE"
                    element={
                        <ProtectedLayout>
                            <ValidateBOE />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/reports"
                    element={
                        <ProtectedLayout>
                            <Reports />
                        </ProtectedLayout>
                    }
                />
                <Route
                    exact
                    path="/settings"
                    element={
                        <ProtectedLayout>
                            <Settings />
                        </ProtectedLayout>
                    }
                />
            </Routes>
            
        </AuthProvider>
        
    );
};

export default App;
