import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const ExportRecordsDetail = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [ eorData, setEorData ] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [eorHistory, setEORHistory] = useState([]);

    useEffect(() => {
        const fetchEorData = async () => {
            try {
                const requestURL = globalState.endPointURL + "/eor/id/" + id;
                const headersUserName = window.localStorage.getItem("headers-username");
                globalState.headers.userName = headersUserName;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers
                });
                const responseData = await response.json();
               // console.log(responseData);
                if (responseData !== null && responseData?.isSuccess) {
                    let data = responseData.eorInfo[0];
                    globalState.incotermId?.filter((item) => {
                        if (String(item.name) === String(data?.shipmentDetails?.incoterms))
                            return data.shipmentDetails.incotermName = item.value;
                    });
                    globalState.shipVia?.filter((item) => {
                        if (String(item.name) === String(data?.shipmentDetails?.shipVia))
                            return data.shipmentDetails.shipViaName = item.value;
                    });
                    globalState.modeTran?.filter((item) => {
                        if (String(item.name) === String(data?.shipmentDetails?.modeOfTransport))
                            return data.shipmentDetails.modeOfTransportName = item.value;
                    });
                    globalState.currency?.filter((item) => {
                        if (String(item.name) === String(data?.shipmentDetails?.currency))
                            return data.shipmentDetails.currencyName = item.value;
                    });
                    setEorData(data);
                    setEORHistory(data?.remarks);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        };
        fetchEorData();
    }, []);

    //Redirect to Export record
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecords");
        });
    };

    const handleEOROpen = (e, data) => {
        if (e.target.innerHTML === "+") {
            let div = document.createElement("div");
            div.className = "accordian-body";
            div.id = data;
            div.innerHTML = data;
            e.target.innerHTML = "-";
            e.target.parentNode.parentNode.appendChild(div);
        } else {
            let element = document.getElementById(data);
            e.target.innerHTML = "+";
            e.target.parentNode.parentNode.removeChild(element);
        }
    }

    return <div className="dashboard">
        <div className="table-heading">
            <h4 className="w-50">Export Record view</h4>
        </div>
        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <form action="">
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 m-0 p-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Export Details</div>
                        {/* <!-- Export Details row --> */}
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="p-1">
                                    {/* <!--  Export Id  --> */}
                                    <div className="detail-view-flex">
                                        <h5>Export Id</h5>
                                        <p>{eorData?.id }</p>
                                    </div>
                                    {/* <!-- end Export Id --> */}
                                    {/* <!-- Client Name --> */}
                                    <div className="detail-view-flex">
                                        <h5>Client Name</h5>
                                        <p>{eorData?.clientName}</p>
                                    </div>
                                    {/* <!-- end Client Name --> */}
                                    {/* <!--  Export Reference  --> */}
                                    <div className="detail-view-flex">
                                        <h5>Export Reference</h5>
                                        <p>{eorData?.reference}</p>
                                    </div>
                                    {/* <!-- end Export Reference --> */}                                    
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="p-1">                                    
                                    {/* <!-- Ship Date --> */}
                                    <div className="detail-view-flex">
                                        <h5>Ship Date</h5>
                                        <p>{eorData?.shipDate}</p>
                                    </div>
                                    {/* <!-- end Ship Date --> */}
                                    {/* <!-- Export Status --> */}
                                    <div className="detail-view-flex">
                                        <h5>Export Status</h5>
                                        <p>{eorData?.status}</p>
                                    </div>
                                    {/* <!-- end Export Status --> */}
                                    {/* <!--  Invoice # --> */}
                                    <div className="detail-view-flex">
                                        <h5>Invoice #</h5>
                                        <p>{eorData?.invoiceNo}</p>
                                    </div>
                                    {/* <!-- end Invoice #--> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- end Export Details row --> */}
                    </div>
                </div>
            </div>

            <div className="row">
                {/* <!-- Exporter details --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Exporter Details</div>
                        <div className="p-1">
                            {/* <!-- Exporter --> */}
                            <div className="detail-view-flex">
                                <h5> Exporter </h5>
                                <p>{eorData?.exporterDetails?.exporter}</p>
                            </div>
                            {/* <!-- end  Exporter --> */}
                            {/* <!-- Shipper --> */}
                            <div className="detail-view-flex">
                                <h5>Shipper</h5>
                                <p>{eorData?.exporterDetails?.shipper}</p>
                            </div>
                            {/* <!-- end Shipper --> */}
                            {/* <!-- Country of Export --> */}
                            <div className="detail-view-flex">
                                <h5>Country of Export</h5>
                                <p>{eorData?.exporterDetails?.countryOfExport}</p>
                            </div>
                            {/* <!-- end Country of Export --> */}
                        </div>
                    </div>
                    {/* <!-- end single-pti-box  --> */}
                </div>
                {/* Exporter details --> */}

                {/* <!-- Importer Details --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Importer Details</div>
                        <div className="p-1">
                            {/* Importer */}
                            <div className="detail-view-flex">
                                <h5>Importer</h5>
                                <p>{eorData?.importerDetails?.importer}</p>
                            </div>
                            {/* end Importer */}
                            {/* consignee / ship To */}
                            <div className="detail-view-flex">
                                <h5>Consignee / Ship To</h5>
                                <p>{eorData?.importerDetails?.shippedTo}</p>
                            </div>
                            {/* end consignee / ship To */}
                            {/* Country of Ultimate Destination */}
                            <div className="detail-view-flex">
                                <h5>Country of Destination</h5>
                                <p>{eorData?.importerDetails?.countryOfDestination}</p>
                            </div>
                            {/* end Country of Ultimate Destination */}

                        </div>
                    </div>
                </div>
                {/* <!-- end Exporter details --> */}
            </div>


            {/* <!-- Shipment Details --> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Shipment Details</div>
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="p-1">
                                    {/* <!--  Incoterm --> */}
                                    <div className="detail-view-flex">
                                        <h5>Incoterms</h5>
                                        <p>{eorData?.shipmentDetails?.incotermName} </p>
                                    </div>
                                    {/* <!-- end  Incoterm --> */}
                                    {/* <!--  Ship via  --> */}
                                    <div className="detail-view-flex">
                                        <h5>Ship via</h5>
                                        <p>{eorData?.shipmentDetails?.shipViaName}</p>
                                    </div>
                                    {/* <!-- end Ship via --> */}
                                    {/* <!-- Currency --> */}
                                    <div className="detail-view-flex">
                                        <h5>Currency</h5>
                                        <p>{eorData?.shipmentDetails?.currencyName}</p>
                                    </div>
                                    {/* <!-- end Currency --> */}
                                    {/* <!--  No. of Package --> */}
                                    <div className="detail-view-flex">
                                        <h5>No. of Package</h5>
                                        <p>{eorData?.shipmentDetails?.noOfPackages}</p>
                                    </div>
                                    {/* <!-- end No. of Package--> */}
                                    {/* <!--  AWB-BL No. --> */}
                                    <div className="detail-view-flex">
                                        <h5>AWB-BL No.</h5>
                                        <p>{eorData?.shipmentDetails?.awbBillNumber}</p>
                                    </div>
                                    {/* <!-- end AWB-BL No.--> */}
                                    {/* <!--  AWB-BL No. --> */}
                                    <div className="detail-view-flex">
                                        <h5>GR Wavier no.</h5>
                                        <p>{eorData?.shipmentDetails?.grWavierNo}</p>
                                    </div>
                                    {/* <!-- end AWB-BL No.--> */}
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="p-1">
                                    {/* <!-- Mode of Transport --> */}
                                    <div className="detail-view-flex">
                                        <h5>Mode of Transport</h5>
                                        <p>{eorData?.shipmentDetails?.modeOfTransportName}</p>
                                    </div>
                                    {/* <!-- end Mode of Transport --> */}
                                    {/* <!-- Gross Wt. --> */}
                                    <div className="detail-view-flex">
                                        <h5>Shipped Bill No</h5>
                                        <p>{eorData?.shipmentDetails?.shippingBillNo}</p>
                                    </div>
                                    {/* <!-- end Gross Wt. --> */}
                                    {/* <!-- Gross Wt. --> */}
                                    <div className="detail-view-flex">
                                        <h5>Shipped Bill Date</h5>
                                        <p>{eorData?.shipmentDetails?.shippingBillDate}</p>
                                    </div>
                                    {/* <!-- end Gross Wt. --> */}
                                    {/* <!-- Gross Wt. --> */}
                                    <div className="detail-view-flex">
                                        <h5>Gross Wt.</h5>
                                        <p>{eorData?.shipmentDetails?.grossWeight}</p>
                                    </div>
                                    {/* <!-- end Gross Wt. --> */}
                                    {/* <!-- GR Wavier Date --> */}
                                    <div className="detail-view-flex">
                                        <h5>GR Wavier Date</h5>
                                        <p>{eorData?.shipmentDetails?.grWavierDate}</p>
                                    </div>
                                    {/* <!-- end GR Wavier Date --> */}                                                                       
                                    
                                </div>
                            </div>

                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                    {/* <!-- end shipment item row --> */}
                    <br></br>
                    {/* !-- history --> */}
                    <div class="row">
                        <div class="col-sm-12 col-lg-12">
                            <div class="single-pti-box">
                                <div class="single-pti-box-header">History</div>
                                <div class="p-2">
                                    {/* !-- accordion --> */}
                                    {eorHistory?.map((history) => {
                                        return <div class="row mb-2"><div className="accordian">
                                            <div className="accordian-header" >
                                                <div>Date : {history.dateTime} </div>
                                                <div className="sign" onClick={e => { handleEOROpen(e, history.message) }}>{'+'}</div>
                                            </div>
                                        </div>
                                        </div>
                                    }
                                    )}
                                    {/* !-- accordion end --> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* end history  */}

                </div>
            </div>


            {/* <!-- buttons --> */}
            <div className="btnooter text-right">
                <button type="button" onClick={handleClose} className="btn btn-secondary ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Back to Export Records <i className="fa fa-times-circle" aria-hidden="true"></i></button>

            </div>
        </form>
    </div>

};

export default ExportRecordsDetail;