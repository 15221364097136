import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const ClientView = () => {

    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        const fetchClients = async () => {
            try {                
                const requestURL = globalState.endPointURL + "/client/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers
                });
                const responseData = await response.json();                
                // console.log(responseData);
                if (responseData !== null) {
                    setClients(responseData?.clientInfo[0]);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    //setErrorMessage("Failed to fetch data. Please try after sometime.");
                } else
                     setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                 setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        fetchClients();
    }, []);

// handleclose
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/clients");
        });
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">Client Details View</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="row  mb-4 mt-4">
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>Id </h5>
                        <p>{clients.id}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Client Name </h5>
                        <p>{clients.name}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Address 1 </h5>
                        <p>{clients.address?.address1}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Address 2</h5>
                        <p>{clients.address?.address2} </p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>City </h5>
                        <p>{clients.address?.city}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>State </h5>
                        <p>{clients.address?.state}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Zip </h5>
                        <p>{clients.address?.zip}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Country </h5>
                        <p>{clients.address?.country}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Contact Person </h5>
                        <p>{clients.contactPerson}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Phone Number </h5>
                        <p>{clients.contactMobile}</p>
                </div>
            </div>

        </div>
        <div className="row pt-4 mt-4">
            <div className="col-sm-12">
               
                <div className="table-heading">
                    <h4 className="mt-2">Billing Detail</h4>
                    
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Billing Name </h5>
                        <p>{clients.billingName}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Billing Email </h5>
                        <p>{clients.billingEmail}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Contact </h5>
                        <p>{clients.billingPerson}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5> Phone Number </h5>
                        <p>{clients.billingMobile}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Address 1 </h5>
                        <p>{clients.billingAddress?.address1}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Address 2 </h5>
                        <p>{clients.billingAddress?.address2}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>City </h5>
                        <p>{clients.billingAddress?.city}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>State </h5>
                        <p>{clients.billingAddress?.state}</p>
                </div>
            </div>
           
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Zip </h5>
                        <p>{clients.billingAddress?.zip}</p>
                </div>
            </div>
           
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Country </h5>
                        <p>{clients.billingAddress?.country}</p>
                </div>
            </div>
            <div className="text-right">
                    <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to Clients <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
        </div>
        </div>
        
</div>
    </section>
};
export default ClientView;
