import React, { useState, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import ITCHsCode from "./ITCHsCode";
import csc from "country-state-city";
import CertificatePopup from './CertificatePopup';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const PTIRecordShipmentItemView = () => {
    const certiGridRef = useRef();
    const [itemParts, setItemParts] = useState([]);
    const [itemCertificate, setItemCertificate] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const { id } = useParams();
    const [partNo, setPartNo] = useState("");
    const [intOrderNo, setIntOrderNo] = useState("");
    const [serialNo, setSerialNo] = useState("");
    const [manuNo, setManuNo] = useState("");
    const [description, setDescription] = useState("");
    const [eccn, setEccn] = useState("");
    const [encCode, setEncCode] = useState("");
    const [itcHsCode, setItcHsCode] = useState("");
    const [usCode, setUsCode] = useState("");
    const [htsCode, setHtsCode] = useState("");
    const [coo, setCoo] = useState("");
    const [uom, setUmo] = useState("");
    const [quantity, setQuantity] = useState("");
    const [amount, setAmount] = useState("");
    const [unitPrice, setUnitPrice] = useState("");
    const [expLIC1, setExpLIC1] = useState("");
    const [expLIC2, setExpLIC2] = useState("");
    const [wpcETA1, setWpcETA1] = useState("");
    const [wpcETA2, setWpcETA2] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const expFileInput = useRef(null);
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [certificateUrl, setCertificateUrl] = useState("");
    const [certificateId, setCertificateId] = useState("");

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    useEffect(() => {
        const fetchShipmentItems = async () => {
            try {
                const requestURL = globalState.endPointURL + "/shipment/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
              //  console.log(responseData);
                if (responseData !== "") {
                    const data = responseData.shipmentItem[0];
                    if (data.certificates !== null) {
                        data.certificates = data.certificates?.filter((item) => {
                            if (item !== null) {
                                return item;
                            }
                        });
                    }
                    data.certificates?.map((item) => {
                        item.expiryDate = item.expiryDate === "null" || item.expiryDate === undefined || item.expiryDate === null ? "" : item.expiryDate;
                    });
                    globalState.uom?.filter((item) => {
                        if (item.name === String(data.itemDetails.uomId))
                            return data.itemDetails.uomName = item.value;
                    });
                    setPartNo(data.item.partNumber);
                    setIntOrderNo(data.item.internalOrderNumber);
                    setSerialNo(data.item.serialNumber);
                    setManuNo(data.item.manufactureNumber);
                    setDescription(data.item.description);
                    setEccn(data.codes.eccn);
                    setEncCode(data.codes.encCode);
                    setItcHsCode(data.codes.itcHsCode);
                    setUsCode(data.codes.usCode);
                    setHtsCode(data.codes.htsCode);
                    setCoo(data.itemDetails.countryOfOrigin);
                    setUmo(data.itemDetails.uomName);
                    setQuantity(data.itemDetails.quantity);
                    setAmount(data.itemDetails.amount);
                    setUnitPrice(data.itemDetails.unitPrice);
                    setItemCertificate(data.certificates);
                    //setExpLIC1(data.expLIC1);
                    //setExpLIC2(data.expLIC2);
                    //setWpcETA1(data.wpcETA1);
                    //setWpcETA2(data.wpcETA2);
                    //handlePartNo(data.partNo);
                } else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined) {
            fetchShipmentItems();
        }
        globalState.itemPartRedirect = "View";
    }, []);
    

    //cancel
    const handleClose = () => {
        if (globalState.ptiRecord.ptiId !== undefined) {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/" + globalState.ptiRecord.ptiId);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/");
            });
        }
    };

    const handleCertView = (certId, certUrl) => {
        setIsOpen(!isOpen);
        setCertificateUrl(certUrl);
        setCertificateId(certId);
    };

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => handleCertView(event.data?.id, event.data?.url)}
                    type="button"
                    className="btn btn-link btn-sm bg-secondary text-light input-flex-button"
                    data-bs-toggle="tooltip" data-bs-placement="top" title="Certificate">
                    <i className="bx bxs-show" aria-hidden="true" ></i></button>
            </p>
        );
    };

    const certiColumnDef = [
        { headerName: 'Certificate Type', field: "certificateTypeId", sort: 'asc', sortable: true, filter: false, width: 300 },
        { headerName: 'Cerificate #', field: "name", sortable: false, filter: true.valueOf, width: 300 },
        { headerName: 'Expiry Date', field: "expiryDate", sortable: false, filter: true, width: 300 },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];


    return <div className="dashboard">
        <div className="table-heading">
            <h4 className="w-50">Shipment Items View</h4>
        </div>
        <form action="">
            <div className="pti-detail-container">
                {/* <!-- pti id --> */}
                <div className="row">
                    <div className="col-sm-3">
                        <div className="from-group pti-field">
                            <div className="detail-view-flex">
                                <h5>PTI Id</h5>
                                <p>{globalState.ptiRecord.ptiId}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- pti single container --> */}
            <div className="row">
                {/* <!-- Shipments items --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Shipment Items</div>
                        <div className="p-4">
                            {/* <!--Part No. --> */}
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>Part No.</h5>
                                    <p>{partNo}</p>
                                </div>
                            </div>
                            {/* <!-- end Part No. --> */}
                            {/* <!-- Internal Order No. --> */}
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>Internal Order No.</h5>
                                    <p>{intOrderNo}</p>
                                </div>
                            </div>
                            {/* <!-- end Internal Order No. --> */}
                            {/* <!-- Serial Number --> */}
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>Serial Number</h5>
                                    <p>{serialNo}</p>
                                </div>
                            </div>
                            {/* <!-- end Serial Number --> */}
                            {/* <!-- Manufacture Number --> */}
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>Manufacture Number</h5>
                                    <p>{manuNo}</p>
                                </div>
                            </div>
                            {/* <!-- end Manufacture Number --> */}
                            {/* <!-- Manufacture Number --> */}
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>Description</h5>
                                    <p>{description}</p>
                                </div>
                            </div>
                            {/* <!-- end Manufacture Number --> */}
                        </div>
                    </div>
                    {/* <!-- end single-pti-box  --> */}
                </div>
                {/* <!--end Shipments items --> */}
                {/* <!-- codes --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Codes</div>
                        <div className="p-4">
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>ECCN</h5>
                                    <p>{eccn}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>ENC Code</h5>
                                    <p>{encCode}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>ITC HS Code</h5>
                                    <p>{itcHsCode}</p>
                                </div>
                            </div>

                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>US Code</h5>
                                    <p>{usCode}</p>
                                </div>
                            </div>
                            <div className="single-pti-box-group">
                                <div className="detail-view-flex">
                                    <h5>HTC Code</h5>
                                    <p>{htsCode}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- end codes --> */}
                {/* <!--shipment item --> */}
                <div className="col-lg-12 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Permits & Certificate</div>
                        <div className="row p-4">
                        <div className="ag-theme-alpine" style={{ height: 300 }}>
                            
                            <AgGridReact
                                ref={certiGridRef}
                                rowData={itemCertificate}
                                columnDefs={certiColumnDef}
                                pagination={true}
                                // onGridReady={onGridReady}
                                paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                                />
                            </div>
                            {isOpen && <CertificatePopup
                                content={
                                    <div id="exampleModal">
                                        <div className="modal-dialog" >
                                            <div className="modal-content">
                                                <div className="popup-header">
                                                    <h5 className="popup-title">Document</h5>
                                                    <br></br>
                                                </div>
                                                <div className="modal-body row">
                                                    <div className=" m-auto">
                                                        <embed src={certificateUrl} type="" width="100%" height="400" />
                                                    </div>
                                                </div>
                                                <br></br>
                                                <div className="col-lg-12 text-right">
                                                    <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                handleClose={togglePopup} />}
                        </div>
                     </div>
                </div>
            </div>


            {/* <!-- item/parts --> */}
            <div className="col-lg-12 col-sm-12 m-0 p-0">
                <div className="row m-0 p-0">
                    <div className="col-lg-12 col-sm-12 p-0 m-0">
                        <div className="single-pti-box">
                            <div className="single-pti-box-header">Item / Part Details</div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="p-4">
                                        {/* <!-- Country of origin --> */}
                                        <div className="single-pti-box-group">
                                            <div className="detail-view-flex">
                                                <h5>Country of origin</h5>
                                                <p>{coo}</p>
                                            </div>
                                        </div>
                                        {/* <!-- end Country of origin --> */}
                                        {/* <!--  Quantity  --> */}
                                        <div className="single-pti-box-group">
                                            <div className="detail-view-flex">
                                                <h5>Quantity</h5>
                                                <p>{quantity}</p>
                                            </div>
                                        </div>
                                        {/* <!-- end Quantity --> */}
                                        {/* <!--  Unit Price --> */}
                                        <div className="single-pti-box-group">
                                            <div className="detail-view-flex">
                                                <h5>Unit Price</h5>
                                                <p>{unitPrice}</p>
                                            </div>
                                        </div>
                                        {/* <!-- end Unit Price--> */}
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="p-4">
                                        {/* <!-- UOM --> */}
                                        <div className="single-pti-box-group">
                                            <div className="detail-view-flex">
                                                <h5>UOM</h5>
                                                <p>{uom}</p>
                                            </div>
                                        </div>
                                        {/* <!-- end UOM --> */}
                                        {/* <!-- Amount --> */}
                                        <div className="single-pti-box-group">
                                            <div className="detail-view-flex">
                                                <h5>Amount</h5>
                                                <p>{amount}</p>
                                            </div>
                                        </div>
                                        {/* <!-- end Amount --> */}
                                    </div>
                                </div>
                            </div>
                            {/* <!-- item/parts --> */}
                        </div>
                    </div>
                    <div className="btn-footer text-right">
                        <button type="button" onClick={handleClose}  className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Back to Shipment Item <i className="fa fa-check-circle" aria-hidden="true">
                        </i>
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>

};

export default PTIRecordShipmentItemView;

