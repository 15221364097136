import React, { useEffect, useState } from "react";
import { BarChart, Bar, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {createUseStyles} from 'react-jss'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalContext } from "../GlobalContext";


const ClientSummaryChart = ()=>{

    const [fromDate, setFromDate] = React.useState(dayjs(new Date).subtract(1,'month'));
    const [toDate, setToDate] = React.useState(dayjs(new Date));
    const [clientSummary, setClientSummary] = useState([])
    const [errorMessage, setErrorMessage] = useState("")
    const { globalState } = React.useContext(GlobalContext)

    const domLayout = "autoHeight";

    const columnDefs=[
        {headerName: 'Client Name',field:"clientName"},
        {headerName: 'No Of IOR Records',field:"iorCount"},
        {headerName: 'IOR Value',field:"iorVolume"},
        {headerName: 'No Of EOR Records ',field:"eorCount"},
        {headerName: 'EOR Value',field:"eorVolume"},
      ]

      useEffect(()=>{
        clientSummaryChartData() 
      },[fromDate,toDate])

      const PickDate = (props)=>{
        const styles = createUseStyles({
          datePicker: {
            "& .MuiTextField-root": {
              width: 165,
              height:100
            }}
        })
        const classes = styles()
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              
            <div className={classes.datePicker}>
            <DatePicker
                label="From"
                value={fromDate} 
                onChange={(newValue) => setFromDate(newValue)}
                
              />
              </div>
              <div className={classes.datePicker}>
              <DatePicker
                label="To"
                value={toDate} 
                onChange={(newValue) => setToDate(newValue)}
                
              />
              </div>
      
            </DemoContainer>
          </LocalizationProvider>
      }
      
      
      
      const clientSummaryData = (summary)=>{
        let i=0;
        let summaryArrayObj=[]
        let summarObjInstance={}

        summary?.filter((summary)=>{
          summarObjInstance=new Object();
      
          summarObjInstance.clientName=summary.clientName;
      
          if((summary.clientName.hasOwnProperty("iorCount")!=undefined && summary || "").clientName.hasOwnProperty("iorCount")==true){
            summarObjInstance.iorCount= summary.iorCount
            summarObjInstance.iorVolume=summary.iorVolume
            summarObjInstance.eorCount=""
            summarObjInstance.eorVolume=""
          }
          if((summary.clientName.hasOwnProperty("eorCount")!=undefined || "") && summary.clientName.hasOwnProperty("iorCount")==true){
            summarObjInstance.eorCount=""
            summarObjInstance.eorVolume="" 
            summarObjInstance.eorCount=summary.eorCount
            summarObjInstance.eorVolume=summary.eorVolume
          }
          if((summary.clientName.hasOwnProperty("eorCount")!=undefined || "") && (summary.clientName.hasOwnProperty("iorCount")!=undefined || "")){
            summarObjInstance.iorCount=summary.iorCount
            summarObjInstance.iorVolume=summary.iorVolume 
            summarObjInstance.eorCount=summary.eorCount
            summarObjInstance.eorVolume=summary.eorVolume 
          }
          summaryArrayObj.push(summarObjInstance)
        })

        return summaryArrayObj
    }
    
        
        
      const clientSummaryChartData = (async () => {
      
        try {
            const requestURL = globalState.endPointURL + "/dashboard/info";
            const reqObject={
              "startDate":fromDate.format("YYYY-MM-DD"),
              "endDate":toDate.format("YYYY-MM-DD"),
              "requestType":"ALL",
              "iorStatus":"ALL",
              "eorStatus":"ALL",
      
            }
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            
            const response = await fetch(requestURL, {
                method: 'POST',
                headers:globalState.headers,
                body:JSON.stringify(reqObject)
            });
            const data = await response.json();
            if (data !== "" && data.isSuccess) {
              if(data?.information?.clientSummary!==undefined){
              const dataSummary = clientSummaryData(data.information.clientSummary)
              setClientSummary(dataSummary)
              }
              else{
                setClientSummary([])
              }
            } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(data.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
        }
      
      });
      


    return  <div className="row"> 
                   <div className="row">
                   <h5 style={{align:"center"}}>Client Wise / Customer Wise-Turnover</h5>
                   <PickDate/>
                   </div>
                   <div className='row ag-theme-alpine' style={{height:295,marginTop:5}}>
                   <AgGridReact 
                   rowData={clientSummary}
                    columnDefs={columnDefs}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 100,
                        filter: true,
                        resizable: true,
                    }}
                    pagination="true"
                    paginationPageSize={5}></AgGridReact>
                   </div>
        </div>
  }

  export default ClientSummaryChart