import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CertificatePopup from '../PTIRecords/CertificatePopup';

const PTIRecordShipmentItemStatus = () => {
    const [ptiRecords, setPtiRecords] = useState([]);
    const [url, setUrl] = useState("");
    const [ptiRecordShipmentItemStatus, setPtiRecordShipmentItemStatus] = useState([]);
    const [ptiHistory, setPTIHistory] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const { state } = useLocation();
    const navigate = useNavigate();
    const [itemParts, setItemParts] = useState([]);
    const { login } = useAuth();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const gridRef = useRef();
    const [searchText, setSearchText] = useState("");
    const { id } = useParams();
    const [ptiRecord, setPtiRecord] = useState([]);
    const [itemPartApprove, setItemPartApprove] = useState(false);
    const [showItemPartApprove, setShowItemPartApprove] = useState(false);
    const [approvedCount, setApprovedCount] = useState(0);
    const [remarks, setRemarks] = useState("");
    const [popupUrl, setPoupURL] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const [uploadHistoryDisable, setUploadHistoryDisable] = useState(false);
    const [remarksErrorMessage, setRemarksErrorMessage] = useState("");
    const [clientList, setClientList] = useState([]);
    const [IncotermList, setIncotermList] = useState([]);
    const [ptiStatusList, setPtiStatusList] = useState([]);
    var arr;
    var incobox;
    var statusBox;

    const domLayout = "autoHeight";
    
    const handleOpen = (e, data) => {
        if (e.target.innerHTML === "+") {
            let div = document.createElement("div");
            div.className = "accordian-body";
            div.id = data;
            div.innerHTML = data;
            e.target.innerHTML = "-";
            e.target.parentNode.parentNode.appendChild(div);
        } else {
            let element = document.getElementById(data);
            e.target.innerHTML = "+";
            e.target.parentNode.parentNode.removeChild(element);
        }
    }
  
    const handleItemPartApprove = () => {
        const rowData = gridRef.current.props.rowData;
        const allRowsApproved = rowData.every(row => row.itemStatus);
       // console.log(allRowsApproved, 'APPROVEDRPWWS')
        if (allRowsApproved) {
            setItemPartApprove(!itemPartApprove);
            ptiRecord.ptiStatus = !itemPartApprove ? "Approved" : "Pending Approval";
            ptiRecord.ptiStatusName = !itemPartApprove ? "Approved" : "Pending Approval"; // add this line
        }
    };

    const onSubmit = () => {
        const shipmentList = [];
        ptiRecordShipmentItemStatus.map((item) => {
            const createShipment = {
                "id": item.itemId,
                "itemStatus": item.itemStatus
            };
            shipmentList.push(createShipment);
        });
        const data = { "shipmentRequest": shipmentList };
        saveShipment(data);
    };

    const saveShipment = async (data) => {
        try {
            if (data?.shipmentRequest?.length === 0) {
                    handleSave(ptiRecord, true);
                    handleClose();
            } else {
                let requestURL = globalState.endPointURL + "/shipment/all";
                let methodType = "PUT";
                const headersUserName = window.localStorage.getItem("headers-username");
                globalState.headers.userName = headersUserName;
                // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null && responseData.isSuccess) {
                        handleSave(ptiRecord, true);
                        handleClose();
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    // setErrorMessage("Failed to save the data.");
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } 
        catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            //   console.log("Error" + e); 
        }
    };

    const toggleButton = (params, val) => {
        params.data.itemStatus = val;
        if (val) {
            setApprovedCount(approvedCount + 1);
        } else {
            setApprovedCount(approvedCount - 1);
        }
        const updatedRowData = ptiRecordShipmentItemStatus.map(row => {
            if (row.itemId === params.data.itemId) {
                row.itemStatus = val;
            }
            return row;
        });
        setPtiRecordShipmentItemStatus(updatedRowData);

        let statusApprove = true;
        updatedRowData.forEach(item => {
            if (!item.itemStatus) {
                statusApprove = false;
            }
        });
        setShowItemPartApprove(statusApprove);
    };

    const handleEtaAvailable = (params, val) => {
        params.data.etaApplicable = val;
    };

    const handleBisAvailable = (params, val) => {
        params.data.bisAvailable = val;
    };

    const togglePopup = () => {
        setIsOpen(!isOpen);
    }

    const handlePopup = (params) => {
        setPoupURL(params);
        togglePopup();
    }

    const columnDef = [
        { headerName: 'Item Code', field: "itemCode", sortable: true, filter: true, width: 160 },
        { headerName: 'Description', field: "des", sortable: true, filter: true, width: 200 },
        { headerName: 'HS Code', field: "hscode", sortable: true, filter: true, width: 90 },
        { headerName: 'Restriction', field: "restr", sortable: true, filter: true, width: 80 },
        { headerName: 'BIS Applicable', field: "bisApplicable", sortable: true, width: 100 },
        { headerName: 'BIS Url', field: "bisurl", hide: true, sortable: true, width: 100 },
        {
            headerName: 'BIS Available', field: "bisAvailable",
            cellRendererFramework: (params) => {
                return <div className="avilableView">
                    {
                        <div onChange={(e) => handleBisAvailable(params, e.target.value)}>{params.data.bisAvailable}</div>
                    }
                    {params.data.bisAvailable === "Available" ? <a className="availableSpan" onClick={(e) => handlePopup(params.data.bisurl)}>View</a> : ""}
                </div>
            },

            sortable: true, filter: true, width: 130
        },
        { headerName: 'ETA/WPC Applicable', field: "etaApplicable", sortable: true, filter: true, width: 100 },
        { headerName: 'ETA Url', field: "etaurl", hide: true, sortable: true, width: 100 },
        {
            headerName: 'ETA/WPC Doc', field: "etaAvailable", sortable: true,
            cellRendererFramework: (params) => {
                return <div className="item-avail avilableView">
                    {
                        <div onChange={(e) => handleEtaAvailable(params, e.target.value)}>{params.data.etaAvailable}</div>
                    }
                    {params.data.etaAvailable === "Available" ? <a className="availableSpan" onClick={(e) => handlePopup(params.data.etaurl)}>View</a> : ""}
                </div>
            },
            filter: true, width: 130
        },
        { headerName: 'Other', field: "others", sortable: true, filter: true, width: 80 },
        {
            headerName: 'Pending/Approved', field: "itemStatus",
            cellRendererFramework: (params) => {
                return (
                    <div class="react-toggle">
                        <div id="toggle-slider">
                            <label class="toggle">
                                <input type="checkbox" name="approve" disabled={itemPartApprove} checked={params.data.itemStatus} onClick={(e) => toggleButton(params, e.target.checked)} />
                                <span class="slider"></span>
                                <span class="labels" data-on="Approved" data-off="Pending"></span>
                            </label>
                        </div>
                    </div>
                );
            }, width: 170
        }
    ];

    // clients fetch
    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
              //  console.log(data);
                setClientList(data);
                arr = data;
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const fetchPtiSatus = async () => {
        try {
            const requestURL = globalState.endPointURL + "/ptistatus/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
          //  console.log(responseData);
            // console.log(data);
            var statusArray = []
            if (responseData != null) {
                let data = responseData.ptiStatusInfo?.filter((ptiStatus) => { return ptiStatus.isActive === true })
                setPtiStatusList(data);
                // console.log("PTI status data",data);
                statusBox = data;

            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);

            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    }

    // Incoterms fetch
    const fetchIncoterms = async () => {
        try {
            const requestURL = globalState.endPointURL + "/incoterm/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null) {
                let data = responseData.incotermInfo?.filter((incoterm) => { return incoterm.isActive === true })
               // console.log(data);
                setIncotermList(data);
                incobox = data;
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    // useEffect(() => {
    //     fetchClients();
    //     fetchIncoterms();
    //     fetchPtiSatus();

    // },[]);

    const onGridReady = async () => {

      //  console.log(clientList);

        const fetchPTIRecord = async () => {
            try {
                const requestURL = globalState.endPointURL + "/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData != null && responseData.isSuccess) {
                    const itemStatusList = [];
                    responseData.ptiStatus.map((pti) => {
                        pti.ptiRecords.map((ptiRecord) => {
                            statusBox?.filter((demoo) => {
                                if (demoo.name === ptiRecord.ptiStatus)
                                    return ptiRecord.ptiStatusName = demoo.name;
                            });
                            if (ptiRecord.ptiStatusName === "Approved") {
                                setItemPartApprove(true);
                            }
                            arr?.filter((client) => {
                                // let a =ptiRecord.exporter.exporterId;
                                if (client.id === ptiRecord.exporter.exporterId)
                                    return ptiRecord.exporter.exporterName = client.name;
                            });

                            incobox?.filter((demo) => {
                                if (demo.id === ptiRecord.shipment.incoterm)
                                    return ptiRecord.shipment.incotermName = demo.name;
                            });

                            ptiRecord.shipmentItem?.map((item) => {
                                const itemStatus = {
                                    "itemId": item.id,
                                    "itemCode": item.item.partNumber,
                                    "des": item.item.description,
                                    "hscode": item.codes.itcHsCode,
                                    "itemStatus": item.itemStatus,
                                    "restr": "No",
                                    "bisApplicable": "No",
                                    "bisAvailable": "Not Required",
                                    "etaApplicable": "No",
                                    "etaAvailable": "Not Required",
                                    "url": "",
                                    "others": "---"
                                };

                                item.certificates?.map((certificate) => {
                                    if (certificate.certificateTypeId === "BIS")
                                        itemStatus.bisApplicable = "Yes";
                                    else if (certificate.certificateTypeId === "ETA")
                                        itemStatus.etaApplicable = "Yes";
                                    else if (certificate.certificateTypeId === "Others")
                                        itemStatus.others = "Other";

                                    if (certificate.certificateTypeId === "BIS" && (certificate.url === "" || certificate.url === "null" || certificate.url === null || certificate.url === undefined)) {
                                        itemStatus.bisAvailable = "Pending";
                                    } else if (certificate.certificateTypeId === "BIS" && (certificate.url !== "" && certificate.url !== "null" && certificate.url !== null && certificate.url !== undefined)) {
                                        itemStatus.bisAvailable = "Available";
                                        itemStatus.bisurl = certificate.url;
                                    }
                                    if (certificate.certificateTypeId === "ETA" && (certificate.url === "" || certificate.url === "null" || certificate.url === null || certificate.url === undefined)) {
                                        itemStatus.etaAvailable = "Pending";
                                    } else if (certificate.certificateTypeId === "ETA" && (certificate.url !== "" && certificate.url !== "null" && certificate.url !== null && certificate.url !== undefined)) {
                                        itemStatus.etaAvailable = "Available";
                                        itemStatus.etaurl = certificate.url;
                                    }
                                });
                                itemStatusList.push(itemStatus);
                            });
                        });
                    });
                    let statusApprove = true;
                    itemStatusList?.map((item) => {
                        if (item.itemStatus !== "Approved")
                            statusApprove = false;
                    });
                    setShowItemPartApprove(statusApprove);
                    setPtiRecordShipmentItemStatus(itemStatusList);
                    setPtiRecord(responseData.ptiStatus[0].ptiRecords[0]);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                //console.log(e);
            }
        };
        fetchPTIRecord();
        fetchPTIHistory();
    };

    const fetchPTIHistory = async () => {
        try {
            const requestURL = globalState.endPointURL + "/item/itemstatushistory/" + id;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null && responseData.isSuccess) {
                setPTIHistory(responseData?.itemStatusHistory);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);

        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchClients();
            await fetchIncoterms();
            await fetchPtiSatus();
            onGridReady();
        };
        fetchData();
    }, []);

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const formingPTIRecordRequest = (data) => {
        const createState = {
            ptiStatus: data?.ptiStatus === undefined || data?.ptiStatus === null ? 0 : data?.ptiStatus,
            id: String(data?.ptiId),
            ptiId: data?.ptiId,
            invoiceNumber: data?.invoiceNumber
        };
        return createState;
    };

    //Get item
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/");
        });
    };

    const handleSave = async (data, redirect) => {
        try {
            let requestData = formingPTIRecordRequest(data);
            let requestURL = globalState.endPointURL + "/pti/";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
           // console.log(JSON.stringify(requestData));
            let response = await fetch(requestURL, {
                method: 'PUT',
                body: JSON.stringify(requestData),
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData !== null && responseData.isSuccess) {
                if (redirect)
                    handleClose();
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                // setErrorMessage("Failed to save the data.");
                setUploadDisable(false);
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            } else {
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            }

        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            setUploadDisable(false);
            // console.log("Error" + e);
        }
    };

    const handleSaveHistory = async () => {
        setUploadHistoryDisable(true);
        if (remarks === "") {
            setRemarksErrorMessage("Remarks should not empty");
            setUploadHistoryDisable(false);
        } else {
            const data = {
                "ptiId": id,
                "remark": remarks
            };

            try {
                let requestURL = globalState.endPointURL;
                requestURL = requestURL + "/item/itemstatushistory/";
                let methodType = "POST";
               // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null && responseData.isSuccess) {
                    setRemarks("");
                    setUploadHistoryDisable(false);
                    fetchPTIHistory();
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    // setErrorMessage("Failed to save the data.");
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                }

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log("Error" + e); 
            }
        }
    };

    return <div class="dashboard">

        <div class="table-heading">
            <h4 class="">Shipment Items Status</h4>
        </div>
        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <form action="">

            {/* <!--Order Details --> */}
            <div class="">
                <div class="single-pti-box">
                    <div class="single-pti-box-header">Order Details</div>
                    <div class="row">

                        <div class="col-sm-12 col-md-6">
                            <div class="p-2">
                                {/* <!-- Invoice # --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>Invoice #</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord.invoiceNumber} readOnly />
                                    </div>
                                </div>
                                {/* <!-- end Invoice # --> */}
                                {/* <!-- Invoice Date --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>Invoice Date</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord.invDate} readOnly />
                                    </div>
                                </div>
                                {/* <!-- Invoice Date --> */}
                                {/* <!-- PTi Status --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>PTi Status</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord.ptiStatusName} readOnly />
                                    </div>
                                </div>

                                {/* !-- PTi Status --> */}
                            </div>
                        </div>

                        <div class="col-sm-12 col-md-6">
                            <div class="p-2">
                                {/* !-- Invoice # --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>Expoter</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord?.exporter?.exporterName} readOnly />
                                    </div>
                                </div>
                                {/* !-- end Invoice # --> */}
                                {/* !-- Invoice Date --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>Incoterms</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord?.shipment?.incotermName} readOnly />
                                    </div>
                                </div>
                                {/* !-- Invoice Date --> */}
                                {/* !-- PTi Status --> */}
                                <div class="single-pti-box-group width-full form-group">
                                    <label>Total Package of the Shipments</label>
                                    <div class="input-box">
                                        <input type="text" class="form-control" value={ptiRecord?.shipmentItem?.length} readOnly />
                                    </div>
                                </div>
                                {/* !-- PTi Status --> */}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {/* !-- Order Details --> */}
            {isOpen && <CertificatePopup
                content={
                    <div id="exampleModal">
                        <div className="modal-dialog" >
                            <div className="modal-content">
                                <div className="popup-header">
                                    <h5 className="popup-title">Document</h5>
                                    <br></br>
                                </div>
                                <div className="modal-body row">
                                    <div className="m-auto certi-popup">
                                        <embed src={popupUrl} type="" width="100%" height="400" />
                                    </div>
                                </div>
                                <br></br>
                                <div className="col-lg-12 text-right">
                                    <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                handleClose={togglePopup} />}
            <div class="row mt-4">
                {/* table */}
                <div className="example-header row">
                    <div className="col-lg-4 col-sm-12 ">

                    </div>
                    <div className="col-lg-8 col-sm-12 pr-0">
                        <div className="search-add">
                            <div className="sorting-num mr-2">
                                Page Size:&nbsp;
                                <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <label className="table-search">
                                <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                                <i className="bx bx-search"></i>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="ag-theme-alpine">
                    <AgGridReact
                        ref={gridRef}
                        rowData={ptiRecordShipmentItemStatus}
                        columnDefs={columnDef}
                        pagination={true}
                        domLayout={domLayout}
                        defaultColDef={{
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                        }}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                    ></AgGridReact>
                </div>
                {/* table */}

                <div id="toggle-slider">
                    <span className='mt-1'> Item / Parts</span>&nbsp;&nbsp;
                    <label className="toggle">
                        <input type="checkbox" disabled={ptiRecord.ptiStatusName === "Pending Approval" && !showItemPartApprove} onChange={e => handleItemPartApprove()} checked={itemPartApprove} />
                        <span className="slider"></span>
                        <span className="labels" data-on="Approved" data-off="Not Approved"></span>
                    </label>
                </div>

            </div>

            {/* !-- remarks --> */}
            <div class="row mt-4">
                <div class="col-sm-12 col-lg-12">
                    <div class="single-pti-box">
                        <div class="single-pti-box-header">Remarks</div>
                        <div class="p-2">
                            <div class="row mb-2">
                                <div class="col-sm-12 col-md-10">
                                    <textarea type="text" value={remarks} onChange={e => setRemarks(e.target.value)} placeholder="" rows="2" class="w-100 textarea-box"></textarea>
                                </div>
                                <div class="col-md-2 text-right">
                                    <button type="button" disabled={uploadHistoryDisable} class="addbutton mt-3" onClick={handleSaveHistory}>Save</button>
                                </div>
                            </div>
                            <div className="text-warning">{remarksErrorMessage}</div>
                        </div>
                    </div>
                </div>
            </div>


            {/* !-- history --> */}
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <div class="single-pti-box">
                        <div class="single-pti-box-header">History</div>
                        <div class="p-2">
                            {/* !-- accordion --> */}
                            {ptiHistory?.map((history) => {
                                return <div class="row mb-2"><div className="accordian">
                                    <div className="accordian-header" >
                                        <div>Date : {history.createdDateTime} </div>
                                        <div className="sign" onClick={e => { handleOpen(e, history.name) }}>{'+'}</div>
                                    </div>
                                </div>
                                </div>
                            }
                            )}
                            {/* !-- accordion end --> */}

                        </div>

                    </div>
                </div>
            </div>

            {/* !-- button --> */}
            <div class="btn-footer text-right">
                <button type="button" disabled={uploadDisable} onClick={onSubmit} class="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save"> Save <i class="fa fa-check-circle" aria-hidden="true"></i></button>
                <button type="button" onClick={handleClose} class="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i class="fa fa-times-circle" aria-hidden="true"></i></button>
            </div>
        </form>
    </div>


};

export default PTIRecordShipmentItemStatus;

