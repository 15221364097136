import React from "react";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis} from "recharts";

const simpleData = [
  {
    subject: "PTI records",
    fees: 140
  },
  {
    subject: "EXPORT RECORDS",
    fees: 50
  },
  {
    subject: "TASKS",
    fees: 80
  },
  {
    subject: "PENDING REQUESTS",
    fees: 20
  
  },
  

];
function Chart(){
    return (
    <React.Fragment>
      <ResponsiveContainer width="100%" aspect={3} >
       <LineChart data= {simpleData}>
         <XAxis dataKey ="subject" />
         <Line dataKey="fees" />
         <YAxis />
       </LineChart>
      </ResponsiveContainer>
    </React.Fragment>
   
    );
};

export default Chart