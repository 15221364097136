import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { GlobalContext } from ".././GlobalContext";

export const DialogLeavingPage = ({
  showDialog,
  setShowDialog,
  cancelNavigation,
  confirmNavigation
}) => {
  const { globalState } = React.useContext(GlobalContext);

  const handleDialogClose = () => {
    setShowDialog(false);
  };
  const confirm = async () => {
   
    try {
      if (globalState?.ptiRecord?.ptiId) {
        console.log(globalState.shipItemDetails);
        console.log(globalState.ptiRecord.shipmentItemDetails);
        const itemExit = [];
         let s = new Set()
         globalState.shipItemDetails?.map((item) => {
          s.add(item.id);
        });
        globalState.ptiRecord?.shipmentItemDetails?.map((itemDetail) =>{
          if (!s.has(itemDetail.id)){
                  itemExit.push(itemDetail.id);
            }
        });
        itemExit.map((item) => {

          const requestURL = globalState.endPointURL + "/shipment/id/" + item;
          const response =  fetch(requestURL, {
              method: 'DELETE',
              headers: globalState.headers,
          });
        });
      } else{ 
        globalState.ptiRecord?.shipmentItemDetails?.map((item) => {
          const requestURL = globalState.endPointURL + "/shipment/id/" + item.id;
          const response =  fetch(requestURL, {
              method: 'DELETE',
              headers: globalState.headers,
          });
        });
      }
  
  } catch (e) {
    console.log(e);
  }
  confirmNavigation();
  }


  return (
    <Dialog fullWidth open={showDialog} onClose={handleDialogClose}>
      <DialogTitle>Leaving Page</DialogTitle>
      <DialogContent>
        <Typography>There are some changes</Typography>
        <Typography>If you proceed your changes will be lost</Typography>
        <Typography>Are you sure you want to proceed?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={ cancelNavigation }>
          No
        </Button>
        <Button variant="contained" onClick={ confirm }>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};
