import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import csc from "country-state-city";
import { confirm } from "react-confirm-box";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const ClientDetails = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [clientId, setClientId] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientAddress1, setClientAddress1] = useState("");
    const [clientAddress2, setClientAddress2] = useState("");
    const [clientCity, setClientCity] = useState("");
    const [clientState, setClientState] = useState("");
    const [clientZip, setClientZip] = useState("");
    const [clientCountry, setClientCountry] = useState("");
    const [clientContectPerson, setClientContectPerson] = useState("");
    const [clientPhoneNumber, setClientPhoneNumber] = useState("");
    const [clientCountryCode, setClientCountryCode] = useState("");
    const [billerCountryCode, setBillerCountryCode] = useState("");
    const [active, setActive] = useState(true);
    const [sameCustomer, setSameCustomer] = useState(false);
    const [billerName, setBillerName] = useState("");
    const [billerEmail, setBillerEmail] = useState("");
    const [billerAddress1, setBillerAddress1] = useState("");
    const [billerAddress2, setBillerAddress2] = useState("");
    const [billerCity, setBillerCity] = useState("");
    const [billerState, setBillerState] = useState("");
    const [billerZip, setBillerZip] = useState("");
    const [taxId, setTaxId] = useState("");
    const [currency, setCurrency] = useState("");
    const [billerCountry, setBillerCountry] = useState("");
    const [billerContectPerson, setBillerContectPerson] = useState("");
    const [billerPhoneNumber, setBillerPhoneNumber] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [nameErrorMessage, setNameErrorMessage] = useState("");
    const [clientIdErrorMessage, setClientIdErrorMessage] = useState("");
    const [clientZipErrorMessage, setClientZipErrorMessage] = useState("");
    const [clientContectPersonErrorMessage, setClientContectPersonErrorMessage] = useState("");
    const [clientPhoneNumberErrorMessage, setClientPhoneNumberErrorMessage] = useState("");
    const [billerNameErrorMessage, setBillerNameErrorMessage] = useState("");
    const [billerZipErrorMessage, setBillerZipErrorMessage] = useState("");
    const [billerContectPersonErrorMessage, setBillerContectPersonErrorMessage] = useState("");
    const [billerPhoneNumberErrorMessage, setBillerPhoneNumberErrorMessage] = useState("");
    const [billerEmailErrorMessage, setBillerEmailErrorMessage] = useState("");
    const [activeErrorMessage, setActiveErrorMessage] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage,confirmNavigation,cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);
    const { handleSubmit, clearErrors } = useForm();

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const requestURL = globalState.endPointURL + "/client/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);                
                if (data !== "") {
                    const responseData = data?.clientInfo[0];
                    setClientAddress1(responseData?.address?.address1);
                    setClientAddress2(responseData?.address?.address2);
                    setClientContectPerson(responseData?.contactPerson);
                    setClientPhoneNumber(responseData?.contactMobile);
                    setClientCity(responseData?.address?.city);
                    setClientState(responseData?.address?.state);
                    setClientZip(responseData?.address?.zip);
                    setClientCountry(responseData?.address?.country);
                    let clientCountryCode = "";
                    updatedCountries.map((country) => {
                        if (country.name === responseData?.address?.country)
                            return clientCountryCode = "+" + country.phonecode;
                    });
                    setClientCountryCode(clientCountryCode);
                    setClientName(responseData?.name);
                    setClientId(responseData?.id);
                    setBillerAddress1(responseData?.billingAddress?.address1);
                    setBillerAddress2(responseData?.billingAddress?.address2);
                    setBillerContectPerson(responseData?.billingPerson);
                    setBillerPhoneNumber(responseData?.billingMobile);
                    setBillerCity(responseData?.billingAddress?.city);
                    setBillerState(responseData?.billingAddress?.state);
                    setBillerZip(responseData?.billingAddress?.zip);
                    setTaxId(responseData?.taxId);
                    setCurrency(responseData?.currency);
                    setBillerCountry(responseData?.billingAddress?.country);
                    let billerCountryCode = "";
                    updatedCountries.map((country) => {
                        if (country.name === responseData?.billingAddress?.country)
                            return billerCountryCode = "+" + country.phonecode;
                    });
                    setBillerCountryCode(billerCountryCode);
                    setBillerName(responseData?.billingName);
                    setBillerEmail(responseData?.billingEmail);
                    setActive(responseData?.isActive);
                    setSameCustomer(responseData?.isSameCustomer);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                    // setErrorMessage("Failed to fetch data. Please try after sometime.");
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined)
            fetchClients();
    }, []);

    const countries = csc.getAllCountries();
    // console.log(countries);
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    const updatedStates = (clientCountry) => {
        let countryid;
        updatedCountries.map((country) => {
            if (country.name === clientCountry) {
                countryid = country.value;
            }
        });
        return csc.getStatesOfCountry(countryid).map((state) => ({
            label: state.name, value: state.id, ...state
        }));
    };
    // const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));

    const validateEmail = (email) => {
        //new RegExp('[a-z0-9]+@zenxl.com')
        return new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}').test(email);
    };

    const validateForm = () => {
        let returnVal = true;
        if (clientId === undefined || clientId === null || clientId === '') {
            setClientIdErrorMessage("Please enter client id");
            setUploadDisable(false);
            returnVal = false;
        }
        if (clientZip === undefined || clientZip === null || clientZip === '') {
            setClientZipErrorMessage("Please enter client zip");
            setUploadDisable(false);
            returnVal = false;
        }
        if (clientName === undefined || clientName === null || clientName === '') {
            setNameErrorMessage("Please enter client name");
            setUploadDisable(false);
            returnVal = false;
        }
        if (clientContectPerson === undefined || clientContectPerson === null || clientContectPerson === '') {
            setClientContectPersonErrorMessage("Please enter  contact Person");
            setUploadDisable(false);
            returnVal = false;
        }
        if (clientPhoneNumber === undefined || clientPhoneNumber === null || clientPhoneNumber === '') {
            setClientPhoneNumberErrorMessage("Please enter contact number");
            setUploadDisable(false);
            returnVal = false;
        }
        if (billerZip === undefined || billerZip === null || billerZip === '') {
            setBillerZipErrorMessage("Please enter biller zip");
            setUploadDisable(false);
            returnVal = false;
        }
        if (billerName === undefined || billerName === null || billerName === '') {
            setBillerNameErrorMessage("Please enter  billing name");
            setUploadDisable(false);
            returnVal = false;
        }
        if (billerContectPerson === undefined || billerContectPerson === null || billerContectPerson === '') {
            setBillerContectPersonErrorMessage("Please enter billing Person");
            setUploadDisable(false);
            returnVal = false;
        }
        if (billerPhoneNumber === undefined || billerPhoneNumber === null || billerPhoneNumber === '') {
            setBillerPhoneNumberErrorMessage("Please enter billing Mobile number");
            setUploadDisable(false);
            returnVal = false;
        }
        if (billerEmail === undefined || billerEmail === null || billerEmail === '') {
            setBillerEmailErrorMessage("Please select billing Email");
            setUploadDisable(false);
            returnVal = false;
        } else if (!validateEmail(billerEmail)) {
            setBillerEmailErrorMessage("Please enter Valid Email");
            setUploadDisable(false);
            returnVal = false;
        }
        /*if (!active) {
            setActiveErrorMessage("Please select the active");
            returnVal = false;
        }*/
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        setBillerZipErrorMessage("");
        if (validateForm()) {
            clearErrors();
            const createState = {
                name: clientName,
                contactPerson: clientContectPerson,
                contactMobile: clientPhoneNumber,
                billingName: billerName,
                billingEmail: billerEmail,
                billingPerson: billerContectPerson,
                billingMobile: billerPhoneNumber,
                isActive: active,
                sameCustomer: sameCustomer,
                address: {
                    addressType: "3",
                    address1: clientAddress1,
                    address2: clientAddress2,
                    city: clientCity,
                    state: clientState,
                    zip: clientZip,
                    country: clientCountry
                },
                billingAddress: {
                    addressType: "4",
                    address1: billerAddress1,
                    address2: billerAddress2,
                    city: billerCity,
                    state: billerState,
                    zip: billerZip,
                    country: billerCountry
                },
                taxId: taxId,
                currency: currency
            };
            handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    }
    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/client";
                let methodType = "";
                if (id === undefined)
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.id = id;
                }
                // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null && responseData?.isSuccess)
                    handleClose();
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    //setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    setErrorMessage(globalState.commonErrorMessage);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
        finally {
            setUploadDisable(false);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/clients");
        });
    };

    const handleSameCustomer = (e) => {
        setBillerPhoneNumberErrorMessage("");
        setBillerContectPersonErrorMessage("");
        setBillerZipErrorMessage("");
        if (!sameCustomer) {
            setBillerAddress1(clientAddress1);
            setBillerAddress2(clientAddress2);
            setBillerContectPerson(clientContectPerson);
            setBillerPhoneNumber(clientPhoneNumber);
            setBillerCity(clientCity);
            setBillerState(clientState);
            setBillerZip(clientZip);
            setBillerCountry(clientCountry);
            setBillerCountryCode(clientCountryCode);
        } else {
            setBillerAddress1("");
            setBillerAddress2("");
            setBillerContectPerson("");
            setBillerPhoneNumber("");
            setBillerCity("");
            setBillerState("");
            setBillerZip("");
            setBillerCountry("");
            setBillerCountryCode("");
        }
        setSameCustomer(!sameCustomer);
    };

    const handleMobileNumber = (val) => {
        const regExpNumber = /^[0-9\b]+$/;
        if (regExpNumber.test(val) || val === "")
            setClientPhoneNumber(val);
        setClientPhoneNumberErrorMessage("");
    };

    const handleClientCountry = (val) => {
        setClientCountry(val);
        let countryCode = "";
        updatedCountries.map((country) => {
            if (country.name === val)
                return countryCode = "+" + country.phonecode;
        });
        setClientCountryCode(countryCode);
    };

    const handleBillerCountry = (val) => {
        setBillerCountry(val);
        let countryCode = "";
        updatedCountries.map((country) => {
            if (country.name === val)
                return countryCode = "+" + country.phonecode;
        });
        setBillerCountryCode(countryCode);
    };


    return (
    <><DialogLeavingPage
            showDialog={showDialogLeavingPage}
            setShowDialog={setCanShowDialogLeavingPage}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation} /><section className="home-section">
                <div className="dashboard">
                    <div className="table-heading">
                        <h4 className="w-50">Client Detail</h4>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                        <div className="row mb-4">

                            <div className="col-lg-6 col-md-6">
                                <label>Id</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientId" readOnly={id === undefined ? false : true} placeholder="" value={clientId} onChange={e => { setClientId(e.target.value); setClientIdErrorMessage(""); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{clientIdErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Client Name</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientName" placeholder="" value={clientName} onChange={e => { setClientName(e.target.value); setNameErrorMessage(""); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{nameErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Address 1</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientAddress1" placeholder="" value={clientAddress1} onChange={e => { setClientAddress1(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Address 2</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientAddress2" placeholder="" value={clientAddress2} onChange={e => { setClientAddress2(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>City</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientCity" placeholder="" value={clientCity} onChange={e => { setClientCity(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Country</label>
                                <div className="select-box" name="clientCountry">
                                    <select value={clientCountry} onChange={e => { handleClientCountry(e.target.value); setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        {updatedCountries.map((country) => {
                                            return <option value={country.name}>{country.name}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>State</label>
                                <div className="select-box" name="clientState">
                                    <select value={clientState} onChange={e => { setClientState(e.target.value); setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        {updatedStates(clientCountry)?.map((state) => {
                                            return <option value={state.name}>{state.name}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Zip</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientZip" placeholder="" value={clientZip} onChange={e => { setClientZipErrorMessage(""); setClientZip(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{clientZipErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Contact Person</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="clientContectPerson" placeholder="" value={clientContectPerson} onChange={e => { setClientContectPerson(e.target.value); setClientContectPersonErrorMessage(""); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{clientContectPersonErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Phone Number</label>
                                <div className="input-box w-100 d-flex">
                                    <input type="text" value={clientCountryCode} disabled={true} className="form-control w-15 mr-2" maxLength="5" name="" placeholder="" />
                                    <input type="text" className="form-control w-85" maxLength="10" minLength="7" name="clientPhoneNumber" placeholder="" value={clientPhoneNumber} onChange={e => { handleMobileNumber(e.target.value); setCanShowDialogLeavingPage(true); } } />

                                </div>
                                <div className="text-warning">{clientPhoneNumberErrorMessage}</div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" id="customSwitches" checked={active} onChange={e => { setActiveErrorMessage(""); setActive(!active); setCanShowDialogLeavingPage(true); } } />
                                    <label className="custom-control-label" for="customSwitches">Is Active
                                    </label>
                                </div>
                                <div className="text-warning">{activeErrorMessage}</div>
                            </div>
                        </div>
                        <div className="row pt-4 mb-4">
                            <div className="col-sm-12">

                                <div className="table-heading">
                                    <h4 className="mt-2">Billing Detail</h4>

                                    <div className="same-active">
                                        <div className="custom-control custom-switch">
                                            <input type="checkbox" className="custom-control-input" name="samecustomSwitches"
                                                id="samecustomSwitches" checked={sameCustomer} onChange={handleSameCustomer} />
                                            <label className="custom-control-label" for="samecustomSwitches">Same as client</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Billing Name</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerName" placeholder="" value={billerName} onChange={e => { setBillerNameErrorMessage(""); setBillerName(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{billerNameErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Billing Email</label>
                                <div className="input-box">
                                    <input type="email" className="form-control" name="billerEmail" placeholder="" value={billerEmail} onChange={e => { setBillerEmailErrorMessage(""); setBillerEmail(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{billerEmailErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Contact</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerContectPerson" placeholder="" value={billerContectPerson} onChange={e => { setBillerContectPersonErrorMessage(""); setBillerContectPerson(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{billerContectPersonErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label> Phone Number</label>
                                <div className="input-box w-100 d-flex">
                                    <input type="text" value={billerCountryCode} disabled={true} className="form-control w-15 mr-2" maxLength="5" name="" placeholder="" />
                                    <input type="text" className="form-control" maxLength="10" minLength="7" name="billerPhoneNumber" placeholder="" value={billerPhoneNumber} onChange={e => { setBillerPhoneNumberErrorMessage(""); setBillerPhoneNumber(e.target.value); setCanShowDialogLeavingPage(true); } } />

                                </div>
                                <div className="text-warning">{billerPhoneNumberErrorMessage}</div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Address 1</label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerAddress1" placeholder="" value={billerAddress1} onChange={e => { setBillerAddress1(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Address 2
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerAddress2" placeholder="" value={billerAddress2} onChange={e => { setBillerAddress2(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>City
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerCity" placeholder="" value={billerCity} onChange={e => { setBillerCity(e.target.value); setCanShowDialogLeavingPage(true); } } />

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Country
                                </label>
                                <div className="select-box" name="clientCountry">
                                    <select value={billerCountry} onChange={e => { handleBillerCountry(e.target.value); setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        {updatedCountries.map((country) => {
                                            return <option value={country.name}>{country.name}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>State
                                </label>
                                <div className="select-box" name="clientState">
                                    <select value={billerState} onChange={e => { setBillerState(e.target.value); setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        {updatedStates(billerCountry)?.map((state) => {
                                            return <option value={state.name}>{state.name}</option>;
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Zip
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="billerZip" placeholder="" value={billerZip} onChange={e => { setBillerZipErrorMessage(""); setBillerZip(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                    <div className="text-warning">{billerZipErrorMessage}</div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Tax Id
                                </label>
                                <div className="input-box">
                                    <input type="text" className="form-control" name="taxId" placeholder="" value={taxId} onChange={e => { setTaxId(e.target.value); setCanShowDialogLeavingPage(true); } } />

                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                                <label>Currency
                                </label>
                                <div className="select-box" name="currency">
                                    <select placeholder="" value={currency} onChange={e => { setCurrency(e.target.value); setCanShowDialogLeavingPage(true); } }>
                                        <option value="">Select</option>
                                        <option value="USD">USD</option>
                                        <option value="EURO">EURO</option>
                                        <option value="GBP">GBP</option>
                                        <option value="INR">INR</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="btn-footer text-right">
                            {id === undefined ? <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                                : <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}

                            <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true">
                            </i>
                            </button>
                        </div>
                    </form>
                </div>
            </section></>
    )
};
export default ClientDetails;
