import React, { useState, useCallback, useRef, useEffect, useMemo } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import CertificatePopup from '../PTIRecords/CertificatePopup';
import { confirm } from "react-confirm-box";

const ExportShipmentItem = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const [clients, setClients] = useState([]);
    const [partNumber, setPartNumber] = useState("");
    const [serialNumber, setSerialNumber] = useState("");
    const [clientName, setClientName] = useState("");
    const [Validate, setValidate] = useState([]);
    const [GlobalValidate, setGlobalValidate] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
    const gridRef = useRef();
    const [searchText, setSearchText] = useState("");
    const [exporter, setExporter] = useState('');
    const [isExported, setisExported] = useState('No');
    //const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [isOpen, setIsOpen] = useState(false);
    const [certificateUrl, setCertificateUrl] = useState("");
    const [certificateId, setCertificateId] = useState("");
    const [clientList, setClientList] = useState([]);
    const [exported, setExported] = useState('No');

    const actionButton = (event) => {
        return (
            <p>
                {event.data.documentUrl === "" ? "" : <button type="button" onClick={() => handlePopup(event.data?.id, event.data.documentUrl)} className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>}
            </p>
        );
    };

    const handlePopup = (certId, certUrl) => {
        setIsOpen(!isOpen);
        setCertificateUrl(certUrl);
        setCertificateId(certId);
    }

    const togglePopup = () => {
        setIsOpen(!isOpen);
        setCertificateUrl("");
        setCertificateId("");
    }

    const columnDef = [
        { headerName: '#', valueGetter: 'node.rowIndex+1', sortable: true, filter: true, minWidth: 10 },
        { headerName: 'Part ', field: "partNo", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Serial', field: "serialNo", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Description', field: "description", sortable: true, filter: true, minWidth: 50 },
        { headerName: 'BE No.', field: "beNo", sortable: true, filter: true, minWidth: 40 },
        { headerName: 'BE Date', field: "beDt", sort: 'asc', sortable: true, filter: true, minWidth: 50 },
        { headerName: 'Exported', field: "Exported", sortable: true, filter: true, minWidth: 50 },
        { headerName: 'BOE Seq No.', field: "beSeqNo", sortable: true, filter: true, minWidth: 50 },
        { headerName: 'Actions', "cellRenderer": actionButton, minWidth: 50 },
    ];

    const domLayout = "autoHeight";


    useEffect(()=>{
    // client fetch
    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
                // console.log(data);
                setClientList(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    fetchClients();
},[]);

    const onSubmit = async () => {
        try {
            let clientId = "";
            clientList?.filter((item) => {
                if (item.name === clientName)
                    return clientId = item.id;
            });
            const requestURL = globalState.endPointURL + "/pti/validateboe";
            const reqObject = {
                "clientId": clientId,
                "clientName": clientName,
                "partNumber": partNumber,
                "serialNumber": serialNumber,
            };
            //console.log(reqObject);
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'POST',
                body: JSON.stringify(reqObject),
                headers: globalState.headers,
            }
            );
            const responseData = await response.json();
            //console.log(responseData);
            if (responseData !== null && responseData.isSuccess) {
                const data = responseData?.boeInfos.map((item) => {
                    item.eccn = item.eccn === "null" ? "" : item.eccn;
                    item.beNo = item.beNo === "null" ? "" : item.beNo;
                    item.beDt = item.beDt === "null" ? "" : item.beDt;
                    item.isExported = item.Exported;
                    item.beSeqNo = item.beSeqNo === "null" ? "" : item.beSeqNo;
                    item.documentUrl = item.documentUrl === "null" ? "" : item.documentUrl;
                    item.Exported = item.exportStatus === "Billed" || item.exportStatus === "Delivered" ? "Yes" : "No";
                    if (item.uomId === "1") {
                        item.uomName = "Kgs";
                    } else if (item.uomId === "2") {
                        item.uomName = "Nos";
                    } else if (item.uomId === "3") {
                        item.uomName = "EA";
                    } else {
                        item.uomName = "";
                    }
                    return item;
                })
                .filter((item) => {
                    if(exported === ""){
                        return true;
                    }
                    else{
                        return item.Exported === exported;
                    }
                })
                setValidate(data);
                setGlobalValidate(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {                
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            }else  {
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    }

    const clearingValues = () => {
        //onSubmit(false);
        setExporter("");
        setPartNumber("")
        setSerialNumber("");
        setClientName("");
        setExported("");
        setValidate([]);
    }

    const resetSubmit = () => {
        clearingValues();        
    }

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const handleClose = () => {
        if (globalState.exportRecords.exportId !== undefined) {
            login().then(() => {
                navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDetail/" + globalState.exportRecords.exportId);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDetail/");
            });
        }
    };

    const handleSelect = () => {
        // fetchClients();
        setErrorMessage("");
        const selectedData = gridRef.current.api.getSelectedRows();
       // console.log(selectedData);
        if (selectedData.length === 0) {
            setErrorMessage("Please select any record.");
        } else if (selectedData?.length > 0) {
            let isExport = false;
            let expId = 0;
            selectedData.map((item) => {
                if (item.isExported !== "null" && item.isExported !== "0") {
                    expId = item.isExported;
                    isExport = true;
                }                    
            });
            const buttons = {
                labels: {
                    confirmable: "OK"
                }
            }
            if (isExport) {
                let text = "Export Entry already existed for the selected sequence with the Export ID: " + expId;
                //let result = text.bold();
                alert(text);
                //alert("Export Entry already existed for the selected sequence with the Export ID " + expId);
            }else {
                selectedData.map((item) => {
                    let serialNo = item.serialNo;
                    const existingRecord = globalState.exportRecords.shipmentItems?.find(shipment => shipment.serialNo === serialNo);
                    if (!existingRecord)
                        globalState.exportRecords.shipmentItems?.push(item);
                });
                handleClose();
            }            
        }        
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

    const isFirstColumn = (params) => {
        var displayedColumns = params.columnApi.getAllDisplayedColumns();
        var thisIsFirstColumn = displayedColumns[0] === params.column;
        return thisIsFirstColumn;
    };

    // search bar
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="row mb-2">
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                <div className="col-lg-12 col-sm-12 ">
                    <div className="table-heading mb-2">
                        <h4 className="">Shipment Items</h4>
                    </div>
                </div>
                
                <div className="col-lg-3 col-md-3">
                    <div className="single-pti-group form-group">
                        {/* <label>Item / Part No</label> */}
                        <div className="input-box">
                            <input type="text" placeholder="Item / Part No" className="form-control" value={partNumber} onChange={e => setPartNumber(e.target.value)} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-3 col-md-3">
                    <div className="single-pti-group form-group">
                        {/* <label>Serial No</label> */}
                        <div className="input-box">
                            <input type="text" placeholder="Serial No." className="form-control" value={serialNumber} onChange={e => setSerialNumber(e.target.value)} />
                        </div>
                    </div>

                </div>
                <div className="col-lg-3 col-md-3">
                    <div className="single-pti-group form-group"> 
                        {/* <!-- Client Name --> */}
                             {/* <label>Client Name</label> */}
                                <div className="select-box">
                                    <select id="clientName" value={clientName} onChange={e => setClientName(e.target.value)}>
                                        <option value="select">Client Name</option>
                                            {clientList?.map((item) => {
                                                return <option value={item.name}>{item.name}
                                        </option>
                                            })}
                                    </select>
                                    <div className="text-warning">{clientNameErrorMessage}</div>
                                </div>
                                  
                        {/* <!-- end Client Name --> */}
                 </div>
                </div> 
                <div className="col-lg-3 col-md-3">
                    <div className="single-pti-group form-group"> 
                                <div className="select-box">
                                    <select id="exported" value={exported} onChange={e => setExported(e.target.value)}>
                                        <option value="No" selected>Exported-No</option>
                                        <option value="">Exported-All</option>
                                        <option value="Yes">Exported-Yes</option>
                                    </select>
                                </div>
                 </div>
                </div> 

            </div>

        {/* validation boe table */}
            <div className="example-header row">
                <div className="btn-footer col-lg-6 col-md-6">
                    <button type="button" className="btn btn-primary" onClick={onSubmit} data-bs-toggle="tooltip" data-bs-placement="top" title="Filter">Apply Filter <i className="fa fa-filter" aria-hidden="true"></i></button>
                    <button type="button" className="btn btn-secondary" onClick={resetSubmit} data-bs-toggle="tooltip" data-bs-placement="top" title="Reset">Reset <i className="fa fa-repeat" aria-hidden="true"></i></button>
                </div>
                <div className="col-lg-6 col-sm-12">
                <div className="search-add">
                    <div className="sorting-num">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    &nbsp;
                    <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                    </label>
                </div>
                </div>
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact                    
                    ref={gridRef}
                    rowData={Validate}
                    columnDefs={columnDef}
                    pagination={true}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                        headerCheckboxSelection: isFirstColumn,
                        checkboxSelection: isFirstColumn,
                    }}
                    rowSelection={'multiple' }
                    //onGridReady={onGridReady}
                    //suppressRowClickSelection={true}
                    domLayout={domLayout}
                    //onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>
            {isOpen && <CertificatePopup
                content={
                    <div id="exampleModal">
                        <div className="modal-dialog" >
                            <div className="modal-content">
                                <div className="popup-header">
                                    <h5 className="popup-title">Document</h5>
                                    <br></br>
                                </div>
                                <div className="modal-body row">
                                    <div className=" m-auto certi-popup">
                                        <embed src={certificateUrl} type="" width="100%" height="420" />
                                    </div>
                                </div>
                                <br></br>
                                <div className="row">                                   
                                    <div className="col-lg-12 text-right">
                                        <button type="button" onClick={togglePopup} className="btn btn-secondary addbutton2 ml-2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel"> Cancel <i className="fa fa-times-circle"></i></button>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                handleClose={togglePopup} />}
            <div className="text-right">
                <button type="button" className="addbutton" onClick={handleSelect} data-bs-toggle="tooltip" data-bs-placement="top" title="Select">Select <i className="fa fa-check-circle" aria-hidden="true"></i> </button>&nbsp;
                <button type="button" className="addbutton btn-secondary" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>
        </div>
        </section>
        

};
export default ExportShipmentItem;