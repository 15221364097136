import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/css/index.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "boxicons";
import ScrollToTop from './ScrollToTop';

<link rel="stylesheet" href="boxicons.min.css"/>

// right click stop
// document.querySelectorAll(".js-noMenu").forEach( el => 
//     el.addEventListener('contextmenu', e => e.preventDefault())
//    );

const root = createRoot(document.getElementById("root"));
root.render(<BrowserRouter><ScrollToTop /><App /></BrowserRouter>);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



