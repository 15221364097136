/* eslint-disable react/jsx-no-undef */
import React from "react";
import { useState, useContext, PureComponent, useEffect } from 'react';
import { BarChart, Bar, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import { createUseStyles } from 'react-jss'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalContext } from "../GlobalContext";
import IorEorDbChart from "../components/IorEorDbChart";
import BillingDashboardChart from "../components/BillingDashboardChart"
import ClientSummaryChart from "../components/ClientSummaryChart"
import LifetimeOfRecords from "../components/LifetimeOfRecords";
import IorEorLineChart from "../components/IorEorLineChart"
import PendingWithLifetime from "../components/PendingWithLifeTime"
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import NewPasswordPopup from "../components/NewPasswordPopup";


const Dashboard = () => {
    const [userName, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [modalIsOpen, setIsOpen] = useState(false);

    const isFirstLogin = window.localStorage.getItem("first-login");
    useEffect(() => {
        if (isFirstLogin === "true") {
            setIsOpen(true);
        } else if (isFirstLogin === "false") {
            setIsOpen(false);
        }
    }, [])

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(4),
        textAlign: 'center',
        color: theme.palette.text.primary,
    }));


    return (
        <>
            <section className="home-section">
                <div className="dashboard">
                    <h3 className="header mt-1 mb-2">Dashboard</h3>

                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={4}>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <IorEorDbChart />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <IorEorLineChart />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <BillingDashboardChart />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <LifetimeOfRecords />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <ClientSummaryChart />
                                </Item>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Item>
                                    <PendingWithLifetime />
                                </Item>
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            </section>
            <NewPasswordPopup setIsOpen={setIsOpen} userName={userName} setUsername={setUsername} password={password} setPassword={setPassword} modalIsOpen={modalIsOpen} />
        </>
    )
};





export default Dashboard;
