import React, { useEffect, useState } from "react";
import { BarChart, Bar, Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import {createUseStyles} from 'react-jss'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { GlobalContext } from "../GlobalContext";

const BillingDashboardChart = () => {

    const [fromDate, setFromDate] = React.useState(dayjs(new Date).subtract(1,'month'));
    const [toDate, setToDate] = React.useState(dayjs(new Date));
    const { globalState } = React.useContext(GlobalContext)
    const [errorMessage, setErrorMessage] = useState("")
    const [iorEorChart,setIorEorChart] = useState("")

    const PickDate = ()=>{
        const styles = createUseStyles({
          datePicker: {
            "& .MuiTextField-root": {
              width: 165,
              height:100
            }}
        })
        const classes = styles()
        return <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
              
            <div className={classes.datePicker}>
            <DatePicker
                label="From"
                value={fromDate} 
                onChange={(newValue) => setFromDate(newValue)}
                
              />
              </div>
              <div className={classes.datePicker}>
              <DatePicker
                label="To"
                value={toDate} 
                onChange={(newValue) => setToDate(newValue)}
                
              />
              </div>
      
            </DemoContainer>
          </LocalizationProvider>
      }

      useEffect(()=>{
        prepareIorEorData()
      },[fromDate,toDate])

      const prepareIorEorData = (async ()=> {

        try {
          const requestURL = globalState.endPointURL + "/dashboard/info";
          const reqObject={
            "startDate":fromDate.format("YYYY-MM-DD"),
            "endDate":toDate.format("YYYY-MM-DD"),
            "requestType":"IOR-EOR-DB",
            "iorStatus":"ALL",
            "eorStatus":"ALL",
      
          }
          const headersUserName = window.localStorage.getItem("headers-username");
          globalState.headers.userName = headersUserName;
          
          const response = await fetch(requestURL, {
              method: 'POST',
              headers:globalState.headers,
              body:JSON.stringify(reqObject)
          });
          const data = await response.json();
          // console.log("data",data)
      
          if (data !== "" && data.isSuccess) {
            
            setIorEorChart(processIorEor(data))
          
          } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
              setErrorMessage(data.errorCodes.errorCodes[0].message);
          else
              setErrorMessage(globalState.commonErrorMessage);
      } catch (e) {
          setErrorMessage(globalState.commonErrorMessage);
      }
      })

      const processIorEor=(data)=>{

        let eorStatusCount = data?.information?.eorStatusCount;
        let iorStatusCount = data?.information?.iorStatusCount;
        let iorRequsted=0;
        let iorRejected=0;
        let iorCompleted=0;
        let iorPending=0;
        let eorRequsted=0;
        let eorRejected=0;
        let eorCompleted=0;
        let eorPending=0;

        iorStatusCount?.filter((record)=>{
            switch(record.status){
    
                case "Pending Approval":
                    iorRequsted+=parseInt(record.count);
                    iorPending+=parseInt(record.count);
                       break;
                case "Approved":
                    iorRequsted+=parseInt(record.count);
                       break;
                case "Shipped":
                    iorRequsted+=parseInt(record.count);
                       break;
                case "CL Approval Pending":
                    iorRequsted+=parseInt(record.count);
                       break;
                case "Checklist Approved":
                    iorRequsted+=parseInt(record.count);
                       break;
                case "Assesed":;
                    iorRequsted+=parseInt(record.count);
                        break;
                case "Duty Paid":
                    iorRequsted+=parseInt(record.count);
                        break;                        
                case "Canceled":
                    iorRejected+=parseInt(record.count);
                        break;
                case "Billed":
                    iorCompleted+=parseInt(record.count)
                    iorRequsted+=parseInt(record.count);
                        break;      
                case "Delivered":
                    iorCompleted+=parseInt(record.count)
                    iorRequsted+=parseInt(record.count);
                        break; 
                }
        })

        eorStatusCount?.filter((record)=>{
            switch(record.status){
    
              case "Order Created":
                  eorPending+=parseInt(record.count);
                case "Approved":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "GRW Applied":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "GRW Approved":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "GRW Rejected":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "AWB Requested":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "AWB Genereated":
                  eorRequsted+=parseInt(record.count);
                       break;
                case "Document Uploaded":
                  eorRequsted+=parseInt(record.count);
                       break;                        
                case "Shipment Picked":
                  eorRequsted+=parseInt(record.count);
                        break;
                case "Shipping Bill Filed CHA":
                  eorRequsted+=parseInt(record.count);
                        break;      
                case "Shipping Checklist Approved":
                  eorRequsted+=parseInt(record.count);
                        break; 
                case "CI Complete":
                  eorRequsted+=parseInt(record.count);
                        break; 
                case "LEO Released":
                  eorRequsted+=parseInt(record.count);
                          break; 
                case "Delivered":
                  eorCompleted+=parseInt(record.count);
                        break; 
                case "Billed":
                  eorCompleted+=parseInt(record.count);
                        break;   
                case "Canceled":
                  eorRequsted+=parseInt(record.count);
                  eorRejected+=parseInt(record.count);
                        break;   

                }
        })

        const ior={}
        const eor={}

        ior.name='IOR';
        ior.requested=iorRequsted;
        ior.rejected=iorRejected;
        ior.completed=iorCompleted;
        ior.pending=iorPending;

        eor.name='EOR';
        eor.requested=eorRequsted;
        eor.rejected=eorRejected;
        eor.completed=eorCompleted;
        eor.pending=eorPending;

        const iorEorDBArray =[ior,eor]

        return iorEorDBArray

    }

    return <div className="row"> 
    <div className="row"> 
    <h5 style={{align:"center"}}>IOR-EOR DB</h5>
    <PickDate/>
    </div>
    <div className="row">
              <BarChart
                width={500}
                height={300}
                data={iorEorChart}
                margin={{
                  top: 5,
                  right: 10,
                  left: 10,
                  bottom: 5,
                }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar name="Requested" dataKey="requested" fill="#9c9fa2" />
                <Bar name="Rejected" dataKey="rejected" fill="#ff0000" /> 
                <Bar name="Completed" dataKey="completed" fill="#018500" />
                <Bar name="Pending" dataKey="pending" fill="#fa9a50" /> 
              </BarChart>
    </div>
    </div>
};
export default BillingDashboardChart;
