import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
    const { pathname } = useLocation();
    // console.log(pathname);
    useEffect(() => {
        const element = document.querySelector('.dashboard');
        element?.scrollIntoView();
    }, [pathname]);
    return null;
}