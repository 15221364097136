import React from "react";
import logoLarge from "../assets/images/logo-large.jpg";

const Error = () =>{
    return(
        <div className="err-main">
        <div className="error">
            <div className="err-logo">
            <div className="">
                <img src={logoLarge} alt="login-logo" className="img-fluid" />
              </div>
            </div>
            <h1 className="mt-4">404 Page</h1>
            <p>Redirecting to <span className="err-login"> Login Page </span></p>
        </div>
        </div>
    )

}
export default Error;