import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import { Modal, Button } from 'react-bootstrap';
import { confirm } from "react-confirm-box";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const RoleDetails = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [active, setActive] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [roleErrorMsg, setRoleErrorMsg] = useState("");
    const [descriptionErrorMsg, setDescriptionErrorMsg] = useState("");
    const [roles, setRoles] = useState([]);
    const [uploadDisable, setUploadDisable] = useState(false);
    const { register, handleSubmit, reset, formState } = useForm();
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage,confirmNavigation,cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const requestURL = globalState.endPointURL + "/role/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
               // console.log(responseData);
                if (responseData !== "") {
                    const data = responseData?.rolesInfo[0];
                    setActive(data.isActive);
                    setDescription(data.description);
                    setName(data.name);
                }
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)

                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
               // console.log(e);
            }
        };
        const fetchExistedRoles = async () => {
            try {
                const requestURL = globalState.endPointURL + "/role/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response?.json();
              //  console.log(responseData);
                if (responseData != null) {
                    responseData.rolesInfo?.map((role) => {
                        role.isActive = role.isActive ? "Yes" : "No";
                    });
                    setRoles(responseData?.rolesInfo);
                }
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
             //   console.log(e);
            }
        }

        if (id !== undefined)
            fetchRoles();

        // fetchExistedRoles();
    }, []);


    const validateForm = () => {
        let returnVal = true;
        if (name === undefined || name === null || name === '') {
            setRoleErrorMsg("Please enter a role");
            setUploadDisable(false);
            returnVal = false;
        }
        if (description === undefined || description === null || description === '') {
            setDescriptionErrorMsg("Please enter a description");
            setUploadDisable(false);
            returnVal = false;
        }
        return returnVal;
    };

    const onSubmit = async (data) => {
        setUploadDisable(true);
        setErrorMessage("");
        if (validateForm()) {
            const createState = {
                roleName: name,
                description: description,
                isActive: active
            };
             handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    };

    const handleSave = async (data) => { 
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if(result){
            const requestURL = globalState.endPointURL + "/role";
            let methodType = "";
            if (id === undefined)
                methodType = "POST";
            else {
                methodType = "PUT";
                data.id = id;
            }
            //console.log(JSON.stringify(data));
            //console.log(globalState.headers);

            let response = await fetch(requestURL, {
                method: methodType,
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();
            //console.log(responseData);
            if (responseData?.isSuccess)
                handleClose();
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                //setErrorMessage("Failed to save data.");
            } else {
                setErrorMessage(globalState.commonErrorMessage);
            }
        } 
    }catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e); 
        }
        finally {
            setUploadDisable(false);
        }
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/roles");
        });
    };


    return(
        <><DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation} /><section className="home-section">
            <div className="dashboard">
                <div className="table-heading">
                    <h4>Role Details</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                    <div className="row mb-4">

                        <div className="col-lg-6 col-md-6">
                            <label>Role
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="role" placeholder="role" value={name} onChange={e => { setRoleErrorMsg(""); setName(e.target.value);setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{roleErrorMsg}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <label>Description
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="description" placeholder="description" value={description} onChange={e => { setDescriptionErrorMsg(""); setDescription(e.target.value);setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{descriptionErrorMsg}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" name="isActive" id="customSwitches" checked={active} onChange={e => {setActive(!active); setCanShowDialogLeavingPage(true);}} />
                                <label className="custom-control-label" for="customSwitches">Is Active
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="btn-footer text-right">
                        {id === undefined ? <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                            : <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}
                        <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </form>
            </div>
        </section></>
    )
};
export default RoleDetails;