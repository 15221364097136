import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import csc from "country-state-city";
import { confirm } from "react-confirm-box";

const ExportRecordsDelivery = () => {
    const { handleSubmit, formState } = useForm();
    const navigate = useNavigate();
    const { login } = useAuth();
    const gridRef = useRef();
    const { state } = useLocation();
    const [remarks, setRemarks] = useState("");
    const [eorHistory, setEORHistory] = useState([]);
    const [exportRecords, setExportRecords] = useState([]);
    const [exportDocuments, setExportDocuments] = useState([]);
    const [globalExportRecords, setGlobalExportRecords] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [exportId, setExportId] = useState('');
    const [exportRef, setExportRef] = useState('');
    const [ptiRecords, setPtiRecords] = useState([]);
    const [Date, setDate] = useState('');
    const [clientName, setClientName] = useState('');
    const [clientList, setClientList] = useState([]);
    const [noPkg, setNoPkg] = useState("");
    const [statuss, setStatuss] = useState('');
    const [statussDDL, setStatussDDL] = useState('');
    const [exporter, setExporter] = useState('');
    const [importer, setImporter] = useState('');
    const [coe, setCoe] = useState('');
    const [cod, setCod] = useState('');
    const [shipper, setShipper] = useState('');
    const [consignee, setConsignee] = useState('');
    const [shipDate, setShipDate] = useState('');
    const [incoterms, setIncoterms] = useState('');
    const [modeOfTran, setModeOfTran] = useState('');
    const [shipVia, setShipVia] = useState('');
    const [shippedBillNo, setShippedBillNo] = useState('');
    const [currency, setCurrency] = useState('');
    const [shippedBillDate, setShippedBillDate] = useState('');
    const [grWavierDate, setGrWavierDate] = useState('');
    const [grWavierNo, setGrWavierNo] = useState('');
    const [scometApprove, setScometApprove] = useState(false);
    const [grWavier, setGrWavier] = useState(true);

    const [grossWt, setGrossWt] = useState('');
    const [searchText, setSearchText] = useState("");
    const [invTotal, setInvTotal] = useState('');
    const [invoice, setInvoice] = useState('');
    const [wayBillNo, setWayBillNo] = useState('');
    const [uploadDisable, setUploadDisable] = useState(false);
    const [uploadHistoryDisable, setUploadHistoryDisable] = useState(false);
    const { id } = useParams();
    const [clientNameErrorMessage, setClientNameErrorMessage] = useState("");
    const [statusErrorMessage, setStatusErrorMessage] = useState("");
    const [exporterErrorMessage, setExporterErrorMessage] = useState("");
    const [importerErrorMessage, setImporterErrorMessage] = useState("");
    const [consigneeErrorMessage, setConsigneeErrorMessage] = useState("");
    const [codErrorMessage, setCodErrorMessage] = useState("");
    const [coeErrorMessage, setCoeErrorMessage] = useState("");
    const [shipperErrorMessage, setShipperErrorMessage] = useState("");
    const [remarksErrorMessage, setRemarksErrorMessage] = useState("");

    const regExpAlphaNumber = /^[a-zA-Z0-9]+$/;
    const regExpNumber = /^[0-9\b]+$/;

    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    const updatedStates = (countryId) => csc.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.id, ...state }));
    //const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));

    const validateForm = () => {
        let returnVal = true;
        if (clientName === undefined || clientName === null || clientName === "select" || clientName === "") {
            setClientNameErrorMessage("Please select client name");
            setUploadDisable(false);
            document.getElementById("clientName").focus();
            returnVal = false;
        }
        if (statuss === undefined || statuss === null || statuss === "select" || statuss === "") {
            setStatusErrorMessage("Please select export status");
            setUploadDisable(false);
            document.getElementById("statuss").focus();
            returnVal = false;
        }
        if (exporter === undefined || exporter === null || exporter === "select" || exporter === "") {
            setExporterErrorMessage("Please select exporter");
            setUploadDisable(false);
            document.getElementById("exporter").focus();
            returnVal = false;
        }
        if (shipper === undefined || shipper === null || shipper === "select" || shipper === "") {
            setShipperErrorMessage("Please select shipper");
            setUploadDisable(false);
            document.getElementById("shipper").focus();
            returnVal = false;
        }
        if (coe === undefined || coe === null || coe === "select" || coe === "") {
            setCoeErrorMessage("Please select country of export");
            setUploadDisable(false);
            document.getElementById("coe").focus();
            returnVal = false;
        }
        if (importer === undefined || importer === null || importer === "select" || importer === "") {
            setImporterErrorMessage("Please select importer");
            setUploadDisable(false);
            document.getElementById("importer").focus();
            returnVal = false;
        }
        if (cod === undefined || cod === null || cod === "select" || cod === "") {
            setCodErrorMessage("Please select country of desination");
            setUploadDisable(false);
            document.getElementById("cod").focus();
            returnVal = false;
        }
        if (consignee === undefined || consignee === null || consignee === "select" || consignee === "") {
            setConsigneeErrorMessage("Please select consignee");
            setUploadDisable(false);
            document.getElementById("consignee").focus();
            returnVal = false;
        }
        return returnVal;
    };

    const domLayout = "autoHeight";

    const updateGlobalState = {
        "exportId": exportId,
        "reference": exportRef,
        "status": statuss,
        "shipDate": shipDate,
        "clientName": clientName,
        "invoiceNo": invoice,
        "exporterDetails": {
            "countryOfExport": coe,
            "exporter": exporter,
            "shipper": shipper,
        },
        "importerDetails": {
            "countryOfDestination": cod,
            "importer": importer,
            "shippedTo": consignee,
        },
        "shipmentDetails": {
            "awbBillNumber": wayBillNo,
            "currency": currency,
            "grossWeight": grossWt,
            "incoterms": incoterms,
            "modeOfTransport": modeOfTran,
            "noOfPackages": noPkg,
            "shipVia": shipVia,
            "grWavierNo": grWavierNo,
            "grWavierDate": grWavierDate,
            "grWavierRequired": grWavier,
            "shippingBillNo": shippedBillNo,
            "shippingBillDate": shippedBillDate,
            "isScometApprovalNeeded": scometApprove,
        },
        "id": id,        
        "shipmentItems": exportRecords === undefined ? [] : exportRecords,
        "documents": exportDocuments === undefined ? [] : exportDocuments,
        "remarks": eorHistory === undefined ? [] : eorHistory,
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        if (validateForm()) {
            const updatedDocuments = exportDocuments?.flatMap((item) => {
                if (item !== null) {
                    return {
                        id: item.id,
                    }
                }                
            });
            const updatedShipments = exportRecords?.flatMap((item) => {
                if (item !== null) {
                    return {
                        id: item.itemId,
                    }
                } 
            });
            const updatedRemarks = eorHistory?.flatMap((item) => {
                if (item !== null) {
                    return {
                        id: item.id,
                    }
                } 
            });
            const createState = {
                "reference": exportRef,
                "status": statuss,
                "shipDate": shipDate,
                "invoiceNo": invoice,
                "clientName": clientName,
                "exportId": exportId,
                "exporterDetails": {
                    "countryOfExport": coe,
                    "exporter": exporter,
                    "shipper": shipper
                },
                "importerDetails": {
                    "countryOfDestination": cod,
                    "importer": importer,
                    "shippedTo": consignee
                },
                "shipmentDetails": {
                    "awbBillNumber": wayBillNo,
                    "currency": currency,
                    "grossWeight": grossWt,
                    "incoterms": incoterms,
                    "modeOfTransport": modeOfTran,
                    "noOfPackages": noPkg,
                    "shipVia": shipVia,
                    "grWavierNo": grWavierNo,
                    "grWavierDate": grWavierDate,
                    "grWavierRequired": grWavier,
                    "shippingBillNo": shippedBillNo,
                    "shippingBillDate": shippedBillDate,
                    "isScometApprovalNeeded": scometApprove
                },
                "shipmentItems": updatedShipments,
                "documents": updatedDocuments,
                "Remarks": updatedRemarks,
            };
            handleSave(createState);
        }
    };

    const handleSave = async (data) => {
        try {
            let requestURL = globalState.endPointURL + "/eor/";
            let methodType;
            if (id !== undefined) {
                data.id = id;
                methodType = "PUT";
                data.exportId = exportId === undefined ? "" : parseInt(exportId);
            } else
                methodType = "POST";

          //  console.log(JSON.stringify(data));
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            let response = await fetch(requestURL, {
                method: methodType,
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData !== null && responseData.isSuccess) {
                globalState.mockDataDetails.ExportDetails.push(data);
                handleClose();
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
                //setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            } else {
                setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            setUploadDisable(false);
           // console.log("Error" + e);
        }
    };

    const onGridReady = async () => {
        fetchClients();
        if (id !== undefined) {
            if (globalState?.exportRecords?.exportId) 
                mappingResponseData(globalState.exportRecords);
            else 
                fetchExportData();            
        } else {
            mappingResponseData(globalState.exportRecords);
        }
            globalState.exportRecords = updateGlobalState;
       //  console.log(globalState?.exportRecords);
    };

    const fetchExportData = async () => {
        try {
            const requestURL = globalState.endPointURL + "/eor/id/" + id;
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null && responseData.isSuccess) {
                mappingResponseData(responseData.eorInfo[0]);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
           // console.log(e);
        }
    };

    const mappingResponseData = (data) => {
        const updatedDocuments = data?.documents?.filter((item) => {
            if (item !== null) {                
                return item;
            }
        });
        const updatedShipments = data?.shipmentItems?.filter((item) => {
            if (item !== null) {
                if (item.uomId === "1") {
                    item.uomName = "Kgs";
                } else if (item.uomId === "2") {
                    item.uomName = "Nos";
                } else if (item.uomId === "3") {
                    item.uomName = "EA";
                } else {
                    item.uomName = "";
                }
                return {
                    item
                }
            }
        });
        const updatedRemarks = data?.remarks?.filter((item) => {
            if (item !== null) {
                return {
                    item
                }
            }
        });
       // console.log(data);
        setExportRecords(updatedShipments);
        setGlobalExportRecords(updatedShipments);
        setEORHistory(updatedRemarks);
        setExportDocuments(updatedDocuments);
        setExportId(id);
        setExportRef(data?.reference);
        setStatuss(data?.status === undefined || data?.status === "" ? "Order Created" : data?.status);
        setStatussDDL(data?.status === undefined || data?.status === "" ? "Order Created" : data?.status);
        setShipDate(data?.shipDate);
        setClientName(data?.clientName);
        setInvoice(data?.invoiceNo);
        setCoe(data?.exporterDetails?.countryOfExport);
        setExporter(data?.exporterDetails?.exporter);
        setShipper(data?.exporterDetails?.shipper);
        setCod(data?.importerDetails?.countryOfDestination);
        setImporter(data?.importerDetails?.importer);
        setConsignee(data?.importerDetails?.shippedTo);
        setWayBillNo(data?.shipmentDetails?.awbBillNumber);
        setCurrency(data?.shipmentDetails?.currency);
        setGrossWt(data?.shipmentDetails?.grossWeight);
        setIncoterms(data?.shipmentDetails?.incoterms);
        setModeOfTran(data?.shipmentDetails?.modeOfTransport);
        setNoPkg(data?.shipmentDetails?.noOfPackages);
        setShipVia(data?.shipmentDetails?.shipVia);
        setGrWavier(data?.grWavierRequired === undefined || data?.grWavierRequired === "" ? true : data?.grWavierRequired);
        setGrWavierNo(data?.shipmentDetails?.grWavierNo);
        setGrWavierDate(data?.shipmentDetails?.grWavierDate);
        setShippedBillNo(data?.shipmentDetails?.shippingBillNo);
        setShippedBillDate(data?.shipmentDetails?.shippingBillDate);
        setScometApprove(data?.shipmentDetails?.isScometApprovalNeeded === undefined || data?.shipmentDetails?.isScometApprovalNeeded === "" ? false : data?.shipmentDetails?.isScometApprovalNeeded);
    };

    // const actionButton = (event) => {
        
    //     return (
    //         <p>
    //             <button type="button" onClick={() => deleteExportRecords(event.data.serialNo)} disabled={statussDDL !== "Order Created"} className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
    //         </p>
    //     );
    // };

    // const deleteExportRecords = async (serialNo) => {
    //     globalState.exportRecords = updateGlobalState;
    //     const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
    //     if (result) {
    //         try {
    //             globalState.exportRecords.shipmentItems = globalState.exportRecords.shipmentItems.filter((item) => { return String(item.serialNo) !== String(serialNo); });
    //             const updatedShipments = globalState.exportRecords.shipmentItems?.filter((item) => {
    //                 if (item !== null) {
    //                     return {
    //                         item
    //                     }
    //                 }
    //             });                
    //             setExportRecords(updatedShipments);
    //             setGlobalExportRecords(updatedShipments);
    //         } catch (e) {
    //             setErrorMessage(globalState.commonErrorMessage);
    //             console.log(e);
    //         }
    //     }
    // }

    const columnDef = [        
        { headerName: '#', valueGetter: 'node.rowIndex+1', sortable: true, filter: true, minWidth: 20 },
        { headerName: 'id', hide: true, field: "id", sort: "asc", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Part#', field: "partNo", sortable: true, filter: true, minWidth: 150 },
        { headerName: 'Serial#', field: "serialNo", sortable: true, filter: true, minWidth: 120 },
        { headerName: 'HS Code', field: "hsCode", sortable: true, filter: true, minWidth: 120 },
        { headerName: 'Description', field: "description", sortable: true, filter: true, minWidth: 180 },
        { headerName: 'ECN', field: "eccn", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Qty', field: "quantity", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'MOU', field: "uomName", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Rate', field: "unitPrice", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Amount', field: "amount", sortable: true, filter: true, minWidth: 100 },
        // { headerName: 'Actions', "cellRenderer": actionButton, minWidth: 100 },
    ];

     // upload Documcent
     const UploadDocument = () => {
        globalState.exportRecords = updateGlobalState;
        if (clientName === undefined || clientName === null || clientName === "select" || clientName === "") {
            setClientNameErrorMessage("Please select client name");            
            document.getElementById("clientName").focus();
            return false;
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/ExportRecords/EORDocument");
            });
        } 
    };

  

    //cancel
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecords");
        });
    };

    const handleEOROpen = (e, data) => {
        if (e.target.innerHTML === "+") {
            let div = document.createElement("div");
            div.className = "accordian-body";
            div.id = data;
            div.innerHTML = data;
            e.target.innerHTML = "-";
            e.target.parentNode.parentNode.appendChild(div);
        } else {
            let element = document.getElementById(data);
            e.target.innerHTML = "+";
            e.target.parentNode.parentNode.removeChild(element);
        }
    }

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);

 

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
               // console.log(data);
                setClientList(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handleGrossWt = (t) => {
        let val = (t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
        setGrossWt(val);
    };

    const handleSaveHistory = async () => {
        setUploadHistoryDisable(true);
        if (remarks === "") {
            setRemarksErrorMessage("Remarks should not empty");
            setUploadHistoryDisable(false);
        } else {
            const data = {
                "eorId": id,
                "remarks": [
                    {
                        "message": remarks
                    }
                ]
            };
            try {
                let requestURL = globalState.endPointURL;
                requestURL = requestURL + "/eor/remarks/";
                let methodType = "POST";
                //console.log(JSON.stringify(data));
                const headersUserName = window.localStorage.getItem("headers-username");
                globalState.headers.userName = headersUserName;
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                //console.log(responseData);
                if (responseData !== null && responseData.isSuccess) {
                    setRemarks("");
                    let existData = [];
                    if (eorHistory !== undefined)
                        existData = eorHistory;
                    existData.push(responseData?.eorHistory);
                    setEORHistory(existData);
                    setUploadHistoryDisable(false);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    // setErrorMessage("Failed to save the data.");
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                }

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log("Error" + e); 
            }
        }
    };

    return <form onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">Export Records</h4>
            </div>
            <div className="row m-0 p-0">
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                <div className="col-lg-12 col-sm-12 m-0 p-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Export Details</div>
                        {/* <!-- Export Details row --> */}
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="p-1">
                                    {/* <!--  Export Id  --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Export Id</label>
                                        <div className="input-box">
                                            <input type="text" disabled={true} className="form-control" value={exportId} />
                                        </div>
                                    </div>
                                    {/* <!-- end Export Id --> */}
                                    {/* <!-- Client Name --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Client Name</label>
                                        <div className="select-box">
                                            <select id="clientName" value={clientName} disabled="disabled" onChange={e => setClientName(e.target.value)}>
                                                <option value="select">Select</option>
                                                {clientList?.map((item) => {
                                                    return <option value={item.name}>{item.name}</option>
                                                })}
                                            </select>
                                            <div className="text-warning">{clientNameErrorMessage}</div>
                                        </div>
                                    </div>
                                    {/* <!-- end Client Name --> */}
                                    {/* <!--  Export Reference  --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Export Reference</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={exportRef} readOnly={true} onChange={e => setExportRef(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="p-1">

                                    {/* <!-- end Invoice Date --> */}
                                    {/* <!-- Ship Date --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Invoice Date</label>
                                        <div className="date-box date-box-pti">
                                            <input type="text" className="form-control" value={shipDate} readOnly={true} onChange={e => setShipDate(e.target.value)} placeholder="dd/mm/yyyy" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} />
                                        </div>
                                    </div>
                                    {/* <!-- end Ship Date --> */}
                                    {/* <!-- Export Status --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Export Status</label>
                                        <div className="select-box">
                                            {(statussDDL === "" || statussDDL === undefined || statussDDL === "Order Created") && id === undefined ?
                                            <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>                                                
                                                {globalState.exportStatus?.map((item) => {
                                                    return <option disabled={item.name === "Order Created" ? false : true} value={item.name}>{item.value}</option>
                                                })}
                                            </select>
                                                : ""}
                                          {statussDDL === "Order Created" && id !== undefined ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={(item.name === "Order Created" || item.name === "Approved") && exportRecords?.length > 0 ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Approved" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "GRW Applied" || item.name === "Approved" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "GRW Applied" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "GRW Approved" || item.name === "GRW Applied" || item.name === "GRW Rejected" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "GRW Approved" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "AWB Requested" || item.name == "GRW Approved" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}  
                                            {statussDDL === "GRW Rejected" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "GRW Rejected" || item.name == "GRW Applied" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "AWB Requested" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "AWB Genereated" || item.name == "AWB Requested" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "AWB Genereated" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "GRWAWB Genereated" || item.name == "Document Uploaded" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Document Uploaded" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "Document Uploaded" || item.name == "Shipment Picked" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Shipment Picked" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "Shipment Picked" || item.name == "Shipping Bill Filed CHA" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Shipping Bill Filed CHA" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "Shipping Bill Filed CHA" || item.name == "Shipping Checklist Approved" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Shipping Checklist Approved" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "Shipping Checklist Approved" || item.name == "CI Complete" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "CI Complete" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "CI Complete" || item.name == "LEO Released" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "LEO Released" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "LEO Released" || item.name == "Delivered" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Delivered" ?
                                                <select id="statuss" value={statuss} onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name === "Delivered" || item.name == "Billed" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            {statussDDL === "Billed" ?
                                                <select id="statuss" value={statuss} disabled="disabled" onChange={e => setStatuss(e.target.value)}>
                                                    {globalState.exportStatus?.map((item) => {
                                                        return <option disabled={item.name == "Billed" ? false : true} value={item.name}>{item.value}</option>
                                                    })}
                                                </select>
                                                : ""}
                                            <div className="text-warning">{statusErrorMessage}</div>
                                        </div>
                                    </div>
                                    {/* <!-- end Export Status --> */}
                                    <div className="single-pti-box-group width-full form-group">
                                        <label>Invoice #</label>
                                        <div className="date-box date-box-pti">
                                            <input type="text" className="form-control" readOnly={true} value={invoice} onChange={e => setInvoice(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end Export Details row --> */}
                    </div>
                </div>
            </div>

            <div className="row">
                {/* <!-- Exporter details --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Exporter Details</div>
                        <div className="p-1">
                            {/* <!-- Exporter --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label> Exporter </label>
                                <div className="select-box">
                                    <select id="exporter" value={exporter} disabled="disabled" onChange={e => setExporter(e.target.value)}>
                                        <option value="select">Select</option>
                                        {clientList?.map((item) => {
                                            return <option value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    <div className="text-warning">{exporterErrorMessage}</div>
                                </div>
                            </div>
                            {/* <!-- end  Exporter --> */}
                            {/* <!-- Shipper --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Shipper</label>
                                <div className="select-box">
                                    <select id="shipper" value={shipper} disabled="disabled" onChange={e => setShipper(e.target.value)}>
                                        <option value="select">Select</option>
                                        {clientList?.map((item) => {
                                                    return <option value={item.name}>{item.name}</option>
                                                })}
                                    </select>
                                    <div className="text-warning">{shipperErrorMessage}</div>
                                </div>
                            </div>
                            {/* <!-- end Shipper --> */}
                            {/* <!-- Country of Export --> */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Country of Export</label>
                                <div className="select-box">
                                    <select id="coe" disabled="disabled" value={coe} onChange={e => setCoe(e.target.value)}>
                                        <option value="select">Select</option>
                                        {updatedCountries.map((country) => {
                                            return <option value={country.name}>{country.name}</option>
                                        })}
                                    </select>
                                    <div className="text-warning">{coeErrorMessage}</div>
                                </div>
                            </div>
                            {/* <!-- end Country of Export --> */}
                        </div>
                    </div>
                    {/* <!-- end single-pti-box  --> */}
                </div>
                {/* Exporter details --> */}

                {/* <!-- Importer Details --> */}
                <div className="col-lg-6 col-sm-12">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Importer Details</div>
                        <div className="p-1">
                            {/* Importer */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Importer</label>
                                <div className="select-box">
                                    <select id="importer" disabled="disabled" value={importer} onChange={e => setImporter(e.target.value)}>
                                        <option value="select">Select</option>
                                        {clientList?.map((item) => {
                                            return <option value={item.name}>{item.name}</option>
                                        })}
                                    </select>
                                    <div className="text-warning">{importerErrorMessage}</div>
                                </div>
                            </div>
                            {/* end Importer */}
                            {/* consignee / ship To */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Consignee/Ship To</label>
                                <div className="select-box">
                                    <select id="consignee" disabled="disabled" value={consignee} onChange={e => setConsignee(e.target.value)}>
                                        <option value="select">Select</option>
                                        {clientList?.map((item) => {
                                                    return <option value={item.name}>{item.name}</option>
                                                })}
                                    </select>
                                    <div className="text-warning">{consigneeErrorMessage}</div>
                                </div>
                            </div>
                            {/* end consignee / ship To */}
                            {/* Country of Ultimate Destination */}
                            <div className="single-pti-box-group width-full form-group">
                                <label>Country of Destination</label>
                                <div className="select-box">
                                    <select id="cod" value={cod} disabled="disabled" onChange={e => setCod(e.target.value)}>
                                        <option value="select">Select</option>
                                        {updatedCountries.map((country) => {
                                            return <option value={country.name}>{country.name}</option>
                                        })}
                                    </select>
                                    <div className="text-warning">{codErrorMessage}</div>
                                </div>
                            </div>
                            {/* end Country of Ultimate Destination */}

                        </div>
                    </div>
                </div>
                {/* <!-- end Exporter details --> */}
            </div>


            {/* <!-- Shipment Details --> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Shipment Details</div>
                        <div className="row p-1">
                            {/* <!--  Incoterm --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label> Incoterms</label>
                                    <div className="select-box">
                                        <select value={incoterms} disabled="disabled" onChange={e => setIncoterms(e.target.value)}>
                                            <option value="">Select</option>
                                            {globalState.incotermId?.map((item) => {
                                                return <option value={item.name}>{item.value}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end  Incoterm --> */}
                            {/* <!-- Mode of Transport --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Mode of Transport</label>
                                    <div className="select-box">
                                        <select value={modeOfTran} disabled="disabled" onChange={e => setModeOfTran(e.target.value)}>
                                            <option value="">Select</option>
                                            {globalState.modeTran?.map((item) => {
                                                return <option value={item.name}>{item.value}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end Mode of Transport --> */}
                            {/* <!--  Ship via  --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Ship via</label>
                                    <div className="select-box">
                                        <select value={shipVia} disabled="disabled" onChange={e => setShipVia(e.target.value)}>
                                            <option value="">Select</option>
                                            {globalState.shipVia?.map((item) => {
                                                return <option value={item.name}>{item.value}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Shipped Bill No</label>
                                    <div className="input-box">
                                        <input type="text" readOnly={true} className="form-control" value={shippedBillNo}
                                            onChange={e => regExpAlphaNumber.test(e.target.value) || e.target.value === "" ? setShippedBillNo(e.target.value) : ""} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end Ship via --> */}
                            {/* <!-- Currency --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Currency</label>
                                    <div className="select-box">
                                        <select value={currency} disabled="disabled" onChange={e => setCurrency(e.target.value)}>
                                            <option value="select">Select</option>
                                            {globalState.currency?.map((currency) => {
                                                return <option value={currency.name}>{currency.value}</option>
                                            })}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Shipped Bill Date</label>
                                    <div className="date-box date-box-pti">
                                        <input type="date" placeholder="dd/mm/yyyy" readOnly={true}
                                            value={shippedBillDate} onChange={e => setShippedBillDate(e.target.value)}
                                            className="form-control" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end Currency --> */}
                            {/* <!--  No. of Package --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>No. of Package</label>
                                    <div className="input-box">
                                        <input type="text" className="form-control" value={noPkg} readOnly={true}
                                            onChange={e => regExpNumber.test(e.target.value) || e.target.value === "" ? setNoPkg(e.target.value) : ""} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end No. of Package--> */}
                            {/* <!-- Gross Wt. --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>Gross Wt.</label>
                                    <div className="input-box" >
                                        <input type="text" className="form-control" value={grossWt} readOnly={true}
                                            onChange={e => handleGrossWt(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end Gross Wt. --> */}
                            {/* <!--  AWB-BL No. --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>AWB-BL No.</label>
                                    <div className="input-box">
                                        <input type="text" disabled={statuss !== "AWB Genereated" ? true : false}
                                            className="form-control" value={wayBillNo} readOnly={true}
                                            onChange={e => regExpAlphaNumber.test(e.target.value) || e.target.value === "" ? setWayBillNo(e.target.value) : ""} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end AWB-BL No.--> */}
                            {/* <!-- GR Wavier Date --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>GR Wavier Date</label>
                                    <div className="date-box date-box-pti">
                                        <input type="date" disabled={grWavier && statuss === "GRW Approved" ? false : true}
                                            value={grWavierDate} onChange={e => setGrWavierDate(e.target.value)}
                                            placeholder="dd/mm/yyyy" className="form-control" readOnly={true}
                                            onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end GR Wavier Date --> */}
                            {/* <!-- GR Wavier no. --> */}
                            <div className="col-sm-6">
                                <div className="single-pti-box-group width-full form-group">
                                    <label>GR Wavier no.</label>
                                    <div className="input-box" >
                                        <input type="text" disabled={grWavier && statuss === "GRW Approved" ? false : true}
                                            className="form-control" value={grWavierNo} readOnly={true}
                                            onChange={e => regExpAlphaNumber.test(e.target.value) || e.target.value === "" ? setGrWavierNo(e.target.value) : ""} />
                                    </div>
                                </div>
                            </div>
                            {/* <!-- end GR Wavier no. --> */}
                            <div className="col-sm-6 pb-4">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" name="isActive" id="customSwitches" disabled="disabled"
                                        checked={scometApprove} onChange={e => { setScometApprove(!scometApprove); }} />
                                    <label className="custom-control-label" for="customSwitches">SCOMET Approval Required</label>
                                </div>
                            </div>

                            <div className="col-sm-6 pb-4">
                                <div className="custom-control custom-switch">
                                    <input type="checkbox" className="custom-control-input" name="isActive" disabled="disabled" id="customSwitches2"
                                        checked={grWavier} onChange={e => { setGrWavier(!grWavier); }} />
                                    <label className="custom-control-label" for="customSwitches2">GR Wavier Required</label>
                                </div>
                            </div>

                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                </div>
            </div>
        </div>


        {/* <!-- Shipment item table row --> */}
        <div className="dashboard">
            <div className="col-lg-12 mt-3">
                <div className="example-header row">
                    <div className="col-lg-6 col-sm-12 ">

                        <div className="table-heading">
                            <h4>Shipment Details</h4>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="search-add">
                            <div className="sorting-num d-flex">
                                <span className="mt-2">Page Size:</span>&nbsp;
                                <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>&nbsp;
                            <label className="table-search">
                                <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                                <i className="bx bx-search"></i>
                            </label>
                        </div>
                    </div>
                </div>

                <div className="ag-theme-alpine" >
                    <AgGridReact
                        ref={gridRef}
                        rowData={exportRecords}
                        columnDefs={columnDef}
                        domLayout={domLayout}
                        defaultColDef={{
                            sortable: true,
                            flex: 1,
                            minWidth: 100,
                            filter: true,
                            resizable: true,
                        }}
                        pagination={true}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                    ></AgGridReact>
                </div>
            </div>

            {/* <!-- end shipment item row --> */}
     <br></br>

            {/* !-- history --> */}
            <div class="row">
                <div class="col-sm-12 col-lg-12">
                    <div class="single-pti-box">
                        <div class="single-pti-box-header">History</div>
                        <div class="p-2">
                            {/* !-- accordion --> */}
                            {eorHistory?.map((history) => {
                                return <div class="row mb-2"><div className="accordian">
                                    <div className="accordian-header" >
                                        <div>Date : {history.dateTime} </div>
                                        <div className="sign" onClick={e => { handleEOROpen(e, history.message) }}>{'+'}</div>
                                    </div>
                                </div>
                                </div>
                            }
                            )}
                            {/* !-- accordion end --> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* end history  */}

            {/* <!-- buttons row --> */}
            <div className="row">
                <div className="col-lg-6">
                    <div className="btn-footer">
                        <button type="button" onClick={UploadDocument} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload Document">Upload Document <i className="bx bx-upload" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="btnooter text-right">
                      <button type="button" disabled={uploadDisable} onClick={onSubmit} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                      <button type="button" onClick={handleClose} className="btn btn-secondary ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            {/* <!-- end buttons row --> */}

        </div>

    </form>

};

export default ExportRecordsDelivery;