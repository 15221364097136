import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import CertificatePopup from '../../shipments/PTIRecords/CertificatePopup';



const PTICertificateView = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { handleSubmit, formState } = useForm();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [certificateTypeId, setCertificateTypeId] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [url, setUrl] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const { errors } = formState;
    const [errorMessage, setErrorMessage] = useState("");

    const togglePopup = () => {
        setIsOpen(!isOpen);
      }

    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const responseData = globalState?.itemParts?.itemCertificate?.filter(item => { return String(item.certificate.id) === id; });

                if (responseData !== "") {
                    const data = responseData[0].certificate;
                    setName(data.name);
                    setExpiryDate(data.expiryDate);
                   /* let certificateType = "";
                    if (String(data.certificateTypeId) === "1") {
                        certificateType = "BIS";
                    } else if (String(data.certificateTypeId) === "2") {
                        certificateType = "ETA";
                    } else if (String(data.certificateTypeId) === "3") {
                        certificateType = "Import License";
                    } else if (String(data.certificateTypeId) === "4") {
                        certificateType = "EPR";
                    }*/
                    setCertificateTypeId(data.certificateTypeId);
                    setUrl(data.url);
                } else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined)
            fetchCertificates();
    }, []);

    const handleClose = () => {
        const itemId = globalState?.itemParts?.itemId;
        if (globalState.itemPartRedirect === "View") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-view/" + itemId);
            });
        } else if (globalState.itemPartRedirect === "Add") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details");
            });
        } else if (globalState.itemPartRedirect === "Edit") {
            login().then(() => {
                navigate(state?.path || "/masters/item-parts/item-parts-details/" + itemId);
            });
        }
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 >Certificate</h4>
            </div>

            <form action="" method="">
                <div className="row mb-4">

                    <div className="col-lg-6 col-md-6">

                        <div className="detail-view">
                            <h5>Certificate # </h5>
                            <p>{name}</p>
                        </div>
                    </div>


                    <div className="col-lg-6 col-md-6">

                        <div className="detail-view">
                            <h5>Expiry Date </h5>
                            <p>{expiryDate} </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">

                        <div className="detail-view">
                            <h5>Certificate / Licence Type </h5>
                            <p>{certificateTypeId} </p>
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-6">

                        
                        <div className="detail-view ">
                            <h5>Certificate file </h5>
                            <div className="certi-view"><p >{url} 
                                {url === "" ? "" :
                                    <div className="certi-view-icon" onClick={togglePopup}><i className="bx bxs-show" aria-hidden="true"></i></div>}
                           {isOpen && <CertificatePopup
                                    content={
                                        <div id="exampleModal">
                                            <div className="modal-dialog" >
                                                <div className="modal-content">
                                                    <div className="popup-header">
                                                        <h5 className="popup-title">Document</h5>
                                                        <br></br>
                                                    </div>
                                                    <div className="modal-body row">
                                                        <div className="col-sm-11 m-auto">
                                                            <embed src={url} type="" width="100%" height="300"/>
                                                        </div>
                                                    </div>                                                  
<br></br>
                                                    <div className="col-lg-12 text-right">
                                                      <button onClick={togglePopup} type="button" className="btn btn-secondary addbutton2" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle"></i></button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    handleClose={togglePopup} />}

                           </p></div>


                        </div>
                    </div>

                </div>
                <div className="text-right">
                    <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to Certificates <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
                </div>
            </form>
        </div>
    </section>
};
export default PTICertificateView;
