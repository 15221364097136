import React from "react";

let globalURL=process.env.REACT_APP_GLOBAL_URL; // prod AWS account
//let globalURL = "http://zenxlioreor-env.eba-mjetp9jq.ap-south-1.elasticbeanstalk.com"; // Zenxl AWS account

export const GlobalInitalLoad = {
    s3BuscketConfig: {
       //Zenxl AWS Account
        bucketName:process.env.REACT_APP_BUCKET_NAME,
        Bucket:process.env.REACT_APP_BUCKET,
        region:process.env.REACT_APP_REGION,
        accessKeyId:process.env.REACT_APP_ACCESS_KEY,
        secretAccessKey:process.env.REACT_APP_ACCESS_SECRET,
    },
    PtiItemSatatus: {
        "itemStatus": [{
            "itemCode": 1,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Available",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 12,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 13,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Available",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 14,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "no",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 15,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 16,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 17,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        },
        {
            "itemCode": 18,
            "desc": "test",
            "hsCode": 1234,
            "restr": "yes",
            "BisApplicable": "yes",
            "BisAvailable": "Pending",
            "WPCApplicable": "yes",
            "WPCDoc": "Pending",
            "Other": "---",
            "Action": "GET"
        }
        ]
    },
    BOEDetails : {
    "BOE" : [
        {
            "id" :1,
            "part" :456,
            "sl" : 23,
            "desc" :"test",
            "hsCode" : 3444,
            "uPrice" :1233,
            "Qty" :234,
            "amount" :789,
            "assess" :678,
            "bcd" :678,
            "acd" :678,
            "sws" :6789,
            "sad":789,
            "igst":780,
            "cess":6789,
            "add":678,
            "cvd":788,
            "sg":6789,
            "tValue":7890,
            "tVAlue" :678
        },
    ]
},   
    ExportShipment: {
        "ExportShip": [
            {
                "id": 1,
                "parts": 123,
                "serial": 4567,
                "desc": "testing",
                "beNo.": "A123",
                "beDt": "R324",
                "boeSerial": "ABC123"
            },
            {
                "id": 2,
                "parts": 123,
                "serial": 4567,
                "desc": "testing",
                "beNo.": "A123",
                "beDt": "R324",
                "boeSerial": "ABC123"
            },
            {
                "id": 3,
                "parts": 123,
                "serial": 4567,
                "desc": "testing",
                "beNo.": "A123",
                "beDt": "R324",
                "boeSerial": "ABC123"
            },
        ]
    },
    validateDatas: {
        "validateData": [
            {
                "id": 1,
                "partsId": 123,
                "serial": 12355,
                "description": "test",
                "beno": "a123",
                "bedt": "CD23",
                "isExported":"Yes",
                "boeSerial": "",
                "cellRenderer": "testing"
            },
            {
                "id": 2,
                "partsId": 124,
                "serial": 12356,
                "description": "test",
                "beno": "a123",
                "bedt": "CD23",
                "isExported":"Yes",
                "boeSerial": "",
                "cellRenderer": "testing"
            }
        ]
    },
    mockDataDocumnent: {
        "ExportDocument": [
            {
                "id": 1,
                "documnentType": "AWR",
                "documnentNumber": 123456,
                "uploadDate": "23/08/2022",
            }
        ],
    },
    mockDataDetails: {
        "dashboard": {
            "OrderCreated": 10,
            "Approved": 10,
            "GRWApplied": 12,
            "GRWApproved": 7,
            "GRWRejected": 5,
            "AWBRequested": 2,
            "AWBGenereated": 5,
            "DocumentUploaded": 10,
            "ShipmentPicked": 6,
            "ShippingBillFiled": 15,
            "ShippingChecklistApproved": 2,
            "CIComplete": 4,
            "LEOReleased": 11,
            "Delivered": 12,
            "Billed": 10,
        },

        "ExportDetails": [
            {
                "id": 12,
                "Date": "2022-09-18",
                "invoice": 786,
                "wayBillNo": 86888,
                "clients": "xyz Client",
                "noPkg": 54,
                "grossWt": "56 Kg",
                "isExported":"Yes",
                "status": "Order Created",
                "invTotal": 90
            },
            {
                "id": 13,
                "Date": "2022-09-19",
                "invoice": 786,
                "wayBillNo": 86888,
                "clients": "xyz Client",
                "noPkg": 54,
                "grossWt": "56 Kg",
                "isExported":"No",
                "status": "GRW Applied",
                "invTotal": 100

            },
            {
                "id": 14,
                "Date": "2022-09-28",
                "invoice": 786,
                "wayBillNo": 86888,
                "clients": "xyz Client",
                "noPkg": 54,
                "grossWt": "56 Kg",
                "isExported":"No",
                "status": "GRW Applied",
                "invTotal": 120

            },
            {
                "id": 15,
                "Date": "2022-09-19",
                "invoice": 786,
                "wayBillNo": 86888,
                "clients": "xyz Client",
                "noPkg": 54,
                "grossWt": "56 Kg",
                "isExported":"Yes",
                "status": "Shipment Picked",
                "invTotal": 100

            },
            {
                "id": 16,
                "Date": "2022-09-28",
                "invoice": 786,
                "wayBillNo": 86888,
                "clients": "xyz Client",
                "noPkg": 54,
                "grossWt": "56 Kg",
                "isExported":"No",
                "status": "Shipment Picked",
                "invTotal": 120

            }
        ]
    },     
    ptiRecord: {
        "id": "",
        "ptiId": "",
        "exporterId": "",
        "invNo": "",
        "invDate": "",
        "shipDate": "",
        "ptiStatus": "",
        "partStatus": "",
        "shipperId": "",
        "importerId": "",
        "countryImp": "",
        "consignee": "",
        "countryExp": "",
        "shipVia": "",
        "incotermId": "",
        "wayBillNo": "",
        "modeTran": "",
        "currency": "",
        "grossWt": "",
        "noPkg": "",
        "shipmentItemDetails": [],
        "iorDetails": [],
    },
    "shipItemDetails": [],
    shipmentDetail: {
        "shipmentId": "",
        "id": "",
        "itemStatus": false,
        "assess": "",
        "bcd": "",
        "acd": "",
        "sws": "",
        "sad": "",
        "igst": "",
        "cess": "",
        "add": "",
        "cvd": "",
        "sg": "",
        "tValue": "",
        partNo: "",
        internalOrderNo: "",
        serialNo: "",
        mfgName: "",
        desc: "",
        countryOrg: "",
        quantity: "",
        unitPrice: "",
        umo: "",
        uom: {
            id: ""
        },
        amount: "",
        eccn: "",
        encCode: "",
        hsCode: "",
        usCode: "",
        htsCode: "",
        certificates: []
    },
    selectedUser: {
        premissions: [],
        isSuccess: false,
        errorCodes: [],
        roleId: "",
        userId: "",
        userName: "",
    },
    itemParts: {
        "clientName": "",
        "eanCode": "",
        "eccnCode": "",
        "isSerialized": false,
        "isMultiPack": false,
        "itcHsCode": "",
        "mpin": "",
        "quantity": "",
        "upcCode": "",
        "city": "",
        "itemId": "",
        "expiryDate": "",
        "isActive": true,
        "isDeleted": false,
        "itemCertificate": [],
        "itemDetails": []
    },
    exportRecords: {
        "exportId": "",
        "reference": "",
        "status": "",
        "shipDate": "",
        "clientName": "",
        "invoiceNo": "",
        "exporterDetails": {
            "countryOfExport": "",
            "exporter": "",
            "shipper": "",
        },
        "importerDetails": {
            "countryOfDestination": "",
            "importer": "",
            "shippedTo": "",
        },
        "shipmentDetails": {
            "awbBillNumber": "",
            "currency": "",
            "grossWeight": "",
            "incoterms": "",
            "modeOfTransport": "",
            "noOfPackages": "",
            "shipVia": "",
            "grWavierNo": "",
            "grWavierDate": "",
            "grWavierRequired": true,
            "shippingBillNo": "",
            "shippingBillDate": "",
            "isScometApprovalNeeded": false,
        },
        "id":"",        
        "shipmentItems": [],
        "documents": [],
        "remarks": []
    },
    exportStatus: [{ name: "Order Created", value: "Order Created" },
        { name: "Approved", value: "Approved" },
        { name: "GRW Applied", value: "GRW Applied" },
        { name: "GRW Approved", value: "GRW Approved" },
        { name: "GRW Rejected", value: "GRW Rejected" },
        { name: "AWB Requested", value: "AWB Requested" },
        { name: "AWB Genereated", value: "AWB Genereated" },
        { name: "Document Uploaded", value: "Document Uploaded" },
        { name: "Shipment Picked", value: "Shipment Picked" },
        { name: "Shipping Bill Filed CHA", value: "Shipping Bill Filed CHA" },
        { name: "Shipping Checklist Approved", value: "Shipping Checklist Approved" },
        { name: "CI Complete", value: "CI Complete" },
        { name: "LEO Released", value: "LEO Released" },
        { name: "Delivered", value: "Delivered" },
        { name: "Billed", value: "Billed" }],
    itemPartRedirect: "",
    ptiRecordRedirect: "",
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
        'version': '1.0',
        'traceId': 'GF56DR789',
        'Access-Control-Allow-Origin': globalURL,
        'Access-Control-Allow-Headers': 'Content-Type',
        'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
        'Access-Control-Allow-Credentials': 'true',
        'Access-Control-Max-Age': '600',
        'userName': ""
    },
    endPointURL: globalURL,
    currency: [{ name: "1", value: "US Dollar" },
    { name: "2", value: "Rupee" },
    { name: "3", value: "Euro" },
    { name: "4", value: "Pound" }],
    modeTran: [{ name: "1", value: "Road Transportation" },
    { name: "2", value: "Rail Transportation" },
    { name: "3", value: "Air Transportation" },
    { name: "4", value: "Water or Maritime Transportation" }],
    shipVia: [
        { name: "1", value: "Fedex" },
       {name: "2", value: "DASCHER"},
       {name: "3", value: "DHL"},
       {name: "4", value: "Expeditors"},
       {name: "5", value: "TVS SCS"},
       {name: "6", value: "WatchPoint"} 

],
    incotermId: [{ name: "1", value: "Delivered Duty Paid" },
    { name: "2", value: "Free Carrier Delivered Duty Paid" },
    { name: "3", value: "Free Alongside Ship" },
    { name: "4", value: "Free On Board" },
    { name: "5", value: "Cost & Freight" },
    { name: "6", value: "Cost, Insurance & Freight" },
    { name: "7", value: "Carriage Paid To" },
    { name: "8", value: "Delivered at Frontier" },
    { name: "9", value: "Delivered Ex-Ship" },
    { name: "10", value: "Delivered Duty Unpaid" },
    { name: "11", value: "Ex Works" }],
    consignee: [{ name: "1", value: "Rapid Logistics" },
    { name: "2", value: "Flash C/o Omini All in (Singapore)Pte.Ltd" },
    { name: "3", value: "Flash Global Logistics Inc" },
    { name: "4", value: "Certosa Express" },
    { name: "5", value: "ZENEXPRESS Logistics Pvt Ltd" }],
    importerId: [{ name: "1", value: "Rapid Logistics" },
    { name: "2", value: "Flash C/o Omini All in (Singapore)Pte.Ltd" },
    { name: "3", value: "Flash Global Logistics Inc" },
    { name: "4", value: "Certosa Express" },
    { name: "5", value: "ZENEXPRESS Logistics Pvt Ltd" }],
    shipperId: [{ name: "1", value: "Arista networks" },
    { name: "2", value: "Nutanix inc" },
    { name: "3", value: "Palo Alto networks inc" },
    { name: "4", value: "Rapid Logistics" },
    { name: "5", value: "Flash C/o Omini All in (Singapore)Pte.Ltd" },
    { name: "6", value: "Flash Global Logistics Inc" },
    { name: "7", value: "Certosa Express" },
    { name: "8", value: "ZENEXPRESS Logistics Pvt Ltd" },
    { name: "9", value: "Arista Networks C/o. Flash Global" },
    { name: "10", value: "Certosa Express - Shanghal" },
    { name: "11", value: "FC Kannot Mellanox Technologies" },
    { name: "12", value: "ICL Thailand" },
    { name: "13", value: "JSI Hongkong" },
    { name: "14", value: "Palo Alto Networks BV" },
    { name: "15", value: "Mellanox" },
    { name: "16", value: "Rubrik Inc" }
    ],
    exporter: [{ name: "1", value: "Arista networks" },
    { name: "2", value: "Nutanix inc" },
    { name: "3", value: "Palo Alto networks inc" },
    { name: "4", value: "Rapid Logistics" },
    { name: "5", value: "Flash C/o Omini All in (Singapore)Pte.Ltd" },
    { name: "6", value: "Flash Global Logistics Inc" },
    { name: "7", value: "Certosa Express" },
    { name: "8", value: "ZENEXPRESS Logistics Pvt Ltd" },
    { name: "9", value: "Arista Networks C/o. Flash Global" },
    { name: "10", value: "Certosa Express - Shanghal" },
    { name: "11", value: "FC Kannot Mellanox Technologies" },
    { name: "12", value: "ICL Thailand" },
    { name: "13", value: "JSI Hongkong" },
    { name: "14", value: "Palo Alto Networks BV" },
    { name: "15", value: "Mellanox" },
    { name: "16", value: "Rubrik Inc" }
    ],
    ptiStatus: [{ name: "1", value: "Pending Approval" },
        { name: "2", value: "Approved" },
        { name: "3", value: "Shipped" },
        { name: "4", value: "CL Approval Pending" },
        { name: "5", value: "Checklist Approved" },
        { name: "6", value: "Assesed" },
        { name: "7", value: "Duty Paid" },
        { name: "8", value: "Custom Query" },
        { name: "9", value: "Out of Charge" },
        { name: "10", value: "Delivered" },
        { name: "11", value: "Billed" }],
    partStatus: [{ name: "1", value: "Pending Approval" },
        { name: "2", value: "Approved" },
        { name: "3", value: "Shipped" },
        { name: "4", value: "CL Approval Pending" },
        { name: "5", value: "Checklist Approved" },
        { name: "6", value: "Assesed" },
        { name: "7", value: "Duty Paid" },
        { name: "8", value: "Custom Query" },
        { name: "9", value: "Out of Charge" },
        { name: "10", value: "Delivered" }],
    uom: [{ name: "1", value: "Kgs" },
    { name: "2", value: "Nos" },
    { name: "3", value: "EA" }],
    deletePopup: {
        message: "Are you sure? you want to Delete",
        options: {
            labels: {
                confirmable: "Confirm",
                cancellable: "Cancel"
            }
        }
    },
    savePopup: {
        message: "Are you sure? you want to Save",
        options: {
            labels: {
                confirmable: "Yes",
                cancellable: "Cancel"
            }
        }
    },
    commonErrorMessage: "Due to Technical difficulties not able to complete your request. Please try after some time or Contact Admin."
};


const setGlobalState = () => { };
export const GlobalContext = React.createContext({
    globalState: GlobalInitalLoad,
    setGlobalState: setGlobalState,
});
export const GlobalProvider = GlobalContext.Provider;