import React, { useState, useCallback, useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import S3 from "react-aws-s3";
import AWS from 'aws-sdk';
import axios from "axios"
window.Buffer = window.Buffer || require("buffer").Buffer;

const IORDocument = () => {
    const [IORDocument, setIORDocument] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const [globalIORDocument, setGlobalsetIORDocument] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const gridRef = useRef();
    const [searchText, setSearchText] = useState("");
    const [documentType, setDocumentType] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [documentNo, setDocumentNo] = useState("");
    const [documentId, setDocumentId] = useState("");
    const [updateDate, setUpdateDate] = useState("");
    const fileInput = React.useRef();
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [documentErrorMessage, setDocumentErrorMessage] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const [uploadDocument,setUploadDocument] =useState("");

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewEOR(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editEOR(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deleteEOR(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const domLayout = "autoHeight";

    const columnDef = [
        { headerName: '#', field: "id", valueGetter: 'node.rowIndex+1', sortable: true, filter: true },
        { headerName: '#', field: "id", sort: "asc", hide: true, sortable: true, filter: true },
        { headerName: 'Document Type', field: "documentType", sortable: true, filter: true },
        { headerName: 'Document Number', field: "documentNumber", sortable: true, filter: true },
        { headerName: 'Upload date', field: "updatedDate", sortable: true, filter: true },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const onGridReady = async () => {
        try {            
            // console.log(globalState.ptiRecord.iorDetails);
            let itemDetails = globalState.ptiRecord.iorDetails?.slice();
            //itemDetails[0].uploadDate = formatDate(itemDetails[0].updatedDateTime === null ? itemDetails[0].createdDateTime : itemDetails[0].updatedDateTime);
            setIORDocument(itemDetails);
            setGlobalsetIORDocument(itemDetails);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    // Delete with fetchAPI
    const deleteEOR = async (id) => {
        try {
            const requestURL = globalState.endPointURL + "/document/id/" + id;
            const response = await fetch(requestURL, {
                method: 'DELETE',
                headers: globalState.headers,
            });
            const data = await response.json();
            if (data !== "" && data.isSuccess) {
                let responseData = IORDocument.filter((item) => { return item.id === id; });
                if (responseData[0].documentUrl !== null && responseData[0].documentUrl !== ""
                    && responseData[0].documentUrl !== undefined) {
                    let key = new URL(responseData[0].documentUrl).pathname.substr(1);
                    key = key.replace("%20", " ");
                    const params = { Bucket: globalState.s3BuscketConfig.bucketName, Key: key };
                    const s3 = new AWS.S3(globalState.s3BuscketConfig);
                    s3.deleteObject(params, function (err, data) {
                        if (err) {
                            // console.log(err);
                        } else {
                            // console.log("deleted successfully from s3");
                        }
                    });
                }
                globalState.ptiRecord.iorDetails = globalState.ptiRecord.iorDetails.filter((item) => { return item.id !== id; });
                setIORDocument(IORDocument.filter((item) => { return item.id !== id; }));
            } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(data.errorCodes.errorCodes[0].message);
            else
            setErrorMessage(globalState.commonErrorMessage);
        } catch (e) { 
            // console.log(e); 
            setErrorMessage(globalState.commonErrorMessage);
        }

    };

    const editEOR = (id) => {
        let item = IORDocument.filter((item) => {
            return item.id === id;
        });
        setDocumentId(item[0].id);
        setDocumentNo(item[0].documentNumber);
        setDocumentType(item[0].documentType);
        if (item[0].documentUrl !== "") {
            var pathName = new URL(item[0].documentUrl).pathname;
            // console.log(pathName.split("/").pop());
            setSelectedFile(pathName.split("/").pop());
            //handleDocumentUrl(pathName.split("/").pop());
        }
        //setSelectedFile(new URL(item[0].documentUrl)?.pathname.substr(1));
    };


    //View IOR
    const viewEOR = (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/IORDocumentView/" + id);
        });
    };

    // table fit size
    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
    }, []);


    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    const validateForm = () => {
        let returnVal = true;
        if (documentType === undefined || documentType === null || documentType === '') {
            setDocumentErrorMessage("Please select document Type");
            setUploadDisable(false);
            returnVal = false;
        }
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        setErrorMessage("");
        setUrlErrorMessage("");
        if (validateForm()) {
            let newArr = fileInput.current.files;
            if (newArr.length > 0) {
                for (let i = 0; i < newArr.length; i++) {
                    handleUpload(newArr[i]);
                }
            } else {
                const iorDetails = {
                    documentType: documentType,
                    documentUrl: uploadDocument&&uploadDocument.data&&uploadDocument.data.data&&uploadDocument.data.data.location,
                    documentNumber: documentNo,
                };
                handleSave(iorDetails);
            }
        }        
    };

    // const handleUpload = async (file) => {
    //     // console.log(globalState.ptiRecord.invNo);
    //     let newFileName = globalState.ptiRecord.invNo + "/" + documentType + "_" + documentNo + "/" + file?.name;
    //     const ReactS3Client = new S3(globalState.s3BuscketConfig);
    //     ReactS3Client.uploadFile(file, newFileName).then(data => {
    //         // console.log(data.location);
    //         const iorDetails = {
    //             documentType: documentType,
    //             url: data.location === undefined ? "" : data.location,
    //             documentNumber: documentNo,
    //         };
    //         handleSave(iorDetails);
    //     }).catch(err => console.error(err));
    // };
    const handleUpload = async (file) => {
        // console.log(globalState.ptiRecord.invNo);
        let newFileName = globalState.ptiRecord.invNo + "/" + documentType + "_" + documentNo + "/" + file?.name;
        // const docResponse = new S3(globalState.s3BuscketConfig);
        const formData = new FormData();
        if (file) {
            const fileName = file.name;
            const modifiedFileName = fileName.replace(/\.(?=.*\.)/g, "_"); // removes extra dots from filename
            const modifiedFile = new File([file], modifiedFileName, { type: file.type });
            formData.append("file", modifiedFile);
        }
        const docResponse = await axios.post('http://139.59.40.225:5000/upload-single', formData,);
        setUploadDocument(docResponse)
        // docResponse.uploadFile(file, newFileName).then(data => {
            // console.log(data.location);
            const iorDetails = {
                documentType: documentType,
                documentUrl: docResponse&&docResponse.data&&docResponse.data.data&&docResponse.data.data.location,
                documentNumber: documentNo,
            };
            handleSave(iorDetails);
        // }).catch(err => console.error(err));
    };
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    const handleSave = async (data) => {
        try {
            let requestURL = globalState.endPointURL;
            let methodType;
            if (documentId !== "") {
                requestURL = requestURL + "/document";
                data.id = documentId;
                methodType = "PUT";
            } else {
                requestURL = requestURL + "/document";
                methodType = "POST";
            }
            // console.log(JSON.stringify(data));
            let response = await fetch(requestURL, {
                method: methodType,
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();
            if (responseData !== null && responseData.isSuccess) {
                let itemDetails = globalState.ptiRecord.iorDetails.slice();
                let index = itemDetails.findIndex(function (item, i) {
                    return item.id === documentId;
                });
                if (index >= 0) {
                    itemDetails.splice(index, 1);
                    globalState.ptiRecord.iorDetails.splice(index, 1);
                }
               // responseData.document.uploadDate = formatDate(responseData.document.updatedDateTime === null ? responseData.document.createdDateTime : responseData.document.updatedDateTime);
                globalState.ptiRecord.iorDetails.push(responseData.document);
                setDocumentNo("");
                setDocumentType("");
                setSelectedFile("");
                setDocumentId("");
                // console.log(globalState.ptiRecord.iorDetails);
                itemDetails = globalState.ptiRecord.iorDetails.slice();
                setIORDocument(itemDetails);
                setGlobalsetIORDocument(itemDetails);
                fileInput.current.value = "";
                setUploadDisable(false);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                // setErrorMessage("Failed to save the data.");
                setUploadDisable(false);
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            } else {
               setErrorMessage(globalState.commonErrorMessage);
                setUploadDisable(false);
            }
        } catch (e) {
           setErrorMessage(globalState.commonErrorMessage);
            setUploadDisable(false);
            // console.log("Error" + e);
        }

    };

    //close
    const handleClose = () => {
        if (globalState.ptiRecord.ptiId !== undefined) {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/" + globalState.ptiRecord.ptiId);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/");
            });
        }
    };

    const handleDocumentUrl = (val) => {
        setUrlErrorMessage("");
        if (fileInput.current.files[0].type === "application/pdf" || fileInput.current.files[0].type === "image/png"
            || fileInput.current.files[0].type === "image/jpeg" || fileInput.current.files[0].type === "image/jpg") {
            setSelectedFile(val);
        } else {
            fileInput.current.value = "";
            setUrlErrorMessage("Please upload only pdf file");
            setSelectedFile("");
        }
    };


    return <section className="home-section">
        <div className="dashboard">

  {/* ADD new documnet */}
  <div className="add-document">
                <div className="mb-3">
                    <h4 className="">Add New Document</h4>
                </div>
                <div className="add-document-box">
                    <div className="col-lg-6 col-lg-6 col-sm-12">
                        <div className="document-group form-group">
                            {/* docnument type */}
                            <label>Document Type</label>
                            <div className="select-box">
                                <select value={documentType} onChange={e => setDocumentType(e.target.value)}>
                                    <option value="">select</option>
                                    <option value="Invoice">Invoice</option>
                                    <option value="Packing List">Packing List</option>
                                    <option value="AWB (Airway Bill)">AWB (Airway Bill)</option>
                                    <option value="BOE (Bill Of Entry)">BOE (Bill Of Entry)</option>
                                    <option value="OOC (Out of Charge)">OOC (Out of Charge)</option>
                                    <option value="Payment Receipt">Payment Receipt</option>
                                    <option value="Others">Others</option>
                                </select>
                            </div>
                            {documentErrorMessage && <p className="text-warning"> {documentErrorMessage} </p>}
                        </div>
                        {/* document name */}
                        <div className="d-flex">
                            <div className="document-group form-group">
                                <label>Select File</label>
                                <div className="input-box date-box-pti">
                                    <input type="file" ref={fileInput} className="form-control" onChange={e => handleDocumentUrl(e.target.value)} />
                                </div>
                                {urlErrorMessage && <p className="text-warning"> {urlErrorMessage} </p>}
                            </div>
                        </div>
                        {/* document date */}
                        <div className="document-group form-group">
                            <label>Document Number</label>
                            <div className="input-box">
                                <input type="text" className="form-control" value={documentNo} onChange={e => setDocumentNo(e.target.value)} />
                            </div>
                        </div>
                    </div>
                    <div className="btn-footer text-right">
                        <button className="addbutton" disabled={uploadDisable} onClick={onSubmit} data-bs-toggle="tooltip" data-bs-placement="top" title="Upload">Upload <i className="fa fa-upload" aria-hidden="true"></i> </button>
                        <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-check-circle" aria-hidden="true"></i> </button>                        
                    </div>
                </div>
            </div>
            </div>

            <div className="dashboard mt-2">

            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                <div className="table-heading">
                <h4 className="">IOR Document</h4>
            </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                    <div className="sorting-num pr-2">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>&nbsp;
                        <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={IORDocument}
                    columnDefs={columnDef}
                    pagination={true}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>
            {/* <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div> */}

        </div>

    </section>
};
export default IORDocument;
