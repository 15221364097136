import React, { useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Topbar from "./Topbar";
import Sidebar from "./Sidebar";


const ProtectedLayout = (props) => {
  const { children } = props;
  const location = useLocation();

  const [sideBarToggle, setSideBarToggle] = useState(true);

  const toggleSideBar = () => {
    setSideBarToggle(!sideBarToggle);
    };
    const loginUser = window.localStorage.getItem("login-user");

    return loginUser === 'true' ? (
    <div className="main-wrapper main-wrapper-responsive-lg">
      <Topbar toggleSideBar={toggleSideBar} />
      <Sidebar expanded={sideBarToggle} />
      <main className="main-container container-fluid">{children}</main>
    </div>
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
};
export default ProtectedLayout;
