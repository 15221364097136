import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const HSCodesView = () => {

    const [hscodes, setHSCodes] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    useEffect(() => {
        const fetchHSCodes = async () => {
            try {                
                const requestURL = globalState.endPointURL + "/hscodes/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers
                });
                const data = await response.json();                
                // console.log(data);
                if(data !== null)
                    setHSCodes(data?.hscodeInfos[0]);
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        fetchHSCodes();
    }, []);

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/hscodes");
        });
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">HS Codes Details View</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="row mb-4">
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>HS Code Id</h5>
                        <p>{hscodes.codeId}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>HS Code</h5>
                        <p>{hscodes.hscode}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>4 digit code</h5>
                        <p>{hscodes.digitCode}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>Country</h5>
                        <p>{hscodes.country}</p>
                </div>
            </div>

           
            <div className="col-lg-12 col-md-12">
                <div className="detail-view">
                        <h5>Description </h5>
                        <p>{hscodes.description} </p>
                </div>
            </div>
        </div>
        <div className="text-right">
            <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to HS Codes <i className="bx bxs-check" aria-hidden="true"></i> </button>
        </div>
        </div>
    </section>
};
export default HSCodesView;
