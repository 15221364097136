import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const PtiStatusView = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [ptiStatus, setPtiStatus] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);

    useEffect(() => {
        const fetchPtiStatus = async () => {
            try {
                const requestURL = globalState.endPointURL + "/ptistatus/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers
                });
                const data = await response.json();                
                // console.log(data);
                if(data !== null)
                    setPtiStatus(data?.ptiStatusInfo[0]);
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        fetchPtiStatus();
    }, []);

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/pti-status");
        });
    };

    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">PTI Status Details View</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="row mb-4">
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>Status </h5>
                        <p>{ptiStatus.name}</p>
                </div>
            </div>
            
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                        <h5>Description </h5>
                        <p>{ptiStatus.description}</p>
                </div>
            </div>
        </div>
        <div className="text-right">
            <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to PTI Status <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
        </div>
        </div>
    </section>
};
export default PtiStatusView;
