import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";

const RoleView = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const [incoterms, setIncoterms] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);

    useEffect(() => {
        const fetchIncoterms = async () => {
            try { 
                const requestURL = globalState.endPointURL + "/incoterm/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',                       
                    headers:globalState.headers 
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null)
                    setIncoterms(responseData?.incotermInfo[0]);
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
       fetchIncoterms();
    }, []);
    
    const handleClose = () => {
        login().then(() => {            
            navigate(state?.path || "/masters/incoterm");
        });
    };

    return <section className="home-section">        
        <div className="dashboard">
            <div className="table-heading">
                <h4 className="w-50">Incoterms Details View</h4>                
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="row mb-4">
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Role </h5>
                    <p>{incoterms.name}</p>
                </div>
            </div>
            <div className="col-lg-6 col-md-6">
                <div className="detail-view">
                    <h5>Description </h5>
                    <p>{incoterms.description } </p>
                </div>
            </div>
            <div className="text-right">
                    <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Back">Go Back to Incoterms <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
        </div>
        </div>
       
       </div>   
    </section>
};
export default RoleView;
