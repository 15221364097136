/* eslint-disable no-undef */
import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const PtiRecords = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const gridRef = useRef();
    const [ptiRecordsDashBoard, setPtiRecordsDashBoard] = useState([]);
    let [ptiRecords, setPtiRecords] = useState([]);
    const [globalPtiRecords, setGlobalPtiRecords] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    let [globalsearchText, setglobalSearchText] = useState("");
    const [clientList, setClientList] = useState([]);
    const [IncotermList, setIncotermList] = useState([]);
    const [ptiStatusList, setPtiStatusList] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    let [textStatus, setText] = useState('Pending Approval');
    const [totalPages, setTotalPages] = useState(0);
    const [totalData, setTotalData] = useState(0);
    // const [st, setStartIndex] = useState(0);
    // const [en, setEndIndex] = useState(0);
    var incotermdata;

    const Rname = window.localStorage.getItem("user-role");
    //console.log(Rname);

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewPtiRecords(event.data.ptiId)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editPtiRecords(event.data.ptiId, event.data.ptiStatus)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deletePtiRecords(event.data.ptiId)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
                <button disabled={event.data.ptiStatus === "Assesed" || event.data.ptiStatus === "Duty Paid" || event.data.ptiStatus === "Custom Query" || event.data.ptiStatus === "Out of Charge" || event.data.ptiStatus === "Delivered" ? false : true} onClick={() => boe(event.data.ptiId, event.data.ptiStatus)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="BOE"> <i className="bx bx-bullseye" aria-hidden="true"></i></button>
                {Rname === "Admin" || Rname === "Operation Manager" ? <button disabled={event.data.ptiStatus === "Pending Approval" || event.data.ptiStatus === "Approved" ? false : true} onClick={() => addItemStatus(event.data.ptiId)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Item Status"><i className="bx bx-line-chart" aria-hidden="true"></i></button> : null}
                <button onClick={() => historyPTIRecords(event.data.ptiId)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="History"> <i className="bx bxs-time" aria-hidden="true"></i></button>
            </p>
        );
    };

    const domLayout = "autoHeight";

    const columnDef = [
        // { headerName: 'PTI Id', field: "ptiId", valueGetter: 'node.rowIndex+1', sortable: true, filter: true, minWidth: 50 },
        { headerName: 'PTI Id', field: "ptiId", sortable: true, filter: true, minWidth: 60 },
        { headerName: 'id', hide: true, field: "ptiId", sort: "desc", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Date', field: "invDate", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Clients', field: "exporter.exporterName", sortable: true, filter: true, minWidth: 190 },
        { headerName: 'Inv#', field: "invoiceNumber", sortable: true, filter: true, minWidth: 90 },
        { headerName: 'Inco Terms', field: "shipment.incotermName", sortable: true, filter: true, minWidth: 150 },
        { headerName: 'Waybills#', field: "shipment.awbBlNumber", sortable: true, filter: true, minWidth: 120 },
        { headerName: 'No. of Pkg', field: "shipment.numberOfPackage", sortable: true, filter: true, minWidth: 90 },
        { headerName: 'Status', field: "ptiStatusName", sortable: true, filter: true, minWidth: 150 },
        { headerName: 'Invoice Total', field: "invTotal", sortable: true, filter: true, minWidth: 90 },
        { headerName: 'Actions', "cellRenderer": actionButton, minWidth: 250 },
    ];

    // Incoterms fetch
    const fetchIncoterms = async () => {
        try {
            const requestURL = globalState.endPointURL + "/incoterm/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null) {
                let data = responseData.incotermInfo?.filter((incoterm) => { return incoterm.isActive === true })
                incotermdata = data;
                // console.log(incotermdata);
                setIncotermList(incotermdata);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    // useEffect(() => {
    //     fetchIncoterms();
    // }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //         await fetchIncoterms();
    //         onGridReady();
    //     };
    //     fetchData();
    // }, [currentPage, textStatus]);


    const onGridReady = async () => {
        loadAllData();
    };

    async function loadAllData(globalSearch) {
        try {
            await fetchIncoterms();
            setpaginationPageSize("10")
            const clientRequestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const clientResponse = await fetch(clientRequestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const clientResponseData = await clientResponse.json();
            // console.log(clientResponseData);
            if (clientResponseData != null) {
                clientResponseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let clientData = clientResponseData.clientInfo?.filter((client) => { return client.isActive === true })
                //console.log(clientData);
                //setClientList(clientData);

                const ptiRecord = {
                    "ptiId": "",
                    "exporterId": "",
                    "invNo": "",
                    "invDate": "",
                    "shipDate": "",
                    "ptiStatus": "",
                    "partStatus": "",
                    "shipperId": "",
                    "importerId": "",
                    "countryImp": "",
                    "consignee": "",
                    "countryExp": "",
                    "shipVia": "",
                    "incotermId": "",
                    "wayBillNo": "",
                    "modeTran": "",
                    "currency": "",
                    "grossWt": "",
                    "noPkg": "",
                    "shipmentItemDetails": [],
                    "iorDetails": []
                };
                globalState.ptiRecord = ptiRecord;

                const countResponse = await fetch(`${globalState.endPointURL}/pti/count?status=${textStatus}`);
                const countData = await countResponse.json();
                const totalCount = countData?.totalNoOfRecords;
                setTotalData(totalCount);

                const requestURL = globalState.endPointURL + "/pti/all";
                let pdata = {}
                if (globalSearch != null){
                    pdata = {
                        searchValue: globalSearch
                    };
                } else {
                    pdata = {
                        status: textStatus,
                        pageNo: null,
                    };
                }
                
                const response = await fetch(requestURL, {
                    method: 'POST',
                    body: JSON.stringify(pdata),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                console.log("responseData: ", responseData);
                if (responseData != null && responseData?.isSuccess) {

                    const dashBoard = {};
                    const ptiList = [];
                    responseData?.ptiStatus?.map((pti) => {

                        pti.ptiRecords.map((ptiRecord) => {
                            ptiRecord.invDate = ptiRecord.invDate === null || ptiRecord.invDate === "null" ? "" : ptiRecord.invDate;
                            ptiRecord.shipDate = ptiRecord.shipDate === null || ptiRecord.shipDate === "null" ? "" : ptiRecord.shipDate;
                            // console.log(clientData, "clientData");
                            clientData?.filter((item) => {
                                if (item.id === ptiRecord.exporter.exporterId)
                                    return ptiRecord.exporter.exporterName = item.name;
                            });
                            incotermdata?.filter((incoterm) => {
                                if (incoterm.id === ptiRecord.shipment.incoterm)
                                    return ptiRecord.shipment.incotermName = incoterm.name;
                            });

                            ptiRecord.ptiStatusName = ptiRecord.ptiStatus;
                            ptiList.push(ptiRecord);
                        })
                        let ptiStatusValue = "";
                        globalState.ptiStatus?.filter((item) => {
                            if (item.value === String(pti.ptiStatusId))
                                return ptiStatusValue = item.value;
                        });
                        switch (ptiStatusValue) {
                            case "Pending Approval":
                                dashBoard.pendingApproval = pti.count;
                                break;
                            case "Approved":
                                dashBoard.approved = pti.count;
                                break;
                            case "Shipped":
                                dashBoard.shipped = pti.count;
                                break;
                            case "CL Approval Pending":
                                dashBoard.checklistPending = pti.count;
                                break;
                            case "Checklist Approved":
                                dashBoard.checklistApproved = pti.count;
                                break;
                            case "Assesed":
                                dashBoard.assesed = pti.count;
                                break;
                            case "Duty Paid":
                                dashBoard.dutyPaid = pti.count;
                                break;
                            case "Custom Query":
                                dashBoard.customQuery = pti.count;
                                break;
                            case "Out of Charge":
                                dashBoard.outOfCharge = pti.count;
                                break;
                            case "Delivered":
                                dashBoard.delivered = pti.count;
                                break;
                            case "Billed":
                                dashBoard.billed = pti.count;
                                break;
                        }
                    });
                    // console.log(dashBoard);
                    // console.log(ptiList);
                    setPtiRecordsDashBoard(dashBoard);
                    let invTotal;
                    ptiList?.map((item) => {
                        invTotal = 0;
                        item.shipmentItem?.map((shipmentItem) => {
                            let amount = shipmentItem.itemDetails?.amount === undefined || shipmentItem.itemDetails?.amount === "" ? 0 : shipmentItem.itemDetails?.amount;
                            invTotal = invTotal + Number(amount)
                        });
                        item.invTotal = parseFloat(invTotal.toFixed(2));

                    });

                    setPtiRecords(ptiList);
                    setGlobalPtiRecords(ptiList);

                    // let text = "Pending Approval";
                    // let filterData = text === "" ? responseData.PTIRecordList : responseData?.PTIRecordList?.filter(item => { return item?.ptiStatus.status === text; });
                    // setPtiRecords(filterData);
                    // setGlobalPtiRecords(responseData.PTIRecordList);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(globalState.commonErrorMessage);
                    setErrorMessage('');
                } else
                    setErrorMessage(globalState.commonErrorMessage);
            } else if (!clientResponseData?.isSuccess && clientResponseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(clientResponseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    }

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    // const onFirstDataRendered = useCallback((params) => {

    //     gridRef.current.api.sizeColumnsToFit();

    //   }, []);

    // Delete with fetchAPI
    const deletePtiRecords = async (id) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result) {
            try {
                const requesturl = globalState.endPointURL + "/pti/" + id;
                const response = await fetch(requesturl, {
                    method: 'delete',
                    headers: globalState.headers,
                });
                const responsedata = await response.json();
                // console.log(responsedata);
                if (responsedata !== null && responsedata.isSuccess) {
                    let deleteRecord = ptiRecords.filter((item) => { return item.ptiId === id; });
                    let status = deleteRecord[0].ptiStatusName;

                    let text;
                    switch (status) {
                        case "Pending Approval":
                            text = "pendingApproval";
                            break;
                        case "Approved":
                            text = "approved";
                            break;
                        case "CL Approval Pending":
                            text = "checklistPending";
                            break;
                        case "Checklist Approved":
                            text = "checklistApproved";
                            break;
                        case "Assesed":
                            text = "assesed";
                            break;
                        case "Duty Paid":
                            text = "dutyPaid";
                            break;
                        case "PI Done":
                            text = "piDone";
                            break;
                        case "Custom Query":
                            text = "customQuery";
                            break;
                        case "Pending":
                            text = "pending";
                            break;
                        case "Out Of Charge":
                            text = "outOfCharge";
                            break;
                        case "In Progress":
                            text = "inProgress";
                            break;
                        case "Cancelled":
                            text = "cancelled";
                            break;
                        case "Shipped":
                            text = "shipped";
                            break;
                        default:
                            text = "";
                    }
                    if (text !== "")
                        ptiRecordsDashBoard[text] = ptiRecordsDashBoard[text] - 1;

                    setPtiRecords(ptiRecords.filter((item) => { return item.ptiId !== id; }));
                    setGlobalPtiRecords(ptiRecords.filter((item) => { return item.ptiId !== id; }));
                }
                else if (!responsedata?.issuccess && responsedata?.errorcodes?.errorcodes?.length > 0)
                    setErrorMessage(responsedata.errorcodes.errorcodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        }
    };

    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    const handleGlabalSearchText = async (event) => {
        setglobalSearchText(()=>{
            globalsearchText = event.target.value;
        });
       loadAllData(globalsearchText)
    }

    //Add 
    const addPtiRecords = async () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails");
        });
    };

    //history 
    const historyPTIRecords = async (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/History/" + id);
        });
    };


    // view 
    const viewPtiRecords = async (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecordsView/" + id);
        });
    }

    // Edit
    // const editPtiRecords = async (id) => {
    //     login().then(() => {
    //         navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/" + id);
    //     });
    // };


    // Add item status
    const addItemStatus = (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItemStatus/" + id);
        });
    };

    // BOE
    const boe = (id, status) => {
        if (status === "Delivered") {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/ViewBOE/" + id);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/BOE/" + id);
            });
        }
    };




    // PTIDelivered
    const editPtiRecords = (id, status) => {
        if (status === "Delivered") {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDelivery/" + id);
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordDetails/" + id);
            });
        }
    };

    const ptiStatus = [
        // { "name": "Select", "value": " " },
        { "name": "Pending Approval", "value": ptiRecordsDashBoard?.pendingApproval },
        { "name": "Approved", "value": ptiRecordsDashBoard?.approved },
        { "name": "Shipped", "value": ptiRecordsDashBoard?.shipped },
        { "name": "CL Approval Pending", "value": ptiRecordsDashBoard?.checklistPending },
        { "name": "Checklist Approved", "value": ptiRecordsDashBoard?.checklistApproved },
        { "name": "Assesed", "value": ptiRecordsDashBoard?.assesed },
        { "name": "Duty Paid", "value": ptiRecordsDashBoard?.dutyPaid },
        { "name": "Custom Query", "value": ptiRecordsDashBoard?.customQuery },
        { "name": "Out of Charge", "value": ptiRecordsDashBoard?.outOfCharge },
        { "name": "Delivered", "value": ptiRecordsDashBoard?.delivered },
        { "name": "Billed", "value": ptiRecordsDashBoard?.billed }
    ];


    const handlePart = async (text) => {
        setText(() =>{
            textStatus = text;
        });
        // let itemDetails = globalPtiRecords.slice();
        // let responseData = text === "" || text === "Select" ? itemDetails : itemDetails.filter(item => { return item?.ptiStatusName === text; });
        // setPtiRecords(responseData);
        onGridReady();
    }
    // const handleNextPage = () => {
    //     const nextPage = currentPage + 1;
    //     setCurrentPage(nextPage);
    //     onGridReady({ api: gridRef.current.api }, nextPage);
    // };

    // const handlePreviousPage = () => {
    //     const previousPage = currentPage - 1;
    //     setCurrentPage(previousPage);
    //     onGridReady({ api: gridRef.current.api }, previousPage);
    // };
    // useEffect(() => {
    //     onGridReady();
    // }, [currentPage, textStatus]);

    return <div className="dashboard">


        {/* filter buttons */}
        {/*<div className="filter-container row">
            {ptiStatus.map((items) => {
                return (
                    <div className="single-filter col-lg-2 col-md-3">
                        <button onClick={e => handlePart(items.name)}>{items.name}<span>{items.value === undefined ? 0 : items.value}</span></button>
                    </div>
                );
            })}
        </div> */}

        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="example-header row">
            <div className="col-lg-3 col-sm-12 ">
                <div className="table-heading">
                    <h4 className="">PTI Records</h4>
                </div>
            </div>

            <div className="col-lg-9 col-sm-12">
                <div className="search-add pti-search-add">
                    <div className="d-flex" >
                    <div className="sorting-num">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>

                        <label className="table-search">
                            <input type="text" name="partg" placeholder="Global Search" value={globalsearchText} onChange={handleGlabalSearchText} />
                            <i className="bx bx-search"></i>
                        </label>&nbsp;
                        {/* filter */}
                        <div className="filters-pti mr-2">
                            <div className="filter-select-box">
                                <select onChange={e => handlePart(e.target.value)}>
                                    <option value="">Select</option>
                                    {ptiStatus?.map((item) => {
                                        return <option value={item.name} >{item.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        {/* filter */}
                    </div>
                    <label className="table-search">
                        <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                        <i className="bx bx-search"></i>
                    </label>&nbsp;
                    <button className="addbutton" onClick={addPtiRecords} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>

                </div>
            </div>
        </div>
        <div className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                rowData={ptiRecords}
                columnDefs={columnDef}
                domLayout={domLayout}
                defaultColDef={{
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                    resizable: true,
                }}
                pagination={true}
                onGridReady={onGridReady}
                paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
            ></AgGridReact>
        </div>
    </div>
};

export default PtiRecords;
