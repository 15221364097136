import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import S3 from "react-aws-s3";
import axios from "axios";
 
window.Buffer = window.Buffer || require("buffer").Buffer;
 
 
const PTICertificateDetails = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { formState } = useForm();
    const { globalState } = React.useContext(GlobalContext);
    const [name, setName] = useState("");
    const [certificateTypeId, setCertificateTypeId] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [otherCert, setOtherCert] = useState("");
    const [url, setUrl] = useState("");
    const fileInput = React.useRef();
    const { errors } = formState;
    const [errorMessage, setErrorMessage] = useState("");
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [isManadatory, setIsManadatory] = useState(false);
    const [uploadDisable, setUploadDisable] = useState(false);
    const [uploadDocument,setUploadDocument] =useState("");
 
   
    useEffect(() => {
        const fetchCertificates = async () => {
            try {
                const responseData = globalState?.shipmentDetail?.certificates?.filter(item => { return String(item.certificate.id) === id; });
 
                if (responseData !== "") {
                    const data = responseData[0].certificate;
                    setName(data.name);
                    setExpiryDate(data.expiryDate);
                    setCertificateTypeId(data.certificateTypeId);
                    setUrl(data.url);
                    setIsManadatory(data.isManadatory);
                    setOtherCert(data.otherCert);
                } else
                setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        if (id !== undefined)
            fetchCertificates();
        // console.log(globalState.shipmentDetail);
    }, []);
 
    const handleUpload = async (file) => {      
        // let newFileName = globalState.shipmentDetail.partNo + "/" + certificateTypeId + "_" + name + "." + file?.name;
        // const ReactS3Client = new S3(globalState.s3BuscketConfig);
        // ReactS3Client.uploadFile(file, newFileName).then(data => {
            // console.log(data.location);
            const formData = new FormData();
            if (file) {
                const fileName = file.name;
                const modifiedFileName = fileName.replace(/\.(?=.*\.)/g, "_"); // removes extra dots from filename
                const modifiedFile = new File([file], modifiedFileName, { type: file.type });
                formData.append("file", modifiedFile);
            }
            const docResponse = await axios.post('http://139.59.40.225:5000/upload-single', formData,);
            setUploadDocument(docResponse)
 
            const createState = {
                certificateTypeId: certificateTypeId === undefined ? "" : certificateTypeId,
                expiryDate: expiryDate === undefined ? "" : expiryDate,
                name: name === undefined ? "" : name,
                url: docResponse&&docResponse.data&&docResponse.data.data&&docResponse.data.data.location,
                otherCert: otherCert === undefined ? "" : otherCert,
                isManadatory: isManadatory === undefined ? "" : isManadatory
            };
            handleSave(createState);
        // }).catch(err => console.error(err));
    };
   
 
    const onSubmit = (data) => {
        setUploadDisable(true);
        let existCertificate = undefined;
        globalState.shipmentDetail.certificates.map((item) => {
            if (String(item.certificateTypeId) === String(certificateTypeId)) {
                if (certificateTypeId === "Others") {
                    if (item.otherCert === undefined) {
                        item.otherCert = "";
                    }
                    if (item.otherCert === otherCert)
                        return existCertificate = item.certificateTypeId;
                } else
                    return existCertificate = item.certificateTypeId;
            }                
        });
        if (globalState.shipmentDetail.certificates.length < 0 && existCertificate == undefined) {
            setErrorMessage("Already created a certificate with certificate type name");
            setUploadDisable(false);
            return false;
        } else {
            setUrlErrorMessage("");
            let newArr = fileInput.current.files;
            if (newArr.length > 0) {
                for (let i = 0; i < newArr.length; i++) {
                    handleUpload(newArr[i]);
                }
            } else {
                const createState = {
                    certificateTypeId: certificateTypeId === undefined ? "" : certificateTypeId,
                    expiryDate: expiryDate === undefined ? "" : expiryDate,
                    name: name === undefined ? "" : name,
                    url: uploadDocument&&uploadDocument.data&&uploadDocument.data.data&&uploadDocument.data.data.location,
                    otherCert: otherCert === undefined ? "" : otherCert,
                    isManadatory: isManadatory === undefined ? "" : isManadatory
                };
                handleSave(createState);
            }
        }        
    };
 
    const handleSave = async (data) => {
        try {
            const requestURL = globalState.endPointURL + "/certificate";
            let methodType = "";
            if (id === undefined)
                methodType = "POST";
            else {
                methodType = "PUT";
                data.id = id;
            }
            // console.log(JSON.stringify(data));
 
            let response = await fetch(requestURL, {
                method: methodType,
                body: JSON.stringify(data),
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData?.isSuccess) {
                const certificateInfo = responseData?.itemCertificate[0];
                const certificate = {
                    id: certificateInfo?.id,
                    certificateTypeId: certificateInfo?.certificateTypeId,                    
                    expiryDate: certificateInfo?.expiryDate === "null" ? "" : certificateInfo?.expiryDate,
                    certificateId: certificateInfo?.id,
                    isActive: certificateInfo?.isActive,
                    isDeleted: certificateInfo?.isDeleted,
                    certificateName: certificateInfo?.name,
                    url: certificateInfo?.url,
                    isManadatory: certificateInfo?.isManadatory,
                    otherCert: certificateInfo?.otherCert                    
                };
                globalState?.shipmentDetail?.certificates?.push(certificate);
                handleClose();
            } else if (responseData?.isSuccess === null && responseData?.errorCodes?.errorCodes?.length > 0) {
                errors.message = responseData.errorCodes.errorCodes[0].message;
                setErrorMessage("Please select Certificate / Licence Type");
                setUploadDisable(false);
            } else {
                   setErrorMessage("Please select Certificate / Licence Type");
                setUploadDisable(false);
            }
        } catch (e) {    setErrorMessage("Please select Certificate / Licence Type");
         setUploadDisable(false);
        //   console.log(e);
     }
 
    };
 
    const handleClose = () => {
        // console.log(globalState?.shipmentDetail);
        const shipmentId = globalState?.shipmentDetail?.shipmentId;
        if (shipmentId === undefined) {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItem/");
            });
        } else {
            login().then(() => {
                navigate(state?.path || "/shipments/PTIRecords/PTIRecordShipmentItem/" + shipmentId);
            });
        }
    };
 
 
    const handleExpiryDate = (date) => {
        setExpiryDate(date);
    }
 
    const handleUrl = (val) => {
        setUrlErrorMessage("");        
        if (fileInput.current.files[0].type === "application/pdf" || fileInput.current.files[0].type === "image/png"
            || fileInput.current.files[0].type === "image/jpeg" || fileInput.current.files[0].type === "image/jpg") {
            setUrl(val);
        } else {
            setUrlErrorMessage("Please upload image or pdf file");
            setUrl("");
        }
    };
 
    return <section className="home-section">
        <div className="dashboard">
            <div className="table-heading">
                <h4 >Certificate</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}
            <form action="" method="">
                <div className="row  mb-4">
 
                    <div className="col-lg-6 col-md-6">
                        <label>Certificate #</label>
                        <div className="input-box">
                            <input type="text" className="form-control" name="" placeholder="" value={name} onChange={e => setName(e.target.value)} />
                        </div>
                    </div>
 
 
                    <div className="col-lg-6 col-md-6">
                        <label>Expiry Date</label>
                        <div className="date-box">
                            <input type="date" className="form-control" name="expiryDate" placeholder="" value={expiryDate} onChange={e => handleExpiryDate(e.target.value)} />
                        </div>
                    </div>
 
                    <div className="col-lg-6 col-md-6">
                        <label>Certificate / Licence Type</label>
                        <div className="select-box" name="certificateTypeId">
                            <select value={certificateTypeId} onChange={e => setCertificateTypeId(e.target.value)}>
                                <option value="">Select</option>
                                <option value="BIS">BIS</option>
                                <option value="ETA">ETA</option>
                                <option value="Import License">Import License</option>
                                <option value="EPR">EPR</option>
                                <option value="Others">Others</option>
                            </select>
                        </div>
                    </div>
 
                    {certificateTypeId === "Others" ?
                        <div className="col-lg-6 col-md-6">
                            <label>Other Certificate Type</label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="expiryDate" placeholder="" value={otherCert} onChange={e => setOtherCert(e.target.value)} />
                            </div>
                        </div> : ""}
 
                    <div className="col-lg-6 col-md-6">
                        <label>Certificate file</label>
                        <div className="input-box">
                            <input type="file" className="form-control" ref={fileInput} name="url" placeholder="" value={url}
                                onChange={e => { handleUrl(e.target.value);}} />                                
                        </div>
                        {urlErrorMessage && <p className="text-warning"> {urlErrorMessage} </p>}
                    </div>
                   
                    <div className="col-lg-12 col-sm-12">
                        <div className="custom-control custom-switch">
                            <input type="checkbox" className="custom-control-input" id="customSwitches" name="isSerialized"
                                checked={isManadatory} onChange={e => { setIsManadatory(!isManadatory); }} />
                            <label className="custom-control-label" for="customSwitches">Is Mandatory</label>
                        </div>
                    </div>
                   
 
                </div>
                <div className="btn-footer text-right">
                    <button type="button" disabled={ uploadDisable } onClick={onSubmit} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Upload">Upload <i className="fa fa-upload" aria-hidden="true"></i></button>
                    <button type="button" onClick={handleClose} className="btn btn-secondary" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                </div>
            </form>
        </div>
    </section>
};
export default PTICertificateDetails;
 
 