import React, {  useState, useCallback ,useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const Clients = () => {
    const [clients, setClients] = useState([]);
    const navigate = useNavigate();
    const gridRef = useRef();
    const { login } = useAuth();
    const { state } = useLocation();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewClient(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editClient(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deleteClient(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            </p>
        );
    };

    const domLayout = "autoHeight";

    const columnDef = [
        { headerName: '#', field: "id", sort: 'asc', valueGetter: 'node.rowIndex+1', maxWidth:80 },
        { headerName: 'Client Name', field: "name" },
        { headerName: 'City', field: "address.city" },
        { headerName: 'State', field: "address.state" },
        { headerName: 'Country', field: "address.country" },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            // console.log(responseData);
            if (responseData != null)
                setClients(responseData.clientInfo);
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.sizeColumnsToFit();
      }, []);


    // Delete with fetchAPI
    const deleteClient = async (id) => {
        const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
        if (result){
            try {
                const requestURL = globalState.endPointURL + "/client/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'DELETE',
                    headers: globalState.headers,
                });
                const responseData = await response.json();

                if (responseData !== "" && responseData.isSuccess) {
                    setClients(clients.filter((client) => { return client.id !== id; }));
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    //setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                    setErrorMessage(globalState.commonErrorMessage);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            //  console.log(e);
             }
    }
    };
    
    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    //Add Client
    const addClient = () => {
        login().then(() => {
            navigate(state?.path || "/masters/clients/client-details");
        });
    };

    //Redirect to dash board
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/dashboard");
        });
    };

    //View Client
    const viewClient = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/clients/client-view/" + id);
        });
    };

    //Edit Client
    const editClient = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/clients/client-details/" + id);
        });
    };

    return <section className="home-section">        
        <div className="dashboard">
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}         
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                <div className="table-heading">
                <h4 className="w-50">Clients</h4>
            </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                    <div className="sorting-num mr-2">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                        &nbsp;
                    <button className="addbutton" onClick={addClient} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine">
                <AgGridReact
                    ref={gridRef}
                    rowData={clients}
                    columnDefs={columnDef}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        filter: true,
                        resizable: true,
                    }}
                    pagination={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div> 
        
            <div className="text-right">
                <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
            </div>
        </div>
    </section>
};
export default Clients;
