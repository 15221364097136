import React, { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { useForm } from "react-hook-form";
import csc from "country-state-city";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as Yup from "yup";
import { confirm } from "react-confirm-box";
import { useNavigatingAway } from "../../../hooks/useNavigatingAway";
import { DialogLeavingPage } from "../../../components/DialogLeavingPage";

const HSCodesDetails = () => {
    const [hscodes, setHSCodes] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [hsCodeId, setHsCodeId] = useState("");
    const [hsCode, setHsCode] = useState("");
    const [active, setActive] = useState(true);
    const [category, setCategory] = useState("");
    const [country, setCountry] = useState("");
    const [description, setDescription] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [hsCodeErrorMessage, setHsCodeErrorMessage] = useState("");
    const [codeIdErrorMessage, setCodeIdErrorMessage] = useState("");
    const [digitCodeErrorMessage, setDigitCodeErrorMessage] = useState("");
    const [countryCodeErrorMessage, setCountryCodeErrorMessage] = useState("");
    const [uploadDisable, setUploadDisable] = useState(false);
    const countries = csc.getAllCountries();
    const updatedCountries = countries.map((country) => ({ label: country.name, value: country.id, ...country }));
    // const updatedStates = (countryId) => csc.getStatesOfCountry(countryId).map((state) => ({ label: state.name, value: state.id, ...state }));
    //const updatedCities = (stateId) => csc.getCitiesOfState(stateId).map((city) => ({ label: city.name, value: city.id, ...city }));
    const [canShowDialogLeavingPage, setCanShowDialogLeavingPage] = useState(false);
    const [showDialogLeavingPage,confirmNavigation,cancelNavigation] = useNavigatingAway(canShowDialogLeavingPage);

    const { handleSubmit, clearErrors } = useForm();

    useEffect(() => {
        const fetchHSCodes = async () => {
            try {
                const requestURL = globalState.endPointURL + "/hscodes/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);
                if (data !== "") {
                    const responseData = data?.hscodeInfos[0];
                    setHsCodeId(responseData?.codeId);
                    setHsCode(responseData?.hscode);
                    setCategory(responseData?.digitCode);
                    setCountry(responseData?.country);
                    setDescription(responseData?.description);
                    setActive(responseData?.isActive);
                }
                else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        const fetchHSCodesExit = async () => {
            try {
                const requestURL = globalState.endPointURL + "/hscodes/all";
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const data = await response.json();
                // console.log(data);
                if (data !== null) {
                    data.hscodeInfos?.map((hsCode) => {
                        hsCode.isActive = hsCode.isActive ? "Yes" : "No";
                    });
                    setHSCodes(data.hscodeInfos);

                } else if (!data?.isSuccess && data?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(data.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }

        };
        fetchHSCodesExit();
        if (id !== undefined)
            fetchHSCodes();
    }, []);

    const validateForm = () => {
        let returnVal = true;
        if (hsCode === undefined || hsCode === null || hsCode === '') {
            setHsCodeErrorMessage("Please enter HS code");
            setUploadDisable(false);
            returnVal = false;
        }
        if (hsCodeId === undefined || hsCodeId === null || hsCodeId === '') {
            setCodeIdErrorMessage("Please enter HS code Id");
            setUploadDisable(false);
            returnVal = false;
        }
        if (category === undefined || category === null || category === '') {
            setDigitCodeErrorMessage("Please enter digitCode");
            setUploadDisable(false);
            returnVal = false;
        }
        /*if (country === undefined || country === null || country === '') {
            setCountryCodeErrorMessage("Please enter country");
            returnVal = false;
        }*/
        return returnVal;
    };

    const onSubmit = (data) => {
        setUploadDisable(true);
        clearErrors();

        setErrorMessage("");
        let hsCodeExist = false;
        hscodes?.map((hsCode) => {
            if (hsCode.code === hsCode) {
                hsCodeExist = true;
            }
        });
        if (id === undefined && hsCodeExist) {
            setErrorMessage("HsCode already exist");
            setUploadDisable(false);
        } else if (validateForm()) {
            const createState = {
                codeId: hsCodeId,
                code: hsCode,
                digitCode: category,
                country: country,
                description: description,
                isActive: active
            };
            handleSave(createState);
            setCanShowDialogLeavingPage(false);
        }
    };

    const handleCategory = (val) => {
        const re = /^[0-9\b]+$/;
        if (val === '' || re.test(val)) {
            setCategory(val);
        }
        setDigitCodeErrorMessage("");
    };

    const handleHsCode = (val) => {
        const re = /^[0-9\b]+$/;
        if (val === '' || re.test(val)) {
            setHsCode(val);
        }
        setHsCodeErrorMessage("");
    };

    const handleHsCodeId = (val) => {
        const re = /^[0-9\b]+$/;
        if (val === '' || re.test(val)) {
            setHsCodeId(val);
        }
        setCodeIdErrorMessage("");
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/hscodes";
                let methodType = "";
                if (id === undefined)
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.id = id;
                }
                // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData !== null && responseData?.isSuccess)
                    handleClose();
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
        finally {
            setUploadDisable(false);
        }
    };


    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/hscodes");
        });
    };

    return(<><DialogLeavingPage
        showDialog={showDialogLeavingPage}
        setShowDialog={setCanShowDialogLeavingPage}
        confirmNavigation={confirmNavigation}
        cancelNavigation={cancelNavigation} /><section className="home-section">
            <div className="dashboard">
                <div className="table-heading">
                    <h4 className="w-50">HS Code Details</h4>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                    {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                    <div className="row  mb-4">

                        <div className="col-lg-6 col-md-6">
                            <label>Hs Code Id
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" readOnly={id === undefined ? false : true} name="hsCodeId" placeholder="" value={hsCodeId}
                                    onChange={e => { handleHsCodeId(e.target.value); setCanShowDialogLeavingPage(true);  } } />
                                <div className="text-warning">{codeIdErrorMessage}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <label>Chapter
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="hsCode" placeholder="" maxLength="2" value={hsCode}
                                    onChange={e => { handleHsCode(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{hsCodeErrorMessage}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <label>Hs Code
                            </label>
                            <div className="input-box">
                                <input type="text" className="form-control" name="category" placeholder="" maxLength="8" value={category}
                                    onChange={e => { handleCategory(e.target.value); setCanShowDialogLeavingPage(true); } } />
                                <div className="text-warning">{digitCodeErrorMessage}</div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                            <label>Country
                            </label>
                            <div className="select-box">
                                <select name="country" value={country} onChange={e => { setCountry(e.target.value); setCountryCodeErrorMessage(""); setCanShowDialogLeavingPage(true); } }>
                                    <option value="">Select</option>
                                    {updatedCountries.map((country) => {
                                        return <option value={country.name}>{country.name}</option>;
                                    })}
                                </select>
                            </div>
                            <div className="text-warning">{countryCodeErrorMessage}</div>
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <label>Description
                            </label>
                            <div className="input-box">
                                <textarea type="text" className="form-control" maxLength="256" name="description" placeholder="" value={description} onChange={e => {setDescription(e.target.value); setCanShowDialogLeavingPage(true);}} row="7">
                                </textarea>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" id="customSwitches" checked={active} onChange={e => {setActive(!active); setCanShowDialogLeavingPage(true);}} />
                                <label className="custom-control-label" for="customSwitches">Is Active
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="btn-footer text-right">
                        {id === undefined ? <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                            : <button type="submit" disabled={uploadDisable} className="btn btn-primary" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Update">Update <i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>}
                        <button type="button" onClick={handleClose} className="btn btn-secondary" data-dismiss="modal" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i>
                        </button>
                    </div>
                </form>
            </div>
        </section></>
    )
};
export default HSCodesDetails;
