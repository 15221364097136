import React, { useState, useCallback, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const ExportReport = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const gridRef = useRef();
    const [exportRecords, setExportRecords] = useState([]);
    const [globalExportRecords, setGlobalExportRecords] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [searchText, setSearchText] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [reason, setReason] = useState("");

   const deletePopup = {
        message: "Are you sure? Want to cancel the EOR",
        options: {
            labels: {
                confirmable: "Confirm",
                cancellable: "Cancel"
            }
        }
    }

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewExportRecords(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button disabled={(event.data.status === "Cancelled" ) ? true : false} onClick={() => editExportRecords(event.data.id,event.data.status)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button disabled={event.data.status === "Cancelled" || event.data.status === "Billed" ? true : false} onClick={() => deleteExportRecords(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
                <button onClick={() => historyExportRecords(event.data.exportId)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="History"> <i className="bx bxs-time" aria-hidden="true"></i></button>
            </p>
        );
    };

    const domLayout = "autoHeight";

    //history 
      const historyExportRecords = async (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/Historyeor/" + id);
        });
    };

    const updateExportRecords = {
        "exportId": "",
        "reference": "",
        "status": "",
        "shipDate": "",
        "clientName": "",
        "invoiceNo": "",
        "exporterDetails": {
            "countryOfExport": "",
            "exporter": "",
            "shipper": "",
        },
        "importerDetails": {
            "countryOfDestination": "",
            "importer": "",
            "shippedTo": "",
        },
        "shipmentDetails": {
            "awbBillNumber": "",
            "currency": "",
            "grossWeight": "",
            "incoterms": "",
            "modeOfTransport": "",
            "noOfPackages": "",
            "shipVia": "",
            "grWavierNo": "",
            "grWavierDate": "",
            "grWavierRequired": true,
            "shippingBillNo": "",
            "shippingBillDate": "",
            "isScometApprovalNeeded": false,
        },
        "id": "",
        "shipmentItems": [],
        "documents": [],
        "remarks": []
    };

    const columnDef = [
       /* { headerName: '#', valueGetter: 'node.rowIndex+1', sortable: true, filter: true, minWidth: 40 },
       */ { headerName: 'EOR Id',  field: "id", sort: "desc", sortable: true, filter: true, minWidth: 80 },
        { headerName: 'Date', field: "orderCreatedDate", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Client', field: "clientName", sortable: true, filter: true, minWidth: 180 },
        { headerName: 'Inv#', field: "invoiceNo", sortable: true, filter: true, minWidth: 120 },
        { headerName: 'Waybill#', field: "shipmentDetails.awbBillNumber", sortable: true, filter: true, minWidth: 120 },
        { headerName: 'No. of Pkgs', field: "shipmentDetails.noOfPackages", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Gross Wt.', field: "shipmentDetails.grossWeight", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Exported', field: "isExported", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Status', field: "status", sortable: true, filter: true, minWidth: 150 },
        { headerName: 'Invoice Total', field: "invoiceTotal", sortable: true, filter: true, minWidth: 100 },
        { headerName: 'Actions', "cellRenderer": actionButton, minWidth: 200 },
    ];

    const onGridReady = async () => {
        try {
            globalState.exportRecords = updateExportRecords;
            const requestURL = globalState.endPointURL + "/eor/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
           // console.log(responseData);
            if (responseData != null && responseData?.isSuccess) {
                const data = responseData?.eorInfo?.map((item) => {
                    if (item.status === "Delivered" || item.status === "Billed")
                        item.isExported = "Yes";
                    else
                        item.isExported = "No";
                    let invoiceTotal = 0;
                   // console.log("item: " + item.shipmentItems);
                    item.shipmentItems?.map((shiment) => {
                        shiment.amount = shiment.amount === "" || shiment.amount === "null" ? 0 : shiment.amount;
                        invoiceTotal = invoiceTotal + parseInt(shiment.amount);
                    });
                    item.invoiceTotal = invoiceTotal;
                    return item;
                });
                setExportRecords(data);
                setGlobalExportRecords(data);
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                setErrorMessage(globalState.commonErrorMessage);
            } else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
          //  console.log(e);
        }
    };

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    const addExportRecords = async () => {
        login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDetail");
        });
    };


    // view 
    const viewExportRecords = async (id) => {
        login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecordView/" + id);
        });
    }

    // //Edit
    // const editExportRecords = async (id) => {
    //     login().then(() => {
    //         navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDetail/" + id);
    //     });
    // };

    //Redirect to dash board
    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/dashboard");
        });
    };

    // delete
    const deleteExportRecords = async (id) => {
        const result = await confirm(deletePopup.message, deletePopup.options);
        if (result) {
            const result2 = await prompt("Please enter the Reason:");
            if (result2) {
                const data = { "status": "Cancelled", "id": id };
                try {
                    let requestURL = globalState.endPointURL + "/eor/";
                    let methodType = "PUT";
                   // console.log(JSON.stringify(data));
                    const headersUserName = window.localStorage.getItem("headers-username");
                    globalState.headers.userName = headersUserName;
                    let response = await fetch(requestURL, {
                        method: methodType,
                        body: JSON.stringify(data),
                        headers: globalState.headers,
                    });
                    const responseData = await response.json();
                   // console.log(responseData);
                    if (responseData !== null && responseData.isSuccess) {
                        setExportRecords(exportRecords.map((item) => {
                            if (item.id === id) {
                                item.status = "Cancelled";
                            }
                            return item;
                        }));
                        setGlobalExportRecords(exportRecords.map((item) => {
                            if (item.id === id) {
                                item.status = "Cancelled";
                            }
                            return item;
                        }));
                        const remarksData = {
                            "eorId": id,
                            "remarks": [
                                {
                                    "message": result2
                                }
                            ]
                        };
                        try {
                            let requestURL = globalState.endPointURL;
                            requestURL = requestURL + "/eor/remarks/";
                            let methodType = "POST";
                           // console.log(JSON.stringify(remarksData));
                            globalState.headers.userName = headersUserName;
                            let response = await fetch(requestURL, {
                                method: methodType,
                                body: JSON.stringify(remarksData),
                                headers: globalState.headers,
                            });
                            const responseData = await response.json();
                           // console.log(responseData);
                            if (responseData !== null && responseData.isSuccess) {
                                //Nothing to do
                            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0) {
                                // setErrorMessage("Failed to save the data.");
                                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                            }

                        } catch (e) {
                            setErrorMessage(globalState.commonErrorMessage);
                            // console.log("Error" + e); 
                        }
                    }
                    else if (!responseData?.issuccess && responseData?.errorcodes?.errorcodes?.length > 0)
                        setErrorMessage(responseData.errorcodes.errorcodes[0].message);
                    else
                        setErrorMessage(globalState.commonErrorMessage);

                } catch (e) {
                    setErrorMessage(globalState.commonErrorMessage);
                    // console.log(e);
                }
            }
        }       
    }

    const status = [
        { "name": "Select", "value": "Select" },
        { "name": "Order Created", "value": globalState.mockDataDetails.dashboard.OrderCreated },
        { "name": "Cancelled", "value": globalState.mockDataDetails.dashboard.OrderCreated },
        { "name": "Approved", "value": globalState.mockDataDetails.dashboard.Approved },
        { "name": "GRW Applied", "value": globalState.mockDataDetails.dashboard.GRWApplied },
        { "name": "GRW Approved", "value": globalState.mockDataDetails.dashboard.GRWApproved },
        { "name": "GRW Rejected", "value": globalState.mockDataDetails.dashboard.GRWRejected },
        { "name": "AWB Requested", "value": globalState.mockDataDetails.dashboard.AWBRequested },
        { "name": "AWB Genereated", "value": globalState.mockDataDetails.dashboard.AWBGenereated },
        { "name": "Document Uploaded", "value": globalState.mockDataDetails.dashboard.DocumentUploaded },
        { "name": "Shipment Picked", "value": globalState.mockDataDetails.dashboard.ShipmentPicked },
        { "name": "Shipping Bill Filed CHA", "value": globalState.mockDataDetails.dashboard.ShippingBillFiled },
        { "name": "Shipping Checklist Approved", "value": globalState.mockDataDetails.dashboard.ShippingChecklistApproved },
        { "name": "CI Complete", "value": globalState.mockDataDetails.dashboard.CIComplete },
        { "name": "LEO Released", "value": globalState.mockDataDetails.dashboard.LEOReleased },
        { "name": "Delivered", "value": globalState.mockDataDetails.dashboard.Delivered },
        { "name": "Billed", "value": globalState.mockDataDetails.dashboard.Billed }
    ];

    const handlePart = (text) => {
        //let text = partId.target.innerText;
        //text = text.substr(0, text.indexOf('\n'));
       // console.log(text);
        let itemDetails = globalExportRecords.slice();
        let responseData = text === "" || text === "Select" ? itemDetails : itemDetails.filter(item => { return item?.status === text; });
        setExportRecords(responseData);
    }

    
       // editExportRecords
       const editExportRecords = (id, status) => {
        if (status === "Delivered" || status === "Billed") {
         login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDelivery/" + id);
         });
         } else {
         login().then(() => {
            navigate(state?.path || "/shipments/ExportRecords/ExportRecordsDetail/" + id);
         });
         }
         };


    return <div className="dashboard">

        {errorMessage && <p className="text-warning"> {errorMessage} </p>}
        <div className="example-header row">
            <div className="col-lg-4 col-sm-12 ">
                <div className="table-heading">
                    <h4 className="">Export Records</h4>
                </div>
            </div>

            <div className="col-lg-8 col-sm-12">
                <div className="search-add pti-search-add">
                <div className="d-flex" >
                    <div className="sorting-num">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    &nbsp;
                    {/* filter */}
                    <div className="filters-pti">
                        <div className="filter-select-box">
                            <select onChange={e => handlePart(e.target.value)}>
                                {status?.map((datas) => {
                                    return <option value={datas.name} >{datas.name}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    </div>
                    &nbsp;

                    <label className="table-search">
                        <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                        <i className="bx bx-search"></i>
                    </label>
                    &nbsp;
                    <button className="addbutton" onClick={addExportRecords}>Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>

                </div>
            </div>
        </div>
        <div className="ag-theme-alpine" >
            <AgGridReact
                ref={gridRef}
                rowData={exportRecords}
                columnDefs={columnDef}
                domLayout={domLayout}
                defaultColDef={{
                    sortable: true,
                    flex: 1,
                    minWidth: 100,
                    filter: true,
                    resizable: true,
                }}
                pagination={true}
                onGridReady={onGridReady}
                paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
            ></AgGridReact>
        </div>
        <div className="text-right">
            <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
        </div>

    </div>
};

export default ExportReport;