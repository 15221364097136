import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../assets/css/components/reset-password.scss";
import useAuth from "../../hooks/useAuth";
import resetPasswordIcon from "../../assets/images/Trimmed.jpg";

const ResetPassword = () => {
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .required("Please enter a new valid password")
            .matches(
                /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
        ),
        conformpassword: Yup.string()
            .test('passwords-match', 'Passwords must match', function (value) {
                return this.parent.password === value
            }
        ),
    });
    const formOptions = { mode: "all", resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
       // console.log(JSON.stringify(data, null, 4));
        handleResetPassword(data);
    };

    const handleResetPassword = async (data) => {
        let response = await fetch('https://jsonplaceholder.typicode.com/posts', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        });
        let responseData = await response.json();
        //console.log(responseData);
        /*if (responseData.email === data.email) {*/
            login().then(() => {
                reset();
                navigate(state?.path || "/login");
            });
        //}
    };

    const handleCancel = () => {
        navigate(state?.path || "/login");
    };

    return (
        <section className="login-container">
            <div className="container_fluid h-100">
                <div className="row p-0 m-0">
                    <div className="col-sm-4 m-auto">
                        <div className="login-card">
                            <div className="login-logo">
                                <img src={resetPasswordIcon} alt="send-request" className="img-fluid" />
                            </div>
                            <div className="user-details">
                                <p>Hi Rajesh,</p>
                                <p>Reset Your Password</p>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>                                
                                <div className="form-group">
                                    <label for="">New password</label>
                                    <div className="input-icon">
                                        <i className="bx bxs-lock" ></i>
                                    </div>                                    
                                    <input type="password" className="form-control" placeholder="New password" {...register("password")} />
                                    <div classNameName="text-warning">{errors.password?.message}</div>
                                </div>
                                
                                <div className="form-group">
                                    <label for="">Confirm Password</label>
                                    <div className="input-icon">
                                        <i className="bx bxs-lock" aria-hidden="true"></i>
                                    </div>                                    
                                    <input type="password" className="form-control" placeholder="Confirm new password" {...register("conformpassword")} />
                                    <div classNameName="text-warning">{errors.conformpassword?.message}</div>
                                </div>
                                
                                <ul className="nav reset-nav">
                                    <li className="nav-item">
                                        <button type="submit" classNameName="login-btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Reset">
                                            Reset <i className="bx bxs-no-entry" ></i>
                                        </button>                                        
                                    </li>
                                    <li className="nav-item">
                                        <button onClick={ handleCancel} classNameName="login-btn" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">
                                            Cancel <i className="fa fa-times-circle"></i>
                                        </button>
                                   </li>
                                </ul>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
  
};

export default ResetPassword;
