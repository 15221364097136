import React, { useRef, useState, useCallback, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react';
import { GlobalContext } from "../../../GlobalContext";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import S3 from "react-aws-s3";
import NumericCellEditor from './NumericCellEditor';
import { confirm } from "react-confirm-box";

const BOE = () => {
    const gridRef = useRef();
    const regExpFloat = /^\d*(?:[.,]\d*)?$/;
    const [uploadDisable, setUploadDisable] = useState(false);
    const [boeData, setBoeData] = useState([]);
    const [exchange, setExchange] = useState("");
    const [awb, setAwb] = useState("");
    const [ooc, setOoc] = useState("");
    const [boeInfo, setBoeInfo] = useState([]);
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    const [globalBEO, setGlobalBEO] = useState([]);
    const { globalState } = React.useContext(GlobalContext);
    const { id } = useParams();
    const [url, setUrl] = useState("");
    const [cha, setCha] = useState("");
    const [beDate, setBeDate] = useState("");
    const fileInput = React.useRef();
    const [portcode, setportCode] = useState("");
    const [beNo, setbeNo] = useState("");
    const [bcd, setbcd] = useState(0);
    const [acd, setacd] = useState(0);
    const [sws, setsws] = useState(0);
    const [nccd, setnccd] = useState(0);
    const [sad, setsad] = useState(0);
    const [sg, setsg] = useState(0);
    const [add, setadd] = useState(0);
    const [cvd, setcvd] = useState(0);
    const [fine, setfine] = useState(0);
    const [pnlty, setpnlty] = useState(0);
    const [totalDuty, settotalDuty] = useState(0);
    const [igst, setigst] = useState(0);
    const [tValue, setTValue] = useState(0);
    const [int, setint] = useState(0);
    const [cess, setcess] = useState(0);
    
    const [totalDutyAmount, setTotalDutyAmount] = useState(0);
    const [assessed, setassessed] = useState(0);
    const [urlErrorMessage, setUrlErrorMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const { login } = useAuth();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [boeId, setBoeId] = useState("");
    const [clientList, setClientList] = useState([]);
    var clientData;



    const formingParamsData = (params) => {
        let data = params.data;
        data.assess = data.assess === undefined ? parseFloat(0) : parseFloat(data.assess);
        data.bcdPercent = data.bcdPercent === undefined ? parseFloat(10) : parseFloat(data.bcdPercent);
        data.bcd = parseFloat(((data.assess * data.bcdPercent) / 100));
        data.sws = parseFloat((data.bcd * 0.10));
        data.acdPercent = data.acdPercent === undefined ? parseFloat(0) : parseFloat(data.acdPercent);
        data.acd = data.acdPercent === 0 ? 0 : parseFloat(((data.assess * data.acdPercent) / 100));
        data.sad = parseFloat((data.acd * 0.10));
        data.igstPercent = data.igstPercent === undefined ? parseFloat(18) : parseFloat(data.igstPercent);
        data.igst = parseFloat((((data.bcd + data.acd + data.sws + data.sad + data.assess) * data.igstPercent) / 100));
        data.cess = data.cess === undefined ? parseFloat(0) : parseFloat(data.cess);
        data.itcHsCode = data.itcHsCode === undefined ? parseFloat(0) : parseFloat(data.itcHsCode);
        data.add = data.add === undefined ? parseFloat(0) : parseFloat(data.add);
        data.cvd = data.cvd === undefined ? parseFloat(0) : parseFloat(data.cvd);
        data.sg = data.sg === undefined ? parseFloat(0) : parseFloat(data.sg);
        data.tValue = data.tValue === undefined ? parseFloat(0) : parseFloat(data.tValue);
        data.tDuty = parseFloat(data.bcd + data.acd + data.sws + data.sad + data.igst + data.cess + data.add + data.cvd + data.sg).toFixed(2);
        return params;
    };

    const bcdValueGetter = (params) => {
        params = formingParamsData(params);
        return (params.data.bcd).toFixed(2);
    };

    const acdValueGetter = (params) => {
        params = formingParamsData(params);
        return (params.data.acd).toFixed(2);
    };

    const swsValueGetter = (params) => {
        params = formingParamsData(params);
        return (params.data.sws).toFixed(2);
    };

    const sadValueGetter = (params) => {
        params = formingParamsData(params);
        return (params.data.sad).toFixed(2);
    };

    const igstValueGetter = (params) => {
        params = formingParamsData(params);
        return (params.data.igst).toFixed(2);
    };

    /*const onUpdateSomeValues = useCallback(() => {
        const rowCount = gridRef?.current?.api?.getDisplayedRowCount();
        for (let i = 0; i < 10; i++) {
            const row = Math.floor(Math.random() * rowCount);
            const rowNode = gridRef?.current?.api?.getDisplayedRowAtIndex(row);
            rowNode.setDataValue('d', Math.floor(Math.random() * 10000));
            rowNode.setDataValue('e', Math.floor(Math.random() * 10000));
        }
    }, []);*/

    const settingTotals = useCallback(() => {
        let totalBCD = 0;
        let totalACD = 0;
        let totalSAD = 0;
        let totalSWS = 0;
        let totalIGST = 0;
        let totalCess = 0;
        let totalAdd = 0;
        let totalCvd = 0;
        let totalSg = 0;
        let totalAssessValue = 0;
        let totalDuty = 0;
        let totalTValue = 0;
        const rowCount = gridRef?.current?.api?.getDisplayedRowCount();
        for (let i = 0; i < rowCount; i++) {
            const rowData = gridRef?.current?.api?.getDisplayedRowAtIndex(i);
            const item = rowData.data;
            item.bcd = item.bcd === undefined ? 0 : item.bcd;
            item.acd = item.acd === undefined ? 0 : item.acd;
            item.sws = item.sws === undefined ? 0 : item.sws;
            item.igst = item.igst === undefined ? 0 : item.igst;
            item.tDuty = item.tDuty === undefined ? 0 : item.tDuty;
            item.cess = item.cess === undefined ? 0 : item.cess;
            item.itcHsCode = item.itcHsCode === undefined ? 0 : item.itcHsCode;
            item.add = item.add === undefined ? 0 : item.add;
            item.cvd = item.cvd === undefined ? 0 : item.cvd;
            item.sg = item.sg === undefined ? 0 : item.sg;
            item.assess = item.assess === undefined ? 0 : item.assess;
            item.tValue = item.tValue === undefined ? 0 : item.tValue;

            totalBCD = totalBCD + parseFloat(item.bcd);
            totalACD = totalACD + parseFloat(item.acd);
            totalSWS = totalSWS + parseFloat(item.sws);
            totalSAD = totalSAD + parseFloat(item.sad);
            totalIGST = totalIGST + parseFloat(item.igst);
            totalAssessValue = totalAssessValue + parseFloat(item.assess);
            totalCess = totalCess + parseFloat(item.cess);
            totalAdd = totalAdd + parseFloat(item.add);
            totalCvd = totalCvd + parseFloat(item.cvd);
            totalSg = totalSg + parseFloat(item.sg);
            totalDuty = totalDuty + parseFloat(item.tDuty);
            totalTValue = totalTValue + parseFloat(item.tValue);
        };
        let int = parseFloat((document.querySelector('#int').value));
        let pnlty = parseFloat(document.querySelector('#pnlty').value);
        let fine = parseFloat(document.querySelector('#fine').value);
        let nccd = parseFloat(document.querySelector('#nccd').value);
        totalDuty = totalDuty + nccd;
        window.setTimeout(() => {
            setbcd(parseFloat(totalBCD).toFixed(2));
            setcess(parseFloat(totalCess).toFixed(2));
            setsad(parseFloat(totalSAD).toFixed(2));
            setsg(parseFloat(totalSg).toFixed(2));
            setadd(parseFloat(totalAdd).toFixed(2));
            setcvd(parseFloat(totalCvd).toFixed(2));
            setassessed(parseFloat(totalAssessValue).toFixed(2));
            setacd(parseFloat(totalACD).toFixed(2));
            setsws(parseFloat(totalSWS).toFixed(2));
            setigst(parseFloat(totalIGST).toFixed(2));
            settotalDuty(parseFloat(totalDuty + nccd).toFixed(2));
            setTValue(parseFloat(totalTValue).toFixed(2));
            setTotalDutyAmount(parseFloat(totalDuty + int + pnlty + fine).toFixed(2));
        });
    }, []);

    const numberValueParser = (params) => {
        return params.newValue.replace(/[^0-9.]/g, '');
    };

    const [columnDef, setColumnDef] = useState([
        { headerName: '#', field: "itemId", sortable: true, filter: true, width: 40, editable: true },
        {
            headerName: 'Part #', field: "part", readOnly: true,
            sortable: true, filter: true, editable: false, width: 180,
        },
        {
            headerName: 'SL #', field: "sl", readOnly: true,
            sortable: true, filter: true, editable: false, width: 180
        },
        {
            headerName: 'Desc', field: "desc", readOnly: true,
            sortable: true, filter: true, editable: false, width: 150
        },
        {
            headerName: 'HS Code/CTH', field: "hsCode", readOnly: true,
            sortable: true, filter: true, editable: true, width: 100,
            valueParser: numberValueParser,
        },
        {
            headerName: 'U Price', field: "uPrice", readOnly: true,
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'Qty', field: "qty", readOnly: true,
            sortable: true, filter: true, width: 60, editable: false
        },
        {
            headerName: 'Amount', field: "amount", readOnly: true,
            sortable: true, filter: true, editable: false, width: 100
        },
        {
            headerName: 'Assess Value', field: "assess",
            sortable: true, filter: true, editable: true, width: 100,
            valueParser: numberValueParser,
        },
        {
            headerName: 'BCD%', field: "bcdPercent",
            sortable: true, filter: true, width: 100, editable: true, valueParser: numberValueParser,
        },
        {
            headerName: 'BCD', field: "bcd",
            sortable: true, filter: true, width: 100, editable: false, valueGetter: bcdValueGetter,
        },
        {
            headerName: 'ACD%', field: "acdPercent",
            sortable: true, filter: true, editable: true, width: 100, valueParser: numberValueParser,
        },
        {
            headerName: 'ACD', field: "acd",
            sortable: true, filter: true, width: 100, editable: false, valueGetter: acdValueGetter,
        },
        {
            headerName: 'SWS', field: "sws",
            sortable: true, filter: true, width: 100, editable: false, valueGetter: swsValueGetter,
        },
        {
            headerName: 'SAD', field: "sad",
            sortable: true, filter: true, editable: false, width: 100, valueGetter: sadValueGetter,
        },
        {
            headerName: 'IGST%', field: "igstPercent",
            sortable: true, filter: true, width: 100, editable: true, valueParser: numberValueParser,
        },
        {
            headerName: 'IGST', field: "igst",
            sortable: true, filter: true, width: 100, editable: false, valueGetter: igstValueGetter,
        },
        {
            headerName: 'CESS', field: "cess",
            sortable: true, filter: true, editable: true, width: 100, valueParser: numberValueParser,
        },
        {
            headerName: 'ADD', field: "add",
            sortable: true, filter: true, editable: true, width: 100, valueParser: numberValueParser,
        },
        {
            headerName: 'CVD', field: "cvd",
            sortable: true, filter: true, width: 100, editable: true, valueParser: numberValueParser,
        },
        {
            headerName: 'SG', field: "sg",
            sortable: true, filter: true, width: 100, editable: true, valueParser: numberValueParser,
        },
        {
            headerName: 'T Value', field: "tValue",
            sortable: true, filter: true, width: 100, editable: true, valueParser: numberValueParser,
        },
        {
            headerName: 'Total Duty', field: "tDuty",
            sortable: true, filter: true, editable: false, width: 100
        }
    ]);

    // clients fetch
    const fetchClients = async () => {
        try {
            const requestURL = globalState.endPointURL + "/client/all";
            const headersUserName = window.localStorage.getItem("headers-username");
            globalState.headers.userName = headersUserName;
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response.json();
            //console.log(responseData);
            if (responseData != null) {
                responseData.clientInfo?.sort((a, b) => a.name > b.name ? 1 : -1).map((client) => { return client; });
                let data = responseData.clientInfo?.filter((client) => { return client.isActive === true })
               // console.log(data);
                setClientList(data);
                clientData = data;
            } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handlePagination = (event) => {
        setpaginationPageSize(event);
        gridRef.current.api.paginationSetPageSize(event);
        //onGridReady();
        //gridRef.current.api.setQuickFilter();
    };

    // const handleClick = event => {
    //     fileInput.current.click();
    // };

    // search
    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    const onGridReady = async () => {
        const fetchPTIRecord = async () => {
            try {
                setpaginationPageSize("10")
                const requestURL = globalState.endPointURL + "/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData != null && responseData.isSuccess) {
                    const boeList = [];
                    responseData.ptiStatus.map((pti) => {
                        pti.ptiRecords.map((ptiRecord) => {
                            globalState.ptiStatus?.filter((item) => {
                                if (item.name === String(ptiRecord.ptiStatus))
                                    return ptiRecord.ptiStatusName = item.value;
                            });

                            globalState.exporter?.filter((item) => {
                                if (item.name === String(ptiRecord.exporter.exporterId))
                                    return ptiRecord.exporter.exporterName = item.value;
                            });
                            clientData?.filter((item) => {
                                if (item.id === ptiRecord.importer.importerId)
                                    return ptiRecord.importer.importerName = item.name;
                            });
                            globalState.incotermId?.filter((item) => {
                                if (item.name === String(ptiRecord.shipment.incoterm))
                                    return ptiRecord.shipment.incotermName = item.value;
                            });
                            globalState.currency?.filter((item) => {
                                if (item.name === String(ptiRecord.shipment.currency))
                                    return ptiRecord.shipment.currencyName = item.value;
                            });
                            let invTotal = 0;
                            let itemId = 1;
                            ptiRecord.shipmentItem?.map((item) => {
                                const itemStatus = {
                                    "itemId": itemId,
                                    "shipmentId": item.id,
                                    "part": item.item.partNumber,
                                    "sl": item.item.serialNumber,
                                    "desc": item.item.description,
                                    "hsCode" : item.codes.itcHsCode,
                                    "uPrice": item.itemDetails.unitPrice,
                                    "qty": item.itemDetails.quantity,
                                    "amount": item.itemDetails.amount,
                                    "acd": 0,
                                    "acdPercent": 0,
                                    "add": 0,
                                    "assess": 0,
                                    "bcd": 0,
                                    "bcdPercent": 10,
                                    "cess": 0,
                                    "cvd": 0,
                                    "igst": 0,
                                    "igstPercent": 18,
                                    "sad": 0,
                                    "sg": 0,
                                    "sws": 0,
                                    "tValue": 0,
                                    "tDuty": 0,
                                    "id": 0
                                };
                                invTotal = invTotal + Math.round(itemStatus.amount);
                                itemId = itemId + 1;
                                boeList.push(itemStatus);
                            });

                            const boeDetails = {
                                "invoice": ptiRecord.invoiceNumber,
                                "invDate": ptiRecord.invDate,
                                "invoiceItem": ptiRecord?.shipmentItem?.length,
                                "invoiceAmount": Math.round(invTotal),
                                "currency": ptiRecord.shipment.currencyName,
                                "grossWt": ptiRecord.shipment.grossWeight,
                                "importerName": ptiRecord.importer.importerName,
                                "awbBlNumber": ptiRecord.shipment.awbBlNumber
                            };
                            setAwb(ptiRecord.shipment.awbBlNumber);
                            setBoeInfo(boeDetails);
                        });
                    });
                    fetchPTIBoe(boeList);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                // console.log(e);
            }
        };
        const fetchPTIBoe = async (boeList) => {
            try {
                const requestURL = globalState.endPointURL + "/pti/boe/pti/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                if (responseData != null) {
                    const data = responseData?.boeDetails;
                    setExchange(data?.boeDetails?.exchangeRate);
                    setOoc(data?.boeDetails?.ooc);
                    setBeDate(data?.beDetails?.beDate);
                    setbeNo(data?.beDetails?.beNumber);
                    setCha(data?.beDetails?.cha);
                    setportCode(data?.beDetails?.portCode);
                    setAwb(data?.beDetails?.awb);
                    setUrl(data?.documentUrl);
                    setbcd(data?.dutySummary?.bcd);
                    setcess(data?.dutySummary?.cess);
                    setnccd(data?.dutySummary?.nccd);
                    setsad(data?.dutySummary?.sad);
                    setsg(data?.dutySummary?.sg);
                    setadd(data?.dutySummary?.add);
                    setcvd(data?.dutySummary?.cvd);
                    setassessed(data?.dutySummary?.assessedValue);
                    setacd(data?.dutySummary?.acd);
                    setsws(data?.dutySummary?.sws);
                    setigst(data?.dutySummary?.igst);
                    settotalDuty(data?.dutySummary?.totalDuty === undefined ? 0 : data?.dutySummary?.totalDuty);
                    setfine(data?.dutySummary?.fine === undefined ? 0 : data?.dutySummary?.fine);
                    setint(data?.dutySummary?.interest === undefined ? 0 : data?.dutySummary?.interest);
                    setpnlty(data?.dutySummary?.penalty === undefined ? 0 : data?.dutySummary?.penalty);
                    setTotalDutyAmount(data?.dutySummary?.totalDutyAmount === undefined ? 0 : data?.dutySummary?.totalDutyAmount);
                    setBoeId(data?.id);
                    boeList?.map((boeDetails) => {
                        console.log(responseData?.boeDetails?.tails)
                        responseData?.boeDetails?.itemAccessDetails?.map((item) => {
                            if (boeDetails.shipmentId === item.shipmentId) {
                                boeDetails.acd = item.acd;
                                boeDetails.acdPercent = item.acdPercent;
                                boeDetails.add = item.add;
                                boeDetails.assess = item.assessValue;
                                boeDetails.bcd = item.bcd;
                                boeDetails.bcdPercent = item.bcdPercent;
                                boeDetails.cess = item.cess;
                                boeDetails.cvd = item.cvd;
                                boeDetails.igst = item.igst;
                                boeDetails.igstPercent = item.igstPercent;
                                boeDetails.sad = item.sad;
                                boeDetails.sg = item.sg;
                                boeDetails.sws = item.sws;
                                boeDetails.tValue = item.tValue;
                                boeDetails.tDuty = item.totalDuty;
                                boeDetails.shipmentId = item.shipmentId;
                                boeDetails.id = item.id;
                            }
                        });
                    });
                    //setBoe(boeList);
                    //setGlobalBEO(boeList);
                } else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);

                setBoeData(boeList);
                setGlobalBEO(boeList);
            } catch (e) {
                setErrorMessage(globalState.commonErrorMessage);
                setBoeData(boeList);
                setGlobalBEO(boeList);
            }
        };
        fetchPTIRecord();
    };

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/shipments/PTIRecords/");
        });
    };

    const onSubmit = (data) => {
        // setUploadDisable(true);
        // let newArr = fileInput.current.files;
        // if (newArr.length > 0) {
        //     for (let i = 0; i < newArr.length; i++) {
        //         handleUpload(newArr[i]);
        //     }
        // } else {
        const createState = formingRequest("");
        handleSave(createState);
        // }
    };

    const handleSave = async (data) => {
        try {
            const result = await confirm(globalState.savePopup.message, globalState.savePopup.options);
            if (result) {
                const requestURL = globalState.endPointURL + "/pti/boe";
                let methodType = "";
                // console.log(boeId);
                if (boeId === undefined || boeId === '')
                    methodType = "POST";
                else {
                    methodType = "PUT";
                    data.id = boeId;
                }
               // console.log(JSON.stringify(data));
                let response = await fetch(requestURL, {
                    method: methodType,
                    body: JSON.stringify(data),
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                // console.log(responseData);
                if (responseData?.isSuccess) {
                    handleClose();
                } else if (responseData?.isSuccess === null && responseData?.errorCodes?.errorCodes?.length > 0) {
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                } else {
                    setErrorMessage(globalState.commonErrorMessage);
                }
            }
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            //  console.log(e); 
        } finally {
            setUploadDisable(false);
        }
    };

    const handleTotalDuty = (val) => {
        val = val === '' ? 0 : val;
        setnccd(nccd === undefined ? 0 : nccd);
        setnccd(parseFloat(val));

        let totalDuty = 0;
        const rowCount = gridRef?.current?.api?.getDisplayedRowCount();
        for (let i = 0; i < rowCount; i++) {
            const rowData = gridRef?.current?.api?.getDisplayedRowAtIndex(i);
            const item = rowData.data;
            item.bcd = item.bcd === undefined ? 0 : item.bcd;
            item.acd = item.acd === undefined ? 0 : item.acd;
            item.sws = item.sws === undefined ? 0 : item.sws;
            item.igst = item.igst === undefined ? 0 : item.igst;
            item.tDuty = item.tDuty === undefined ? 0 : item.tDuty;
            item.cess = item.cess === undefined ? 0 : item.cess;
            item.add = item.add === undefined ? 0 : item.add;
            item.cvd = item.cvd === undefined ? 0 : item.cvd;
            item.sg = item.sg === undefined ? 0 : item.sg;
            item.assess = item.assess === undefined ? 0 : item.assess;
            item.tValue = item.tValue === undefined ? 0 : item.tValue;

            totalDuty = totalDuty + parseFloat(item.tDuty);
        };
        let total = (parseFloat(totalDuty) + parseFloat(val)).toFixed(2);
        settotalDuty(total);
        setTotalDutyAmount(parseFloat(parseFloat(total) + parseFloat(pnlty) + parseFloat(int) + parseFloat(fine)).toFixed(2));
    };

    const handleTotalDutyAmount = (val, text) => {

        val = val === '' ? 0 : val;
        settotalDuty(totalDuty === undefined ? 0 : totalDuty);
        setpnlty(pnlty === undefined ? 0 : pnlty);
        setint(int === undefined ? 0 : int);
        setfine(fine === undefined ? 0 : fine);
        if (text === "int") {
            setint(parseFloat(val));
            setTotalDutyAmount(parseFloat(parseFloat(totalDuty) + parseFloat(pnlty) + parseFloat(val) + parseFloat(fine)).toFixed(2));
        } else if (text === "pntly") {
            setpnlty(parseFloat(val));
            setTotalDutyAmount(parseFloat(parseFloat(totalDuty) + parseFloat(int) + parseFloat(val) + parseFloat(fine)).toFixed(2));
        } else if (text === "fine") {
            setfine(parseFloat(val));
            setTotalDutyAmount(parseFloat(parseFloat(totalDuty) + parseFloat(int) + parseFloat(val) + parseFloat(pnlty)).toFixed(2));
        }
    };

    const formingRequest = (url) => {
        const itemAccessDetails = [];
        boeData?.map((item) => {
           // console.log(item);
            item.itemId = item.itemId === undefined ? 0 : item.itemId;
            item.bcd = item.bcd === undefined ? 0 : item.bcd;
            item.acd = item.acd === undefined ? 0 : item.acd;
            item.sws = item.sws === undefined ? 0 : item.sws;
            item.igst = item.igst === undefined ? 0 : item.igst;
            item.tDuty = item.tDuty === undefined ? 0 : item.tDuty;
            item.cess = item.cess === undefined ? 0 : item.cess;
            item.add = item.add === undefined ? 0 : item.add;
            item.cvd = item.cvd === undefined ? 0 : item.cvd;
            item.sg = item.sg === undefined ? 0 : item.sg;
            item.assess = item.assess === undefined ? 0 : item.assess;
            item.bcdPercent = item.bcdPercent === undefined ? 10 : item.bcdPercent;
            item.acdPercent = item.acdPercent === undefined ? 0 : item.acdPercent;
            item.igstPercent = item.igstPercent === undefined ? 18 : item.igstPercent;
            item.tValue = item.tValue === undefined ? 0 : item.tValue;
            item.sad = item.sad === undefined ? 0 : item.sad;
            item.hsCode = item.hsCode === undefined ? '' : item.hsCode;

            const itemAccess = {
                "seqNumber": item.itemId,
                "acd": parseFloat(item.acd).toFixed(2),
                "acdPercent": item.acdPercent,
                "add": item.add,
                "assessValue": item.assess,
                "bcd": parseFloat(item.bcd).toFixed(2),
                "bcdPercent": item.bcdPercent,
                "cess": item.cess,
                "cvd": item.cvd,
                "igst": item.igst,
                "igstPercent": item.igstPercent,
                "sad": parseFloat(item.sad).toFixed(2),
                "sg": item.sg,
                "sws": parseFloat(item.sws).toFixed(2),
                "tValue": item.tValue,
                "totalDuty": parseFloat(item.tDuty).toFixed(2),
                "shipmentId": item.shipmentId,
                "id": item.id,
                "itcHsCode": item.hsCode,
            }
            itemAccessDetails.push(itemAccess);
        });
        const createState = {
            "beDetails": {
                "beDate": beDate,
                "beNumber": beNo,
                "cha": cha,
                "portCode": portcode,
                "awb": awb
            },
            "boeDetails": {
                "exchangeRate": exchange,
                "ooc": ooc
            },
            "documentUrl": url,
            "dutySummary": {
                "bcd": bcd,
                "acd": acd,
                "sws": sws,
                "nccd": nccd,
                "sad": sad,
                "sg": sg,
                "add": add,
                "cvd": cvd,
                "igst": igst,
                "cess": cess,
                "assessedValue": assessed,
                "totalDuty": parseFloat(totalDuty).toFixed(2),
                "fine": parseFloat(fine).toFixed(2),
                "interest": parseFloat(int).toFixed(2),
                "penalty": parseFloat(pnlty).toFixed(2),
                "totalDutyAmount": parseFloat(totalDutyAmount).toFixed(2),
            },
            "itemAccessDetails": itemAccessDetails,
            "ptiId": id
        };
        return createState;
    };

    const onCellEditingStarted = useCallback((event) => {
        //console.log('cellEditingStarted');
    }, []);

    const onCellEditingStopped = useCallback((event) => {
        //console.log('cellEditingStopped');
        settingTotals();
    }, []);

    const handleUpload = async (file) => {
        // let newFileName = id + "/" + boeInfo.invoice + "/BOE/" + file?.name;
        // const ReactS3Client = new S3(globalState.s3BuscketConfig);
        // ReactS3Client.uploadFile(file, newFileName).then(data => {
        // console.log(data.location);
        // const createState = formingRequest(data.location);
        //     // handleSave(createState);
        // }).catch(err => console.error(err));
    };

    const handleUrl = (val) => {
        setUrlErrorMessage("");
        if (fileInput.current.files[0].type === "application/pdf") {
            setUrl(val);
        } else {
            setUrlErrorMessage("Please upload pdf file");
            setUrl("");
        }
    };

    const domLayout = "autoHeight";

    return <form>
        <div className="dashboard" id="dashboard">
            <div className="table-heading">
                <h4 className="w-50">BOE Details</h4>
            </div>
            {errorMessage && <p className="text-warning"> {errorMessage} </p>}

            {/* <!- Duty summary--> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">BE Details</div>
                        <div className="row">
                            <div className="col-lg-6 col-sm-12">
                                <div className="p-2">
                                    {/* <!-- Invoice # --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label> Invoice # </label>
                                        <div className="input-box">
                                            <input type="text" readOnly={true} value={boeInfo.invoice} className="form-control" />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Date --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Date</label>
                                        <div className="date-box date-box-pti">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invDate} />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Item --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Items</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invoiceItem} />
                                        </div>
                                    </div>
                                    {/* <!-- Invoice Amount --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Invoice Amount $</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.invoiceAmount} />
                                        </div>
                                    </div>
                                    {/* <!-- currency --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Currency</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.currency} />
                                        </div>
                                    </div>
                                    {/* <!-- Exchange --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Exchange (INR)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={exchange} onChange={e => setExchange(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <!-- currency --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>OOC</label>
                                        <div className="input-box">
                                            <input type="date" className="form-control" value={ooc} onChange={e => setOoc(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6 col-sm-12">
                                <div className="p-2">
                                    {/* Port Code */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Port Code</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={portcode} onChange={e => setportCode(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* BE No. */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BE No.</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={beNo} onChange={e => setbeNo(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* BE Date */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BE Date</label>
                                        <div className="date-box date-box-pti">
                                            <input type="date" className="form-control" value={beDate} onChange={e => setBeDate(e.target.value)} placeholder="dd/mm/yyyy" onFocus={(e) => { e.currentTarget.type = "date"; e.currentTarget.focus() }} />
                                        </div>
                                    </div>
                                    {/* Gross Wt (KG) */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Gross Wt (KG)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.grossWt} />
                                        </div>
                                    </div>
                                    {/* Importer */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Importer</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={boeInfo.importerName} />
                                        </div>
                                    </div>
                                    {/* CHA */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>CHA</label>
                                        <div className="select-box">
                                            <select value={cha} onChange={e => setCha(e.target.value)}>
                                                <option value="select">Select</option>
                                                <option value="cha1">CHA 1</option>
                                                <option value="cha2">CHA 2</option>
                                                <option value="cha3">CHA 3</option>
                                                <option value="cha4">CHA 4</option>
                                                <option value="cha5">CHA 5</option>

                                            </select>
                                        </div>
                                    </div>
                                    {/* AWB */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>AWB</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={awb} onChange={e => setAwb(e.target.value)} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                </div>
            </div>

            {/* <!- Duty summary--> */}
            <div className="row m-0 p-0">
                <div className="col-lg-12 col-sm-12 p-0 m-0">
                    <div className="single-pti-box">
                        <div className="single-pti-box-header">Duty Summary</div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="p-2">
                                    {/* <!--  BCD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>BCD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={bcd} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!--  ACD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>ACD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={acd} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!--  SWS --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>SWS</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sws} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- NCCD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>NCCD</label>
                                        <div className="input-box">
                                            <input type="text" id="nccd" className="form-control" value={nccd}
                                                onChange={e => handleTotalDuty(e.target.value)} />
                                        </div>
                                    </div>
                                    {/* <!--  ADD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>ADD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={add} />
                                        </div>
                                    </div>
                                    {/* <!--  CVD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>CVD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" readOnly={true} value={cvd} />
                                        </div>
                                    </div>
                                    {/* <!-- IGST --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>IGST</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={igst} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!--  CESS --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>CESS</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={cess} readOnly={true} />
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="p-2">
                                    {/* <!--  SAD --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>SAD</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sad} readOnly={true} />
                                        </div>
                                    </div>

                                    {/* <!--  SG --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>SG</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={sg} readOnly={true} />
                                        </div>
                                    </div>

                                    {/* <!--  Assessed Value --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Assessed Value</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={assessed} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- Total Duty --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Total Duty (A)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={totalDuty} readOnly={true} />
                                        </div>
                                    </div>
                                    {/* <!-- Int --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>INT (B)</label>
                                        <div className="input-box">
                                            <input type="text" id="int" className="form-control" value={int}
                                                onChange={e => handleTotalDutyAmount(e.target.value, "int")} />
                                        </div>
                                    </div>
                                    {/* <!-- PNLTY --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>PNLTY (C)</label>
                                        <div className="input-box">
                                            <input type="text" id="pnlty" className="form-control" value={pnlty}
                                                onChange={e => handleTotalDutyAmount(e.target.value, "pntly")} />
                                        </div>
                                    </div>
                                    {/* <!-- Fine --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>FINE (D)</label>
                                        <div className="input-box">
                                            <input type="text" id="fine" className="form-control" value={fine}
                                                onChange={e => handleTotalDutyAmount(e.target.value, "fine")} />
                                        </div>
                                    </div>
                                    {/* <!-- Total duty amount --> */}
                                    <div className="single-pti-box-group  form-group width-full">
                                        <label>Total Duty Amount (E) <br /> (E = A+B+C+D)</label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" value={totalDutyAmount} readOnly={true} />
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        {/* <!-- end shipment details --> */}
                    </div>
                </div>
            </div>
        </div>

        {/* item assess */}
        <div className="dashboard mt-2">
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="w-50">Item Assess Details</h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                        <div className="sorting-num mr-2">
                            Page Size:&nbsp;
                            <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                    </div>
                </div>
            </div>
            <div className="ag-theme-alpine" id="boe-table" >
                <AgGridReact
                    ref={gridRef}
                    rowData={boeData}
                    columnDefs={columnDef}
                    domLayout={domLayout}
                    defaultColDef={{
                        //editable: true,
                        sortable: true,
                        //flex: 1,
                        //minWidth: 100,
                        filter: true,
                        resizable: true,
                    }}
                    pagination={true}
                    onGridReady={onGridReady}
                    onCellEditingStarted={onCellEditingStarted}
                    onCellEditingStopped={onCellEditingStopped}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
            </div>
            <br></br><br></br>
            {/* <!-- buttons row --> */}
            <div className="row">
                {/* <div className="col-lg-6">
                    <div className="btn-footer">
                        <button type="button" onClick={handleClick} className="btn btn-primary" data-bs-toggle="tooltip"
                            data-bs-placement="top" title="Upload BOE">Upload BOE <i className="fa fa-download" aria-hidden="true"></i></button>
                        <input
                            type="file"
                            className="form-control" ref={fileInput} name="url"
                            onChange={e => { handleUrl(e.target.value); }}
                            style={{ display: 'none' }}
                        />
                        <span> {url?.replaceAll('\\', '/').split('/').pop()}</span> */}
                {/*<input type="hidden" className="form-control" ref={fileInput} name="url" placeholder="" value={url}
                            onChange={e => { handleUrl(e.target.value); }} />*/}
                {/* </div>
                    {urlErrorMessage && <p className="text-warning"> {urlErrorMessage} </p>}
                </div> */}
                <div className="col-lg-12 text-right" >
                    <div className="btnooter text-right">
                        <button type="button" disabled={uploadDisable} onClick={onSubmit} className="btn btn-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Save">Save <i className="fa fa-check-circle" aria-hidden="true"></i></button>
                        <button type="button" onClick={handleClose} className="btn btn-secondary ml-2" data-bs-toggle="tooltip" data-bs-placement="top" title="Cancel">Cancel <i className="fa fa-times-circle" aria-hidden="true"></i></button>
                    </div>
                </div>
            </div>
            {/* <!-- end buttons row --> */}

        </div>
    </form>
};

export default BOE;