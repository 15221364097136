import React, { useState, useRef, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

const ItemPartsView = () => {
    const gridRef = useRef();
    const [items, setItems] = useState([]);
    const [certificates, setCertificates] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const { state } = useLocation();
    const { id } = useParams();
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
   

    const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text);
    };

    useEffect(() => {
        const fetchItemParts = async () => {
            try {
                const requestURL = globalState.endPointURL + "/item/id/" + id;
                const response = await fetch(requestURL, {
                    method: 'GET',
                    headers: globalState.headers,
                });
                const responseData = await response.json();
                //console.log(responseData);
                if (responseData !== null) {
                    globalState.itemParts = responseData?.itemInfo[0];
                    setItems(responseData?.itemInfo[0]);
                    responseData?.itemInfo[0].itemCertificate?.map((item) => {                        
                        item.certificate.expiryDate = item.certificate.expiryDate === "null" || item.certificate.expiryDate === undefined ? "" : item.certificate.expiryDate;                        
                    });
                    setCertificates(responseData?.itemInfo[0].itemCertificate);
                }                    
                else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                    setErrorMessage(responseData.errorCodes.errorCodes[0].message);
                else
                    setErrorMessage(globalState.commonErrorMessage);
            } catch (e) {
                    setErrorMessage(globalState.commonErrorMessage);
              //  console.log(e);
            }
        };       
        if (id !== undefined) {
            fetchItemParts();         
        }
        globalState.itemPartRedirect = "View";
    }, []);

    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/masters/item-parts");
        });
    };

    const handleSerializedPart = () => {
        login().then(() => {
            navigate(state?.path || "/masters/item-parts/item-parts-serialized-parts");
        });
    };    

    //View Certificate
    const viewCertificate = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/item-parts/item-parts-certificate-view/" + id);
        });
    };

    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewCertificate(event.data.certificate.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
            </p>
        );
    };

    const columnDef = [
        { headerName: '#', field: "certificate.id", sortable: true, filter: true },
        { headerName: 'Certificate / Licence Type', field: "certificate.certificateTypeId", sortable: true, filter: true },
        { headerName: 'Certificate Name', field: "certificate.name", sortable: true, filter: true },
        { headerName: 'Expiry Date', field: "certificate.expiryDate", sortable: true, filter: true },
        {headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

    return <section className="home-section">
        <div className="dashboard">

            <div className="row">
                {errorMessage && <p className="text-warning"> {errorMessage} </p>}
                <div className="col-sm-6">

                <div className="row  mb-2">
                        <div className="table-heading mb-2">
                            <h4 >Item / Parts </h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>Item / Parts Id </h5>
                                <p>{items.itemId}</p>
                            </div>
                        </div>


                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>Client Name
                                </h5>
                                <p>{items.clientName}</p></div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>Part#</h5>
                                <p>{items.part}</p></div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>Description
                                </h5>
                                <p>{items.description}</p></div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" disabled={true} className="custom-control-input" 
                                            checked={items.isSerialized}  />
                                        <label className="custom-control-label" for="customSwitches">Is Serialized</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="col-sm-6">

                       <div className="row">

                        <div className="table-heading mb-2">
                            <h4 >Codes</h4>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>UPC CODE</h5>
                                <p>{items.upcCode}</p>
                                </div>

                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>EAN CODE
                                </h5>
                                <p>{items.eanCode}</p></div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>ECCN CODE
                                </h5>
                                <p>{items.eccnCode}</p></div>
                        </div>


                        <div className="col-lg-12 col-md-12">
                            <div className="detail-view-flex">
                                <h5>ITC HS CODE
                                </h5>
                                <p>{items.itcHsCode}</p></div>
                        </div>

                        <div className="col-lg-12 col-sm-12">
                            <div className="row">
                                <div className="col-lg-6 col-sm-6">
                                    <div className="custom-control custom-switch">
                                        <input type="checkbox" className="custom-control-input" disabled={true }
                                            checked={items.isMultiPack} />
                                        <label className="custom-control-label" for="customSwitches2">Is Multipack</label>
                                    </div>
                                </div>

                                {items.isMultiPack ?
                                    <div className="col-lg-6 col-md-6 qty-flex">
                                        <label>Quantity
                                        </label>
                                        <div className="input-box">
                                            <input type="text" className="form-control" name="quantity" readOnly={true} placeholder="" value={items.quantity} />
                                        </div>
                                    </div> : ""}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="dashboard">
                <div className="certificate-item">
                    <div className="example-header row">
                        <div className="col-lg-6 col-sm-12 ">
                        <div className="table-heading">
                            <h4 className="">Certificates</h4>
                        </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                            <div className="search-add">
                            <div className="sorting-num">
                                Page Size:&nbsp;
                                <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            &nbsp;
                                <label className="table-search">
                                    <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                                    <i className="bx bx-search"></i>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="ag-theme-alpine" style={{ height: 300 }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={certificates}
                            columnDefs={columnDef}
                            pagination={true}
                            paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                            cacheQuickFilter={true}
                        />
                    </div>
                </div><br /><br />
               

            </div>
            <div className="text-right"><br />
      <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Go Back to Item Parts">Go Back to Item Parts <i className="fa fa-check-circle" aria-hidden="true"></i> </button>
  </div>
        </div>
     
    </section>
    
};
export default ItemPartsView;
