import React, { useState, useCallback ,useRef } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../GlobalContext";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirm } from "react-confirm-box";

const Incoterms = () => {
    const [incoterms, setIncoterms] = useState([]);
    const navigate = useNavigate();
    const { login } = useAuth();
    const gridRef = useRef();
    const { state } = useLocation();
    const [paginationPageSize, setpaginationPageSize] = useState("");
    const [searchText, setSearchText] = useState("");
    const { globalState } = React.useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = useState("");
   
    
    const actionButton = (event) => {
        return (
            <p>
                <button onClick={() => viewIncoterm(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="View"> <i className="bx bxs-show" aria-hidden="true"></i></button>
                <button onClick={() => editIncoterm(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Edit"> <i className="bx bxs-edit" aria-hidden="true"></i></button>
                <button onClick={() => deleteIncoterm(event.data.id)} type="button" className="btn btn-link btn-sm bg-secondary text-light" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete"><i className="bx bxs-trash" aria-hidden="true"></i></button>
            
            </p>
        );
    };

    const columnDef = [
        { headerName: 'S.no', field: "id", valueGetter: 'node.rowIndex+1', sortable: true, filter: true },
        { headerName: 'Incoterm', field: "name", sortable: true, filter: true },
        { headerName: 'Description', field: "description", sortable: true, filter: true },
        { headerName: 'Is Active', field: "isActive", sortable: true, filter: true },
        { headerName: 'Actions', "cellRenderer": actionButton },
    ];

    const domLayout = "autoHeight";

    const onGridReady = async () => {
        try {
            const requestURL = globalState.endPointURL + "/incoterm/all";
            const response = await fetch(requestURL, {
                method: 'GET',
                headers: globalState.headers,
            });
            const responseData = await response?.json();
            // console.log(responseData);
            if (responseData != null) {
                responseData.incotermInfo?.map((incoterm) => {
                    incoterm.isActive = incoterm.isActive ? "Yes" : "No";                    
                });
                setIncoterms(responseData?.incotermInfo);
            }   
            else if (!responseData?.isSuccess && responseData?.errorCodes?.errorCodes?.length > 0)
                setErrorMessage(responseData.errorCodes.errorCodes[0].message);
            else
                setErrorMessage(globalState.commonErrorMessage);
        } catch (e) {
            setErrorMessage(globalState.commonErrorMessage);
            // console.log(e);
        }
    };

    const handlePagination = (event) => {
        setpaginationPageSize(event);
    };

     // search
     const handleSearchText = (text) => {
        setSearchText(text);
        gridRef.current.api.setQuickFilter(text)
    };


   // Delete with fetchAPI
   const deleteIncoterm = async (id) => {
    const result = await confirm(globalState.deletePopup.message, globalState.deletePopup.options);
    if (result){
        try {             
            const requestURL = globalState.endPointURL + "/incoterm/id/" + id;
            
            const response = await fetch(requestURL, {
                method: 'DELETE',
                headers: globalState.headers,                
            });                   
           
            if (response !== "" && response.status === 200) {
                setIncoterms(incoterms.filter((incoterm) => { return incoterm.id !== id; }));
                return;
            } else if (response !== "" && response.status === 400) {
                setErrorMessage(globalState.commonErrorMessage);
                return;
            } else {
                setErrorMessage(globalState.commonErrorMessage);
            }
        } catch (e) { 
            setErrorMessage(globalState.commonErrorMessage);
        // console.log(e); 
    }
    }
   
};

    //Add Incoterm
    const addIncoterm = async () => {
        login().then(() => {  navigate(state?.path || "/masters/incoterm/incoterm-details");
          
        });
    };

    //View Incoterm
    const viewIncoterm = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/incoterm/incoterm-view/" + id);
        });       
    };


    //Edit Incoterm
    const editIncoterm = (id) => {
        login().then(() => {
            navigate(state?.path || "/masters/incoterm/incoterm-details/" + id);
        });
    };


    const handleClose = () => {
        login().then(() => {
            navigate(state?.path || "/dashboard");
        });
    };

    return <section className="home-section">        
        <div className="dashboard">
            <div className="example-header row">
                <div className="col-lg-6 col-sm-12 ">
                    <div className="table-heading">
                        <h4 className="">Incoterms</h4>
                    </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                    <div className="search-add">
                    <div className="sorting-num mr-2">
                        Page Size:&nbsp;
                        <select value={paginationPageSize} onChange={e => handlePagination(e.target.value)}>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                    <label className="table-search">
                            <input type="text" name="part" placeholder="Search" value={searchText} onChange={e => handleSearchText(e.target.value)} />
                            <i className="bx bx-search"></i>
                        </label>
                        &nbsp;
                        <button className="addbutton" onClick={addIncoterm} data-bs-toggle="tooltip" data-bs-placement="top" title="Add">Add <i className="fa fa-plus-circle" aria-hidden="true"></i> </button>
                    </div>
                </div>
            </div>


            <div className="ag-theme-alpine" style={{ height: 550 }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={incoterms}
                    columnDefs={columnDef}
                    pagination={true}
                    domLayout={domLayout}
                    defaultColDef={{
                        sortable: true,
                        flex: 1,
                        minWidth: 150,
                        filter: true,
                        resizable: true,
                    }}
                    onGridReady={onGridReady}
                    // onFirstDataRendered={onFirstDataRendered}
                    paginationPageSize={paginationPageSize === "" ? 10 : paginationPageSize}
                ></AgGridReact>
        </div> 
        
        <div className="text-right">
            <br /> <button className="addbutton" onClick={handleClose} data-bs-toggle="tooltip" data-bs-placement="top" title="Close">Close <i className="fa fa-times-circle" aria-hidden="true"></i> </button>
        </div>
        </div>
    </section>
};
export default Incoterms;
